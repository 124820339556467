<template>
  <div>
    <table class="table">
      <thead>
        <th>School Year</th>

        <th>Gender</th>

        <th>Attended</th>
      </thead>

      <tbody v-for="(gender, key, index) in genders" :key="index">
        <tr v-for="(yearGroup, index) in gender" :key="index">
          <td>{{ yearGroup.yearGroup }}</td>
          <td>{{ key }}</td>
          <td>{{ yearGroup.sessionsAttended }}</td>
        </tr>
      </tbody>

      <!-- <tr v-for="(value, index) in female" :key="index">
          <td>{{ value.yearGroup }}</td>
          <td>F</td>
          <td>{{ value.sessionsAttended }}</td>
        </tr> -->
    </table>
  </div>
</template>

<script>
export default {
  name: 'AsmoDistinctParticipantsTable',
  props: ['school'],
  data() {
    return {};
  },
  computed: {
    genders() {
      return this.school.participants;
    },

    male() {
      return this.school.participants.male;
    },
    female() {
      return this.school.participants.female;
    },
  },
};
</script>

<style scoped>
table {
  width: 100%;
}

table thead {
  text-align: center;
}

table tbody {
  text-align: center;
}

.toggle {
  text-align: right;
  margin: 15px;
}
</style>
