<template>
  <div>
        <v-row class="mb-4">
            <v-col align="center">
                <v-skeleton-loader
                        class="mb-2 mt-2 resource-loader"

                        :type="headingSkeletonLayout"
                        :loading="_loading"
                        transition-group="scale-transition"
                >
                        <h4 class="display-1">
                            <Dictionary module="billing" string="title" />
                        </h4>
                        <p class="mb-5 body-1">
                            <Dictionary module="billing" string="subtitle" />
                        </p>

                </v-skeleton-loader>
            </v-col>
        </v-row>

        <v-skeleton-loader
                class="resource-loader"
                min-height="226"
                :type="cardSkeletonLayout"
                :loading="_loading"
                transition-group="slide-y-transition"
        >
          <v-list two-line dense>
            <ClientBillingResourceListItem v-for="client in clients" :key="client.clientId" :client="client" />
          </v-list>
        </v-skeleton-loader>

  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import ClientBillingResourceListItem
  from '../../../../../global/components/UILibrary/Cards/Resources/ClientBillingResourceListItem.vue';

export default Vue.extend({
  name: 'ClientBillingPage',
  data() {
    return {
      headingSkeletonLayout: 'heading, sentences',
      cardSkeletonLayout: 'list-item-avatar-two-line@3',
    };
  },
  computed: {
    ...mapGetters(['_loading', 'clients']),
  },
  methods: {

  },
  components: {
    ClientBillingResourceListItem,
  },
  mounted() {},
});
</script>

<style scoped>

    .v-list {
        background: transparent;
    }

</style>
