<template>
    <tr>
        <td class="text-center">
            <i class="fas fa-circle success text-success"></i>
        </td>
        <td>{{fullName}} Booked {{bookingCreated}}</td>
        <td>{{booking.dob}}</td>
    </tr>
</template>

<script>
    import moment from 'moment';
  import { mapGetters } from 'vuex';
  export default {
    name: 'BookingMadeListItem',
    data() {
        return {
          dateFormat: 'DD MMMM hh:mmA'
        }
    },
    props: {
      run: {
        type: Object,
        required: true
      },
      booking: {
        required: true,
        type: Object
      }
    },
    computed: {
      ...mapGetters(['preview']),
      fullName() {
        return `${this.booking.firstName} ${this.booking.lastName}`
      },
      selected: {
        get() {
          return this.$store.getters.selected
        },
        set(value) {
          this.$store.commit('SET_SELECTED', value);
        }
      },
      bookingCreated() {
        return moment.utc(this.booking.created * 1000).format(this.dateFormat);
      },
      bookingWithRun() {
        return {...this.booking, ...{runId:this.run.runId} }
      }
    }
  }
</script>

<style scoped>

</style>