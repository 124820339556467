
const primary = '#09425e';
export default {

  primary, //  '#00334d', // cfk navy,
  'top-bar-background': primary,
  secondary: '#f05b8b', // cfk pink,
  accent: '#03b5da', // cfk vibrant blue
  warning: '#f69170', // cfk orange,
  error: {
    base: '#d44e7a', // darker pink shade '#ff2636',
    lighten5: '#fffbfc',
  },
  success: '#57b9ac', // c4k cyan/green,

  background: {
    base: '#f6f8fb',
    darken1: '#eaedf4'
  }, // light grey


  body: '#121212',
  'form-border': '#c6d1dc',

  info:'#66c5f2', // cfk light blue
  oldcfkorange: '#f69170', // cfk orange,

  disabled: '#fafafa', // greyed out(grey lighten-5),
  UIAction: '#bdbdbd', // greyed out(grey lighten-1)
  cyan: '#66c5f2', // '#72c1ec',
  magenta: '#f05b8b',
  white: '#fff',


};
