'use strict';

export default {
	state: {
		bookings: {
			0: {
				className: '',
				venueName: '',
				termName: '',
				runId: '',
				campId: '',
				bookingId: '',
				childId: '',
				parentId: '',
				useRecurringPayments: '',
				bookingInstances: {},
				instanceGroups: {
					instanceGroupName: '',
					bookingInstances: {}
				},
			}
		},
	},
	actions: {

    async GET_ALL_BOOKINGS_FOR_PERSON(context, {personId, localLoading, limit}) {
      const request = {
        url: `/api/v4/booking/${personId}`,
				parameters: {
					limit
				}

      };
      const mutation = 'SET_ALL_BOOKINGS';
      const options = {
        request,
        mutation,
				localLoading
      };

      return await context.dispatch('MAKE_GET_REQUEST', options);
    },

	},
	mutations: {
		SET_ALL_BOOKINGS(state, payload) {
			state.bookings = payload;
		}
	},
	getters: {
		bookings(state) {
			return state.bookings;
		}
	}

};
