<template>
  <a href="/" class="logo-link">
    <v-img
           :src="src"
           max-width="200"
           max-height="75"
           position="center"
           contain
    ></v-img>
    </a>
</template>

<script>
export default {
  name: 'Logo',
  computed: {
    src() {
      return this.$store.getters.darkMode ? '/vue/dev/app/global/assets/images/logo-reverse.png' : '/vue/dev/app/global/assets/images/logo.png';
    },
  },

};
</script>

<style scoped>
.logo-link{ display: block;}
</style>
