<template>
  <td>
    {{ lastbookingDateFormatted }}
  </td>
</template>

<script>
import { formatLocaleDateFromTimestamp } from './../../../../../../utilities/helpers';
export default {
  name: 'ContactsTableColumnLatestBooking',
  components: {},
  props: {
    lastBookingDate: {
      required: false,
      type: String,
    },
  },
  data() {
    return {};
  },
  computed: {
    lastbookingDateFormatted() {
      return formatLocaleDateFromTimestamp(this.lastBookingDate);
    },
  },
  methods: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  updated() {},
};
</script>

<style scoped></style>
