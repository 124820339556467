import { post, get } from '../../../../../services/http';

/**
 * @returns {Promise<*|undefined>}
 */
export async function getSid() {
  const url = '/rest/v1/public/sms/sid';
  const headers = { 'Content-Type': 'application/json' };

  return get({
    url,
    headers,
  });
}

/**
 * @param sid
 * @param phoneNumber
 * @param countryCode
 * @returns {Promise<any>}
 */
export async function setup(sid, phoneNumber, countryCode) {
  // send number to the backend sendVerificationToken()
  const url = '/rest/v1/public/sms/setup';
  const headers = { 'Content-Type': 'application/json' };
  const body = {
    sid,
    phoneNumber,
    countryCode,
  };

  return post({
    url,
    headers,
    body,
  }, { recaptcha: true });
}

/**
 * @param sid
 * @param phoneNumber
 * @param code
 * @returns {Promise<any>}
 */
export async function sendAuth(sid, phoneNumber, code) {
  const url = '/rest/v1/public/sms/auth';
  const headers = { 'Content-Type': 'application/json' };
  const body = {
    sid,
    phoneNumber,
    code,
  };

  return post({
    url,
    headers,
    body,
  }, { recaptcha: true });
}
