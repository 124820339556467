<template>

    <v-row  v-if="!loading" >
      <v-col cols="12">
        <SubscriptionGuides  />
        <SubscriptionsTablesList/>
      </v-col>
    </v-row>
</template>

<script>

import { mapGetters } from 'vuex';
import SubscriptionsTablesList from '../../components/Subscriptions/ViewSubscriptions/SubscriptionsTablesList';
import SubscriptionGuides from '../../components/Subscriptions/ViewSubscriptions/SubscriptionGuides';

export default {
  name: 'ViewSubscriptionsPage',
  computed: {
    ...mapGetters([]),
    loading() {
      return this.$store.getters.globalLoading;
    },
  },
  data() {
    return {
      helpMessage: {
        quote: 'We have updated our subscription feature to allow pausing and group editing. Please see our guides for further details.',
        image: '/assets-dev/images/team/heather.png',
      },
    };
  },
  components: {
    SubscriptionsTablesList,
    SubscriptionGuides,
  },
  async created() {
    // this.$store.commit('SET_GLOBAL_LOADING', true);
    await this.$store.dispatch('GET_ALL_SUBSCRIPTIONS', { globalLoading: true });
    // this.$store.commit('SET_GLOBAL_LOADING', false);
  },

};
</script>

<style scoped>

</style>
