import CustomRegisterSettingsPage from '../pages/Settings/CustomRegisterSettingsPage';
export default [
  {
    component: CustomRegisterSettingsPage,
    path: '/cms/settings/custom-registers',
    name: 'CustomRegisterSettingsPage',
    meta: {
      title: 'Custom Registers Page',
    },
  },
];
