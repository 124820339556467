<template>
<v-list-item  @click="" :disabled="true">
  <v-dialog v-model="dialog" max-width="480" fullscreen hide-overlay transition="dialog-bottom-transition" pa-2>
    <template v-slot:activator="{ on: cancel }">
        <v-list-item-title v-on="{...cancel}" >Booking Log...</v-list-item-title>
    </template>
    <v-card>

      <v-toolbar dark color="primary">
        <v-toolbar-title class="headline white--text">Booking Log</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn flat @click="dialog = false">Close</v-btn>
        </v-toolbar-items>
      </v-toolbar>

      
      <ParentBookingLog :person-id="subscription.personId" v-if="dialog"/>
      


    </v-card>
  </v-dialog>
</v-list-item>
</template>

<script>

  import { mapGetters } from 'vuex';
  import { formatMoney, formatDate } from '&/helpers.js'

  import ParentBookingLog from './../Bookings/ParentBookingLog';

  export default {
    name: 'ViewBookingLogMenuItem',
    data() {
      return {
        dialog: false

      }
    },
    computed: {
      
    },
    props: {
      subscription : {
        required: true,
        type: Object
      }
    },

    mounted() {

    },

    components: {
      ParentBookingLog
    }
  }
</script>

<style scoped>

</style>