<template>
    <v-list three-line subheader dense>
        <UpcomingC4KSubscriptionPaymentListItem />

        <v-subheader v-if="previousInvoicesWithoutZeroValues.length > 0">History</v-subheader>
        <C4KSubscriptionPaymentsListItem v-for="invoice in previousInvoicesWithoutZeroValues" :invoice="invoice" :key="invoice.id" />
    </v-list>
</template>

<script>

  import { mapGetters } from 'vuex';
  import { formatMoney, formatDate } from '&/helpers.js';
  import C4KSubscriptionPaymentsListItem from './C4KSubscriptionPaymentsListItem';
  import UpcomingC4KSubscriptionPaymentListItem from './UpcomingC4KSubscriptionPaymentListItem';

  export default {
    name: 'C4KSubscriptionPayments',
    data() {
      return  {
      }
    },
    props: {

    },
    computed: {
      ...mapGetters(['_subscription']),
      subscription () {
        return this._subscription
      },
      previousInvoices() {
        return this.subscription.invoices.past;
      },
      previousInvoicesWithoutZeroValues() {
          return Object.values(this.previousInvoices).filter(item => {
              return item.amount_paid > 0 || item.discount !== null;
          })
      }

    },

    methods: {
    },

    components: {
      C4KSubscriptionPaymentsListItem,
      UpcomingC4KSubscriptionPaymentListItem
    },

      mounted() {

      }


  }
</script>

<style scoped>

</style>