<template>

	<!-- <LoadingSpinner size="fa-2x" v-if="loading"/> -->
	<div class="check">
		<transition name="bounce">
			<p v-html="attendanceInput" v-on:click="updateAttendance" v-if="show">
			</p>
		</transition>


		<div style="text-align: center;" v-if="pendingServerUpdate">
			<i class="fas fa-circle pending" data-fa-transform="shrink-8"></i>
		</div>

		<div v-else>
			&nbsp;
		</div>


	</div>

</template>


<script>


// Standard Check Boxes
const bookedNotAttendedCheck = '<a> <i class="fal fa-circle fa-2x" style="color: #ddd" aria-hidden="true"></i> </a>';
const attendedCheck = '<a><i class="fal fa-check-circle fa-2x" style="color: #03A9F3" aria-hidden="true"></i></a>';
const absentCross = '<a><i class="fal fa-times-circle fa-2x" style="color: #FF3B30" aria-hidden="true"></i></a>';


//  Trial Check Boxes
const trialBookedNotAttended = '<a><i class="fal fa-gavel fa-2x" style="color: #ddd" aria-hidden="true"></i></a><p style="font-size: 0.7rem;margin: 0;color: #ddd;"> TRIAL</p>';

const trialAttended = '<a> <i class="fal fa-gavel fa-2x" style="color: #e91e63" aria-hidden="true"></i> </a><p class = "trial-text" style="font-size: 0.7rem;margin: 0;color: #e91e63;" > TRIAL</p>';

const noBooking = '<div></div>';


export default {
  name: 'AttendanceCheckBox',
  data() {
    return {
      bookingData: '',
      loading: false,
      show: true,
    };
  },
  components: {

  },
  props: ['attendanceData', 'runInstanceId'],

  methods: {

    updateAttendance() {
      // if the box is empty, just forget it!
      // a bit dirty and too tightly coupled to markup, change this.
      if (!this.bookingData) {
        return false;
      }

      this.loading = true;
      this.show = false;


      // this is what we're sending to the store;
      const payload = {};

      payload.attendanceData = this.bookingData;
      payload.runId = this.$route.params.id;

      this.$store.dispatch('UPDATE_ATTENDANCE', payload)
        .then((response) => {
          this.loading = false;
          this.show = true;
        });
    },

    setupCheckBoxesWithBookingData() {
      // if there's data, and it hasn't just been cancelled
      this.bookingData = this.attendanceData.bookings.find((booking) => this.runInstanceId == booking.runInstanceId);
    },
  },

  computed: {
    attendanceInput() {
      this.setupCheckBoxesWithBookingData();

      if (this.bookingData) {
        // TRIALS STUFF
        if (this.bookingData.isTrial == '1' && this.bookingData.attended) {
          return trialAttended;
        }
        if (this.bookingData.isTrial == '1' && this.bookingData.bookingInstanceId) {
          return trialBookedNotAttended;
        }

        if (this.bookingData.attended > 0) {
          return attendedCheck;
        }
        if (this.bookingData.bookingInstanceId) {
          return bookedNotAttendedCheck;
        }
        return noBooking;
      }
    },

    pendingServerUpdate() {
      if (this.bookingData) {
        return this.$store.getters.pendingAttendanceRequestbookingInstances
          .includes(this.bookingData.bookingInstanceId);
      }
    },
  },


  mounted() {


  },


};
</script>


<style scoped lang="scss">
@import './../../../assets/scss/variables.scss';


.check {
	display: grid;
	grid-template-columns: 1fr;
	align-items: center;
	transition: all .2s ease-in-out;
}

.check:hover {
  transition: all .2s ease-in-out;
  transform: scale(1.05) perspective(1px)
}


.pending {
	// color: $lightblue;
}

.bounce-enter-active {
  animation: bounce-in .5s;
}
.bounce-leave-active {
  animation: bounce-in .5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}


p {
	text-align: center;
	cursor: pointer;
	margin: 16px 0 0 0;
}

.fa-check-circle i {
	// color: $cfk-cyan !important;
}

.trial-text {
	font-size: 1.3rem;
	margin-bottom: 0;
	color: #fdfdfd;
}

</style>
