import { get } from '../../../../../services/http';

function urlBuilderGetContacts(payload) {
  let string = '/api/contacts/all-contacts';
  if (payload.type === 'run') {
    string += `?runId=${payload.id}`;
    return string;
  }
  if (payload.type === 'camp') {
    string += `?campId=${payload.id}`;
    return string;
  }
  return string;
}

export async function getContacts(payload) {
  const url = urlBuilderGetContacts(payload);
  const headers = { 'Content-Type': 'application/json' };
  return get({
    url,
    headers,
  });
}

export async function getRegistrationQuestions() {
  const url = '/api/registration-questions/all';
  const headers = { 'Content-Type': 'application/json' };
  return get({
    url,
    headers,
  });
}

export async function updateSetupFeePaidStatus(payload) {
  const url = `/cms/contacts/setup-fee/${payload.childId}/${payload.status}`;
  const headers = { 'Content-Type': 'application/json' };
  return get({
    url,
    headers,
  });
}
