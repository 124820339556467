import Vue from 'vue';
import Vuex from 'vuex';

import { get } from '../../../services/http';
import { getSid } from '../pages/SignUp/services/twillio-mobile-verification';

Vue.use(Vuex);

// VUEX Store
export default {
  state: {
    user: {
      email: '',
      isCurrentUser: false,
      isOrganiser: false,
      registered: false,
      valid: false,
    },
    booleans: {
      betaClubMessage: false,
      approved: false,
      sent: false,
    },
    club: {
      clubUrl: '',
      chosenDomain: '',
      completeURL: '',
    },
    subdomain: {
      available: false,
    },
    environment: '',
    sid: '',
    link: '',
    clientId: '',
    errorMessage: '',
    errorMessageCode: '',

    countries: [],
    selectedCountry: '',
    checkingSubdomain: false,
    clubName: '',
    phoneNumber: '',
  },
  actions: {
    /**
     * @param context
     * @param payload
     * @returns {Promise<void>}
     * @constructor
     */
    async CHECK_USERNAME(context, payload) {
      let requestData = {};
      let json;
      try {
        const url = '/api/v4/public/authentication/email-status-check';
        const request = new Request(url, {
          method: 'POST',
          credentials: 'same-origin',
          body: JSON.stringify(
            {
              emailAddress: payload,
            },
          ),
        });

        requestData = await fetch(request);
        json = await requestData.json();

        context.commit('UPDATE_USER', json);
      } catch (error) {
        requestData.status = false;
        requestData.data = error.message;
      }
      return requestData;
    },

    /**
     * @param context
     * @param payload
     * @returns {Promise<void>}
     * @constructor
     */
    async CREATE_NEW_USER(context, payload) {
      await context.commit('UPDATE_GLOBAL_LOADING', {
        mutation: 'create-new-user',
        status: true,
      });
      let requestData = {};
      try {
        const url = '/api/v4/public/signup/new-user';
        const request = new Request(url, {
          method: 'POST',
          credentials: 'same-origin',
          body: JSON.stringify(
            {
              username: payload.userName,
              firstName: payload.firstName,
              lastName: payload.lastName,
              password: payload.password,
            },
          ),
        });

        requestData = await fetch(request);
      } catch (error) {
        requestData.status = false;
        requestData.data = error.message;
      }
      return requestData;
    },

    /**
     * @param context
     * @param payload
     * @returns {Promise<void>}
     * @constructor
     */
    async LOGIN_USER_SIGN_UP(context, payload) {
      let requestData = {};
      try {
        const url = '/api/v4/public/authentication/login-user';
        const request = new Request(url, {
          method: 'POST',
          credentials: 'same-origin',
          body: JSON.stringify(
            {
              username: context.state.user.email,
              password: payload,
              _remember_me: 1,
            },
          ),
        });

        requestData = await fetch(request);
        // const json = await requestData.json();

        return requestData;
      } catch (error) {
        requestData.status = false;
        requestData.data = error.message;
      }
      return requestData;
    },

    /**
     * @param context
     * @param payload
     * @returns {Promise<void>}
     * @constructor
     */
    async CHECK_SUBDOMAIN_AVAILABILITY(context, payload) {
      let requestData = {};
      try {
        const url = '/api/subdomain-status-check';
        const request = new Request(url, {
          method: 'POST',
          credentials: 'same-origin',
          body: JSON.stringify(
            {
              subdomain: payload.subdomain,
              countryCode: payload.countryCode,
            },
          ),
        });

        requestData = await fetch(request);
        const json = await requestData.json();

        context.commit('UPDATE_SUBDOMAIN_STATUS_SIGN_UP', json.available);
      } catch (error) {
        requestData.status = false;
        requestData.data = error.message;
      }
      return requestData;
    },

    /**
     * @param context
     * @param payload
     * @returns {Promise<void>}
     * @constructor
     */
    async BETA_COUNTRY_CLIENT_SIGN_UP(context, payload) {
      let requestData = {};
      try {
        const url = '/api/beta-country-sign-up';
        const request = new Request(url, {
          method: 'POST',
          credentials: 'same-origin',
          body: JSON.stringify(
            {
              subdomain: payload.subdomain,
              countryCode: payload.countryCode,
              name: payload.name,
              message: payload.message,
              emoji: payload.emoji,
            },
          ),
        });

        requestData = await fetch(request);
        return requestData;
      } catch (error) {
        requestData.status = false;
        requestData.data = error.message;
      }
      return requestData;
    },

    /**
     * @param context
     * @param payload
     * @returns {Promise<void>}
     * @constructor
     */
    async CREATE_NEW_CLIENT_RECORD(context, payload) {
      let requestData = {};
      try {
        const url = '/api/new-client';
        const request = new Request(url, {
          method: 'POST',
          credentials: 'same-origin',
          body: JSON.stringify(
            {
              subdomain: payload.subdomain,
              countryCode: payload.countryCode,
              name: payload.name,
              message: payload.message,
              emoji: payload.emoji,
            },
          ),
        });

        requestData = await fetch(request);
        const json = await requestData.json();

        context.commit('SET_LOGIN_LINK_SIGN_UP', json.link);
        context.commit('SET_CLIENT_ID_SIGN_UP', json.clientId);

        return requestData;
      } catch (error) {
        requestData.status = false;
        requestData.data = error.message;
      }
      return requestData;
    },

    /**
     * @param context
     * @returns {Promise<void>}
     * @constructor
     */
    async GET_SID(context) {
      await context.commit('UPDATE_GLOBAL_LOADING', {
        mutation: 'getSid',
        status: true,
      });
      this.approved = false;
      this.sent = true;

      const response = await getSid();
      context.commit('UPDATE_SID_SIGN_UP', response.data.sid);
      await context.commit('UPDATE_GLOBAL_LOADING', {
        mutation: 'getSid',
        status: false,
      });
      return response;
    },

    /**
     * @param context
     * @returns {Promise<void>}
     * @constructor
     */
    async GET_COUNTRIES(context) {
      await context.commit('UPDATE_GLOBAL_LOADING', {
        mutation: 'countries',
        status: true,
      });
      const url = '/rest/v1/public/sms/countries';
      const headers = { 'Content-Type': 'application/json' };
      const response = await get({
        url,
        headers,
      });
      context.commit('UPDATE_COUNTRIES_SIGN_UP', response.data.countries);
      context.commit('UPDATE_SELECTED_COUNTRY_SIGN_UP', 'GB');
      await context.commit('UPDATE_GLOBAL_LOADING', {
        mutation: 'countries',
        status: false,
      });
      context.commit('UPDATE_SENT_SIGN_UP', false);
      return response;
    },

    /**
     * @param context
     * @returns {Promise<*>}
     * @constructor
     */
    async GET_USER_NAME(context) {
      await context.commit('UPDATE_GLOBAL_LOADING', {
        mutation: 'userName',
        status: true,
      });

      const url = '/rest/v1/public/sms/user-name';
      const headers = { 'Content-Type': 'application/json' };
      const response = await get({
        url,
        headers,
      });
      if (response.code === 200) {
        context.commit('UPDATE_EMAIL_SIGN_UP', response.data.user);
      } else {
        window.location.href = 'https://bookings.class4kids.co.uk';
      }
      await context.commit('UPDATE_GLOBAL_LOADING', {
        mutation: 'userName',
        status: false,
      });
      return response;
    },

    /**
     * @param context
     * @returns {Promise<*>}
     * @constructor
     */
    async GET_ENVIRONMENT(context) {
      await context.commit('UPDATE_GLOBAL_LOADING', {
        mutation: 'environment',
        status: true,
      });

      const url = '/rest/v1/public/sms/environment';
      const headers = { 'Content-Type': 'application/json' };
      const response = await get({
        url,
        headers,
      });
      context.commit('UPDATE_ENVIRONMENT_SIGN_UP', response.data);
      await context.commit('UPDATE_GLOBAL_LOADING', {
        mutation: 'environment',
        status: false,
      });
      return response;
    },
  },
  mutations: {
    UPDATE_USER(state, payload) {
      state.user = payload;
    },

    UPDATE_EMAIL_SIGN_UP(state, payload) {
      state.user.email = payload;
    },

    UPDATE_SUBDOMAIN_STATUS_SIGN_UP(state, payload) {
      state.subdomain.available = payload;
    },

    SET_LOGIN_LINK_SIGN_UP(state, payload) {
      state.link = payload;
    },

    SET_CLIENT_ID_SIGN_UP(state, payload) {
      state.clientId = payload;
    },
    UPDATE_BETA_CLUB_MESSAGE_SIGN_UP(state, payload) {
      state.booleans.betaClubMessage = payload;
    },
    UPDATE_APPROVED_SIGN_UP(state, payload) {
      state.booleans.approved = payload;
    },
    UPDATE_SENT_SIGN_UP(state, payload) {
      state.booleans.sent = payload;
    },
    UPDATE_SID_SIGN_UP(state, payload) {
      state.sid = payload;
    },
    UPDATE_ENVIRONMENT_SIGN_UP(state, payload) {
      state.environment = payload;
    },
    UPDATE_PHONE_NUMBER_SIGN_UP(state, payload) {
      state.phoneNumber = payload;
    },
    UPDATE_NAME_SIGN_UP(state, payload) {
      state.clubName = payload;
    },
    UPDATE_COUNTRIES_SIGN_UP(state, payload) {
      state.countries = payload;
    },
    UPDATE_SELECTED_COUNTRY_SIGN_UP(state, payload) {
      state.selectedCountry = payload;
    },
    UPDATE_ERROR_MESSAGE_SIGN_UP(state, payload) {
      state.errorMessage = payload;
    },
    UPDATE_CHECKING_SUB_DOMAIN_SIGN_UP(state, payload) {
      state.checkingSubdomain = payload;
    },
    UPDATE_CLUB_URL_SIGN_UP(state, payload) {
      state.club.clubUrl = payload;
    },
    UPDATE_CHOSEN_DOMAIN_SIGN_UP(state, payload) {
      state.club.chosenDomain = payload;
    },
    UPDATE_COMPLETE_URL_SIGN_UP(state, payload) {
      state.club.completeURL = payload;
    },
    RESET_STATE_SIGN_UP(state, payload) {
      Object.keys(state.booleans)
        .forEach((boolean) => {
          state.booleans[boolean] = payload;
        });
    },
  },

  getters: {
    userSignUp(state) {
      return state.user;
    },
    userNameSignUp(state) {
      return state.user.email;
    },
    clubNameSignUp(state) {
      return state.clubName;
    },
    phoneNumberSignUp(state) {
      return state.phoneNumber;
    },
    isNewUserSignUp(state) {
      return state.user.email.length > 0 && !state.user.registered;
    },
    isExistingUserSignUp(state) {
      return state.user.email.length > 0 && state.user.registered;
    },
    subdomainSignUp(state) {
      return state.subdomain;
    },
    loginLinkSignUp(state) {
      return state.link;
    },
    clientIdSignUp(state) {
      return state.clientId;
    },
    booleansSignUp(state) {
      return state.booleans;
    },
    clubSignUp(state) {
      return state.club;
    },
    sidSignUp(state) {
      return state.sid;
    },
    environmentSignUp(state) {
      return state.environment;
    },
    countriesSignUp(state) {
      return state.countries;
    },
    selectedCountrySignUp(state) {
      return state.selectedCountry;
    },
    errorMessageSignUp(state) {
      return state.errorMessage;
    },
    checkingSubdomainSignUp(state) {
      return state.checkingSubdomain;
    },
  },
};
