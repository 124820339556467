export default {
  en: {
    title: 'Billing',
    subtitle: 'Click below to manage your subscription with ClassForKids',
    subscriptionOutstandingText: 'Your account is currently outstanding',
    subscriptionActiveText: ' per month',
    subscriptionNotSetUpText: 'Please set up your subscription',
    subscriptionNotSetUpButtonText: 'Set up Now',
    manageButtonTextOutstanding: 'Update Now',
    manageButtonTextActive: 'Update',
    clientOutstandingInvoiceAlertMessage: 'You have a failed payment, please update your payment method and pay any outstanding balances',


  },

  fr: {
    title: 'S\'identifier',
    subtitle: 'Bienvenue a ClassForKids, c\'est la bonne la monde',
  },
};
