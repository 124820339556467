import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import fr from 'vuetify/src/locale/fr';
import light from '../assets/themes/light';
import dark from '../assets/themes/dark';


Vue.use(Vuetify);
export default new Vuetify({
  icons: {
    iconfont: 'fa',
  },
  lang: {
    locales: { fr, },
    current: 'en',
  },
  customProperties: true,
  dark: false,
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light,
      dark,
    },
  },
});
