<template>
    <v-row>
        <v-col cols="12">
            <v-row justify="center" align="center"
                   align-content="center">
                <v-col cols="12" align-content="center">
                    <v-card-title class="text-center">
                        <h3 class="primary--text text-center">
                            <span class="no-wrap">
                              <Dictionary module="betaCountrySignUpMessage" string="titleLineOne"/>
                            </span>
                            <span class="no-wrap">
                              <Dictionary module="betaCountrySignUpMessage" string="titleLineTwo"/>
                            </span>
                        </h3>
                    </v-card-title>
                </v-col>
            </v-row>

            <v-row dense justify="center" align="center"
                   align-content="center">
                <v-col cols="12" align-self="center">
                    <CfkGreenTickSuccess :size="75"/>
                </v-col>
            </v-row>

            <v-row justify="center" align="center" align-content="center">
                <v-col cols="12">
                    <v-card-subtitle class="text-center larger">
                      <Dictionary module="betaCountrySignUpMessage" string="subtitle"/>
                    </v-card-subtitle>
                </v-col>
                <v-col cols="12">
                    <Button @click="goToMarketingSite()" class="primary">
                      <Dictionary module="betaCountrySignUpMessage" string="button"/>
                    </Button>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import CfkGreenTickSuccess from '../../components/SignUp/CfkGreenTickSuccess.vue';

export default Vue.extend({
  name: 'BetaCountrySignUpMessagePage',
  components: {
    CfkGreenTickSuccess,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters([
      'userSignUp',
      'globalLoading',
      'clientIdSignUp',
      'loginLinkSignUp',
      'subdomainSignUp',
      'booleansSignUp',
      'clubSignUp',
      'codeSignUp',
      'sidSignUp',
      'environmentSignUp',
      'countriesSignUp',
      'selectedCountrySignUp',
    ]),
  },
  methods: {
    goToMarketingSite() {
      window.location.href = 'https://classforkids.io/clubs';
    },
  },
  async mounted() {
    if (this.booleansSignUp.betaClubMessage === false) {
      await this.$router.push({ name: 'CreateClientPage' });
    }
    this.$store.commit('RESET_STATE_SIGN_UP', false);
  },
});
</script>

<style scoped>
    .v-btn:not(.v-btn--round).v-size--default {
        min-width: 100% !important;
    }
    .v-card__title {
        flex-direction: column !important;
    }

    .no-wrap {
        white-space: nowrap;
    }

    .row + .row--dense {
        text-align: center;
    }
</style>
