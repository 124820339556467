<template>
  <div class="text-center">
    <v-dialog
        v-model="dialog"
        width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2 text-center px-7">
          Sending messages
        </v-card-title>

        <v-card-text class="pa-9">
            Please select at least one
            contact to send a message <br>

            We cannot allow you to message
            all your contacts, please either
            select choose a class or camp
            register to message all contacts
            in a class or use our Booking
            Status filter
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'ContactsMessageModal',
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['messageModalDialogContacts']),
    dialog: {
      get() {
        return this.messageModalDialogContacts;
      },
      set(value) {
        this.$store.commit('SET_MESSAGE_MODAL_DIALOG_CONTACTS', value);
      },
    },
  },
  methods: {},
  beforeCreate() {
  },
  created() {
  },
  beforeMount() {
  },
  mounted() {
  },
  updated() {
  },
});
</script>

<style scoped>

</style>
