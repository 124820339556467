<template>
            <v-menu offset-y>
              <template #activator="{ on: menu }">
                <v-btn text icon v-on="{...menu}" :disabled="loading">
                    <v-icon>fal fa-ellipsis-h</v-icon>
                </v-btn>
              </template>
              <v-list>

                <ManageSubscriptionMenuItem :subscription="subscription" />

              </v-list>
            </v-menu>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { formatMoney, formatDate, getCurrencySymbol } from '&/helpers.js'
  import ManageSubscriptionMenuItem from './ManageSubscriptionMenuItem';
  import CancelSubscriptionMenuItem from './CancelSubscriptionMenuItem';
  import ViewBookingLogMenuItem from './ViewBookingLogMenuItem';
  import ViewCoveringPaymentsMenuItem from './ViewCoveringPaymentsMenuItem';



  export default {
    name: 'SubscriptionListItemActionsMenu',
    data() {
      return {

      }
    },
    computed: {
        ...mapGetters(['loading']),
    },
    props: {
      subscription : {
        required: true,
        type: Object
      }
    },
    components:{
      ManageSubscriptionMenuItem,
      CancelSubscriptionMenuItem,
      ViewBookingLogMenuItem,
      ViewCoveringPaymentsMenuItem
    }
  }
</script>

<style scoped>

</style>