<template>
	<div class = "text-center">	
		<img class="circle" :src="imageUrl" v-if="image">

		<div class="circle" v-bind:class="[classes ? classes[randomNumber] : '']" v-else>
			<h3 class = "circle-letters">{{letters}}</h3>
		</div>
	</div>
</template>


<script>

	export default {
		name: 'Avatar',
		data: function () {
			return {
			}
		},
		props: ['firstName', 'lastName', 'personId', 'image', 'number'],

		computed: {
			childName: function() {
				return this.firstName + ' ' + this.lastName;
			},
			letters: function() {
				if(this.firstName && this.lastName) {
					return this.firstName.substring(0, 1).toUpperCase() + this.lastName.substring(0, 1).toUpperCase();
				}

			},
			classes: function() {
				var colours = ['green', 'grey', 'blue', 'pink', 'dark_blue', 'light_blue', 'cream', 'green_blue', 'purple', 'peach'];
				return colours
			},
			randomNumber: function() {

				if(this.number) {
					return Math.floor(this.number * this.classes.length);
				} else {
					return Math.floor(Math.random() * this.classes.length);	
				}
				
			},
			imageUrl() {
				return `https://s3-eu-west-1.amazonaws.com/store.class4kids.co.uk/sample-pics/${this.personId}.jpg`
			}

		},
		components: {

		},
		methods: {

		},

		created: function() {

		}
	}
</script>



<style scoped>



.circle {
	width: 75px;
	height: 75px;
	display: grid;
	grid-template-columns: 1fr;
	align-items: center;
	border-radius: 50%;
	margin: 0 auto;
	/*background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%);*/
}


.circle-letters {
	font-size: 1.4rem;
	text-align: center;
	/*line-height: 50px;*/
	color: #fff;
}

.green {
	background-image: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
}

.grey {
	background-image: linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%);
}

.blue {
	background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%);
}

.pink {
	background-image: linear-gradient(120deg, #f093fb 0%, #f5576c 100%);
}

.dark_blue {
	background-image: linear-gradient(to top, #30cfd0 0%, #330867 100%);

}

.light_blue {
	background-image: radial-gradient(circle 248px at center, #16d9e3 0%, #30c7ec 47%, #46aef7 100%);
}

.cream {
	background-image: linear-gradient(135deg, #fdfcfb 0%, #e2d1c3 100%);
}

.green_blue {
	background-image: linear-gradient(180deg, #2af598 0%, #009efd 100%);
}

.purple {
	background-image: linear-gradient(to top, #c471f5 0%, #fa71cd 100%);
}

.peach {
	background-image: linear-gradient(to right, #f78ca0 0%, #f9748f 19%, #fd868c 60%, #fe9a8b 100%);
}
	
</style>








