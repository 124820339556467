<template>

  <v-expansion-panels>
    <RunListItem v-for="run in runs" :key="run.runId" :run="run"  />
  </v-expansion-panels>

</template>

<script>
import { mapGetters } from 'vuex';
import RunListItem from './RunListItem';

export default {
  name: 'RunsList',
  computed: {
    ...mapGetters(['runs', 'selected']),
  },
  components: {
    RunListItem,
  },
};
</script>

<style scoped>

</style>
