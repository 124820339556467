export function setCookie(name, value, timestamp, domain = null) {
  let expires = '';
  if (timestamp) {
    const date = new Date(timestamp * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  const domainString = domain !== null ? `domain=${domain}` : '';
  document.cookie = `${name}=${value || ''}${expires}; path=/; ${domainString}`;
}

export function getCookie(name) {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}
