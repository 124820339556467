export default {
  en: {
    title: 'Sign in',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque aut blanditiis consequatur doloremque exercitationem harum, in ipsum libero numquam optio quisquam reiciendis vel vitae. Harum, iure, obcaecati. Ab explicabo, qui.',

    login_button_prompt: 'Sign in',
    change_username_prompt: 'Change',
    not_your_username_prompt: 'Not your username?',
    login_remember_me_text: 'Stay signed in for 1 week',
    reset_password_error_prompt: 'or reset your password',
  },

  fr: {
    title: 'S\'identifier',
    subtitle: 'Bienvenue a ClassForKids, c\'est la bonne la monde',
  },
};
