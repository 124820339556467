<template>
  <div>
    <v-card class="mb-6 pa-5" v-if="showGenericMessage">
      <h4 class="primary--text pb-3">
        <Dictionary module="noUserResourcesGenericMessage" string="title"></Dictionary>
      </h4>
      <p class="primary--text">
        <Dictionary module="noUserResourcesGenericMessage" string="subtitle"></Dictionary>
      </p>
    </v-card>

    <div v-else>
      <v-card class="mb-6 pa-5">
        <h4 class="primary--text pb-3">
          <Dictionary module="noUserResourcesParentMessage" string="title"></Dictionary>
        </h4>
        <p class="primary--text">
          <Dictionary module="noUserResourcesParentMessage" string="subtitle"></Dictionary>
        </p>
      </v-card>

      <v-card class="mb-6 pa-5">
        <h4 class="primary--text pb-3">
          <Dictionary module="noUserResourcesCoachMessage" string="title"></Dictionary>
        </h4>
        <p class="primary--text">
          <Dictionary module="noUserResourcesCoachMessage" string="subtitle"></Dictionary>
        </p>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NoUserResourcesListItem',
  data() {
    return {
      showGenericMessage: true
    }
  }
};

</script>

<style scoped>

</style>
