<template>
  <div id="runContainer">
    <LoadingSpinner v-if="loading" size="fa-5x" />

    <div v-else>
      <div v-if="run.bookings.length > 0">
        <div id="runTopBar">
          <div id="info">
            <div class="infoDetails">
              <div class="infoIcon"><i class="fal fa-graduation-cap"></i></div>
              <div>
                <h5 class="className">{{ settings.className }}</h5>
              </div>
            </div>

            <div class="infoDetails venueName">
              <div class="infoIcon">
                <i class="fal fa-map-marker"></i>
              </div>
              <div>
                <h5>{{ settings.venueName }}</h5>
              </div>
            </div>

            <div class="infoDetails time">
              <div class="infoIcon">
                <i class="fal fa-clock"></i>
              </div>

              <div v-html="times" class="runTime">

              </div>

              <div class="infoIcon">
                <i class="fal fa-calendar-alt"></i>
              </div>

              <div>
                <h5>{{ settings.termName }}</h5>
              </div>


              <div class="infoIcon">
                <i class="fal fa-wifi-slash"></i>
              </div>

              <div>
                 <SaveRunOffline :runId="id" size="fa-sm" :verbose="true" />
              </div>
            </div>

            <div class="offline">
              <div>
                <a
                  href="#"
                  @click.prevent="openShare"
                  v-if="canShare"
                  style="text-align: right;display: inherit;color: #35485d;"
                  ><i class="fal fa-share-alt fa-lg"></i
                ></a>
              </div>
            </div>
          </div>

          <div id="controls">
            <QuickTakeAttendance />
          </div>
        </div>

        <!-- <h4><i class="fal fa-hand-point-up"></i> Click a day to start taking attendance</h4> -->
        <Attendance />
      </div>

      <div v-else class="no-booking-message">
        <h2 style="text-align: center">No Bookings</h2>
      </div>
    </div>
  </div>
</template>

<script>

import { format, closestIndexTo } from 'date-fns';
import moment from 'moment';
import Attendance from '../../components/OfflineRegisters/Schedule/Attendance';
import QuickTakeAttendance from '../../components/OfflineRegisters/Schedule/QuickTakeAttendance';
import SaveRunOffline from '../../components/OfflineRegisters/Schedule/SaveRunOffline';
import LoadingSpinner from '../../components/Global/LoadingSpinner.vue';

export default {
  name: 'Run',
  data() {
    return {
      loading: true,
    };
  },

  computed: {
    id() {
      return this.$route.params.id;
    },
    run() {
      return this.$store.getters.run;
    },
    settings() {
      return this.run.settings;
    },
    runInstances() {
      return this.run.runInstances;
    },

    day() {
      return format(this.run.runInstances[0].startDateTime * 1000, 'dddd');
    },

    times() {
    let output = '';
    Object.values(this.run.sessions).forEach((session, index) => {
      output += `

      <h5>${session.day}</h5>
      <h5>${session.startTime}</h5>
            <div>
              <i class="fal fa-arrow-right"></i>
            </div>
            <h5>
              ${session.endTime}
            </h5>

            `;
    });
    return output;
	},


    firstInstance() {
      const { 0: first } = this.runInstances;
      return first;
    },

    lastInstance() {
      const { [this.runInstances.length - 1]: last } = this.runInstances;
      return last;
    },

    startTime() {
      // const firstInstance = this.runInstances.shift();
      return moment.utc(this.firstInstance.startDateTime * 1000).format('HH:mmA');
    },

    endTime() {
      // const lastInstance = this.runInstances.pop();
      return moment.utc(this.lastInstance.endDateTime * 1000).format('HH:mmA');
    },

    cached() {
      const { cachedRuns } = this.$store.getters;
      return cachedRuns.includes(this.id);
    },
    canShare() {
      return false;
      // return navigator.share;
    },
    openShare() {
      const subdomain = 'demoot';

      const url = `https://${subdomain}.classforkids.io/info/${this.id}`;

      navigator
        .share({
          title: 'Share this Class',
          text:
            'Use any of your installed apps to let people know about this class',
          url,
        })
        .then(() => console.log('Successfully shared'))
        .catch((error) => console.log('Error sharing:', error));
    },
  },

  methods: {
    findClosestRunInstanceToToday() {
      const today = parseInt(format(new Date(), 'X'));
      const arrayOfRunInstanceDates = this.$store.getters
        .allRunInstanceStartTimes;
      const date = closestIndexTo(today, arrayOfRunInstanceDates);
      return date;
    },
  },

  components: {
    Attendance,
    QuickTakeAttendance,
    SaveRunOffline,
    LoadingSpinner,
  },

  mounted() {
    this.$store.dispatch('RESET_CURRENT_INDEX');
    const runId = this.id;
    this.$store
      .dispatch('GET_RUN', runId)
      .then((response) => {
        this.$store.dispatch('HANDLE_RESIZING');

        const date = this.findClosestRunInstanceToToday();

        this.$store.dispatch('SET_CLOSEST_INDEX', date);
        this.loading = false;
      })
      .catch((error) => {
        console.error('Error: ', error);
      });
  },
  created() {

  },
};
</script>

<style scoped lang="scss">
@import '../../assets/scss/variables';

.no-booking-message {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  text-align: center;
  height: 100vh;
}
#runTopBar {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-flow: row;
  // grid-auto-columns: 1fr;
  // align-items: center;
  // border: 2px solid $primary;
  border-radius: 4px;
  // padding: 24px;
  margin-bottom: 16px;
}

#info {
  // display: grid;
  // grid-template-columns: 1fr;
  // grid-gap: 8px;
  padding: 8px;
}

#controls {
  padding: 16px;
}

.infoDetails {
  display: grid;
  grid-template-columns: 1fr 7fr;
}

#info h2,
h3,
h4,
h5 {
  margin: 0;
}

.time {
  // margin-top: 16px;
}

.infoIcon {
  align-items: center;
  text-align: center;
}

.runTime {
	display: grid;
	grid-template-columns: 2fr 1fr 48px 1fr;
	column-gap: 8px;
	align-items: center;
	text-align: left;
  max-width: 45%;

	h5 {
		margin: 8px;
	}
}

.className {
  font-weight: 600;
  // color: $cfk-primary;
}

.venueName {
  color: #b3b6c2;
  // font-size: 0.9rem;
}

.offline {
  margin-top: 32px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}

@media (min-width: 415px) {
  #runTopBar {
    grid-template-columns: 2fr 1fr;
  }
}

@media (min-width: 768px) {
  #runContainer {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    background-color: #fff;
    padding: 16px;
  }
}
</style>
