'use strict';

export default {
	state: {
		subscription: {
			default_source: {
				last4: '',
				exp_month: '',
				exp_year: '',
				brand: ''
			}
		},
		invoices: {
			upcoming: {
				lines: {
					data: {}
				},
				period_end: ''
			},
			past: {}
		},

		invoiceDetail: {
			lines: {
				data: {}
			}
		},
		checkout: {
			session: {
				id: ''
			},
			commission: 0.0,
			stripeFeePercentage: 0.0,
			stripeFeeFixed: 0.0,
			plan: {
				monthlyCost: ''
			},
			currencySymbolHex: '',
			trialLength: 0,
			hasSubscription: false,
			stripePublicKey: ''
		},
		 placeholderLogoSrc: '/favicon-512x512.png',
		 logoUrls: []
	},
	actions: {
		async GET_CLIENT_LOGOS_URL(context, payload) {
			const request = {
				url: '/api/v4/client/logo-urls',
				parameters: payload
			};
			const mutation = 'UPDATE_CLIENT_LOGOS_URL';
			const options = {
				request,
				mutation
			};
			return await context.dispatch('MAKE_GET_REQUEST', options);
		},
		async GET_CLIENT_INVOICES_FROM_STRIPE({ dispatch }, payload) {
			const request = {
				url: '/api/v4/client/stripe/invoices'
			};
			const mutation = 'SET_STRIPE_INVOICES';
			const options = {
				request,
				mutation
			};

			await dispatch('MAKE_POST_REQUEST', options);
		},

		async GET_CLIENT_INVOICE_DETAIL_FROM_STRIPE({ dispatch }, payload) {
			const invoiceId = payload;
			const request = {
				url: '/api/v4/client/stripe/invoices/detail',
				parameters: {
					invoiceId
				}
			};
			const mutation = 'SET_STRIPE_INVOICE_DETAIL';
			const options = {
				request,
				mutation
			};

			await dispatch('MAKE_POST_REQUEST', options);
		},

		async GET_STRIPE_SUBSCRIPTION_DETAILS({ dispatch }, payload) {
			const request = {
				url: '/api/v4/client/get-subscription-details'
			};
			const mutation = 'SET_STRIPE_SUBSCRIPTION_VARIABLES';
			const options = {
				request,
				mutation
			};

			await dispatch('MAKE_POST_REQUEST', options);
		}
	},

	mutations: {
		UPDATE_CLIENT_LOGOS_URL(state, payload) {
			state.logoUrls = payload;
		},
		SET_STRIPE_INVOICES(state, payload) {
			state.subscription = payload.subscription;
			state.invoices = payload.invoices;
		},
		SET_STRIPE_INVOICE_DETAIL(state, payload) {
			state.subscription = payload.subscription;
			state.invoiceDetail = payload.invoice;
		},
		SET_STRIPE_SUBSCRIPTION_VARIABLES(state, payload) {
			state.checkout = payload;
		}
	},
	getters: {
		subscription(state) {
			return state.subscription;
		},
		invoices(state) {
			return state.invoices;
		},
		upcomingInvoice(state) {
			return state.invoices.upcoming;
		},
		pastInvoices(state) {
			return state.invoices.past;
		},
		invoiceDetail(state) {
			return state.invoiceDetail;
		},
		checkout(state) {
			return state.checkout;
		},
		placeholderLogoSrc(state) {
			return state.placeholderLogoSrc;
		},
		logoUrls(state) {
			return state.logoUrls;
		}
	}
};
