<template>
    <v-container>
    <v-row>

        <v-col cols="12" text-right>
            <v-btn small text @click="filters.show = !filters.show" color="info">
                Filters
                <v-icon small>fas fa-filter fa-xs</v-icon>
            </v-btn>
        </v-col>
    </v-row>

    <v-row>


        <v-col cols="12" v-if="filters.show" id="filters">

            <v-list subheader dense>
                <v-subheader>Filters</v-subheader>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>
                            Only Subscription Bookings
                        </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action text-xs-right>
                        <v-switch
                                v-model="showOnlySubscriptionBookings"
                        >
                        </v-switch>
                    </v-list-item-action>
                </v-list-item>


                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>
                            Only Current Bookings
                        </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action text-xs-right>
                        <v-switch
                                v-model="showOnlyCurrentBookings"
                        >
                        </v-switch>
                    </v-list-item-action>
                </v-list-item>
            </v-list>
        </v-col>
    </v-row>
    <v-skeleton-loader
            :loading="loading"
            type="list-item-avatar-three-line@6">
        <v-row>
            <v-col cols="12">
                <v-list subheader three-line>
                    <v-subheader>Booking Log</v-subheader>
                    <ParentBookingLogListItem
                            v-for="booking in subscriptionBookings"
                                              :booking="booking"
                                              :key="booking.bookingId"/>

                    <v-list-item :href="viewContactRoute">
                        <v-list-item-content>
                            <v-list-item-subtitle>View All</v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action>
                            <v-btn text icon color="UIAction">
                                <v-icon> fas fa-chevron-right</v-icon>
                            </v-btn>
                        </v-list-item-action>

                    </v-list-item>

                </v-list>
            </v-col>
        </v-row>
    </v-skeleton-loader>
</v-container>
</template>

<script>

import { mapGetters } from 'vuex';
import moment from 'moment';
import ParentBookingLogListItem from './ParentBookingLogListItem';

export default {
  name: 'ParentBookingLog',
  data() {
    return {
      filters: {
        show: false,
      },
      loading: false,
      showOnlySubscriptionBookings: true,
      showOnlyCurrentBookings: true,
      bookingLimit: 25,
      items: [25, 50, 100],
    };
  },
  props: {
    personId: {
      required: true,
      type: String,
    },
    limitToSubscriptions: {
      required: false,
      type: Boolean,
    },
  },


  computed: {
    ...mapGetters(['bookings']),
    viewContactRoute() {
      return `/cms/contacts/view/${this.personId}`;
    },
    subscriptionBookings() {
      return Object.values(this.bookings)
        .filter(this.subscriptionBookingsFilter)
        .filter(this.currentBookingsFilter);
    },
  },
  methods: {
    async getBookingsForParent() {
      this.loading = true;
      const payload = {
        personId: this.personId,
        limit: this.bookingLimit,
      };
      await this.$store.dispatch('GET_ALL_BOOKINGS_FOR_PERSON', payload);
      this.loading = false;
    },
    subscriptionBookingsFilter(booking) {
      return this.showOnlySubscriptionBookings ? parseInt(booking.useRecurringPayments) === 1 : true;
    },
    currentBookingsFilter(booking) {
      const bookingInstances = booking.runId ? Object.values(booking.bookingInstances) : Object.values(booking.instanceGroups);
      const now = moment().unix();
      return this.showOnlyCurrentBookings ? bookingInstances.find((bookingInstance) => parseInt(bookingInstance.startDateTime) >= now) : true;
    },

  },
  created() {
    this.showOnlySubscriptionBookings = this.limitToSubscriptions;
    this.getBookingsForParent();
  },

  beforeDestroy() {
    this.$store.commit('SET_ALL_BOOKINGS', {});
  },
  components: {
    ParentBookingLogListItem,
  },

};
</script>


<style scoped>
    #filters {
        background-color: #f9f9f9;
    }

</style>
