export default {
  en: {
    continue: 'Continue',
    back: 'Back',
    resend: 'Resend',
    login: 'Login',
    next: 'Next',
    IAccept: 'I Accept',
    letsGo: 'Let\'s Go!',
    'terms-and-conditions-acceptance': 'I accept the general',
    'terms-and-conditions-name': 'terms and conditions',
    loginPrompt: 'Already have an account?',
    loginPromptButton: 'Log In',
  },
};
