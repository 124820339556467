import Vue from 'vue';

export default {
  state: {
    globalLoading: {},
    loading: false,
    verified: false,
    lazyLoader: {
      continue: true
    }
  },
  actions: {
    async MAKE_GET_REQUEST(context, payload) {
      // await context.commit('UPDATE_LOADING', {
      //   mutation: payload.mutation,
      //   status: true,
      //   localLoading : payload.localLoading
      // });

      if (payload.globalLoading) {
        await context.commit('UPDATE_GLOBAL_LOADING', {
          mutation: payload.mutation,
          status: payload.globalLoading,
        });
      }

      const request = new Request(payload.request.url, {
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json'
        },
        method: 'GET'
      });

      const response = await fetch(request);

      try {
        if (!response.ok || response.status != 200) {
          console.error('error is: ', response.statusText);

          if (response.status == 401) {
            return (window.location.href = '/login');
          }

          return context.commit('SET_ERROR_NOTIFICATION', response);
        } else {
          context.commit('UPDATE_VERIFIED', response.ok);

          if (payload.message) {
            context.commit('SET_SUCCESS_NOTIFICATION', payload);
          }

          const json = await response.json();

          if (payload.mutation) {
            await context.commit(payload.mutation, json.data);
          }
        }
      } catch (error) {
        //commit some error update
        context.commit('SET_ERROR_NOTIFICATION', error);
        console.error('error is: ', error);
      }

      // set globalLoading to false;
      // return context.commit('UPDATE_LOADING', {
      //   mutation: payload.mutation,
      //   status: false,
      //   localLoading: payload.localLoading,
      // });

      await context.commit('UPDATE_GLOBAL_LOADING', {
        mutation: payload.mutation,
        status: false
      });
    },

    async MAKE_LAZY_LOAD_GET_REQUEST(context, payload) {
      if (payload.globalLoading) {
        await context.commit('UPDATE_GLOBAL_LOADING', {
          mutation: payload.mutation,
          status: payload.globalLoading,
        });
      }

      const request = new Request(payload.request.url, {
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json'
        },
        method: 'GET'
      });

      const response = await fetch(request);

      try {
        if (!response.ok || response.status != 200) {
          console.error('error is: ', response.statusText);
          context.commit('SET_ERROR_NOTIFICATION', response);
        } else {
          context.commit('UPDATE_VERIFIED', response.ok);
          context.commit('SET_SUCCESS_NOTIFICATION', payload);
          const json = await response.json();
          await context.commit(payload.mutation, json.data);
        }
      } catch (error) {
        //commit some error update
        context.commit('SET_ERROR_NOTIFICATION', error);
        console.error('error is: ', error);
      }

      return await context.commit('UPDATE_GLOBAL_LOADING', {
        mutation: payload.mutation,
        status: false,
      });
    },

    async MAKE_POST_REQUEST(context, payload) {
      if (payload.globalLoading) {
        await context.commit('UPDATE_GLOBAL_LOADING', {
          mutation: payload.mutation,
          status: payload.globalLoading,
        });
      }

      const request = new Request(payload.request.url, {
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(payload.request.parameters)
      });

      const response = await fetch(request);

      try {
        if (!response.ok || response.status != 200) {
          console.error('error is: ', response.statusText);

          if (response.status == 401) {
            return (window.location.href = '/login');
          }

          context.commit('SET_ERROR_NOTIFICATION', response);
        } else {
          context.commit('UPDATE_VERIFIED', response.ok);
        }

        const json = await response.json();

        if (json.error) {
          context.commit('SET_ERROR_NOTIFICATION', json.error);
        } else {
          if (payload.mutation) {
            await context.commit(payload.mutation, json.data);
          }

          if (payload.message) {
            context.commit('SET_SUCCESS_NOTIFICATION', payload);
          }
        }
      } catch (error) {
        //commit some error update
        context.commit('SET_ERROR_NOTIFICATION', error);
        console.error('error is: ', error);
      }

      //set globalLoading to false;
      await context.commit('UPDATE_GLOBAL_LOADING', {
        mutation: payload.mutation,
        status: false,
      });

      return response;
    },
    async MAKE_PUT_REQUEST(context, payload) {
      //  debugger;
      if (payload.globalLoading) {
        await context.commit('UPDATE_GLOBAL_LOADING', {
          mutation: payload.mutation,
          status: payload.globalLoading,
        });
      }


      const request = new Request(payload.request.url, {
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json'
        },
        method: 'PUT',
        body: JSON.stringify(payload.request.parameters)
      });

      const response = await fetch(request);

      try {
        if (!response.ok || response.status != 200) {
          console.error('error is: ', response.statusText);

          if (response.status == 401) {
            return (window.location.href = '/login');
          }

          context.commit('SET_ERROR_NOTIFICATION', response);
        } else {
          context.commit('UPDATE_VERIFIED', response.ok);
        }

        const json = await response.json();

        if (json.error) {
          context.commit('SET_ERROR_NOTIFICATION', json.error);
        } else {
          if (payload.mutation) {
            await context.commit(payload.mutation, json.data);
          }

          if (payload.message) {
            context.commit('SET_SUCCESS_NOTIFICATION', payload);
          }
        }
      } catch (error) {
        //commit some error update
        context.commit('SET_ERROR_NOTIFICATION', error);
        console.error('error is: ', error);
      }

      // set globalLoading to false;
      // await context.commit('UPDATE_LOADING', {
      //   mutation: payload.mutation,
      //   status: false
      // });
      await context.commit('UPDATE_GLOBAL_LOADING', {
        mutation: payload.mutation,
        status: false,
      });
      return response;
    }
  },

  mutations: {
    UPDATE_LOADING(state, payload) {
      if (payload.localLoading) {
        state.loading = payload.status;
      } else if (payload.mutation) {
        Vue.set(state.globalLoading, payload.mutation, payload.status);
      }
    },
    UPDATE_GLOBAL_LOADING(state, payload) {
      Vue.set(state.globalLoading, payload.mutation, payload.status);
    },

    UPDATE_VERIFIED(state, payload) {
      state.verified = payload;
    }
  },

  getters: {
    globalLoading(state) {
      return !Object.values(state.globalLoading).every(
        mutation => mutation === false
      );
    },
    loading(state) {
      return state.loading;
    },
    verified(state) {
      return state.verified;
    },
    continueLazyLoading(state) {
      return state.lazyLoader.continue;
    }
  }
};
