<template>
    <div>
        <ManageSubscriptionNavigationDrawer :subscription="subscription" :drawer.sync="drawer" />
<!--        <SubscriptionListItemActionsMenu :subscription="subscription" v-if="$vuetify.breakpoint.smAndDown" />-->
    </div>
</template>

<script>
import SubscriptionListItemActionsMenu from '../../SubscriptionListItemActionsMenu';
import ManageSubscriptionNavigationDrawer from '../../ManageSubscription/ManageSubscriptionNavigationDrawer';

export default {
  name: 'Actions',
  data() {
    return {
      drawer: false,
    };
  },
  props: {
    subscription: {
      required: true,
      type: Object,
    },
  },
  computed: {
  },
  methods: {
  },
  components: {
    ManageSubscriptionNavigationDrawer,
    SubscriptionListItemActionsMenu,
  },
};
</script>
