<template>
  <td>
    <v-row no-gutters dense>
      <v-col cols="12" style="font-weight: 550">
        <a
          :href="`/cms/contacts/view/${childId}`"
          style="text-decoration: none"
        >
          {{ childFirstName }} {{ childLastName }}

          <v-tooltip bottom v-if="showPhotoPermissions">
            <template v-slot:activator="{ on, attrs }">
              <span v-html="photoPermissionIcon" v-bind="attrs" v-on="on">
              </span>
            </template>
            <span>{{ photoPermissionText }}</span>
          </v-tooltip>
        </a>
      </v-col>
    </v-row>
    <v-row no-gutters dense>
      <v-col cols="12">
        <a
          :href="`/cms/contacts/view/${parentId}`"
          style="text-decoration: none"
        >
          {{ parentFirstName }} {{ parentLastName }}
        </a>
      </v-col>
    </v-row>
  </td>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'ContactsTableColumnName',
  components: {},
  props: {
    childFirstName: {
      required: false,
      type: String || undefined,
    },
    childLastName: {
      required: false,
      type: String || undefined,
    },
    parentFirstName: {
      required: false,
      type: String || undefined,
    },
    parentLastName: {
      required: false,
      type: String || undefined,
    },
    parentId: {
      required: false,
      type: String || undefined,
    },
    childId: {
      required: false,
      type: String || undefined,
    },
    photoPermission: {
      required: false,
      type: String || undefined,
    },
  },

  data() {
    return {};
  },
  computed: {
    ...mapGetters(['settings']),

    showPhotoPermissions() {
      const { settings } = this;
      return settings.showPhotoPermissions;
    },
    photoPermissionIcon() {
      const permission = this.photoPermission;
      if (permission === '0') {
        return `<span class="fa-stack">
            <i class="fas fa-camera fa-stack-1x"></i>
            <i class="fa fa-ban fa-stack-2x UIAction--text"></i>
          </span>`;
      } else if (permission === null) {
        return `<span>
            <i class="fa fa-circle fa-xs UIAction--text"></i>
          </span>`;
      }
    },
    photoPermissionText() {
      const permission = this.photoPermission;
      if (permission === '0') {
        return `This parent requested photos not be taken of their child`;
      } else if (permission === null) {
        return `This parent has not confirmed whether they give permissions for photos. We recommend they update this in their Parent Account`;
      }
    },
  },
  methods: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  updated() {},
};
</script>

<style scoped></style>
