<template>
    <v-card :class="padding">


        <v-list-item
                v-if="!link"
                :href="resource.url"
                class="py-2"
                data-test="field"
        >
            <v-list-item-avatar>
                <v-icon color="accent">{{icon}}</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title>
                    <h6 class="title text-truncate">{{ title }}</h6>
                </v-list-item-title>
                <v-list-item-subtitle>
                    {{ resource.role }}
                </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
                <v-btn icon ripple>
                    <v-icon color="accent" size="16">fas fa-chevron-right</v-icon>
                </v-btn>
            </v-list-item-action>
        </v-list-item>
        <v-list-item
                v-if="link"
                :to="{name: link}"
                class="py-2"
        >
            <v-list-item-avatar>
                <v-icon color="accent">{{icon}}</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title>
                    <h6 class="title text-truncate">{{ title }}</h6>
                </v-list-item-title>
                <v-list-item-subtitle>
                    {{ resource.role }}
                </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
                <v-btn icon ripple>
                    <v-icon color="accent" size="16">fas fa-chevron-right</v-icon>
                </v-btn>
            </v-list-item-action>
        </v-list-item>
    </v-card>
</template>

<script>
export default {
  name: 'ResourceListItem',
  data() {
    return {
      icons: {
        parent: 'fal fa-users',
        'parent-app': 'fal fa-repeat',
        client: 'fal fa-calendar-alt',
        franchise: 'fal fa-chart-network',
        admin: 'fal fa-user-lock',
        manage: 'fal fa-cogs',
      },
      titles: {
        'parent-app': 'Monthly Class Payments',
      },
    };
  },
  props: {
    resource: {
      required: false,
      type: Object,
    },
    index: {
      required: false,
      type: Number,
    },
  },
  computed: {
    padding(){
      if (this.index === 0) {
        return '';
      }
      switch (this.resource.type) {
        case 'admin':
        case 'manage':
          return 'mt-8';
        default:
          return 'mt-4';
      }
    },
    icon(){
      return this.icons[this.resource.type];
    },
    title(){
      return this.titles[this.resource.type] ? this.titles[this.resource.type] : this.resource.title;
    },
    link() {
      let name = null;
      switch (this.resource.type) {
        case 'parent-app':
          name = 'Home';
          break;
        case 'parent':
          name = null;
          break;
        default:
          name = null;
          break;
      }
      return name;
    },
  },
};
</script>

<style scoped>


</style>
