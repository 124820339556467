import Vue from 'vue';
import Router from 'vue-router';
import store from '../store';

import loginRoutes from './login';
import campsRouting from './camps';
import clientSubscriptionRouting from './client-subscription';
import clientInvoicesRouting from './client-invoices';
import UILibraryRoutes from './ui-library';
import subscriptionsRouting from './subscriptions';
import sendSMSRouting from './send-sms';
import asmoRouting from './asmo';
import userResourceRouting from './user-resources';
import offlineRegisterRouting from './offline-registers';
import autoEnrolRouting from './auto-enrol';
import clientBillingRouting from './client-billing';
import signUp from './sign-up';
import settings from './settings';
import contactsRouting from './contacts';

import UserResourcesLayout from '../../../global/layouts/UserResourcesLayout.vue';

import bookingsRedirect from './redirect/bookingsRedirect';

Vue.use(Router);

const ClassForKidsUILibraryLayout = () => import(/* webpackPrefetch: true */ '../layouts/ClassForKidsUILibrary');
const ManagementSystemLayout = () => import(/* webpackPrefetch: true */ '../layouts/ManagementSystem');
const GlobalLoginLayout = () => import(/* webpackPrefetch: true */ '../../../global/layouts/GlobalLoginLayout');
const PublicSignUpLayout = () => import(/* webpackPrefetch: true */ '../layouts/PublicSignUpLayout');

const router = new Router({
  saveScrollPosition: false,
  hashbang: false,
  mode: 'history',
  routes: [
    {
      component: GlobalLoginLayout,
      path: '',
      children: [
        ...loginRoutes,
      ],
    },
    {
      component: UserResourcesLayout,
      path: '',
      children: [
        ...userResourceRouting,
        ...clientBillingRouting,
      ],
    },
    {
      component: ManagementSystemLayout,
      path: '',
      children: [
        ...campsRouting,
        ...clientSubscriptionRouting,
        ...subscriptionsRouting,
        ...clientInvoicesRouting,
        ...sendSMSRouting,
        ...asmoRouting,
        ...offlineRegisterRouting,
        ...autoEnrolRouting,
        ...settings,
        ...contactsRouting,
      ],
    },
    {
      component: ClassForKidsUILibraryLayout,
      path: '',
      children: [
        ...UILibraryRoutes,
      ],
    },
    {
      component: PublicSignUpLayout,
      path: '',
      children: [
        ...signUp,
      ],
    },
  ],
});

router.beforeEach((to, from, next) => {
  // this redirects any https://bookings.class4kids.co.uk/ to https://bookings.class4kids.co.uk/sign-up
  // this will need to be expanded as the single page app grows
  if (to.name !== 'SignUpInitialPage' && bookingsRedirect(window.location)) {
    next({ name: 'SignUpInitialPage' });
    return;
  }

  if (to.meta.guard) {
    store
      .dispatch('CHECK_LOGIN')
      .then(() => {
        const { guard } = to.meta;
        guard.canActivate()
          .then((result) => {
            if (result) {
              next();
            } else {
              next({ name: 'LoginPage' });
            }
          });
      });
  } else {
    next();
  }
  if (to.meta.pageTitle) {
    document.title = to.meta.pageTitle;
  }
});

export default router;
