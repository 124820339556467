<template>
  <v-row>
    <v-col>
      <v-form>
        <SignUpTitle/>
        <v-row justify="center">
          <v-col cols="12">
            <v-text-field
                color="primary"
                label="Email"
                type="text"
                v-model="userName"
                outlined
                required
                flat
                readonly
                dense
                autocomplete
            />
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="6">
            <v-text-field
                color="primary"
                label="First Name"
                type="text"
                v-model="firstName"
                outlined
                required
                flat
                autofocus
                :rules="nameRules"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
                color="primary"
                label="Last Name"
                type="text"
                v-model="lastName"
                outlined
                required
                flat
                :rules="nameRulesComputed"
            />
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12">
            <PasswordInput
                v-model="password"
                :rules="passwordRulesComputed"
                required
            />
            <v-checkbox
                v-model="checkbox"
                dense
            >
              <template v-slot:label>
                <div>
                  {{ $dictionary.keyWords['terms-and-conditions-acceptance'] }} <a class="cfk-link" @click="dialog = true">{{ $dictionary.keyWords['terms-and-conditions-name'] }}</a>
                </div>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12">
            <Button
                style="min-width: 100%"
                @click="signUp()"
                class="primary"
                :loading="localLoading"
                :disabled="!valid"
            >
              <Dictionary module="keyWords" string="next"/>
            </Button>
          </v-col>
        </v-row>


        <v-row clas="mt-8 mb-4" justify="center" style="margin-top: 32px">
          <v-col cols="4">
            <div class="text-center">{{ $dictionary.keyWords.loginPrompt }}</div>
          </v-col>
          <v-col cols="4">
            <Button ghost primary href="https://account.classforkids.io">{{ $dictionary.keyWords.loginPromptButton }}</Button>
          </v-col>
        </v-row>


<!--        <SignUpTestimonial/>-->
      </v-form>
      <v-dialog
          v-model="dialog"
          width="80%"
      >
        <v-card class="pa-5">
          <TermsOfUse/>
          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <Button
                color="primary"
                @click="accept()"
            >
              <Dictionary module="keyWords" string="IAccept"/>
            </Button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import Vue from 'vue';
import {mapGetters} from 'vuex';
import {passwordValueRequiredRule, passwordValidRule} from '../../../../global/helpers/rules/password';
import {nameRequiredRule} from '../../../../global/helpers/rules/nameRules';
import PasswordInput from '../../../../global/components/UILibrary/Inputs/PasswordInput.vue';
import SignUpTestimonial from '../../components/SignUp/SignUpTestimonial.vue';
import TermsOfUse from '../../components/SignUp/TermsOfUse.vue';
import Button from '../../../../global/components/UILibrary/Buttons/Button.vue';
import SignUpTitle from '../../components/SignUp/SignUpTitle.vue';

export default Vue.extend({
  name: 'SignUpFormPage',
  components: {
    PasswordInput,
    SignUpTestimonial,
    TermsOfUse,
    Button,
    SignUpTitle,
  },
  props: {},
  data() {
    return {
      password: '',
      firstName: '',
      lastName: '',
      localLoading: false,
      checkbox: false,
      enableVerificationRulesLastName: false,
      enableVerificationRulesPassword: false,
      passwordRules: [
        passwordValueRequiredRule,
        passwordValidRule,
      ],
      nameRules: [
        nameRequiredRule,
      ],
      dialog: false,
    };
  },
  computed: {
    ...mapGetters([
      'globalLoading',
      'environmentSignUp',
      'userNameSignUp',
      'userSignUp',
    ]),
    passwordRulesComputed() {
      if (this.enableVerificationRulesPassword || passwordValidRule(this.password) === true) {
        this.enableVerificationRulesPassword = true;
        return this.passwordRules;
      }
      return [];
    },
    nameRulesComputed() {
      if (this.enableVerificationRulesLastName || nameRequiredRule(this.password) === true) {
        this.enableVerificationRulesLastName = true;
        return this.nameRules;
      }
      return [];
    },
    userName() {
      return this.userNameSignUp;
    },
    passwordValidChecker() {
      return this.passwordRules.every((rule) => rule(this.password) === true);
    },
    valid() {
      return this.passwordValidChecker
          && this.firstName.length > 0
          && this.lastName.length > 0
          && this.checkbox;
    },
    environment() {
      return this.environmentSignUp.environment;
    },
  },
  methods: {
    async signUp() {
      this.localLoading = true;
      const payload = {
        userName: this.userName,
        firstName: this.firstName,
        lastName: this.lastName,
        password: this.password,
      };
      const response = await this.$store.dispatch('CREATE_NEW_USER', payload);
      if (response.status === 200) {
        var url;
        if(this.environment === 'dev') {
          url = 'http://account.docker.dev.sandbox.classforkids.io/';
        } else {
          url = `https://account${this.environment === 'stage' ? '.staging' : ''}.classforkids.io`;
        }

        window.location.href = url;
        return;
      }
      await this.$store.commit('UPDATE_GLOBAL_LOADING', {
        mutation: 'create-new-user',
        status: false,
      });
      this.localLoading = false;
    },
    accept() {
      this.dialog = false;
      this.checkbox = true;
    },
  },
  beforeCreate() {
  },
  created() {
  },
  beforeMount() {
  },
  mounted() {
    if (!this.userName) {
      this.$router.push({name: 'SignUpInitialPage'});
    }
    if (this.userSignUp.isCurrentUser) {
      this.$router.push({name: 'SignInExistingClientPage'});
    }
    this.$store.dispatch('CHECK_LOGIN');
  },
  updated() {
  },
  destroyed() {
  },
});
</script>

<style scoped>
.no-wrap {
  white-space: nowrap;
}

.v-card__title {
  flex-direction: column !important;
}

.v-btn:not(.v-btn--round).v-size--default {
  min-width: 100% !important;
}
</style>
