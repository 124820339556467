<template>
  <div v-if="showSmsForm">
    <form
      method="POST"
      :action="'/cms/communications/sms/compose'"
      v-if="true"
      style="text-align: left"
    >
      <Button
        type="submit"
        :disabled="selectedContacts.length < 1"
        block
        color="primary"
        ghost
      >
        <v-icon size="18px">fal fa-sms</v-icon> &nbsp; {{ buttonText }}
      </Button>
      <div v-for="id in childIdsOfSelectedKids" :key="id">
        <input type="hidden" name="selectedKids[]" :value="id" />

        <input type="hidden" name="context" value="contacts" />

        <input type="hidden" name="id" :value="false" />
      </div>
    </form>

    <div v-else>
      <a
        class="radius tiny button"
        style="background: #f85c8d"
        data-reveal-id="sendMessageModal"
        ><i class="fal fa-mobile"></i> Send Custom SMS</a
      >

      <div
        id="sendMessageModal"
        class="reveal-modal small"
        data-reveal
        aria-labelledby="modalTitle"
        aria-hidden="true"
        role="dialog"
      >
        <p class="lead">Sending messages</p>
        <p>Please select at least one contact to send a message</p>
        <p>
          We cannot allow you to message all your contacts, please either select
          choose a class or camp register to message all contacts in a class or
          use our Booking Status filter
        </p>
        <img
          style="margin: 0 auto; display: block"
          src="/assets-dev/images/app/messaging-contacts.gif"
          alt="Send Messages"
        />
        <a class="close-reveal-modal" aria-label="Close">&#215;</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ContactsSendSmsForm',
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['selectedContacts', 'settings']),
    childIdsOfSelectedKids() {
      return this.selectedContacts.map((item) => item.childId);
    },
    buttonText() {
      return `Send ${
        this.selectedContacts.length > 0 ? this.selectedContacts.length : ''
      } Custom SMS`;
    },
  },
  methods: {
    sendSms() {
      this.$store.dispatch('SEND_GROUP_SMS_CONTACTS');
    },
    showSmsForm() {
      return this.settings.features.sms;
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  updated() {},
};
</script>

<style scoped></style>
