import { render, staticRenderFns } from "./SubscriptionsTablesList.vue?vue&type=template&id=349167c2&scoped=true&"
import script from "./SubscriptionsTablesList.vue?vue&type=script&lang=js&"
export * from "./SubscriptionsTablesList.vue?vue&type=script&lang=js&"
import style0 from "./SubscriptionsTablesList.vue?vue&type=style&index=0&id=349167c2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "349167c2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VCard,VCol,VRow,VTab,VTabItem,VTabs,VTabsItems,VTabsSlider,VTooltip})
