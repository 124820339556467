<template>
    <v-list dense subheader avatar>
        <v-subheader v-if="user.isAdmin">
            Stripe Status
        </v-subheader>
        <v-list-item v-if="user.isAdmin">
            <v-list-item-avatar>
                <v-icon color="UIAction">fab fa-cc-stripe</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title>
                    {{stripeSubscriptionStatus}}
                </v-list-item-title>
                <v-list-item-subtitle>

                </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
                <!--                    <v-icon>fal fa-chevron-right</v-icon>-->
            </v-list-item-action>
        </v-list-item>

        <v-subheader>
            Status
        </v-subheader>
        <v-list-item>
            <v-list-item-avatar >
                <v-icon color="UIAction">fad fa-info-circle</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title>
                    {{C4KSubscriptionStatus}}
                </v-list-item-title>
                <v-list-item-subtitle>

                </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
                <!--                    <v-icon>fal fa-chevron-right</v-icon>-->
            </v-list-item-action>
        </v-list-item>
    </v-list>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SubscriptionStatus',
  computed: {
    ...mapGetters(['_subscription', 'user']),
    stripe() {
      return this._subscription.stripe;
    },
    c4k() {
      return this._subscription.c4k;
    },
    stripeSubscriptionStatus() {
      return this.stripe.status.toUpperCase();
    },
    C4KSubscriptionStatus() {
      return this.c4k.status;
    },
  },
};
</script>

<style scoped>

</style>
