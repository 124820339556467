(function () {
  const Vue = require('vue');
  const main = new Vue({
    data() {
      return {
        isOnline: true,
        lastOnline: 0,
      };
    },
    methods: {
      updateStatus() {
        if (typeof window.navigator.onLine === 'undefined') {
        	// If not supported assume we are online
          main.isOnline = true;
        } else {
          main.isOnline = window.navigator.onLine;
          main.lastOnline = new Date();
        }
      },
    },
  });

  // fire it up
  main.updateStatus();

  window.addEventListener('online', main.updateStatus);
  window.addEventListener('offline', main.updateStatus);

  module.exports = main;
}());
