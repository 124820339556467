<template>
    <tr>
        <td class="checkbox">
            <input type="checkbox" :value="booking" v-model="selected">
        </td>

        <td>
            <h5 class="mbn">{{childName}}</h5>
            {{parentName}}
        </td>

        <td>
            {{balance}}
        </td>

        <td>
            {{lastInvoiceSent}}
        </td>
    </tr>
</template>

<script>
import { mapGetters } from 'vuex';
import { formatMoney, formatDate } from '../../../../../../utilities/helpers';
export default {
  name: 'InvoiceRunBookingListItem',
  data() {
    return {

    };
  },
  computed: {
    ...mapGetters(['selectedOrders']),
    parentName() {
      return `${this.booking.firstName} ${this.booking.lastName}`;
    },
    childName() {
      return `${this.booking.childFirstName} ${this.booking.childLastName}`;
    },

    balance() {
      return formatMoney(this.booking.orderItemBalance);
    },
    lastInvoiceSent() {
      return this.booking.lastInvoiceSent ? formatDate(this.booking.lastInvoiceSent) : 'Never';
    },
    selected: {
      get() {
        return this.selectedOrders[this.booking.bookingId];
      },
      set(value) {
        this.$store.commit('UPDATE_SELECTED_ORDERS', { booking: this.booking });
      },
    },
  },
  props: {
    booking: {
      required: true,
      type: Object,
    },
    run: {
      required: true,
      type: Object,
    },
  },
  watch: {
    // selected(add) {
    //
    // }
  },
};
</script>

<style scoped>

</style>
