export default {
  en: {
    title: 'Reset your password',
    subtitle: 'Please enter the email address you used to set up your ClassForKids account. We will contact you with information about how to reset your password.',
    forgot_password_prompt: 'Forgot your password?',
    reset_password_button_prompt: 'Reset my password',
    problem_receiving_email_button_prompt: 'Didn\'t receive an email?'

  },
};
