<template>
    <v-row row class="text-left">
        <v-col cols="12">

            <v-tabs v-model="activeTab">
                <v-tab :key="0" ripple>Payments</v-tab>
                <v-tab :key="1" ripple>Bookings</v-tab>
                <v-tabs-items v-model="activeTab" touchless>
                    <v-tab-item :key="0">
                        <ManageSubscriptionDetailList  />
                    </v-tab-item>
                    <v-tab-item :key="1">
                        <keep-alive>
                            <ParentBookingLog :person-id="personId" :limit-to-subscriptions="true" />
                        </keep-alive>
                    </v-tab-item>
                </v-tabs-items>
            </v-tabs>
        </v-col>
    </v-row>


</template>

<script>

import { mapGetters } from 'vuex';
import ParentBookingLog from '../../Bookings/ParentBookingLog';
import ManageSubscriptionDetailList
  from './ManageSubscriptionDetailList';
import SubscriptionMixin from '../../../mixins/Subscriptions/SubscriptionMixin';

export default {
  name: 'ManageSubscription',
  mixins: [SubscriptionMixin],
  props: {
    personId: {
      type: String,
      required: true,
    },
    subscription: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      activeTab: 0,
      timeOut: 30,
    };
  },

  computed: {
    ...mapGetters(['clientId', 'subscriptionParent', 'user']),
  },
  methods: {

  },

  components: {
    ParentBookingLog,
    ManageSubscriptionDetailList,
  },

  async mounted() {

  },

  beforeDestroy() {
    this.$store.commit('SET_PERSON_BY_ID', {});
  },
};
</script>

<style scoped>
    >>>.v-tabs__slider {
        height:3px;
    }

    >>>.v-tabs__item--active {
        font-weight: 700;
        color: #1f4153;
    }

</style>
