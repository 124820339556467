<template>
  <td>
    <a
      @click="toggleSetupFeePaidStatus"
      style="text-decoration: none"
      v-if="!loading"
    >
      <v-row dense no-gutters>
        <v-col cols="12">
          <CfkGreenTickSuccess :size="30" v-if="setupFeePaidComputed" />
          <CfkRedNotSuccess :size="30" v-else />
        </v-col>
      </v-row>
      <v-row dense no-gutters v-if="lastSetupFeePaidDateComputed">
        <div>
          {{ lastSetupFeePaidDateComputed }}
        </div>
      </v-row>
    </a>
    <v-progress-circular indeterminate color="primary" v-else />
  </td>
</template>

<script>
import { formatLocaleDateFromTimestamp } from './../../../../../../utilities/helpers';
import CfkGreenTickSuccess from '../../SignUp/CfkGreenTickSuccess.vue';
import CfkRedNotSuccess from '../../SignUp/CfkRedNotSuccess.vue';

export default {
  name: 'ContactsTableColumnsSetupFeePaid',
  components: {
    CfkGreenTickSuccess,
    CfkRedNotSuccess,
  },
  props: {
    setupFeePaid: {
      required: false,
      type: String,
    },
    lastSetupFeePaidDate: {
      required: false,
      type: String,
    },
    childId: {
      required: false,
      type: String || undefined,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    setupFeePaidComputed() {
      let result = false;
      if (this.setupFeePaid === '1') {
        result = true;
      }
      return result;
    },
    lastSetupFeePaidDateComputed() {
      return this.lastSetupFeePaidDate
        ? formatLocaleDateFromTimestamp(this.lastSetupFeePaidDate)
        : false;
    },
  },
  methods: {
    async toggleSetupFeePaidStatus() {
      this.loading = true;
      const payload = {
        childId: this.childId,
        status: this.setupFeePaid === '1' ? 'not-paid' : 'paid',
      };
      const result = await this.$store.dispatch(
        'UPDATE_SETUP_FEE_PAID_STATUS',
        payload
      );
      if (result.status === '200') {
        this.loading = false;
      } else {
        this.loading = false;
      }
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  updated() {},
};
</script>

<style scoped></style>
