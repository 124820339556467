'use strict';
import Vue from 'vue';

export default {
  state: {
    maxCharacters: 160,
    recipients: {},
    messageContent: '',
    context: {
      location: '',
      runId: '',
    },
  },
  actions: {
    UPDATE_SMS_MESSAGE_CONTENT({ commit }, payload) {
      commit('SET_MESSAGE_CONTENT', payload);
    },

    async GET_SMS_RECIPIENTS({ dispatch }, payload) {
      const request = {
        url: '/api/v4/messaging/sms/get/contacts',
        parameters: {
          selectedKids: payload,
        },
      };
      const mutation = 'SET_SMS_RECIPIENTS';
      const options = {
        request,
        mutation,
      };

      await dispatch('MAKE_POST_REQUEST', options);
    },

    async SEND_SMS_MESSAGE({ dispatch }, payload) {
      const request = {
        url: '/api/v4/messaging/sms/send-message',
        parameters: {
          recipients: payload.recipients,
          body: payload.body,
        },
      };
      const mutation = 'RESET_MESSAGE';
      const options = {
        request,
        mutation,
      };

      await dispatch('MAKE_POST_REQUEST', options);
    },
    async SEND_SMS_MESSAGE_FEEDBACK_TO_GOOGLE_SHEET({ dispatch }, payload) {
      const request = {
        url: '/api/v4/utility/sms/feedback',
        parameters: {
          clubName: payload.clubName,
          recipientCount: payload.recipientCount,
          message: payload.message,
          context: payload.context,
        },
      };

      const options = {
        request,
      };

      await dispatch('MAKE_POST_REQUEST', options);
    },
  },
  mutations: {
    RESET_MESSAGE(state, { value }) {
      state.messageContent = '';
      // state.recipients = {};
      Vue.set(state, 'recipients', {});
    },

    SET_MESSAGE_CONTENT(state, { value }) {
      state.messageContent = value;
    },

    SET_SMS_RECIPIENTS(state, value) {
      state.recipients = value;
    },
    SET_CONTEXT(state, payload) {
      state.context = payload;
    },
  },
  getters: {
    maxCharacters(state) {
      return state.maxCharacters;
    },
    recipients(state) {
      return state.recipients;
    },

    recipientsUnique(state) {
      let unique = {};
      Object.values(state.recipients).forEach((child) => {
        const mobile = child.parent.mobile;
        const re = /^(\+\d{1,3}[- ]?|0)?\d{10}$/
        if (mobile.match(re)) {
          const parentId = child.parent.personId;
          unique[parentId] = child;
        }
      });

      return unique;
    },

    invalidRecipientsUnique(state) {
      let unique = {};
      Object.values(state.recipients).forEach((child) => {
        const mobile = child.parent.mobile;

        const re = /^(\+\d{1,3}[- ]?|0)?\d{10}$/
        if (!mobile.match(re)) {
          const parentId = child.parent.personId;
          unique[parentId] = child;
        }
      });

      return unique;
    },

    mobileNumbers(state) {
      let mobileNumbers = [];
      Object.values(state.recipients).forEach((child) => {
        mobileNumbers.push(child.parent.mobile);
      });
      return Array.from(new Set(mobileNumbers));
      // using set to get all unique values
    },

    recipientsCount(state) {
      return Object.values(state.recipients).length;
    },

    recipientsUniqueCount(state) {
      let unique = {};
      Object.values(state.recipients).forEach((child) => {
        const mobile = child.parent.mobile;

        const re = /^(\+\d{1,3}[- ]?|0)?\d{10}$/
        if (mobile.match(re)) {
          const parentId = child.parent.personId;
          unique[parentId] = child;
        }
      });
      return Object.values(unique).length;
    },

    invalidRecipientsUniqueCount(state) {
      let unique = {};
      Object.values(state.recipients).forEach((child) => {
        const mobile = child.parent.mobile;

        const re = /^(\+\d{1,3}[- ]?|0)?\d{10}$/
        if (!mobile.match(re)) {
          const parentId = child.parent.personId;
          unique[parentId] = child;
        }
      });

      return Object.values(unique).length;
    },

    recipientsCountText(state) {
      const recipientsCount = Object.values(state.recipients).length;
      return `${recipientsCount} ${
        recipientsCount == 1 ? 'recipient' : 'recipients'
      }`;
    },

    messageContent(state) {
      return state.messageContent;
    },
    context(state) {
      return state.context;
    },
  },
};
