<template>

  <v-row v-if="hasOutstandingSubscription && alerts">
    <v-col>
      <v-alert
          border="top"
          prominent
          type="error"
          size="small"
          color="error"
          transition="scroll-y-transition"
      >
        <template v-slot:prepend>
          <div class="mr-4">
            <v-icon>fas fa-file-invoice</v-icon>
          </div>
        </template>
        <v-row align="center">
          <v-col class="grow">
            {{$dictionary.billing.clientOutstandingInvoiceAlertMessage}}
          </v-col>
          <v-col class="shrink">
            <Button color="error" ghost class="white--text" :to="{name: 'ClientBillingPage'}">
              {{ $dictionary.billing.manageButtonTextOutstanding }}
            </Button>
          </v-col>
        </v-row>
      </v-alert>
    </v-col>
  </v-row>

</template>
<script>
import { mapGetters } from 'vuex';
import Vue from 'vue';
import Button from '../components/UILibrary/Buttons/Button.vue';

export default Vue.extend({
  name: 'ClientOutstandingSubscriptionAlert',
  components: { Button },
  computed: {
    ...mapGetters(['clients', 'alerts']),
    hasOutstandingSubscription() {
      return Object.values(this.clients).filter((client) => client.outstanding === true).length > 0;
    },
  },
});
</script>
