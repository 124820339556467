<template>
  <v-row justify="center" align="center">
    <v-col cols="12">
        <h2 class="primary--text text-center">
          {{title}}
        </h2>
    </v-col>
  </v-row>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'SignUpTitle',
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {
    title() {
      return this.$dictionary.initialPageTitle.title;
    },
  },
});
</script>

<style scoped>

</style>
