'use strict';

export default {

  state: {
    person: {},
  },

  actions:{
    async GET_PERSON_BY_ID({dispatch},{personId}) {
      const request = {
        url: `/api/v4/subscriptions/parent/${personId}`,
      };
      const mutation = 'SET_PERSON_BY_ID';
      const options = {
        request,
        mutation,
      };
      return await dispatch('MAKE_GET_REQUEST', options);
    }
  },

  mutations: {
    SET_PERSON_BY_ID(state, payload) {
      state.person = payload;
    }
  },

  getters:{
    person(state) {
      return state.person
    }
  }
}
