import {
  NOTIFY_URL,
  LIST_SMS_TEMPLATES,
  CREATE_SMS_TEMPLATE,
  READ_SMS_TEMPLATE,
  UPDATE_SMS_TEMPLATE,
  DELETE_SMS_TEMPLATE
} from '../credentials';
import {get, post, remove, put} from '../../http'
const CONTENT_TYPE = 'application/json';

/**
 *
 * Get a list of all the SMS Templates currently stored
 *
 * @param token_type
 * @param access_token
 * @returns {Promise<*>}
 */
export async function listSMSTemplates({token_type, access_token}) {
  const url = `${NOTIFY_URL}/${LIST_SMS_TEMPLATES}`;
  const headers = new Headers({
    'Authorization' : `${token_type} ${access_token}`
  })
  return await get({url, headers});
}

/**
 * Create an SMS template
 * @param token_type
 * @param access_token
 * @param templateName
 * @param templateBody
 * @param description
 * @param tags
 * @returns {Promise<any>}
 */
export async function createSMSTemplate({token_type, access_token}, {templateName, templateBody, description, tags = {}}) {
  const url = `${NOTIFY_URL}/${CREATE_SMS_TEMPLATE}`;
  const headers = new Headers({
    'Authorization' : `${token_type} ${access_token}`,
    'Content-Type': CONTENT_TYPE
  });

  const body = {
    templateName,
    'body': templateBody,
    description,
    tags
  }
  return await post({url, headers, body});
}

/**
 * Retrieve an SMS template by it's templateName
 * @param token_type
 * @param access_token
 * @param templateName
 * @returns {Promise<*>}
 */
export async function getSMSTemplate({token_type, access_token}, {templateName}) {
  const queryString = new URLSearchParams(`templateName=${templateName}`);
  const url = `${NOTIFY_URL}/${READ_SMS_TEMPLATE}?${queryString}`;
  const headers = new Headers({
    'Authorization' : `${token_type} ${access_token}`,
    'Content-Type': CONTENT_TYPE
  });
  const body = {};
  return await get({url, headers, body});
}

/**
 * Update an SMS template
 * @param token_type
 * @param access_token
 * @param templateName
 * @param templateBody
 * @param description
 * @param tags
 * @returns {Promise<any>}
 */
export async function updateSMSTemplate({token_type, access_token}, {templateName, templateBody, description, tags= {}}) {
  const url = `${NOTIFY_URL}/${UPDATE_SMS_TEMPLATE}`;
  const headers = new Headers({
    'Authorization' : `${token_type} ${access_token}`,
    'Content-Type': CONTENT_TYPE
  });

  const body = {
    templateName,
    'body': templateBody,
    description,
    tags
  }
  return await put({url, headers, body});
}

/**
 * Delete an SMS template by it's templateName
 * @param token_type
 * @param access_token
 * @param templateName
 * @returns {Promise<any>}
 */
export async function deleteSMSTemplate({token_type, access_token}, {templateName}) {
  const queryString = new URLSearchParams(`templateName=${templateName}`);
  const url = `${NOTIFY_URL}/${DELETE_SMS_TEMPLATE}?${queryString}`;
  const headers = new Headers({
    'Authorization' : `${token_type} ${access_token}`,
    'Content-Type': CONTENT_TYPE
  });
  const body = {};
  return await remove({url, headers, body});
}







