<template>
<v-list-item :disabled="true" @click="">
  <v-dialog v-model="cancelModal" max-width="480" pa-2>
    <template v-slot:activator="{ on: cancel }">
        <v-list-item-title v-on="{...cancel}" >Cancel Subscription...</v-list-item-title>
    </template>
    <v-card>
      <v-card-title class="headline red white--text">Cancel Subscription</v-card-title>
        <v-alert
            :value="true"
            color="alert"
            icon="fad fa-exclamation-triangle"
            outline
            pa-5
          >
            Are you sure you want to cancel this subscription?
      </v-alert>





      <v-card-text>

      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" flat @click="cancelModal = false">Cancel</v-btn>
        <v-btn color="green darken-1" flat @click="">Cancel Subscription</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</v-list-item>
</template>

<script>

  import { mapGetters } from 'vuex'
  import { formatMoney, formatDate, getCurrencySymbol } from '&/helpers.js'

  export default {
    name: 'CancelSubscriptionMenuItem',
    data() {
      return {
        cancelModal: false

      }
    },
    props: {
      subscription : {
        required: true,
        type: Object
      }
    },
  }
</script>

<style scoped>

</style>