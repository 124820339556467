'use strict';
import Vue from 'vue';

export default {
	state: {
		term: {
			termId: '',
			name: '',
			active: '0',
			startDateTime: null,
			endDateTime: null,
			parentTermId: '',
			runs: {
				0: {
					class: {
						name: '',
						classId: ''
					},
					venue: {
						name: '',
						venueId: '',
						city: ''
					},

					runId: '',
					bookings: {},
					bookingCount: 0,
					trialCount: 0
				}
			}
		},
		selected: [],
		preview: false,
	},
	actions : {
		async GET_BOOKINGS_BY_TERM_ID(context, {termId}) {
			const url = `/api/v4/auto-enrol/${termId}/bookings`;
			const options = {
				credentials: 'same-origin',
				method: 'GET'
			}
			const request = new Request(url, options);
			const response = await fetch(request);
			const json = await response.json();

			let bookings =[];
			Object.values(json.data.runs).map( run => {
				const bookingList = Object.values(run.bookings);

				const bookingListWithRuns = bookingList.filter( booking => {
					if(!booking.created) {
						booking.runId = run.runId;
						return booking;
					}
				});

				bookings.push(...bookingListWithRuns);
			});

			context.commit('SET_SELECTED', bookings);

			return context.commit('SET_BOOKINGS_BY_TERM_ID', json.data);
		},


		async MAKE_AUTO_ENROL_BOOKINGS(context, {termId, bookingsToMake}) {

			const url = `/api/v4/auto-enrol/${termId}/bookings`;
			const options = {
				credentials: 'same-origin',
				method: 'POST',
				body: JSON.stringify({bookingsToMake: bookingsToMake})
			}
			const request = new Request(url, options);
			const response = await fetch(request);
			const json = await response.json();

			return context.commit('UPDATE_BOOKING', json.data.booking);
		}
	},
	mutations: {
		SET_BOOKINGS_BY_TERM_ID(state, payload) {
			state.term = payload;
		},

		UPDATE_BOOKING(state, booking) {
			state.term.runs[booking.runId].bookings[booking.personId] = booking;
			// state.selected.find( selectedBooking => {
			// 	return selectedBooking.personId === booking.personId;
			// }).created = booking.created;
		},

		SET_SELECTED(state, payload) {
			state.selected = payload;
		},

		SET_PREVIEW(state, payload) {
			state.preview = payload;
		}

	},
	getters: {
		runs(state) {
			return state.term.runs;
		},
		selected(state) {
			return state.selected
		},

		preview(state) {
			return state.preview;
		}

	}
};
