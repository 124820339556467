<template>

    <v-skeleton-loader
      type="list-item-avatar-three-line"
      :loading="updating"
    >
            <v-list dense subheader avatar>
            <v-subheader>
                Plan
            </v-subheader>
            <v-list-item v-if="!editing">
                <v-list-item-avatar>
                    <v-icon color="UIAction">fas fa-book-spells</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title>
                        {{planName}}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        {{formatMoney(plan.amount)}} per {{plan.interval}}
                    </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action v-if="!subscriptionHasProblem">
                    <v-btn small text icon @click="editing=true">
                        <v-icon small color="UIAction">fas fa-edit</v-icon>
                    </v-btn>
                </v-list-item-action>
            </v-list-item>

                <v-list-item v-else>
                    <v-list-item-content style="width:100%">
                       <v-select
                                    class="pa-2"
                                    v-model="selectedPlan"
                                    :items="plans"
                                    item-value="recurringPaymentPlanId"
                                    :item-text="planText"
                                    label="Select a new plan"
                                    append-icon="fas fa-times-circle"
                                    append-outer-icon="fas fa-check-circle"
                                    @click:append="editing = false"
                                    @click:append-outer="updatePlan"
                                    style="width:100%"
                            ></v-select>
                    </v-list-item-content>
                </v-list-item>


        </v-list>

</v-skeleton-loader>

</template>

<script>
import { mapGetters } from 'vuex';
import SubscriptionMixin from '../../../mixins/Subscriptions/SubscriptionMixin';
import { formatMoney } from '&/helpers.js';

export default {
  name: 'ManageSubscriptionPlan',
  mixins: [SubscriptionMixin],
  data() {
    return {
      editing: false,
      selectedPlan: '',
      updating: false,
    };
  },
  computed: {
    ...mapGetters(['_subscription']),
    stripe() {
      return this._subscription.stripe;
    },
    parent() {
      return this._subscription.parent;
    },
    plan() {
      return this.stripe.plan;
    },
    planName() {
      return this.plan.nickname ? this.plan.nickname : 'Subscription Plan';
    },

    plans() {
      return Object.values(this.$store.getters.plans);
    },
    subscriptionHasProblem() {
      return this._subscription.c4k.status === 'problem';
    },
  },

  methods: {
    async updatePlan() {
      this.updating = true;
      const subscriptionId = this._subscription.c4k.recurringPaymentSubscriptionId;
      await this.changeSubscriptionPlansAtStripe({ subscriptionId, planId: this.selectedPlan });
      this.editing = false;
      this.updating = false;
    },

    planText(plan) {
      return `${plan.name} - ${formatMoney(plan.cost)} / ${plan.frequency}`;
    },

  },

  mounted() {
    if (!this.plans.length > 0) {
      this.$store.dispatch('GET_PAYMENT_PLANS');
    }
  },

};
</script>

<style scoped>

</style>
