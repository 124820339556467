'use strict';

import Vue from 'vue';

export default {
  state: {
    pageState: {
      inReview: false
    },
    estimatedPrice: '',
    campBooking: {
      bookingId: '',
      orderId: '',
      camp: {
        class: {
          name: ''
        },
        venue: {
          name: ''
        },
        offersHalfDays: false,
        instances: {}
      },
      campId: '',
      child: {
        age: '',
        firstName: '',
        gender: '',
        lastName: '',
        personId: ''
      },
      created: '',
      parent: {
        firstName: '',
        lastName: '',
        personId: ''
      }
    },

    changeLog: {
      runInstancesAdded: {},
      bookingInstancesToCancel: {}
    },
    changeLogUpdatesUnsaved: false
  },
  actions: {
    async GET_CAMP_BOOKING(context, payload) {
      const request = {
        url: `/api/v4/camps/edit/booking/${payload}`
      };
      const mutation = 'SET_CAMP';
      const options = {
        request,
        mutation
      };

      await context.dispatch('MAKE_GET_REQUEST', options);
    },

    async GET_CAMP_BOOKING_PRICE(context, payload) {
      const request = {
        url: `/api/v4/camps/edit/booking/${payload}/price`
      };
      const mutation = 'SET_CAMP_ESTIMATED_PRICE';
      const options = {
        request,
        mutation
      };

      await context.dispatch('MAKE_GET_REQUEST', options);
    },

    async GET_CAMP_DAY_TO_EDIT(context, payload) {
      await context.commit('SET_CAMP_DAY_TO_EDIT', payload);
    },

    async GET_EDITING_DAY_CONFIG(context, payload) {
      await context.commit('SET_EDITING_DAY_CONFIG', payload);
    },

    async PUSH_TO_CHANGE_LOG(context, payload) {
      await context.commit('UPDATE_CHANGE_LOG', payload);
    },

    async PUSH_TO_RUN_INSTANCES_TO_ADD(context, payload) {
      await context.commit('UPDATE_RUN_INSTANCES_TO_ADD', payload);
    },
    async PUSH_TO_BOOKING_INSTANCES_TO_REMOVE(context, payload) {
      await context.commit('UPDATE_BOOKING_INSTANCES_TO_REMOVE', payload);
    },

    async UPDATE_CAMP_BOOKING(context, payload) {
      const request = {
        url: `/api/v4/camps/edit/booking/amend`,
        parameters: payload
      };
      const mutation = 'UPDATE_CAMP';
      const options = {
        request,
        mutation
      };

      return await context.dispatch('MAKE_POST_REQUEST', options);
      // await context.dispatch('GET_CAMP_BOOKING', payload.bookingId);
    }
  },
  mutations: {
    SET_CHANGELOG_UPDATES_SAVED(state, payload) {
     state.changeLogUpdatesUnsaved = payload.value;
    },
    SET_CAMP(state, payload) {
      state.campBooking = payload;
    },

    SET_CAMP_ESTIMATED_PRICE(state, payload) {
      state.estimatedPrice = payload;
    },
    SET_CAMP_DAY_TO_EDIT(state, payload) {
      state.campDayToEdit = payload;
    },

    SET_PAGE_STATE(state, payload) {
      state.pageState[payload.stateItem] = payload.value;
    },

    SET_EDITING_DAY_CONFIG(state, payload) {
      state.editingDayConfig[payload.type]['active'] = payload.active;
      state.editingDayConfig[payload.type]['runInstanceId'] =
        payload.runInstanceId;
      state.editingDayConfig[payload.type]['bookingInstanceId'] =
        payload.bookingInstanceId;
    },

    UPDATE_CAMP(state, payload) {
      state.changeLog.runInstancesAdded = {};
      state.changeLog.bookingInstancesToCancel = {};
      state.pageState.inReview = false;
    },

    UPDATE_RUN_INSTANCES_TO_ADD(state, payload) {
      const exists = state.changeLog.runInstancesAdded[payload.runInstanceId];

      if (!exists) {
        Vue.set(
          state.changeLog.runInstancesAdded,
          payload.runInstanceId,
          payload
        );
      } else {
        Vue.delete(state.changeLog.runInstancesAdded, payload.runInstanceId);
      }
    },

    UPDATE_BOOKING_INSTANCES_TO_REMOVE(state, payload) {
      const exists =
        state.changeLog.bookingInstancesToCancel[payload.runInstanceId];

      if (!exists) {
        Vue.set(
          state.changeLog.bookingInstancesToCancel,
          payload.runInstanceId,
          payload
        );
      } else {
        Vue.delete(
          state.changeLog.bookingInstancesToCancel,
          payload.runInstanceId
        );
      }
    },

    UPDATE_CHANGE_LOG(state, payload) {
      state.changeLog.bookingId = payload.bookingId;
      if (!payload.wasBooked) {
        state.changeLog.bookingInstancesToCancel.push(
          payload.bookingInstanceToCancel
        );
      } else {
        state.changeLog.bookingId = payload.bookingId;
        state.changeLog.runInstancesAdded.push(payload.runInstanceToAdd);
      }
    }
  },
  getters: {
    booking(state) {
      return state.campBooking;
    },
    parent(state) {
      return state.campBooking.parent;
    },
    inReview(state) {
      return state.pageState.inReview;
    },
    instances(state) {
      return state.campBooking.camp.instances;
    },
    campId(state) {
      return state.campBooking.campId;
    },
    camp(state) {
      return state.campBooking.camp;
    },
    classDescription(state) {
      return state.campBooking.camp.class;
    },
    venue(state) {
      return state.campBooking.camp.venue;
    },
    campBookingChild(state) {
      return state.campBooking.child;
    },
    changeLog(state) {
      return state.changeLog;
    },
    changeLogUpdatesUnsaved(state) {
      return state.changeLogUpdatesUnsaved;
    },
    estimatedPrice(state) {
      return state.estimatedPrice;
    }
  }
};
