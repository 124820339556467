<template>
  <v-row>
    <v-col>
      <SignUpTitle/>
      <v-row justify="center">
        <v-col cols="12">
          <UsernameInput
              v-model="userName"
              outlined
              required
              :error-message-value="errorMessageValue"
              autofocus
              :rules="rulesComputed"
              @keydown="keydown"
          />
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12">
          <Button
              style="min-width: 100%"
              @click="next()"
              class="primary"
              :loading="localLoading"
              :disabled="!validChecker"
          >
            <Dictionary module="keyWords" string="next"/>
          </Button>
        </v-col>
      </v-row>
      <SignUpTestimonial/>
    </v-col>
  </v-row>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import {
  emailValidRule,
  emailValueRequiredRule,
  emailFieldContainsCharacters,
} from '../../../../global/helpers/rules/email';
import UsernameInput from '../../../../global/components/UILibrary/Inputs/UsernameInput.vue';
import SignUpTestimonial from '../../components/SignUp/SignUpTestimonial.vue';
import SignUpTitle from '../../components/SignUp/SignUpTitle.vue';

export default Vue.extend({
  name: 'SignUpInitialPage',
  components: {
    UsernameInput,
    SignUpTestimonial,
    SignUpTitle,
  },
  props: {},
  data() {
    return {
      userName: '',
      localLoading: false,
      userNameSubmitted: false,
      rules: [
        emailValueRequiredRule,
        emailFieldContainsCharacters,
        emailValidRule,
      ],
      errorMessageValue: '',
      enableVerificationRules: false,
    };
  },
  computed: {
    ...mapGetters([
      'globalLoading',
      'environmentSignUp',
      'userNameSignUp',
      'userSignUp',
      'isNewUserSignUp',
      'isExistingUserSignUp',
    ]),
    rulesComputed() {
      if (this.enableVerificationRules || emailValidRule(this.userName) === true) {
        this.enableVerificationRules = true;
        return this.rules;
      }
      return [];
    },
    validChecker() {
      return this.rules.every((rule) => rule(this.userName) === true);
    },
  },
  methods: {
    async next() {
      if (this.validChecker === false) {
        this.enableVerificationRules = true;
        return;
      }
      this.localLoading = true;
      const response = await this.$store.dispatch('CHECK_USERNAME', this.userName);
      if (response.status === 200) {
        if (this.isNewUserSignUp) {
          await this.$router.push({ name: 'SignUpFormPage' });
        }
        if (this.isExistingUserSignUp) {
          window.location.href = `/saml/login`;
          // window.location.href = `/saml/login?login_hint=${this.userName}`;
          // await this.$router.push({ name: 'SignInExistingClientPage' });
        }
      }
      this.localLoading = false;
    },
    keydown() {
      this.typing = true;
    },
  },
  mounted() {},
  updated() {},
});
</script>

<style scoped>
</style>
