<template>
  <div>
    <div v-if="!dataAvailableForTable">
      <v-alert color="warning" icon="fad fa-exclamation-triangle" :value="true">
        No Attendance Data
      </v-alert>
    </div>

    <div v-else>
      <div class="mt-2" style="text-align: right">
        <v-btn
          text
          v-bind:color="activityTypeClass"
          v-on:click.prevent="verboseMode = !verboseMode"
          right
        >
          <span v-text="verboseModeToggleText"></span>
          <v-icon right>fad fa-school</v-icon>
        </v-btn>
      </div>

      <div class="table">
        <table class="table" v-if="dataAvailableForTable">
          <thead>
            <th>Gender</th>

            <th>Year</th>

            <th v-if="verboseMode">Weeks</th>

            <th v-if="verboseMode">Attended</th>

            <th>Average</th>
          </thead>

          <tbody v-for="(gender, key, index) in participants" :key="index">
            <tr v-for="(children, index) in gender" :key="index">
              <td>{{ key }}</td>
              <td>{{ children.yearGroup }}</td>
              <td v-if="verboseMode">{{ run.numberOfSessions }}</td>
              <td v-if="verboseMode">{{ children.sessionsAttended }}</td>
              <td
                v-text="getAverageParticipants(children.sessionsAttended)"
              ></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AsmoRunTable',
  props: ['run', 'activityTypeClass'],
  data: function () {
    return {
      verboseMode: false,
    };
  },
  computed: {
    participants() {
      return this.run.participants;
    },
    males: function () {
      return this.run.participants.male;
    },
    females: function () {
      return this.run.participants.female;
    },
    verboseModeToggleText: function () {
      let text;
      this.verboseMode ? (text = 'Hide working') : (text = 'Show Working');
      return text;
    },
    dataAvailableForTable: function () {
      return Object.values(this.participants).length > 0;
    },
  },

  methods: {
    getAverageParticipants: function (value) {
      let decimalPlaces;
      this.verboseMode ? (decimalPlaces = 1) : (decimalPlaces = 0);
      return this.round(value / this.run.numberOfSessions, decimalPlaces);
    },

    round: function (value, decimals) {
      return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
    },
  },
};
</script>

<style scoped>
.table {
  overflow-x: auto;
  width: 100%;
}

table tbody {
  text-align: center;
}
</style>
