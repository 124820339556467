<template>
    <v-list-item @click="">
        <v-list-item-avatar>

            <v-icon color="success" v-if="invoicePaid">fas fa-check-circle</v-icon>
            <v-icon color="error" v-else>fas fa-times-circle</v-icon>
        </v-list-item-avatar>


        <v-list-item-content>
            <!--            <v-list-item-title>{{amountPaidFormatted}} <strong v-if="containsRefund" class="caption"> ({{totalRefundAmountFormatted}} Refunded) </strong></v-list-item-title>-->
            <v-list-item-title>{{amountPaidFormatted}}
                <!--                          <v-chip small>£0.69 Refund</v-chip>-->
            </v-list-item-title>
            <v-list-item-subtitle>
                {{invoiceStatusText}}
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="coveringPaymentAmount > 0">
                Includes covering payment of {{ formatCoveringPaymentAmount }}
            </v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
            <v-list-item-action-text v-if="containsRefund">{{totalRefundAmountFormatted}} Refunded
            </v-list-item-action-text>
            <v-menu offset-y>
                <template v-slot:activator="{ on }">
                    <v-btn text icon v-on="on">
                        <v-icon>fal fa-ellipsis-v</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item @click="" v-if="canRefund">
                        <v-dialog v-model="refundModalOpen" max-width="480" pa-2>
                            <template v-slot:activator="{ on }">
                                <v-list-item-title v-on="on">Refund payment...</v-list-item-title>
                            </template>
                            <v-card>

                                <v-card-title class="headline">Refund Payment</v-card-title>
                                <v-alert
                                        :value="true"
                                        color="warning"
                                        icon="fad fa-exclamation-triangle"
                                        outline
                                        pa-5
                                >
                                    Once issued, a refund <strong>cannot</strong> be cancelled
                                </v-alert>


                                <v-card-text>
                                    <v-list dense subheader>
                                        <v-list-item v-for="refund in refunds" :key="refund.id">
                                            <v-list-item-action></v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{formatMoney(refund.amount)}}
                                                </v-list-item-title>
                                                <v-list-item-subtitle>
                                                    Refunded {{formatDate(refund.created)}}
                                                </v-list-item-subtitle>
                                            </v-list-item-content>

                                            <v-list-item-action>

                                            </v-list-item-action>

                                        </v-list-item>
                                    </v-list>
                                </v-card-text>


                                <v-card-text>
                                    <v-text-field
                                            label="Refund"
                                            v-model="amountPaidInDecimal"
                                            :prefix="currencySymbol"
                                            :rules="[required, maxValue]"
                                    ></v-text-field>
                                </v-card-text>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="info" text small @click="refundModalOpen = false">Cancel</v-btn>
                                    <v-btn color="error" depressed small @click="refundCharge" :disabled="refund.amount > amountPaid">
                                        Refund
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-list-item>

                    <v-list-item :disabled="true">
                        <v-list-item-title>Void Invoice...</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-list-item-action>


    </v-list-item>
</template>

<script>

  import {mapGetters} from 'vuex'
  import {formatMoney, formatDate, getCurrencySymbol} from '&/helpers.js'

  export default {
    name: 'C4KSubscriptionPaymentsListItem',
    data() {
      return {
        refundModalOpen: false,
        refund: {
          amount: 0
        },
        required: value => !!value || 'Required.',
        maxValue: value =>  (value * 100) <= this.amountPaidIncludingRefunds || 'You are trying to refund more than was paid',

      }
    },
    props: {
      invoice: {
        required: true,
        type: Object
      }
    },
    computed: {
      currencySymbol() {
        return getCurrencySymbol()
      },
      invoicePaid() {
        return this.invoice.paid
      },
      amountPaid() {
        return this.invoice.amount_paid;
      },
      amountPaidIncludingRefunds() {
        return this.amountPaid - this.amountRefunded;
      },
      amountRefunded() {
        let value = 0;
        if (this.containsRefund) {

          this.refunds.forEach(refund => {
            value += refund.amount;
          });
        }
        return value;
      },
      amountPaidInDecimal: {
        get() {
          return (this.amountPaidIncludingRefunds / 100).toFixed(2);
        },
        set(value) {
          this.refund.amount = parseInt(value * 100);
        }
      },
      amountPaidFormatted() {
        return formatMoney(this.amountPaid);
      },
      paidDate() {
        return this.invoice.created;
      },
      paidDateFormatted() {
        return formatDate(this.paidDate);
      },
      canRefund() {
        return this.invoice.amount_paid > 0 && this.invoice.charge && this.invoice.charge.amount_refunded < this.invoice.amount_paid;
      },
      containsRefund() {
        return this.invoice.charge && this.invoice.charge.refunds && Object.values(this.invoice.charge.refunds.data).length > 0;
      },
      totalRefundAmountFormatted() {
        if (this.containsRefund) {
          return formatMoney(this.invoice.charge.amount_refunded);
        }
      },
      refunds() {
        if (this.containsRefund) {
          return this.invoice.charge.refunds.data;
        }
      },
      invoiceStatusText() {
        let text = '';
        if (this.invoice.paid) {
          text += `Paid on ${this.paidDateFormatted}`;
        }
        return text;

      },
      coveringPaymentAmount() {
        let total = 0;
        this.invoice.lines.data.forEach((x) => {
          if (x.description === 'Covering Payment') {
            total += x.amount;
          }
        })
        return total;
      },
      formatCoveringPaymentAmount() {
        return this.formatMoney(this.coveringPaymentAmount);
      }
    },

    methods: {
      formatMoney(value) {
        return formatMoney(value)
      },
      formatDate(value) {
        return formatDate(value)
      },
      async refundCharge() {
        this.refundModalOpen = false;
        const amount = this.refund.amount;
        const chargeId = this.invoice.charge.id;
        const localLoading = true;
        const payload = {
          chargeId,
          amount,
          localLoading
        }

        await this.$store.dispatch('REFUND_CHARGE_FROM_SUBSCRIPTION', payload)
        // this.getSubscriptions()
      },
    },

    mounted() {
      this.refund.amount = this.amountPaidIncludingRefunds;
    }
  }
</script>

<style scoped>

</style>