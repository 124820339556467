// new

export default {
  state: {
    snackbar: {
      show: false,
      status: '',
      message: '',
      icon: ''
    }
  },
  actions: {},

  mutations: {
    SET_ERROR_NOTIFICATION(state, payload) {
      state.snackbar.status = 'error';
      state.snackbar.icon = '🤔';

      state.snackbar.message = payload.statusText
        ? payload.statusText
        : payload.message;
      state.snackbar.show = true;

      state.loading = {};
    },

    SET_SUCCESS_NOTIFICATION(state, payload) {
      // state.snackbar.status state.snackbar.status = 'success';
      state.snackbar.icon = payload.message.icon ? payload.message.icon : '🌚';
      state.snackbar.status = payload.message.info ? 'info' : 'success';

      state.snackbar.message = payload.message.success
        ? payload.message.success
        : 'Success!';
      state.snackbar.show = true;
      state.loading = {};
    },

    //sets the state of the snackbar message + type
    SET_SNACKBAR_DETAILS(state, payload) {
      state.snackbar.message = payload.message;
      state.snackbar.status = payload.messageType;
      state.snackbar.icon = payload.icon;
      state.snackbar.fontAwesome = payload.fontAwesome;
    },

    RESET_ERROR_STATE(state) {
      state.snackbar.status = '';
      state.snackbar.icon = '';
      state.snackbar.show = false;
    }
  },

  getters: {
    snackbar(state) {
      return state.snackbar;
    },
    snackbarStatus(state) {
      return state.snackbar.status;
    },
    snackbarMessage(state) {
      return state.snackbar.message;
    },
    snackbarIcon(state) {
      return state.snackbar.icon;
    },
    snackbarFontAwesome(state) {
      return state.snackbar.fontAwesome;
    }
  }
};
