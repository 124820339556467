

import { formatMoney, formatDate, getCurrencySymbol } from '&/helpers.js'
export default {
  name: 'CoveringPaymentsMixin',

  data() {
    return {
      add: {
        amount: '1.00'
      },
      rules: {
          required: value => !!value || 'Required.',
          minValue: value => (value) >= 1  || 'Minimum amount is 1.00',
          maxValue: value => (value * 100) <= this.planCost * 2  || 'Can\'t add more than double the cost of the plan',
          maxCoveringPayments: value => ((value * 100) + this.combinedValueOfCoveringPayments ) <= this.planCost * 3  || 'Upcoming invoice shouldn\'t be more than three times the cost of the plan',
      }
    }
  },

  methods: {
    async addCoveringPayment() {

      const message = {
        icon: '🙌',
        success: `Covering Payment for ${formatMoney(this.amountInDecimal * 100)} successfully added`
      };

      const payload = {
        recurringPaymentSubscriptionId: this.subscription.c4k.recurringPaymentSubscriptionId,
        amount: parseFloat(this.amountInDecimal),
        message
      };

      this.addDialog = false;
      await this.$store.dispatch('ADD_SUBSCRIPTION_COVERING_PAYMENT', payload);
      // this.$store.dispatch('GET_SUBSCRIPTION_COVERING_PAYMENTS', payload)

      this.add.amount = '1.00';
    },


    async removeCoveringPayment() {

      const message = {
        icon: '👍',
        info: 'info',
        success: `Covering Payment removed`
      };

      this.dialog = false;
      const payload = {
        subscriptionCoveringPaymentId: this.coveringPayment.subscriptionCoveringPaymentId,
        message
      }
      await this.$store.dispatch('REMOVE_SUBSCRIPTION_COVERING_PAYMENT', payload)

      // this.$store.dispatch('GET_SUBSCRIPTION_COVERING_PAYMENTS', {recurringPaymentSubscriptionId: this.recurringPaymentSubscriptionId});

    },

    formatMoney(value) {
      return formatMoney(value);
    }
  },

  computed: {
    currencySymbol() {
      return getCurrencySymbol()
    },
    amountInDecimal() {
      return this.add.amount;
    },
    validAmount() {
      // const $planAmount = this.subscription.subscription ? this.subscription.subscription.plan.amount : 1;
      return this.add.amount > 0
      // && this.add.amount < (($planAmount / 100) * 2);
    },
    planCost() {
      return this.subscription.stripe.plan.amount;
    },
    combinedValueOfCoveringPayments() {
      return this.amountDue;
    }
  }
}