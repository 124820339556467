<template>
  <v-list two-line dense>
    <div id="title" class="mb-4" v-if="!userHasNoClientResources">
      <v-img contain left width="300px" src="/pwa-static/images/logo_organisers_white.png"></v-img>
    </div>
    <TopLevelDomainListItem v-for="domain in FilterOutCurrentTldFromData" :key="domain[0]" :domain="domain"/>
    <ClientResourceListItem v-for="(resource, index) in clientResources" :key="resource.key" :resource="resource" :index="index"/>

    <!--        <a href="#" class="subtitle-1"> Create a new Club on ClassForKids</a>-->
    <NoUserResourcesListItem v-if="userHasNoResources"/>
  </v-list>
</template>

<script>
import { mapGetters } from 'vuex';
import ClientResourceListItem from './ClientResourceListItem.vue';
import TopLevelDomainListItem from './TopLevelDomainListItem.vue';
import NoUserResourcesListItem from './NoUserResourcesListItem.vue';
export default {
  name: 'ClientResourceList',
  methods: {
    getCurrentUrlTld() {
      return window.location.href.match(/\.\w{2,5}\b/g).join('');
    },
  },
  computed: {
    ...mapGetters(['resources', 'loginData']),
    clientResources() {
      return this.resources.client;
    },
    TLDData() {
      return Object.entries(this.loginData.topLevelDomains);
    },
    FilterOutCurrentTldFromData() {
      const array = [];
      for (let i = 0; i < this.TLDData.length; i++) {
        if (this.TLDData[i][0] !== this.getCurrentUrlTld()) {
          array.push(this.TLDData[i]);
        }
      }
      return array;
    },
    userHasNoClientResources() {
      return this.resources.client.length === 0;
    },
    userHasNoResources() {
      return this.resources.length === 0 && Object.values(this.loginData.topLevelDomains).length === 0;
    },
  },
  components: {
    ClientResourceListItem,
    TopLevelDomainListItem,
    NoUserResourcesListItem,
  },
  mounted() {},
  updated() {}
};
</script>

<style scoped>

.v-list {
  background: transparent;
}

#title {
  background: #0a425e;
  padding: 8px;
  border-radius: 4px 4px 4px 4px;
  border-bottom: 4px solid #ff8364;
}
</style>
