
import { format } from 'date-fns';
import localforage from 'localforage';

localforage.config({
  name: 'attendanceCache',
});

const online = require('../assets/js/online');

const store = {
  state: {
    syncing: false,
    attendanceRequestQueue: [],
    cachedRuns: [],
    speech: true,
  },
  actions: {
    async SAVE_OFFLINE(context, payload) {
      const registerCache = await caches.open('c4k-registers');
      // const runRequest = new Request(Routing.generate('ApiBundle_run_get_run_v3', {runId: payload}), {method : 'GET'});
      const runRequest = new Request(`/api/runs/v3/${payload}`, {
        method: 'GET',
        credentials: 'same-origin',
      });
      registerCache.add(runRequest);
      const options = {
        action: 'add',
        runId: payload,
      };
      context.commit('UPDATE_CACHED_RUNS', options);
    },

    async REMOVE_FROM_CACHE(context, payload) {
      const registerCache = await caches.open('c4k-registers');
      // const runRequest = new Request(Routing.generate('ApiBundle_run_get_run_v3', {runId: payload}), {method : 'GET'});
      const runRequest = new Request(`/api/runs/v3/${payload}`, {
        method: 'GET',
        credentials: 'same-origin',
      });
      registerCache.delete(runRequest);

      const options = {
        action: 'delete',
        runId: payload,
      };

      context.commit('UPDATE_CACHED_RUNS', options);
    },
    async GET_CACHED_RUNS(context, payload) {
      const registerCache = await caches.open('c4k-registers');
      const keys = await registerCache.keys();
      for (let i = keys.length - 1; i >= 0; i--) {
        const { url } = keys[i];
        const runId = url.substr(url.lastIndexOf('/') + 1);
        const options = {
          action: 'add',
          runId,
        };
        context.commit('UPDATE_CACHED_RUNS', options);
      }
    },

    async ADD_TO_ATTENDANCE_CACHE(context, payload) {
      localforage.setItem(payload.key, payload.value);
      context.commit('UPDATE_CACHED_ATTENDANCE_REQUESTS', payload);
    },

    async GET_CACHED_ATTENDANCE_REQUESTS(context, payload) {
      localforage.iterate((value, key) => {
        const start = key.lastIndexOf('-');
        context.commit('UPDATE_CACHED_ATTENDANCE_REQUESTS', {
          key,
          bookingInstanceId: key.substring(start + 1, key.length),
          value,
        });
      });
    },

    async SYNC_ATTENDANCE_WITH_SERVER(context, payload) {
      // make the requests
      const requests = context.state.attendanceRequestQueue;
      const { length } = requests;

      let successfulRequests = 0;

      requests.forEach(async (request) => {
        // console.log(requests.length, i);

        // const request = requests[i];
        console.log(request.value);
        const url = request.value;
        const updateAttendanceRequest = new Request(url, {
          method: 'POST',
          credentials: 'same-origin',
        });

        try {
          context.commit('UPDATE_SYNCING', true);
          const updateAttendance = await fetch(updateAttendanceRequest);
          const responseJson = await updateAttendance.json();

          context.commit('SET_ATTENDANCE_DATA', responseJson);

          // update the cache for successful requests
          localforage.removeItem(request.key);

          // successfulRequests.push(request);
          context.commit(
            'REMOVE_KEY_FROM_ATTENDANCE_REQUEST_CACHE',
            request.key,
          );
          successfulRequests++;

          if (successfulRequests == requests.length) {
            await context.commit('UPDATE_SYNCING', false);
          }
        } catch (error) {
          console.error('Error: ', error);
        }
      });

      // need to do this seperate because buggy as it removes requests from array
      // for( var j = 0; j < successfulRequests.length; j++) {
      // 	context.commit('REMOVE_KEY_FROM_ATTENDANCE_REQUEST_CACHE', successfulRequests[j].key);
      // }
    },

    async TOGGLE_SPEECH(context, payload) {
      context.commit('UPDATE_SPEECH_TOGGLE');
    },
  },
  mutations: {
    UPDATE_SYNCING(state, payload) {
      state.syncing = payload;
    },

    UPDATE_CACHED_RUNS(state, payload) {
      if (payload.action == 'add') {
        state.cachedRuns.push(payload.runId);
      } else {
        const i = state.cachedRuns.indexOf(payload.runId);
        if (i != -1) {
          state.cachedRuns.splice(i, 1);
        }
      }
    },
    UPDATE_CACHED_ATTENDANCE_REQUESTS(state, payload) {
      state.attendanceRequestQueue.push(payload);
    },

    REMOVE_KEY_FROM_ATTENDANCE_REQUEST_CACHE(state, payload) {
      const i = state.attendanceRequestQueue.findIndex((request) => request.key == payload);

      if (i != -1) {
        state.attendanceRequestQueue.splice(i, 1);
      }
    },

    UPDATE_SPEECH_TOGGLE(state, payload) {
      state.speech = !state.speech;
    },
  },
  getters: {
    supportsPWAFeatures(state) {
      return 'serviceWorker' in navigator;
    },

    syncing(state) {
      return state.syncing;
    },

    isOnline() {
      return online.isOnline;
    },
    lastOnline() {
      return format(online.lastOnline, 'DD/YY HH:mm');
    },
    cachedRuns(state) {
      return state.cachedRuns;
    },
    attendanceRequestQueue(state) {
      return state.attendanceRequestQueue;
    },

    pendingAttendanceRequestbookingInstances(state) {
      return state.attendanceRequestQueue.map((request) => request.bookingInstanceId);
    },
    speech(state) {
      return state.speech;
    },
  },
};

export default store;
