<template>
    <span>{{ lastPaidDateFormatted}}
            <span v-if="paymentErrorMessage">
                    <br />
                    <span class="caption font-weight-bold error--text">
                        {{paymentErrorMessageFormatted}}
                    </span>
            </span>
    </span>
</template>

<script>

import { formatDate } from '&/helpers.js';

export default {
  name: 'PaymentAttempt',
  data() {
    return {
    };
  },
  props: {
    subscription: {
      required: true,
      type: Object,
    },
  },
  computed: {
    lastPaidDateFormatted() {
      return formatDate(this.subscription.c4k.lastPaid.date);
    },

    paymentErrorMessage() {
      return this.subscription.c4k.lastPaid.status;
    },
    paymentErrorMessageFormatted() {
      // we log the other message to the DB straight from Stripe so this seemed easy place to change it without mucking up the data
      return this.subscription.c4k.lastPaid.status === 'Your card has insufficient funds.' ? 'The customers card failed due to insufficient funds' : this.subscription.c4k.lastPaid.status;
    },
  },
  methods: {
  },
  components: {
  },
};
</script>
