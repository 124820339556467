<template>

<span>
        <v-btn
            color="primary"
            @click="dialog = !dialog"
            :disabled="subscriptionPlanCannotBeChanged"
            outlined
            class="font-weight-bold"
            small
        >
            <v-icon small>fas fa-exchange fa-2x</v-icon> &nbsp;Change {{subscriptions.length > 0 ? subscriptions.length : ''}} Plan{{subscriptions.length > 1 ? 's' : ''}}
        </v-btn>

        <v-layout row>
        <v-navigation-drawer
            v-model="dialog" app clipped right :temporary="!updating" width="420">
            <v-sheet flat>

                <v-list dense three-line class="pt-4 mt-2">
                    <v-list-item>
                        <v-list-item-avatar color="success">
                            <v-icon>
                                fad fa-book-spells
                            </v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>
                                <h5 class="title font-weight-bold">Change Plans</h5>
                            </v-list-item-title>
                            <v-list-item-subtitle color="UIAction">
                                Update the plans the selected parents are currently subscribed to.
                            </v-list-item-subtitle>
                        </v-list-item-content>

                    </v-list-item>

                </v-list>


                <v-layout row fluid fill-height class="mt-2">
                    <v-flex xs12>
                        <v-select
                            class="pa-2"
                            v-model="selectedPlan"
                            :items="plans"
                            item-value="recurringPaymentPlanId"
                            :item-text="planText"
                            clearable
                            label="Please select a new plan"
                        ></v-select>


                        <v-list dense subheader avatar v-if="selectedPlan">
                            <v-subheader>
                                New Plan
                            </v-subheader>
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-icon>far fa-book-spells</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{newPlan.name}}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{formatMoney(newPlan.cost)}}
                                    </v-list-item-subtitle>
                                </v-list-item-content>

                                <v-list-item-action>
                                    <!--                    <v-icon>fal fa-chevron-right</v-icon>-->
                                </v-list-item-action>
                            </v-list-item>
                            <v-subheader>Selected</v-subheader>
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-icon>far fa-users</v-icon>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                    {{subscriptions.length}} Parents Selected
                                </v-list-item-content>
                            </v-list-item>


                            <v-layout v-if="!updating">
                                <v-flex class="mt-2 pa-2">
                                    <v-layout row justify-center>
                                        <v-dialog v-model="confirm" persistent max-width="290">
                                            <template v-slot:activator="{ on }">
                                                <v-btn color="success" small :disabled="!selectedPlan" v-on="on" block pa-3>Change Plans</v-btn>
                                            </template>
                                            <v-card>
                                                <v-card-title class="headline">Change {{subscriptions.length}} Plans?</v-card-title>
                                                <v-card-text>Are you sure you want to change these plans?</v-card-text>
                                                <v-card-actions>
                                                    <v-spacer></v-spacer>
                                                    <v-btn color="error" text @click="confirm = false">No</v-btn>
                                                    <v-btn @click.prevent="changePlans" color="success" text :disabled="!selectedPlan">Change Plans</v-btn>
                                                </v-card-actions>

                                            </v-card>
                                        </v-dialog>
                                    </v-layout>
                                </v-flex>
                            </v-layout>

                          <v-layout v-else>
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-icon color="primary">fas fa-spinner fa-spin</v-icon>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                  <strong>
                                    {{ updateMessage }}
                                  </strong>
                                </v-list-item-content>
                            </v-list-item>


                          </v-layout>
                        </v-list>
                    </v-flex>

                </v-layout>









            </v-sheet>
        </v-navigation-drawer>
    </v-layout>
    </span>
</template>

<script>
import { formatMoney, formatDate, getCurrencySymbol } from '&/helpers.js';
import SubscriptionMixin from '../../mixins/Subscriptions/SubscriptionMixin'
import { mapGetters } from 'vuex';

export default {
  name: 'ChangeSubscriptionPlanModal',
  data() {
    return {
      dialog: false,
      confirm: false,
      selectedPlan: '',
      updating: false,
      updateMessage: ''
    }
  },
  mixins: [SubscriptionMixin],
  props: {
    subscriptions : {
      required: true,
      type: Array
    }
  },
  computed: {
    plans() {
      return Object.values(this.$store.getters.plans);
    },
    newPlan() {
      return this.$store.getters.plans[this.selectedPlan];
    },
    subscriptionPlanCannotBeChanged() {
      return this.updating || !this.subscriptions.length > 0;
    }

  },
  methods:{

    async updatePlan(subscriptionId) {
      return this.changeSubscriptionPlansAtStripe({subscriptionId, planId: this.selectedPlan});
    },
    async changePlans() {
      this.confirm = false;
      const planId = this.selectedPlan;
      this.updating = true;

      for(let i = 0; i < this.subscriptions.length; i++) {
        this.updateMessage = `${i + 1} subscriptions updated`;
        const subscriptionId = this.subscriptions[i].c4k.recurringPaymentSubscriptionId;
        await this.changeSubscriptionPlansAtStripe({ subscriptionId, planId });
      }

      this.dialog = false;
      this.selectedPlan = '';
      this.updating = false;
    },
    formatMoney(value) {
      return formatMoney(value);
    },
    planText(plan) {
      return `${plan.name} - ${formatMoney(plan.cost)} / ${plan.frequency}`
    }
  }
}
</script>

<style scoped>

</style>
