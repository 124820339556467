<template>
  <v-card class="mt-4 mb-4">
    <v-list-item :href="link" class="py-2">
      <v-list-item-avatar>
        <v-icon color="accent">fas fa-camera-alt</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>
          <h6 class="title text-truncate">{{ title }}</h6>
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ subtitle }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-btn icon ripple>
          <v-icon color="accent" size="16">fas fa-chevron-right</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
  </v-card>
</template>
<script>
export default {
  name: 'PhotoPermissionAvailableBanner',
  data() {
    return {};
  },
  props: {},
  computed: {
    icon() {
      return this.icons[this.resource.type];
    },
    title() {
      return 'Photo Permissions';
    },
    subtitle() {
      return 'Update your Photo Permissions';
    },
    link() {
      return '/permissions';
    },
  },
};
</script>

<style scoped></style>
