<template>
    <v-tooltip bottom v-if="countOfBookings > 0" >
        <template v-slot:activator="{ on }">
            <span v-on="on" style="cursor:pointer">{{ countOfBookings}}</span>
        </template>
        <span v-html="childNamesFormatted"></span>
    </v-tooltip>
</template>

<script>


export default {
  name: 'Bookings',
  data() {
    return {
    };
  },
  props: {
    subscription: {
      required: true,
      type: Object,
    },
  },
  computed: {
    parent() {
      return this.subscription.parent;
    },
    bookings() {
      return this.parent.bookings;
    },
    children() {
      return this.parent.children;
    },
    childNamesFormatted() {
      let text = '';

      if (this.children) {
        const children = Object.values(this.children);
        const countOfChildren = children.length;
        children.forEach((child, index) => {
          const childFullName = `${child.firstName} ${child.lastName}`;
          text += childFullName;
          index < (countOfChildren - 1) ? text += '<br />' : '';
        });
      }

      return text;
    },
    countOfBookings() {
      return this.parent.bookingCount;
    },
  },
  methods: {
  },
  components: {
  },
};
</script>
