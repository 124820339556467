
import Vue from 'vue';

export default {
  state: {
    subscriptions: {},
    cancelled: {},
    notSetUp: {},
    plans: {},
    summary: {
      totals: {
        successfulSubscriptions: 0,
        waitingSubscriptions: 0,
        problemSubscriptions: 0,
        cancelledSubscriptions: 0,
        total: 0,
      },
      activeTotal: '',
      problemTotal: 0,
      thisMonth: '',
    },

  },
  actions: {

    async GET_STRIPE_SUBSCRIPTIONS({ dispatch }, payload) {
      const { subscriptionIds, localLoading } = payload;

      const request = {
        url: '/api/v4/subscriptions/stripe-subscriptions',
        parameters: {
          subscriptionIds,
        },
      };
      const mutation = 'SET_STRIPE_SUBSCRIPTIONS';
      const options = {
        request,
        mutation,
        localLoading,
      };

      await dispatch('MAKE_POST_REQUEST', options);
    },

    async GET_SUBSCRIPTION_UPCOMING_INVOICES({ dispatch }, payload) {
      const { customerIds, localLoading } = payload;

      const request = {
        url: '/api/v4/subscriptions/stripe-upcoming-invoices',
        parameters: {
          customerIds,
        },
      };
      const mutation = 'SET_UPCOMING_INVOICES';
      const options = {
        request,
        mutation,
        localLoading,
      };

      await dispatch('MAKE_POST_REQUEST', options);
    },

    async GET_ALL_SUBSCRIPTIONS({ dispatch }, { localLoading }) {
      const request = {
        url: '/api/v4/subscriptions/all',
      };
      const mutation = 'SET_SUBSCRIPTIONS';
      const options = {
        request,
        mutation,
        localLoading,
      };
      await dispatch('MAKE_GET_REQUEST', options);
    },

    async GET_SUCCESSFUL_SUBSCRIPTIONS({ dispatch }, { localLoading }) {
      const request = {
        url: '/api/v4/subscriptions/successful',
      };
      const mutation = 'SET_SUCCESSFUL_SUBSCRIPTIONS';
      const options = {
        request,
        mutation,
        localLoading,
      };
      await dispatch('MAKE_GET_REQUEST', options);
    },

    async GET_SUBSCRIPTIONS_NOT_SET_UP({ dispatch }, { localLoading }) {
      const request = {
        url: '/api/v4/subscriptions/not-set-up',
      };
      const mutation = 'SET_SUBSCRIPTIONS_NOT_SET_UP';
      const options = {
        request,
        mutation,
        localLoading,
      };
      await dispatch('MAKE_GET_REQUEST', options);
    },

    async GET_WAITING_SUBSCRIPTIONS({ dispatch }, { localLoading }) {
      const request = {
        url: '/api/v4/subscriptions/waiting',
      };
      const mutation = 'SET_WAITING_SUBSCRIPTIONS';
      const options = {
        request,
        mutation,
        localLoading,
      };
      await dispatch('MAKE_GET_REQUEST', options);
    },

    async GET_PROBLEM_SUBSCRIPTIONS({ dispatch }, { localLoading }) {
      const request = {
        url: '/api/v4/subscriptions/problem',
      };
      const mutation = 'SET_PROBLEM_SUBSCRIPTIONS';
      const options = {
        request,
        mutation,
        localLoading,
      };
      await dispatch('MAKE_GET_REQUEST', options);
    },

    async GET_CANCELLED_SUBSCRIPTIONS({ dispatch }, { localLoading }) {
      const url = '/api/v4/subscriptions/cancelled';
      const request = {
        url,
      };
      const mutation = 'SET_CANCELLED_SUBSCRIPTIONS';
      const options = {
        request,
        mutation,
        localLoading,
      };
      await dispatch('MAKE_GET_REQUEST', options);
    },

    async GET_PAYMENT_PLANS({ dispatch }, payload) {
      const request = {
        url: '/api/v4/subscriptions/plans',
      };
      const mutation = 'SET_PAYMENT_PLANS';
      const options = {
        request,
        mutation,
      };
      await dispatch('MAKE_GET_REQUEST', options);
    },
    async GET_SUBSCRIPTION_COVERING_PAYMENTS({ dispatch }, { recurringPaymentSubscriptionId, localLoading }) {
      const request = {
        url: `/api/v4/subscriptions/covering-payments/${recurringPaymentSubscriptionId}`,
      };
      const mutation = 'SET_SUBSCRIPTION_COVERING_PAYMENTS';
      const options = {
        request,
        mutation,
        localLoading,
      };
      await dispatch('MAKE_GET_REQUEST', options);
    },

    async GET_SUBSCRIPTIONS_SUMMARY({ dispatch }) {
      const request = {
        url: '/api/v4/subscriptions/summary',
      };
      const mutation = 'SET_SUBSCRIPTIONS_SUMMARY';
      const options = {
        request,
        mutation,
      };
      await dispatch('MAKE_GET_REQUEST', options);
    },

  },
  mutations: {

    SET_STRIPE_SUBSCRIPTIONS(state, payload) {
      const { subscriptions } = state;

      for (const key in payload) {
        const subscription = Object.values(subscriptions).find((subscription) => subscription.c4k.stripeSubscriptionId === key);
        Vue.set(subscription, 'stripe', payload[key].stripe);
      }
    },
    SET_UPCOMING_INVOICES(state, payload) {
      const { subscriptions } = state;
      for (const key in payload) {
        const subscription = Object.values(subscriptions).find((subscription) => subscription.c4k.stripeSubscriptionId === key);
        if (subscription) {
          Vue.set(subscription.invoices, 'upcoming', payload[key].invoices.upcoming);
        }
      }
    },

    SET_SUBSCRIPTIONS(state, payload) {
      state.subscriptions = payload;
    },

    SET_SUBSCRIPTIONS_NOT_SET_UP(state, payload) {
      state.notSetUp = payload;
    },
    SET_CANCELLED_SUBSCRIPTIONS(state, payload) {
      state.cancelled = payload;
    },
    SET_PAYMENT_PLANS(state, payload) {
      state.plans = payload;
    },
    SET_SUBSCRIPTIONS_SUMMARY(state, payload) {
      state.summary = payload;
      state.plans = payload.plans;
    },
  },
  getters: {
    allSubscriptions(state) {
      return state.subscriptions;
    },
    successfulSubscriptions(state) {
      return Object.values(state.subscriptions).filter((subscription) => subscription.status === 'active');
    },
    notSetUpSubscriptions(state) {
      return state.notSetUp;
    },
    waitingSubscriptions(state) {
      return Object.values(state.subscriptions).filter((subscription) => subscription.status === 'waiting');
    },
    problemSubscriptions(state) {
      return Object.values(state.subscriptions).filter((subscription) => subscription.status === 'problem');
    },
    cancelledSubscriptions(state) {
      // filter out active, waiting and problem subs - should update this to be done in the BE
      return Object.values(state.cancelled).filter((subscription) => !state.subscriptions[subscription.parent.personId]);
    },
    plans(state) {
      return state.plans;
    },
    summary(state) {
      return state.summary;
    },
    // UI Stuff
    table(state) {
      return {
        headers: [
          {
            text: 'Name',
            align: 'left',
            value: 'parent.firstName',
            sortable: true,
          },
          { text: 'Collection', value: 'collection', sortable: false },
          { text: 'Plan', value: 'plan' },
          { text: 'Last Payment Attempt', value: 'lastPaid' },
          { text: 'Next Invoice', value: 'subscription.invoices' },
          { text: 'Bookings', value: 'parent.bookingCount' },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
      };
    },

    // IF THIS WORKS REPLACE WITH ITS OWN CALL
    people(state) {
      const people = {};
      const { subscriptions } = state;
      Object.values(subscriptions).forEach((subscription) => {
        people[subscription.parent.personId] = subscription.parent;
      });

      return people;
    },
  },
};
