const ClientInvoicesPage = () => import(/* webpackPrefetch: true */ './../pages/Client/ClientInvoicesPage');
const ClientInvoiceDetailPage = () => import(/* webpackPrefetch: true */ './../pages/Client/ClientInvoiceDetailPage');
const ClientUpcomingInvoiceDetailPage = () => import(/* webpackPrefetch: true */ './../pages/Client/ClientUpcomingInvoiceDetailPage');

const ClientInvoiceSMSLineItemsDetailPage = () => import(/* webpackPrefetch: true */ './../pages/Client/ClientInvoiceSMSLineItemsDetailPage');


export default [
  {
    component: ClientInvoicesPage,
    path: '/cms/settings/invoices',
    name: 'ClientInvoicesPage',
    meta: {
      title: 'Billing',
    },
  },
  {
    component: ClientUpcomingInvoiceDetailPage,
    path: '/cms/settings/invoices/upcoming',
    name: 'ClientUpcomingInvoiceDetailPage',
    meta: {
      title: 'Upcoming Invoice',
    },
  },

  {
    component: ClientInvoiceDetailPage,
    path: '/cms/settings/invoices/:invoiceId',
    name: 'ClientInvoiceDetailPage',
    meta: {
      title: 'Invoice',
    },
  },

  {
    component: ClientInvoiceSMSLineItemsDetailPage,
    path: '/cms/settings/invoices/:invoiceId/billing',
    name: 'ClientInvoiceSMSLineItemsDetailPage',

    meta: {
      title: 'Custom SMS',
    },
  },
];
