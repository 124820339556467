const url = '/rest/v1/client/settings/custom-registers';

export default {
    state: {
        url: '/rest/v1/client/settings/custom-registers',
        customRegisterOptions: {}
    },
    actions: {
        async GET_CUSTOM_REGISTER_OPTIONS({commit}, payload) {
            const headers = {'Content-Type': 'application/json'};
            const requestParams = {
                method: 'GET',
                credentials: 'same-origin',
                headers
            }

            const request = new Request(url, requestParams);
            const response = await fetch(request);
            const {data} = await response.json();            
            commit('SET_CUSTOM_REGISTER_OPTIONS', data);
        },
        async CREATE_CUSTOM_REGISTER_OPTION({commit}, payload) {
            const {name, type, options} = payload
            const headers = {'Content-Type': 'application/json'};
            const requestParams = {
                method: 'POST',
                credentials: 'same-origin',
                body: JSON.stringify({name, type, options}),
                headers
            }

            const request = new Request(url, requestParams);
            const response = await fetch(request);
            const json = await response.json();
            return json;
        },
        async UPDATE_CUSTOM_REGISTER_OPTION({commit}, payload) {
            const {contactAttributeId, name, type, options, active} = payload
            const headers = {'Content-Type': 'application/json'};
            const requestParams = {
                method: 'PUT',
                credentials: 'same-origin',
                body: JSON.stringify({contactAttributeId, name, type, options, active}),
                headers
            }

            const request = new Request(url, requestParams);
            const response = await fetch(request);
            const json = await response.json();
            return json;
        }
    },
    mutations: {
        SET_CUSTOM_REGISTER_OPTIONS(state, payload) {
            state.customRegisterOptions = payload;
        }
    },
    getters:{
        customRegisterOptions(state) {
            return state.customRegisterOptions;
        }
    }
}