<template>
  <v-card>
    <v-snackbar :multi-line="multiLine" v-model="show" :color="snackBarColor" :timeout="6000" top="top">
      <span class="pr-2">
        {{ snackbarIcon }}
      </span>
      {{ messageProperty }}
      <v-btn dark text @click="hideSnackBar">
        Close
      </v-btn>
    </v-snackbar>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapMutations } from 'vuex';

export default {
  name: 'NotificationSnackbar',
  data() {
    return {
      snackbarOnOff: false
    };
  },

  watch: {
    // show(value) {
    //   if (value) {
    //     this.showSnackbar();
    //   }
    // }
  },
  computed: {
    ...mapGetters([
      'loading',
      'snackbar',
      'snackbarStatus',
      'snackbarMessage',
      'snackbarIcon'
    ]),
    multiLine() {
      if(this.snackbarMessage){
       if(this.snackbarMessage.length > 47){
         return true;
       }else {
         return false;
       }
      }
    },
    snackBarColor() {
      if (this.snackbarStatus) {
        return this.snackbarStatus;
      }
    },
    messageProperty() {
      if (this.snackbarMessage) {
        return this.snackbarMessage;
      }
    },
    show: {
      get() {
        return this.snackbar.show;
      },
      set(value) {
        this.$store.commit('RESET_ERROR_STATE');
      }
    }
  },
  components: {},

  methods: {
    showSnackbar() {
      this.snackbarOnOff = true;
    },
    hideSnackBar() {
      this.$store.commit('RESET_ERROR_STATE');
    }
  },
  mounted() {},
  updated() {}
};
</script>

<style scoped>
img {
  max-width: 60px;
}
</style>
