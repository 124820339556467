export default {
  en: {
    button_loading: 'Loading...',
    button_next_stage: 'Next',
    continue_button_prompt: 'Continue'
  },
  fr: {
    button_loading: 'Chargement...',
  },

};
