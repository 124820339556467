import Vue from 'vue';

export default {
  state: {
    paymentResponse: {},
    order: {
      balance: 0,
      clientId: null,
      clientName: null,
      email: null,
      numberOfCardsOnRecord: null,
      orderId: null,
      orderItems: {
        bookings: {},
      },

      personId: null,
      showCheckoutPage: null,
      status: {
        countryCode: '',
        currencyCode: ''
      },
      subDomain: null,
      subscriptionPaymentPlansToSetup: [],
      totals: {
        bookingsTotal: 0,
        discountsTotal: 0,
        membershipsTotal: 0,
        platformFee: 0,
        setupFeesTotal: 0,
        totalToPay: 0
      },
      value: null
    },
    paymentMethods: {},
    selectedCard: {},
  },
  actions: {
    async MAKE_PAYMENT_REQUEST_PAYMENT(context, payload) {
      const request = {
        url: payload.url,
        parameters: payload.parameters
      };
      const mutation = 'UPDATE_ORDER_DETAILS';
      const options = {
        request,
        mutation
      };

      return await context.dispatch('MAKE_POST_REQUEST', options);
    },

    UPDATE_SELECTED_CARD(context, payload) {
      context.commit('SET_SELECTED_CARD', payload);
    },

    async ADD_NEW_CARD(context, payload) {
      const request = {
        url: '/api/v4/people/person/add_new_payment_method',
        parameters: {
          token: payload.token
        }
      };
      const mutation = 'ADD_NEW_CARD';
      const message = {
        success: 'Card added',
        icon: '💳',
        info: true
      };
      const options = {
        request,
        mutation,
        message
      };
      return await context.dispatch('MAKE_POST_REQUEST', options);
    },

    async GET_ORDER(context, payload) {
      const request = {
        url: `/api/v4/orders/${payload.clientId}/${payload.orderId}`
      };
      const mutation = 'UPDATE_ORDER';
      const options = {
        request,
        mutation
      };
      await context.dispatch('MAKE_GET_REQUEST', options);
    }
  },

  mutations: {
    UPDATE_ORDER_DETAILS(state, payload) {
      return (state.paymentResponse = payload);
    },

    SET_SELECTED_CARD(state, payload) {
      state.selectedCard = payload;
    },

    ADD_NEW_CARD(state, payload) {
    },

    UPDATE_ORDER(state, payload) {
      state.order = payload;
      state.paymentMethods = payload.paymentMethods;

      state.selectedCard = Object.values(state.paymentMethods).length > 0 ? Object.values(state.paymentMethods)[0] : {};
    }
  },

  getters: {

    paymentResponse(state) {
      return state.paymentResponse;
    },
    selectedCard(state) {
      return state.selectedCard;
    },

    order(state) {
      return state.order;
    },

    paymentMethods(state) {
      return state.paymentMethods;
    },
    countryCode(state) {
      return state.order.status.countryCode;
    },
    currencyCode(state) {
      return state.order.status.currencyCode;
    },

  }
};
