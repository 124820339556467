const SetupPaymentPlan = () => import(/* webpackPrefetch: true */ './../pages/NewCustomerSubscription/SetupPaymentPlanPage');
const SubscriptionSetupPage = () => import(/* webpackPrefetch: true */ './../pages/NewCustomerSubscription/SubscriptionSetupPage');

export default [
  {
    component: SetupPaymentPlan,
    path: '/cms/settings/setup-payment-plan',
    name: 'SetupPaymentPlan',

    meta: {
      title: 'Subscription Billing',
    },
  },

  {
    component: SubscriptionSetupPage,
    path: '/cms/settings/setup-payment-plan/successful',
    name: 'SubscriptionSetupPage',

    meta: {
      title: 'Subscription Successful',
    },
  },
];
