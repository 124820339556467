import ClientBillingPage from '../pages/Client/Billing/ClientBillingPage';

export default [
  {
    component: ClientBillingPage,
    path: '/billing',
    name: 'ClientBillingPage',
    meta: {
      title: 'Billing',
    },
  },
];
