<template>
    <span v-html="textFormatted"></span>
</template>

<script>
import config from '../assets/text/index';

export default {
  name: 'Dictionary',
  data() {
    return {
      isStringRegExp: new RegExp(/[a-zA-Z\_]+/g),
      findCurlyBracesRegExp: new RegExp(/[^{\{]+(?=}\})/g),
    };
  },
  props: {
    module: {
      required: true,
      type: String,
    },
    string: {
      required: true,
      type: String,
      default() {
        return '';
      },
    },
    replace: {
      required: false,
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    interpolateString(stringToReplace) {
      const variables = this.getVariables(stringToReplace);
      let interpolatedString = stringToReplace;
      variables.forEach((variable) => {
        const replacement = this.replace[variable];
        if (replacement) {
          interpolatedString = this.findAndReplaceString(interpolatedString, variable, replacement);
        }
      });
      return interpolatedString;
    },
    getVariables(stringToReplace) {
      return stringToReplace.match(this.findCurlyBracesRegExp)
        ? stringToReplace.match(this.findCurlyBracesRegExp)
        : [];
    },
    findAndReplaceString(string, find, replace) {
      if (this.isStringRegExp.test(string)) {
        // eslint-disable-next-line no-useless-escape
        return string.replace(new RegExp(`\{\{(?:\\s+)?(${find})(?:\\s+)?\}\}`), replace);
      }
      // eslint-disable-next-line no-useless-escape
      throw new Error('Find statement does not match regular expression: /[a-zA-Z\_]+/');
    },
  },
  computed: {
    textFormatted() {
      const string = config[this.module][this.locale][this.string];
      return this.interpolateString(string);
    },
    locale(){
      return this.$vuetify ? this.$vuetify.lang.current : 'en';
    },
  },
};
</script>

<style scoped>

</style>
