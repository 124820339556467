<template>
    <v-container fluid>
        <v-row>
            <v-col xs12>
                <!-- <OfflineBanner /> -->
                  <router-view></router-view>
            </v-col>

        </v-row>
    </v-container>

</template>

<script>
import OfflineBanner from '../components/OfflineRegisters/OfflineBanner.vue';

export default {
  name: 'OfflinRegistersLayout',
  data() {
    return {};
  },
  methods: {
    handleResize() {
      this.$store.dispatch('HANDLE_RESIZING');
    },
    toggleSpeech() {
      this.$store.dispatch('TOGGLE_SPEECH');
    },
    goBack() {
      this.$router.go(-1);
    },
  },
  components: {
    OfflineBanner,
  },
  computed: {
    speech() {
      return this.$store.getters.speech;
    },
    speechIcon() {
      if (this.speech) {
        return '<i class="fal fa-volume-up fa-lg"></i>';
      }
      return '<i class="fal fa-volume-mute fa-lg"></i>';
    },
    canGoBack() {
      return this.$route.name != 'Schedule';
    },
  },
  created() {
    window.addEventListener('resize', this.handleResize);
  },
};
</script>


<style lang="scss">
@import '../assets/scss/variables';
[v-cloak] {
  display: none;
}

#back a {
  color: $primary;
  text-decoration: none;
}

/*styles*/
.label {
  border-radius: 8px;
  padding: 4px;
  color: #fff !important;
  font-size: 0.7rem;
  font-weight: 700;
}

.speech {
  text-align: right;
  margin-right: 32px;
}

.speech a {
  text-decoration: none;
  color: #fff;
}
</style>
