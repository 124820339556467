<template>
  <v-row dense no-gutters>
    <v-col cols="12">
      <v-row no-gutters dense>
        <v-col cols="8" xl="11" lg="10" md="10" sm="9" align-self="center">
          <v-row dense no-gutters>
            <v-col cols="12" class="my-1">
              <v-btn class="ml-5" color="pink" fab small depressed>
                <strong> {{ selectedContacts.length }} </strong>
              </v-btn>
              <strong>Contacts Selected</strong>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="4" xl="1" lg="2" md="2" sm="3">
          <v-row dense no-gutters justify="start">
            <v-col cols="6" class="mt-1" align="start">
              <ContactsSendEmailForm />
            </v-col>

            <v-col cols="6" align="end">
              <ContactsSendSmsForm class="mt-1" v-if="showSms" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import ContactsSendEmailForm from '../../components/Contacts/ContactsSendEmailForm.vue';
import ContactsSendSmsForm from '../../components/Contacts/ContactsSendSmsForm.vue';
import FilteredContactsMixin from '../../components/Contacts/Mixins/FilteredContactsMixin.vue';

export default {
  name: 'ContactsBottomNavigation',
  components: {
    ContactsSendEmailForm,
    ContactsSendSmsForm,
  },
  props: {},
  mixins: [FilteredContactsMixin],
  data() {
    return {};
  },

  computed: {
    ...mapGetters(['settings']),
    showAlternativeText() {
      return this.selectedContacts.length === 1;
    },
    smsButtonStyle() {
      return this.$vuetify.breakpoint.mdAndUp ? 'my-1 ml-5' : 'my-1';
    },
    childIdsOfSelectedKids() {
      return this.selectedContacts.map((item) => item.childId);
    },
    showSms() {
      return this.settings.features.sms;
    },
  },
  methods: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  updated() {},
};
</script>

<style scoped></style>
