<template>
    <div v-if="show" class="install" @click="triggerAddToHomeScreen">
      <div class="message">
        <p>
          Download this to your device for a better experience
        </p>
      </div>
      <div class="icon">
        <i class="fal fa-arrow-alt-circle-down fa-2x" style="color: #fff"></i>
      </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'InstallToDevice',
  data() {
    return {
      show: false,
      installPromptEvent: {}

    };
  },
  methods: {
    triggerAddToHomeScreen() {
      this.installPromptEvent.prompt();
      this.show = false;
    }
  },

  computed: {

  },
  components: {

  },
  created: function() {
  window.addEventListener('beforeinstallprompt', (event) => {
    // Prevent Chrome <= 67 from automatically showing the prompt
    event.preventDefault();
    // Stash the event so it can be triggered later.
    this.installPromptEvent = event;
    // Update the install UI to notify the user app can be installed

    // console.log(this.installPromptEvent);
    this.show = true;
  });
  }
};
</script>

<style lang="scss" scoped>
/*@import '~@/variables.scss';*/

.install {
  display: grid;
  grid-template-columns: 7fr 1fr;
  align-items:center;
}


.message p {
  color: #fff;
  font-weight: 600;
}

.icon {
  text-align: center;
}

</style>
