<template>

  <div>
    <v-row dense>
      <v-col>
      <label for="hiddenUsernameForBrowserAutocomplete" v-show="false"></label>
      <input id="hiddenUsernameForBrowserAutocomplete"
             type="text" autocomplete="username" :value="username" v-show="false" />
        <PasswordInput
          v-model="password"
          :rules="passwordRules"
          :autocomplete="'current-password'"
          :autofocus="true"
          required
          :errorMessageState="errorMessage.state"
          :errorMessageValue="errorMessage.value"
          tabindex="2"
              >

      </PasswordInput>
        <v-checkbox class="mt-0" label="Stay signed in for 1 week"
                    v-model="rememberMe">
          <template v-slot:label>
            <span class="primary--text">
              <Dictionary module="login" string="login_remember_me_text" />
            </span>
          </template >
        </v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <Button
            :disabled="!valid"
            block
            @click.prevent="login()"
            :loading="loading"
            type="submit"
            tabindex="3"
        >
          <Dictionary module="login" string="login_button_prompt" />
        </Button>
      </v-col>
    </v-row>
    <v-row dense class="mb-5">
      <v-col
          class="text-center"
      >
        <router-link
            data-test="forgotPassword"
            :to="{name: 'ForgotPassword', params: {username}}"
        >
          <Dictionary module="forgotPassword" string="forgot_password_prompt" />
        </router-link>
      </v-col>
    </v-row>
  </div>

</template>

<script>

import Vue from 'vue';
import { mapGetters } from 'vuex';
import { passwordValueRequiredRule } from '../../../helpers/rules/password';

import { setCookie } from '../../../../services/cookies';

export default Vue.extend({
  name: 'PasswordAuthentication',
  data() {
    return {
      password: '',
      rememberMe: true,
      loading: false,
      passwordRules: [
        passwordValueRequiredRule,
      ],
    };
  },
  props: {
    username: {
      required: true,
      type: String,
    },
    valid: {
      required: true,
      type: Boolean,
    },

  },
  computed: {
    ...mapGetters(['loginErrorMessage', 'loginErrorMessageState']),
    errorMessage() {
      return {
        value: this.loginErrorMessageState ? this.loginErrorMessage : '',
        state: this.loginErrorMessageState,
      };
    },
  },
  methods: {
    handleRememberMe(rememberMe) {
      if (rememberMe !== null) {
        setCookie(rememberMe.name, rememberMe.value, rememberMe.expiry, rememberMe.domain);
      }
    },
    handleSuccessfulLoginRequest(response) {
      if (response.code === 201) {
        this.handleRememberMe(response.data.rememberMe);

        if (response.data.redirect !== null) {
          window.location.href = response.data.redirect.url;
        } else {
          this.$router.push({ name: 'ResourcesPage' });
        }
      } else {
        this.loading = false;
      }
    },
    async login() {
      this.loading = true;
      const data = {
        username: this.username,
        password: this.password,
        rememberMe: this.rememberMe,
      };
      const response = await this.$store.dispatch('LOGIN_USER', data);

      this.handleSuccessfulLoginRequest(response);
    },
  },

  watch: {
    password(){
      if (this.loginErrorMessageState) {
        this.$store.commit('UPDATE_LOGIN_ERROR_MESSAGE_STATE', false);
      }
    },
  },

  created() {},

  mounted() {},

  updated() {},

  components: {

  },
});
</script>

<style scoped>
.data-v-e13200fe{

}
</style>
