<template>
  <transition name="list" appear>
  <div class="booking" :class="{background: showBackgroundColor}">
    <div class="names">
      <p class="childDetails">
        {{name}}
      </p>

      <div class="parent">
        <p class="parentDetails">
          {{parentName}}
        </p>

        <p class="parentDetails">
          <a :href="bookingMobileFormatted">
            {{booking.mobile}}
          </a>
        </p>

      </div>


    </div>

  	<AttendanceCheckbox class="attendance" v-for="instance in runInstances" :run-instance-id="instance.runInstanceId" :attendance-data="booking" :key="instance.runInstanceId"/>
  </div>
</transition>
</template>

<script>
'use strict';
import AttendanceCheckbox from './AttendanceCheckbox';
export default {
  name: 'RunBooking',
  data () {
    return {
    }
  },

  computed: {
  	name () {
  		return `${this.booking.firstName} ${this.booking.lastName}`;
  	},

    parentName() {
      const parentName = `${this.booking.parentFirstName} ${this.booking.parentLastName}`;
      if(parentName != this.name) {
        return parentName;
      }
    },

    bookingMobileFormatted() {
      return 'tel:' + this.booking.mobile;
    },

  	runInstances () {
  		return this.$store.getters.run.runInstances;
  	},
  	attendanceData () {
  		return this.booking.bookings
  	},

    showBackgroundColor() {
      return this.index % 2 == 0;
    },
  },

  props: {
  	booking: {
  		type: Object,
  		required: true
  	},
    index: {
      required: true,
      type: Number
    }
  },

  components: {
  	AttendanceCheckbox
  },

  mounted() {

  },

  created(){

  }

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

@import './../../../assets/scss/variables.scss';

.list-enter-active, .list-leave-active {
  transition: all 0.1s;
}

.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  // transform: translateY(10px);
}



.attendance {
  text-align: center;
}

.names {
  margin-left: 16px;
}

.names p {
  letter-spacing: 0.05rem;
}

.childDetails {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 0.9rem;
}

.parent {
  display: grid;
  grid-template-columns: 1fr;
}

.parentDetails {
  margin: 0 0 8px 0;
  font-size: 0.8rem;
  letter-spacing: 0.1rem;
  font-size: 0.8rem;

}

.parentDetails a {
  text-decoration: none;
  // color: $cfk-pink;
}

.background {
  background-color: #F4F4F4;

}

@media (min-width: 640px) {

  .parent {
    display: grid;
    grid-template-columns: 5fr 3fr;

  }

}








</style>
