<template>


<div>
  
</div>


</template>

<script>



export default {
  name: 'ResetCache',
  data () {
    return {
      loading: false
    }
  },
  methods: {
    async resetCache() {
      const keys = await caches.keys();
      for(let i = 0; i < keys.length; i++) {
        await caches.delete(keys[i]);
      }
    }
  },

  computed: {

  },
  components: {

  },
  created: function () {
    this.resetCache()
      .then(this.$router.push({ name: 'Schedule'}));
  }
}


</script>

<style scoped>

.banner {
  padding: 16px;
  display: grid;
  align-items: center;
  grid-auto-rows: 64px;
}

.banner.online {
  background-color: #03a9f4;
  transition-property: all;
  transition-duration: .5s;
  padding: 8px 32px 8px 32px;
  grid-template-columns: 7fr 1fr;

}

.banner.offline {
  background-color: #ff5722;

  transition-property: all;
  transition-duration: .5s;
  grid-template-columns: 1fr 1fr;

}

.syncButton {
  text-decoration: none;
  cursor: pointer;
  color: #fff;
}


.message {
  padding: 0;
  margin: 0;
  font-size: 1.2rem;
  color: #fff;
}




</style>