import CreateClientPage from '../pages/SignUp/CreateClientPage.vue';
import CodeInputPage from '../pages/SignUp/CodeInputPage.vue';
import BetaCountrySignUpMessagePage from '../pages/SignUp/BetaCountrySignUpMessagePage.vue';
import SignUpInitialPage from '../pages/SignUp/SignUpInitialPage.vue';
import SignUpFormPage from '../pages/SignUp/SignUpFormPage.vue';
import SignInExistingClientPage from '../pages/SignUp/SignInExistingClientPage.vue';

export default [
  {
    component: CreateClientPage,
    path: '/create-client',
    name: 'CreateClientPage',
    meta: {
      title: 'Create Client',
      pageTitle: 'Create A System',
    },
  },
  {
    component: CodeInputPage,
    path: '/verification',
    name: 'CodeInput',
    meta: {
      title: 'Verification',
      pageTitle: 'Create A System',
    },
  },
  {
    component: BetaCountrySignUpMessagePage,
    path: '/beta-message',
    name: 'BetaCountrySignUpMessagePage',
    meta: {
      title: 'Welcome',
      pageTitle: 'Create A System',
    },
  },
  {
    component: SignUpInitialPage,
    path: '/sign-up',
    name: 'SignUpInitialPage',
    meta: {
      title: 'Sign Up',
      pageTitle: 'Create A System',
    },
  },
  {
    component: SignUpFormPage,
    path: '/details',
    name: 'SignUpFormPage',
    meta: {
      title: 'Details',
      pageTitle: 'Create A System',
    },
  },
  {
    component: SignInExistingClientPage,
    path: '/sign-in',
    name: 'SignInExistingClientPage',
    meta: {
      title: 'Sign In',
      pageTitle: 'Create A System',
    },
  },
];
