<template> 	
    <tr>
      	<td>{{paymentAmountFormatted}}</td>
      	<td>{{createdDateFormatted}}</td>
      	<td>{{paymentDateFormatted}}</td>
      	<td class="justify-center">
      		<v-dialog v-model="dialog" width="320">
      			<template v-slot:activator="{on: removeCoveringPayment}">
	                <v-btn flat icon v-on="{...removeCoveringPayment}">
	                    <v-icon>fal fa-trash-alt</v-icon>
	                </v-btn>
      			</template>

      			<v-card>
			        <v-card-title class="headline">Remove Pament?</v-card-title>
			        <v-card-text>Are you sure you want to remove this payment from the upcoming subscription?</v-card-text>
			        <v-card-actions>
			          <v-spacer></v-spacer>
			          <v-btn color="blue" flat @click="dialog = false">Cancel</v-btn>
			          <v-btn color="red" flat @click="removeCoveringPayment">Remove</v-btn>
			        </v-card-actions>
      			</v-card>
      		</v-dialog>
      	</td>

    </tr>
</template>

<script>

import { mapGetters } from 'vuex';
import { formatMoney, formatDate } from '&/helpers.js';
	
export default {
	name: 'SubscriptionCoveringPaymentLogListItem',
	data() {
		return {
			dialog: false
		}
	},
	props: {
		payment: {
			required: true,
			type: Object
		},
		recurringPaymentSubscriptionId: {
			required: true,
			type: String
		}
	},
	computed: {
		paymentAmountFormatted() {
			return formatMoney(this.payment.value)
		},
		createdDateFormatted() {
			return formatDate(this.payment.created);
		},
		paymentDateFormatted() {
			if(this.payment.invoiceItem) {
				return formatDate(this.payment.invoiceItem.period.end)
			}
		}

	},
	methods: {


	},
	mounted() {
      	
	},
	components: {
		
	}





}
</script>