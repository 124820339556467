import { postPasswordResetRequest, postNewPasswordForReset, postMagicLinkRequest } from '../../services/authentication/resetPassword';

const getDefaultResetPasswordState = () => ({
  passwordSent: false,
});

export default {
  state: getDefaultResetPasswordState(),
  actions: {
    async REQUEST_PASSWORD_RESET({ commit }, payload) {
      const json = await postPasswordResetRequest(payload);
      if (json.code !== 200) {
        commit('SET_ERROR_NOTIFICATION', json.data);
      } else {
        const handleSnackbar = {
          message: {
            success: json.data.message,
            icon: '😀',
            info: 'info',
          },
        };
        commit('SET_SUCCESS_NOTIFICATION', handleSnackbar);
      }
      commit('UPDATE_PASSWORD_EMAIL_SENT', json);
      return json;
    },

    async UPDATE_PASSWORD({ commit }, payload) {
      const json = await postNewPasswordForReset(payload);
      if (json.code !== 200) {
        commit('SET_ERROR_NOTIFICATION', json.data);
      } else {
        const handleSnackbar = {
          message: {
            success: json.data.message,
            icon: '😀',
            info: 'success',
          },
        };
        commit('SET_SUCCESS_NOTIFICATION', handleSnackbar);
      }
      return json;
    },

    async PROCESS_MAGIC_LINK({ commit }, payload) {
      const json = await postMagicLinkRequest(payload);
      if (json.code !== 201) {
        commit('SET_ERROR_NOTIFICATION', json.data);
      }
      return json;
    },
  },

  mutations: {
    UPDATE_PASSWORD_EMAIL_SENT(state) {
      state.passwordSent = true;
    },
    UPDATE_PASSWORD_EMAIL_NOT_SENT(state) {
      state.passwordSent = false;
    },
    RESET_RESETPASSWORD_STATE(state) {
      Object.assign(state, getDefaultResetPasswordState());
    },
  },

  getters: {
    passwordSent({ passwordSent }) {
      return passwordSent;
    },
  },
};
