<template>
  <v-card class="mt-2" transition="slide-y-transition">
    <v-toolbar v-bind:color="activityTypeIn" dark>
      <v-toolbar-title
        >{{ run.class }} | {{ classDay }} {{ classStartTime }} -
        {{ classEndTime }}</v-toolbar-title
      >
    </v-toolbar>

    <v-row row wrap>
      <v-col cols="12" md="6">
        <v-list two-line>
          <v-list-item @click="copyToClipboard(run.class)">
            <v-list-item-content>
              <v-list-item-title>Class Name</v-list-item-title>
              <v-list-item-subtitle v-html="run.class"></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="copyToClipboard(run.activity)">
            <v-list-item-content>
              <v-list-item-title>Activity</v-list-item-title>
              <v-list-item-subtitle
                v-html="run.activity"
              ></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="copyToClipboard(termTime)">
            <v-list-item-content>
              <v-list-item-title>Term</v-list-item-title>
              <v-list-item-subtitle v-html="termTime"></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="copyToClipboard(run.numberOfSessions)">
            <v-list-item-content>
              <v-list-item-title>Sessions</v-list-item-title>
              <v-list-item-subtitle
                v-html="run.numberOfSessions"
              ></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="copyToClipboard(schools)">
            <v-list-item-content>
              <v-list-item-title>Schools</v-list-item-title>
              <v-list-item-subtitle v-html="schools"></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="copyToClipboard(run.when)">
            <v-list-item-content>
              <v-list-item-title>When</v-list-item-title>
              <v-list-item-subtitle v-html="run.when"></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="copyToClipboard(run.venue)">
            <v-list-item-content>
              <v-list-item-title>Venue</v-list-item-title>
              <v-list-item-subtitle v-html="run.venue"></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
      <v-flex xs12 md6>
        <AsmoRunTable :run="run" :activityTypeClass="activityTypeIn" />
      </v-flex>
    </v-row>
  </v-card>
</template>

<script>
import moment from 'moment';
import AsmoRunTable from './AsmoRunTable';

export default {
  name: 'AsmoRun',
  props: ['run'],
  data: function () {
    return {};
  },

  methods: {
    copyToClipboard: function (value) {
      // todo fix this to work with native copy function that most browsers support now
      return false;

      this.$copyText(value)
        .then((event) => {
          this.$store.commit('SET_SHOW_SNACKBAR', value);
          this.$store.commit('SET_COPIED_VALUE', value);
          console.log(event);
        })
        .catch((error) => {
          alert('something went wrong please try again');
        });
    },
  },
  computed: {
    schools: function () {
      return this.run.schools.toString();
    },

    classStartTime: function () {
      return moment(this.run.startTime * 1000).format('h:mma');
    },

    classDay: function () {
      return moment(this.run.startTime * 1000).format('ddd');
    },

    classEndTime: function () {
      return moment(this.run.endTime * 1000).format('h:mma');
    },
    termTime: function () {
      let startTime = moment(this.run.termStartDateTime * 1000).format(
        'ddd Do MMM YY '
      );
      let endTime = moment(this.run.termEndDateTime * 1000).format(
        'ddd Do MMM YY '
      );
      return startTime + ' - ' + endTime;
    },
    activityTypeIn: function () {
      var activities = ['sport'];
      if (!activities.includes(this.run.activityType)) {
        return 'warning';
      }
      return 'primary';
    },
  },

  components: {
    AsmoRunTable,
  },

  mounted: function () {},
};
</script>

<style></style>
