
const moment = require('moment');
// VUEX Store
export default {
  state: {
    asmo: {
      runs: [],
      participants: [],
      dates: {
        dateTo: null,
        dateFrom: null
      },
      snackbar: {
        show: false,
        timeout: 1000,
        value: ''
      },
      stats: []
    }
  },
  actions: {
    async GET_ASMO_REPORT(context, {dateFrom, dateTo}) {
      const request = {
        url: `/api/reports/ASMO/report?dateFrom=${dateFrom}&dateTo=${dateTo}`,
      };
      const mutation = 'UPDATE_ASMO_REPORT_DATA';
      const options = {
        request,
        mutation
      };
      await context.dispatch('MAKE_GET_REQUEST', options);
    },

    async GET_ASMO_RUNS_REPORT(context, payload) {
      const request = {
        url: `/api/reports/ASMO/runs?dateFrom=${dateFrom}&dateTo=${dateTo}`,
      };
      const mutation = 'UPDATE_ASMO_RUNS';
      const options = {
        request,
        mutation
      };
      await context.dispatch('MAKE_GET_REQUEST', options);


    },
    async GET_ASMO_DISTINCT_PARTICIPANTS(context, payload) {
      const request = {
        url: `/api/reports/ASMO/distinct-participants?dateFrom=${dateFrom}&dateTo=${dateTo}`,
      };
      const mutation = 'UPDATE_ASMO_DISTINCT_PARTICIPANTS';
      const options = {
        request,
        mutation
      };
      await context.dispatch('MAKE_GET_REQUEST', options);

    },

    UPDATE_DATE_FIELDS(context, payload) {
      context.commit('SET_DATES', payload);
    },

    async GET_MOCK_CLUBS(context, payload) {
      const url = '/api/manage/mockdata';
      const request = new Request(url, {
        credentials: 'same-origin',
        method: 'GET'
      });

      let result = await fetch(request);
      let response = await result.json();

      context.commit('UPDATE_MOCK_CLUBS', response.data);
    }
  },
  mutations: {
    UPDATE_ASMO_REPORT_DATA(state, payload) {
      state.asmo.runs = payload.runs;
      state.asmo.participants = payload.distinctParticipants;
    },
    UPDATE_ASMO_RUNS(state, payload) {
      state.asmo.runs = payload;
    },
    UPDATE_ASMO_DISTINCT_PARTICIPANTS(state, payload) {
      state.asmo.participants = payload;
    },

    SET_DATES(state, payload) {
      state.asmo.dates[payload.key] = payload.value;
    },

    SET_COPIED_VALUE(state, payload) {
      state.asmo.snackbar.value = payload;
    },

    SET_SHOW_SNACKBAR(state, payload) {
      state.asmo.snackbar.show = !state.snackbar.show;
    },
    UPDATE_MOCK_CLUBS(state, payload) {
      for (var i = 0; i < payload.length; i++) {
        let increaseAvailable =
          (parseInt(payload[i].totalOffline) / parseInt(payload[i].totalOnline) * 100).toFixed(2);
        let commission = (parseInt(payload[i].totalOnline) * payload[i].cRate) / 100;
        let potentialIncrease = ((commission * increaseAvailable) / 100).toFixed(2) ;
        let potentialTotal = (parseInt(commission) + parseInt(potentialIncrease));
        
        state.stats.push({
          absorbCharge: payload[i].absorbChange,
          clientName: payload[i].clientName,
          cRate: payload[i].cRate,
          totalOnline: payload[i].totalOnline,
          totalOffline: payload[i].totalOffline,
          totalPayments: parseInt(payload[i].totalOffline) + parseInt(payload[i].totalOnline),
          increaseAvailable: increaseAvailable,
          commission: commission,
          potentialIncrease: potentialIncrease,
          potentialTotal: potentialTotal
        });
      }
    }
  },

  getters: {
    asmo(state) {
      return state.asmo
    },
    participants(state) {
      return state.asmo.participants;
    },
    dateFrom(state) {
      return state.asmo.dates.dateFrom;
    },
    dateTo(state) {
      return state.asmo.dates.dateTo;
    },
    dateFromTimestamp(state) {
      if (state.asmo.dates.dateFrom) {
        return moment(state.asmo.dates.dateFrom).format('X');
      }
    },
    dateToTimestamp(state) {
      if (state.asmo.dates.dateTo) {
        return moment(state.asmo.dates.dateTo).format('X');
      }
    },
    copiedValue(state) {
      return state.asmo.snackbar.value;
    },
    snackbarShowing(state) {
      return state.asmo.snackbar.show;
    },
    snackbarTimeout(state) {
      return state.asmo.snackbar.timeout;
    },
    stats(state){
      return state.asmo.stats;
    }
  }
};
