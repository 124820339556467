<template>
    <v-sheet>

        <v-list dense subheader>
            <v-subheader>Parent</v-subheader>
            <v-list-item :href="`/cms/contacts/view/${parent.personId}`">
                <v-list-item-avatar color="success">
                    <!-- todo - FIX THIS -->
                    <span class="white--text">{{parent.firstName[0]}}{{ parent.lastName[0] }}</span>
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title>
                        {{ parent.firstName }}
                        {{ parent.lastName }}
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>

        <SubscriptionStatus v-if="stripe" />
        <ManageSubscriptionPlan v-if="stripe && activeSubscription && canEditSubscriptionCollectionOrPlan" />
        <ManagePaymentCollection  v-if="stripe && activeSubscription && canEditSubscriptionCollectionOrPlan" />
        <C4KSubscriptionPayments />
        <CancelSubscriptionButton v-if="stripe && activeSubscription" />
        <ReactivateSubscriptionButton v-if="user.isAdmin && stripe && !activeSubscription" />


    </v-sheet>
</template>

<script>
import { mapGetters } from 'vuex';

import C4KSubscriptionPayments from '../C4KSubscriptionPayments';
import SubscriptionMixin from '../../../mixins/Subscriptions/SubscriptionMixin';
import CancelSubscriptionButton from './CancelSubscriptionButton';
import ReactivateSubscriptionButton from './ReactivateSubscriptionButton';
import ManageSubscriptionPlan from './ManageSubscriptionPlan';
import ManagePaymentCollection from './ManagePaymentCollection';
import SubscriptionStatus from './SubscriptionStatus';

export default {
  name: 'ManageSubscriptionDetailList',
  mixins: [SubscriptionMixin],
  computed: {
    ...mapGetters(['_subscription', 'clientId', 'user', 'stripeSubscriptionStates']),
    // TO DO - fix this set up in the store
    parent() {
      return this._subscription.parent;
    },
    stripe() {
      return this._subscription.stripe;
    },
    c4k() {
      return this._subscription.c4k;
    },
    activeSubscription() {
      return this.stripeSubscriptionStates.includes(this.stripe.status);
    },
    subscriptionId() {
      return this.stripe.id;
    },
    couponValue() {
      if (this.discount.coupon.percent_off) {
        return `${this.discount.coupon.percent_off}% off`;
      }
      return `${getCurrencySymbol()}${this.discount.coupon.amount_off} off`;
    },
    couponDuration() {
      return this.discount.coupon.duration;
    },
    canEditSubscriptionCollectionOrPlan() {
      return this._subscription.status !== 'problem';
    },
  },

  components: {
    SubscriptionStatus,
    ManagePaymentCollection,
    ManageSubscriptionPlan,
    CancelSubscriptionButton,
    ReactivateSubscriptionButton,
    C4KSubscriptionPayments,
  },

  mounted() {
  },

};
</script>

<style scoped>


</style>
