<template>
    <v-row justify="center">
        <v-col cols="12" md="6">
            <v-card-text>
                <div class="text--primary bold">
                  <Dictionary module="testimonial" string="paragraph"/>
                </div>
            </v-card-text>
            <v-card-text>
              <Dictionary module="testimonial" string="client"/>
            </v-card-text>
        </v-col>
        <v-col cols="12" md="6">
            <v-img
                    src="../../../../../../../assets-prod/images/marketing/cms-preview.png">
            </v-img>
        </v-col>
    </v-row>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'SignUpTestimonial',
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
  beforeCreate() {
  },
  created() {
  },
  beforeMount() {
  },
  mounted() {
  },
  updated() {
  },
  destroyed() {
  },
});
</script>

<style scoped>
    .bold {
        font-weight: bold;
    }
</style>
