import ASMOReportsPage from '../pages/ASMO/AsmoReportPage';

export default [
  {
    component: ASMOReportsPage,
    path: '/cms/reporting',
    name: 'ASMOReportsPage',
    meta: {
      title: 'ASMO Report',
    },
  },
];
