<template>
    <v-data-table
		hide-actions
		:headers="headers"
		:items="coveringPayments"
		:loading="loading"
    >
    <template v-slot:items="coveringPayments">
      <SubscriptionCoveringPaymentLogListItem :payment="coveringPayments.item" :recurringPaymentSubscriptionId="recurringPaymentSubscriptionId" />
    </template>
    </v-data-table>   
</template>

<script>

import { mapGetters } from 'vuex';
import SubscriptionCoveringPaymentLogListItem from './SubscriptionCoveringPaymentLogListItem';
	
export default {
	name: 'SubscriptionCoveringPaymentLog',
	data() {
		return {
			headers:[
				{ text: 'Amount', value: 'value' },
				{ text: 'Date Added', value: 'created' },
				{ text: 'Payment Date', value: 'created' },				
				{ text: 'Actions', value: 'value', sortable: false },
			],
		}
	},
	props: {
		recurringPaymentSubscriptionId: {
			required: true,
			type: String
		}
	},
	computed: {
		...mapGetters(['loading']),
		coveringPayments() {
			return Object.values(this.$store.getters.coveringPayments);
		}
	},
	methods: {
		getCoveringPaymentsForSubscription() {
		const payload = {
		        recurringPaymentSubscriptionId: this.recurringPaymentSubscriptionId,
                localLoading: true
		      };
	    this.$store.dispatch('GET_SUBSCRIPTION_COVERING_PAYMENTS', payload);				
		}
	},
	mounted() {
      	this.getCoveringPaymentsForSubscription();
	},
	components: {
		SubscriptionCoveringPaymentLogListItem
	}





}
</script>