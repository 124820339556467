<template>
  <v-footer absolute padless color="transparent" class="login-footer elevation-2 mt-6">
    <v-card flat tile class="flex" color="transparent">
      <v-card-title >
        <div id="company-info">
          <a href="https://classforkids.io" style="text-decoration: none;">&copy;&nbsp;ClassForKids {{ new Date().getFullYear() }}</a> &nbsp;
          <br />
          <div id="privacy-info">
            <a href="https://classforkids.io/clubs/privacy-cookie-policy/">Privacy &amp; Cookie Policy</a>
            <v-dialog
                v-model="recaptchaDialog"
                width="500"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                >
                  <v-icon size="small">fas fa-lock</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="headline">
                  <v-img
                      src="/pwa-static/images/google_recaptcha.svg"
                      position="center"
                      contain
                      height="60"
                  ></v-img>
                </v-card-title>
                <v-card-text class="pt-4">
                  This site is protected by reCAPTCHA and the Google
                  <a href="https://policies.google.com/privacy">Privacy Policy</a> and
                  <a href="https://policies.google.com/terms">Terms of Service</a> apply.
                </v-card-text>

                <v-divider></v-divider>
                <v-card-actions>
                  <v-btn
                      block
                      text
                      @click="recaptchaDialog = false"
                  >
                    OK
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </div>
        <v-spacer></v-spacer>
        <div>
          <v-btn
              v-for="socialNetwork in socialNetworks"
              :key="socialNetwork.icon"
              class="mx-4"
              :color="socialNetwork.color"
              icon
              :href="socialNetwork.url"
              target="_blank"
          >
            <v-icon size="24px">
              {{ socialNetwork.icon }}
            </v-icon>
          </v-btn>
        </div>
      </v-card-title>
      <v-tooltip top close-delay="200">

      </v-tooltip>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  name: 'ProfileFooter',
  data() {
    return {
      recaptchaDialog: false,
      socialNetworks: [
        {
          name: 'Facebook',
          url: 'https://www.facebook.com/class4kids/',
          icon: 'fab fa-facebook',
          color: '#3A5895',
        },
        {
          name: 'Twitter',
          url: 'https://twitter.com/ClassForKids',
          icon: 'fab fa-twitter',
          color: '#1C9DEB',
        },
        {
          name: 'LinkedIn',
          url: 'https://www.linkedin.com/company/classforkids/',
          icon: 'fab fa-linkedin',
          color: '#0170AD',
        },
        {
          name: 'Instagram',
          url: 'https://www.instagram.com/classforkidsuk',
          icon: 'fab fa-instagram',
          color: '#C83085',
        },
        {
          name: 'YouTube',
          url: 'https://www.youtube.com/channel/UC9CPQZlLVDaIALJImun1Ing',
          icon: 'fab fa-youtube',
          color: '#F7021A',
        },
      ],
    };
  },
};
</script>

<style scoped>

.login-footer{
  border-top: 1px solid var(--v-background-darken1) !important;
}

#company-info {
  font-size: 1rem;
}

#privacy-info {
  font-size: 0.8rem;
}

</style>
