<template>
  <div>
    <component :is="authenticationMethodComponent" :username="username" :valid="valid" />
  </div>

</template>

<script>

import Vue from 'vue';
import PasswordAuthentication from './AuthenticationMethods/PasswordAuthentication.vue';
import WebAuthnAuthentication from './AuthenticationMethods/WebAuthnAuthentication.vue';

export default Vue.extend({
  name: 'AuthenticationMethod',
  data() {
    return {
      methods:
        {
          password: {
            component: 'PasswordAuthentication',
            type: 'password',
            label: 'Password',
          },
          webauthn: {
            component: 'WebAuthnAuthentication',
            type: 'webauthn',
            label: 'Web Authentication',
          },

        },
      selectedMethod: 'password',
    };
  },
  props: {
    username: {
      required: true,
      type: String,
    },
    valid: {
      required: true,
      type: Boolean,
    },

  },
  computed: {
    authenticationMethod() {
      return this.methods[this.selectedMethod];
    },
    authenticationMethodComponent() {
      return this.authenticationMethod.component;
    },
  },
  components: {
    WebAuthnAuthentication,
    PasswordAuthentication,
  },
});
</script>

<style scoped>

</style>
