export default {
  state: {
    globalLoading: false,
  },

  mutations: {
    SET_GLOBAL_LOADING(state, value) {
      state.globalLoading = value;
    },
  },
  getters: {
    _loading(state) {
      return state.globalLoading;
    },
  },


};
