<template>
    <v-row justify="center">
        <v-col cols="12" sm="8" md="6" lg="6" xl="6" class="mt-2">
            <v-dialog v-model="confirm" persistent>
                <template v-slot:activator="{ on }">
                    <v-btn block
                           small outlined color="error" v-on="on">
                        Cancel Subscription
                    </v-btn>
                </template>
                <v-card>
                    <v-card-title class="headline">Cancel Subscription?</v-card-title>
                    <v-card-text>We'll stop automatically taking payments from this parent,
                        are you sure you want to cancel this subscription?
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="info" text @click="confirm = false">No</v-btn>
                        <v-btn @click.prevent="cancelSubscription(subscriptionId)"
                               color="error"
                               text>Yes
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

        </v-col>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import SubscriptionMixin from '../../../mixins/Subscriptions/SubscriptionMixin';

export default {
  name: 'CancelSubscriptionButton',
  mixins: [SubscriptionMixin],
  data() {
    return {
      confirm: false,
    };
  },
  computed: {
    ...mapGetters(['_subscription', 'loading', 'user']),
    subscriptionId() {
      return this._subscription.stripe.id;
    },
  },
  mounted() {
  },
};
</script>

<style scoped>

</style>
