<template>
  <v-slide-x-transition>
    <v-icon
      v-show="mounted"
      class="text-center success--text"
      :style="{ fontSize: size + 'px' }"
    >
      far fa-check-circle
    </v-icon>
  </v-slide-x-transition>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'CfkGreenTickSuccess',
  components: {},
  props: {
    size: {
      type: Number,
      default: 12,
    },
    delay: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      mounted: false,
    };
  },
  computed: {},
  methods: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {
    setTimeout(() => {
      this.mounted = true;
    }, this.delay);
  },
  updated() {},
  destroyed() {},
});
</script>

<style scoped>
.col-12 {
  text-align: center;
}
</style>
