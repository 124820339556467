<template>
<v-list-item @click="">
  <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition"  pa-2>
    <template v-slot:activator="{ on: cancel }">
        <v-list-item-title v-on="{...cancel}" >Covering Payments</v-list-item-title>
    </template>
    <v-card>
      <v-toolbar dark color="alert">
          <v-toolbar-title class="headline white--text">Covering Payments</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn flat @click="dialog = false">Close</v-btn>
          </v-toolbar-items>
      </v-toolbar>


      <v-card-text>
        <SubscriptionCoveringPaymentLog :recurring-payment-subscription-id="subscription.recurringPaymentSubscriptionId" v-if="dialog"/>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-dialog v-model="addDialog" transition="dialog-bottom-transition" max-width="320">
          <template v-slot:activator="{ on: add }">
              <v-btn small flat v-on="{...add}" color="info">
                Add New Payment
             </v-btn>
          </template>
          <v-card>

            <v-card-title class="headline">Add Payment</v-card-title>
            <v-card-text>
              <v-text-field
                label="Covering Payment Amount"
                v-model="add.amount"
                :prefix="currencySymbol"
                :loading="loading"
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="addDialog = false" flat small>Cancel</v-btn>
              <v-btn @click="addCoveringPayment" color="info" small :disabled="!validAmount">Add</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-actions>


    </v-card>
  </v-dialog>
</v-list-item>
</template>

<script>

  import { mapGetters } from 'vuex'
  import { formatMoney, formatDate, getCurrencySymbol } from '&/helpers.js'
  import SubscriptionCoveringPaymentLog from './CoveringPayments/SubscriptionCoveringPaymentLog';
  import CoveringPaymentsMixin from '../../mixins/Subscriptions/CoveringPaymentsMixin'

  export default {
    name: 'ViewCoveringPaymentsMenuItem',
    mixins: [CoveringPaymentsMixin],
    data() {
      return {
        dialog: false,
        addDialog: false,
      }
    },
    props: {
      subscription : {
        required: true,
        type: Object
      }
    },

    computed: {
      ...mapGetters(['loading']),

    },
    methods: {

    },
    components: {
      SubscriptionCoveringPaymentLog
    }
  }
</script>

<style scoped>

</style>
