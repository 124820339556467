<template>
    <v-row justify="center">
        <v-col cols="12" md="10" lg="8" xl="8" :class=dynamicPadding>
            <v-card elevation="1" class="intercom-gap px-5">
                <ManageSubscription :person-id="personId"/>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import ManageSubscription from '../../components/Subscriptions/ManageSubscription/ManageSubscription';
import SubscriptionMixin from '../../mixins/Subscriptions/SubscriptionMixin';

export default {
  name: 'ManageSubscriptionPage',
  components: { ManageSubscription },
  mixins: [SubscriptionMixin],
  watch: {
    async clientId(value) {
      if (value) {
        this.$store.commit('SET_GLOBAL_LOADING', true);
        await this.getPerson();
        await this.getSubscriptionsForPerson();
        this.$store.commit('SET_GLOBAL_LOADING', false);
      }
    },
  },
  computed: {
    ...mapGetters(['clientId']),
    personId() {
      return this.$route.params.personId;
    },
    dynamicPadding() {
      return this.$vuetify.breakpoint.smAndDown ? 'py-0' : 'py-4';
    },
  },
};
</script>

<style scoped>
    .intercom-gap {
        margin-bottom: 10rem;
    }
</style>
