<template>
  <v-row row wrap>
    <v-col cols="12">
      <v-toolbar color="primary" flat extended>
        <v-toolbar-title class="white--text">Reports</v-toolbar-title>
      </v-toolbar>

      <v-row row wrap>
        <v-col cols="12" md="10" offset-md="1" lg="8" offset-lg="2">
          <v-card class="card--flex-toolbar">
            <v-img
              src="/assets-prod/images/app/bg-pattern.png"
              height="175px"
              aspect-ratio="2.75"
            ></v-img>
            <v-container fluid fill-height>
              <v-row fill-height>
                <v-col cols="12" align-end>
                  <span class="headline">Asmo Report</span>
                  <div>
                    <div>
                      Please select the dates to generate your ASMO report
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-container>

            <v-card-actions>
              <v-row row wrap>
                <v-col cols="12">
                  <DatePicker key="dateFrom" />
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    :disabled="!canGenerateReport"
                    @click="generateReport"
                  >
                    Generate Report
                    <v-icon right>fad fa-pencil</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>

          <div v-if="loading" style="text-align: center; margin-top: 25%">
            <v-progress-circular
              indeterminate
              v-bind:size="75"
              color="primary"
            ></v-progress-circular>
            <h3 class="body-1">Loading Reports</h3>
          </div>
        </v-col>
      </v-row>

      <v-row row wrap>
        <v-col
          cols="12"
          md="10"
          offset-md="1"
          lg="8"
          offset-lg="2"
          v-if="!loading && firstReportGenerated"
        >
          <div v-if="!(runs.length > 0)">
            <v-card>
              <v-alert
                color="info"
                icon="fad fa-info-circle"
                :value="!(runs.length > 0)"
              >
                Sorry, no report data found within these dates.
              </v-alert>
            </v-card>
          </div>

          <div v-else>
            <ASMODistinctParticipants />
            <div v-for="(run, index) in runs" :key="index">
              <ASMORun :run="run" />
            </div>
          </div>

          <v-snackbar
            :timeout="snackbarTimeout"
            color="info"
            v-model="snackbar"
          >
            {{ copiedValue }} copied
            <v-btn text @click.native="snackbar = false">Close</v-btn>
          </v-snackbar>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import moment from 'moment';

import { mapGetters } from 'vuex';

import DatePicker from '../../components/ASMO/DatePicker';
import ASMORun from '../../components/ASMO/AsmoRun';
import ASMODistinctParticipants from '../../components/ASMO/AsmoDistinctParticipants';

export default {
  name: 'AsmoReportPage',
  data() {
    return {
      loading: false,
      firstReportGenerated: false,
    };
  },

  methods: {
    generateReport() {
      this.loading = true;

      const payload = {
        dateFrom: this.dateFromTimestamp,
        dateTo: this.dateToTimestamp,
      };

      this.$store.dispatch('GET_ASMO_REPORT', payload).then((response) => {
        this.loading = false;
        this.firstReportGenerated = true;
      });
    },
  },

  computed: {
    ...mapGetters([
      'asmo',
      'dateFromTimestamp',
      'dateToTimestamp',
      'copiedValue',
      'snackbarTimeout',
    ]),
    runs() {
      return this.asmo.runs;
    },

    canGenerateReport() {
      return (
        this.dateFromTimestamp &&
        this.dateToTimestamp &&
        this.dateToTimestamp > this.dateFromTimestamp
      );
    },

    snackbar: {
      get() {
        return this.$store.getters.snackbarShowing;
      },
      set(value) {},
    },

    home() {
      return '/cms/';
    },
  },

  components: {
    DatePicker,
    ASMORun,
    ASMODistinctParticipants,
  },

  mounted() {
    this.dateFrom = moment().format('YYYY-MM-DD');
    this.dateTo = moment().format('YYYY-MM-DD');
  },
  created() {},
};
</script>

<style>
.card--flex-toolbar {
  margin-top: -64px;
}
</style>
