// control binding order - from stack: http://stackoverflow.com/questions/4742610/attaching-jquery-event-handlers-so-that-they-are-triggered-first

import moment from 'moment';

export function getCurrencySymbol() {
  return document.querySelector('[data-currency-symbol-hex-code]').dataset.currencySymbolHexCode
}

//use to format prices - will need updating if we get countries that use currency symbol after, will be grand for now
export function formatMoney(priceInHundredths) {
  var currencySymbol = getCurrencySymbol();
  var formattedPrice = (priceInHundredths / 100).toFixed(2);
  return currencySymbol + formattedPrice;
}

export function formatMoneyAdvanced(priceInHundredths) {
  var currencySymbol = document.querySelector('[data-currency-symbol-hex-code]')
    .dataset.currencySymbolHexCode;
  var formattedPrice = (priceInHundredths / 100).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

  return currencySymbol + formattedPrice;
}





export function birthdaysToday(child) {
  var date = new Date(child.dob);
  var now = new Date();
  var dateCurrentYear = date.setYear(now.getFullYear());

  const today = moment(now).format('MM-DD');
  const birthday = moment(dateCurrentYear).format('MM-DD');

  return today == birthday;
}

export function birthdayInNextSevenDays(child) {
  var birthday = new Date(child.nextBirthday);
  var now = new Date();

  // Set time to noon.
  var nextBirthday = moment(birthday).add(12, 'hours');

  var tomorrow = moment().add(1, 'days');
  var nextWeek = moment(tomorrow).add(7, 'days');

  var startOfTomorrow = tomorrow.startOf('day');
  var endOfNextWeek = nextWeek.endOf('day');

  var range = moment(nextBirthday).isBetween(startOfTomorrow, endOfNextWeek);

  return range;
}

export function birthdayInLastSevenDays(child) {
  var date = new Date(child.dob);
  var now = new Date();
  var birthdayCurrentYear = date.setYear(now.getFullYear());

  var yesterday = moment().subtract(1, 'days');
  var lastWeek = moment(yesterday).subtract(7, 'days');

  var endOfYesterday = yesterday.endOf('day');
  var startOfLastweek = lastWeek.startOf('day');

  var birthday = moment(birthdayCurrentYear);

  var range = moment(birthday).isBetween(startOfLastweek, endOfYesterday);

  return range;
}

export function birthdayInFuture(child) {
  var birthday = new Date(child.nextBirthday);

  var yesterday = moment().subtract(1, 'days');
  var lastWeek = moment(yesterday)
    .subtract(7, 'days')
    .add(1, 'year');
  var startOfLastWeekNextYear = lastWeek.startOf('day');

  var tomorrow = moment().add(1, 'days');
  var nextWeek = moment(tomorrow).add(7, 'days');
  var endOfNextWeek = nextWeek.endOf('day');

  var birthdayUpcoming = moment(birthday).isBetween(
    endOfNextWeek,
    startOfLastWeekNextYear
  );

  return birthdayUpcoming;
}

export function nextBirthdayIsInThisYear(child) {
  var birthday = new Date(child.nextBirthday);
  var nextBirthday = moment(birthday);
  var now = moment();

  if (nextBirthday.year() == now.year()) {
    return true;
  } else {
    return false;
  }
}

export function showDayMonthTimestamp(timestamp, short = false) {
  var timestamp = moment.unix(timestamp);

  if (short) {
    return timestamp.format('Do MMM');
  } else {
    return timestamp.format('Do MMMM');
  }
}

export function showDayMonth(birthday, short = false) {
  if (short) {
    return birthday.format('Do MMM');
  } else {
    return birthday.format('Do MMMM');
  }
}

export function showDayMonthAndTime(timestamp) {
  var formatter = moment.unix(timestamp);
  return formatter.format('Do MMMM HH:mm');
}

export function showDayMonthYear(birthday, short = false) {
  if (short) {
    return birthday.format('Do MMM YY');
  } else {
    return birthday.format('Do MMMM YYYY');
  }
}

export function formatDate(value) {
  if (value == null) {
    return "N/A";
  }
  return moment.unix(value).format('DD MMM YYYY');
}

export function formatLocaleDateFromTimestamp(timestamp, format = 'L') {
  if(timestamp === null) {
    return '';
  }
  let locale = navigator.language || window.navigator.language
  moment.locale(locale);
  return moment.unix(timestamp).format(format);
}

export function formatShortDate(value) {
  return moment.unix(value).format('Do MMM');
}

export function formatCardExpiry(card) {
  var year = new Date(card.exp_year.toString());
  // var year = card.exp_year.toString()
  var shortYearString = moment(year).format('YY');

  var expiryMonthString = card.exp_month.toString();

  var monthString = '';
  if (expiryMonthString.length < 2) {
    var addedZero = '0' + expiryMonthString;
    monthString += addedZero;
  } else {
    monthString = expiryMonthString;
  }

  return monthString + '/' + shortYearString;
}

export function randomErrorEmoji() {
  // const emojis = ['😱', '😰', '😕', '😨', '⏰'];
  const emojis = ['⏰'];
  return emojis[Math.floor(Math.random() * emojis.length)];
}



export function isValidUKMobileNumber(number) {
  return number ? number.match(/((\+44(\s\(0\)\s|\s0\s|\s)?)|0)7\d{3}(\s)?\d{6}/g) : false;
}


export default {
  getCurrencySymbol,
  formatMoney,
  formatMoneyAdvanced,
  birthdaysToday,
  birthdayInNextSevenDays,
  birthdayInLastSevenDays,
  birthdayInFuture,
  formatLocaleDateFromTimestamp,
  nextBirthdayIsInThisYear,
  showDayMonthYear,
  showDayMonth,
  formatDate,
  formatCardExpiry,
  randomErrorEmoji,
  isValidUKMobileNumber

};
