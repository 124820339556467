<template>
    <div>
        <v-btn
                elevation="0"
                fab
                x-small
                color="info"
                @click="openSubscriptionDrawer"
                :disabled="loading"
        >
            <v-icon>fad fa-user-circle</v-icon>
        </v-btn>
        <v-navigation-drawer
                v-model="drawer"
                temporary
                clipped
                app
                right
                width="420"
        >
            <v-toolbar flat color="white" v-if="$vuetify.breakpoint.smAndDown">
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-btn text icon @click="drawer = false"><v-icon>fas fa-times</v-icon></v-btn>
                </v-toolbar-items>
            </v-toolbar>

            <v-list avatar dense two-line class="pt-4 mt-2">
                <v-list-item>
                    <v-list-item-avatar color="info">
                        <v-icon>
                            fas fa-user
                        </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title>
                            <h5 class="title font-weight-bold">Manage Subscription</h5>
                        </v-list-item-title>
                        <v-list-item-subtitle color="UIAction">
                            View and Manage a Parent's subscription
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <v-skeleton-loader :loading="loading" type="list-item-avatar-three-line@6">
                <v-card flat class="px-1">
                    <ManageSubscription :subscription="subscription"
                                        :person-id="parent.personId" v-if="drawer"/>
                </v-card>
            </v-skeleton-loader>

        </v-navigation-drawer>

    </div>

</template>

<script>
import { mapGetters } from 'vuex';
import ManageSubscription from './ManageSubscription';
import SubscriptionMixin from '../../../mixins/Subscriptions/SubscriptionMixin';

export default {
  name: 'ManageSubscriptionNavigationDrawer',
  data() {
    return {
      drawer: false,
      loading: false,
    };
  },
  mixins: [SubscriptionMixin],
  props: {
    subscription: {
      required: true,
      type: Object,
    },
  },
  computed: {
    ...mapGetters(['clientId']),
    parent() {
      return this.subscription.parent;
    },
    personId() {
      return this.parent.personId;
    },
  },

  methods: {
    async openSubscriptionDrawer() {
      this.drawer = true;
      this.loading = true;
      await this.getSubscriptionsForPerson();
      await this.getPerson();
      this.loading = false;
    },

  },
  components: {
    ManageSubscription,
  },

  async mounted() {

  },

};

</script>
