import defaultText from './default/default';
import p404 from './default/p404';
import login from './login/login';
import forgotPassword from './login/forgotPassword';
import userResources from './userResources/userResources';
import resetPassword from './login/resetPassword';
import checkEmail from './login/checkEmail';
import showErrorNoTokenOrEmail from './login/showErrorNoTokenOrEmail';
import noUserResourcesCoachMessage from './userResources/noUserResourcesCoachMessage';
import noUserResourcesParentMessage from './userResources/noUserResourcesParentMessage';
import noUserResourcesGenericMessage from './userResources/noUserResourcesGenericMessage';
import billing from './client/billing';
import permissions from './permissions/permissions';
import facebookBrowserDetected from './payments/facebookBrowserDetected';
import initialPageTitle from './signUp/initialPageTitle';
import testimonial from './signUp/testimonial';
import successful from './signUp/successful';
import betaCountrySignUpMessage from './signUp/betaCountrySignUpMessage';
import existingClient from './signUp/existingClient';
import codeInput from './signUp/codeInput';
import keyWords from './keyWords/keyWords';
import createClient from './signUp/createClient';

export default {
  default: defaultText,
  p404,
  login,
  userResources,
  forgotPassword,
  resetPassword,
  checkEmail,
  showErrorNoTokenOrEmail,
  noUserResourcesCoachMessage,
  noUserResourcesParentMessage,
  noUserResourcesGenericMessage,
  billing,
  facebookBrowserDetected,
  initialPageTitle,
  testimonial,
  successful,
  betaCountrySignUpMessage,
  existingClient,
  codeInput,
  keyWords,
  createClient,
  permissions
};
