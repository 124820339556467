<template>
    <v-expand-transition>
        <v-list-item  @click="">
            <v-list-item-content>
                <v-list-item-title>
                  {{ getPlanName }}
                </v-list-item-title>

                <v-list-item-subtitle >
                    <span v-if="lineItem.type === 'subscription'">
                        Subscription Plan
                    </span>
                    <span v-else>
                        {{formatMoney(lineItem.amount)}}
                    </span>
                </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>


                <v-dialog v-model="dialog" persistent max-width="290">
                    <template v-slot:activator="{ on }">
                        <v-btn icon small @click="" :disabled="lineItem.type === 'subscription' || !coveringPayment " @click="dialog = true" :loading="loading" v-on="on">
                            <v-icon color="UIAction">fal fa-times-circle</v-icon>
                        </v-btn>

                    </template>
                    <v-card>
                        <v-card-title class="title font-weight-bold">Remove Payment?</v-card-title>
                        <v-card-text>Are you sure you would like to remove this payment from the upcoming invoice?</v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="info" text @click="dialog = false">Cancel</v-btn>
                            <v-btn color="error" depressed @click="remove">Remove</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

            </v-list-item-action>
        </v-list-item>
    </v-expand-transition>

</template>

<script>
  import { mapGetters } from 'vuex'
  import CoveringPaymentsMixin from '../../../mixins/Subscriptions/CoveringPaymentsMixin'
  export default {
    name: 'UpcomingSubscriptionInvoiceLineItems',
    mixins: [CoveringPaymentsMixin],
    data() {
      return {
        dialog: false,
        loading: false
      }
    },
    props: {
      lineItem: {
        required: true,
        type: Object
      }
    },
    computed: {
      ...mapGetters(['coveringPayments']),
      invoice() {
        return this.$store.getters.nextInvoice;
      },
      getPlanName() {
        if (this.lineItem.plan !== null) {
          return this.lineItem.plan.nickname;
        }
        return this.lineItem.description;
      },
      coveringPayment() {
        return Object.values(this.coveringPayments).find(cp => {
          return cp.stripeInvoiceItemId === this.lineItem.id
        })
      }
    },
    methods: {
      async remove() {
        this.loading = true;
        this.dialog = false;
        await this.removeCoveringPayment();
        this.loading = false;
      }
    }
  }
</script>

<style scoped>

</style>
