<template>


<div class="container">

  <div id="toolbar">
    <div class="controls" v-if="show">


      <div v-if="shouldShowBackArrow" class="back">
        <v-btn fab text v-on:click="moveBackward" >
          <v-icon>fal fa-angle-left</v-icon>
        </v-btn>
        <h4 v-html="numberOfSessionsBehindTextLabel"></h4>
      </div>

      <div v-else>
        &nbsp;
      </div>

      <div v-if="shouldShowForwardArrow" class="forward">
        <h4 v-html="numberOfSessionsAheadTextLabel"></h4>
        <v-btn fab text v-on:click="moveForward" >
          <v-icon>fal fa-angle-right</v-icon>
        </v-btn>
        
      </div>

      <div v-else>
        &nbsp;
      </div>
    </div>


    <div class="run" v-if="show">
      <div class="name">
        <strong>
          <h4>
          Name
          </h4>
        </strong>
      </div>


      <RunInstances class="runInstances" v-for="instance in runInstances" :run-instance="instance" :key="instance.runInstanceId" :runInstanceId="instance.runInstanceId" :allBookingsArray="allBookings"/>
    </div>

  </div>

	    <RunBooking v-for="(booking, index) in bookings" :booking="booking" :key="booking.bookingId" :index="index" v-if="show"/>

</div>


</template>

<script>


import RunInstances from './RunInstances';
import RunBooking from './RunBooking';

export default {
  name: 'Attendance',
  data () {
    return {
      show: true,
        drawer: false
      // allBookings: [],
    }
  },

  computed: {
  	runInstances () {
  		return this.$store.getters.run.runInstances;
  	},

  	bookings () {
  		return this.$store.getters.run.bookings;
  	},

    shouldShowBackArrow() {
      return this.numberOfSessionsBehind > 0;
    },

    shouldShowForwardArrow() {
      return this.numberOfSessionsAhead > 0;
    },

    totalNumberOfSessions() {
      return this.$store.getters.totalNumberOfRunInstances;
    },

    numberOfSessionsAhead() {
      return this.calculateNumberOfSessionsInTheFuture();
    },

    numberOfSessionsAheadTextLabel() {
      if( this.numberOfSessionsAhead == 1 ) {
        return 'Next Session'
      } else {
        return 'Next ' + this.numberOfSessionsAhead + ' Sessions';
      }
    },

    numberOfSessionsBehind() {
      if(this.currentIndexOfInstancesIsGreaterThanZero) {
        return this.calculateNumberOfSessionsInThePast();
      } else {
        return 0;
      }
    },


    numberOfSessionsBehindTextLabel() {
      if( this.numberOfSessionsBehind == 1 ) {
        return 'Last Session'
      } else {
        return 'Last ' + this.numberOfSessionsBehind + ' Sessions'
      }
    },

    allBookings() {
      let bookings = [];
      for (var i = 0; i < this.bookings.length; i++) {
        for (var x = 0; x < this.bookings[i].bookings.length; x++) {
          bookings.push(this.bookings[i].bookings[x]);
        }
      }
      return bookings; 
    }


  },

  methods : {

      moveForward() {
        this.show = false;
        this.$store.dispatch('UPDATE_CURRENT_INDEX', this.$store.getters.numberOfRunInstancesToShow)
          .then( () => {

            if("vibrate" in window.navigator) {
              window.navigator.vibrate([50]);
            }

            this.show = true;
          });
      },


      moveBackward() {
        this.show = false;
        this.$store.dispatch('UPDATE_CURRENT_INDEX', -(this.$store.getters.numberOfRunInstancesToShow))
          .then(() => {

          if("vibrate" in window.navigator) {
            window.navigator.vibrate([50]);
          }

            this.show = true;
          });
      },

      calculateNumberOfSessionsInThePast() {

        var currentIndex = this.$store.getters.currentRunInstanceIndex;
        var limit = this.$store.getters.numberOfRunInstancesToShow;

        var totalSessionRemaining = this.totalNumberOfSessions - currentIndex;
        var totalNumberOfSessionsInThePast = this.totalNumberOfSessions - totalSessionRemaining;

        if( (totalNumberOfSessionsInThePast - limit) >= limit ) {
          return limit;
        } else {
          return totalNumberOfSessionsInThePast;
        }
      },

      calculateNumberOfSessionsInTheFuture() {
        var currentIndex = this.$store.getters.currentRunInstanceIndex;
        var limit = this.$store.getters.numberOfRunInstancesToShow;

        var totalSessionRemaining = this.totalNumberOfSessions - currentIndex;

        if( (totalSessionRemaining - limit) > limit ) {
          return limit;
        } else if ( (totalSessionRemaining - limit) <= 0) {
          return 0
        } else {
          return totalSessionRemaining - limit;
        }
      },

      currentIndexOfInstancesIsGreaterThanZero() {
        var currentIndex = this.$store.getters.currentRunInstanceIndex;
        return currentIndex > 0;
      },
      


  },

  mounted() {

  },

  components: {
  	RunInstances,
  	RunBooking
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

@import './../../../assets/scss/variables.scss';

.container {
  // border: 2px solid $primary;
  border-radius: 4px;
  transition: 1s all;
}

/*#toolbar {*/
/*  position: -webkit-sticky;*/
/*  position: sticky;*/
/*  top: 0;*/
/*}*/

.controls {
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: center;
  grid-auto-rows: 64px;
  align-items: center;
  // background-color: $primary;
}

.controls .back {
  display: grid;
  grid-template-columns: 1fr 7fr;
  text-align: left;
  align-items: center;

  h4 {
    text-align: left;
  }
}


.controls .forward {
  display: grid;
  grid-template-columns: 7fr 1fr;
  text-align: right;
  align-items: center;

  h4 {
    text-align: right;
  }
}

.arrow {
  text-align: center;
}


.run {
  display:grid;
  grid-column-gap: 10px;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  grid-auto-rows: 32px;

  padding: 10px 0 10px 0;
  align-items: center;
  // border-bottom: 2px solid $primary;
  background-color: #fff;
}





@media (min-width: 415px) {
  .run {
    grid-template-columns: 2fr;
  }
}

.booking {
  display:grid;
  grid-template-columns: 2fr;
  grid-column-gap: 8px;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  // grid-auto-rows: 92px;
  align-items: center;
}

.name {
  margin-left: 16px;
}

.name h4 {
  margin: 0;
  // color: $primary;
}



</style>
