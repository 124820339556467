<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { debounce } from 'lodash';
import { setup } from '../services/twillio-mobile-verification';
import { minNumbersInMobileNumber } from '../../../../../global/helpers/rules/mobileNumber';
import {
  textSignUpBeta,
  textSignUp,
  getEmoji,
} from '../../../../../../slack';

export default Vue.extend({
  name: 'SignUpMixin',
  data() {
    return {};
  },
  computed: {
    ...mapGetters([
      'roles',
      'userSignUp',
      'globalLoading',
      'clientIdSignUp',
      'loginLinkSignUp',
      'subdomainSignUp',
      'booleansSignUp',
      'clubSignUp',
      'sidSignUp',
      'environmentSignUp',
      'countriesSignUp',
      'selectedCountrySignUp',
      'errorMessageSignUp',
      'clubNameSignUp',
      'checkingSubdomainSignUp',
      'phoneNumberSignUp',
    ]),

    clubName: {
      get() {
        return this.clubNameSignUp;
      },
      set(value) {
        this.$store.commit('UPDATE_NAME_SIGN_UP', value);
      },
    },
    phoneNumber: {
      get() {
        return this.phoneNumberSignUp;
      },
      set(value) {
        this.$store.commit('UPDATE_PHONE_NUMBER_SIGN_UP', value);
      },
    },
    user() {
      return this.userSignUp;
    },
    sent() {
      return this.booleansSignUp.sent;
    },
    completeURL() {
      this.$store.commit('UPDATE_COMPLETE_URL_SIGN_UP', `${this.clubSignUp.chosenDomain}.${this.domain}.io`);
      return `${this.clubSignUp.chosenDomain}.${this.domain}.io`;
    },
    selectedCountryTld() {
      return '.io';
    },
    countries() {
      return this.countriesSignUp;
    },
    sid() {
      return this.sidSignUp;
    },
    errorMessage() {
      return this.errorMessageSignUp;
    },
    userName() {
      return this.userSignUp.email;
    },
    clubUrl() {
      return this.clubSignUp.clubUrl;
    },
    betaClubMessage() {
      return this.booleansSignUp.betaClubMessage;
    },
    checkingSubdomain() {
      return this.checkingSubdomainSignUp;
    },
    selectedCountry: {
      get() {
        return this.selectedCountrySignUp;
      },
      set(value) {
        this.$store.commit('UPDATE_SELECTED_COUNTRY_SIGN_UP', value);
      },
    },
    selectedCountryInBeta() {
      return this.countriesDataPopulated ? this.countries[this.selectedCountry].beta : false;
    },
    countriesDataPopulated() {
      return Object.keys(this.countries).length > 0;
    },
    phoneNumberNotLongEnough() {
      return this.phoneNumber.length < minNumbersInMobileNumber;
    },
    countriesArray() {
      return Object.keys(this.countries).map((key) => this.countries[key]);
    },
    customSubdomainTransformed: {
      get() {
        if (this.clubName.length > 0) {
          return `${this.clubName.toLowerCase()}.${this.domain}.io`;
        }
        this.customSubdomain = '';
        return '';
      },
      set(value) {
        this.customSubdomain = value
          .toLowerCase()
          .replace(/\s/g, '')
          .replace(/,+$/, '')
          .replace(/[^A-Z0-9]+/gi, '-');
      },
    },
    subdomainAvailable() {
      return (
        (this.subdomain.length > 0
              && this.subdomainSignUp.available)
          || (this.customSubdomainTransformed.length
              && this.subdomainSignUp.available)
      );
    },
    subdomain() {
      return this.clubName
        .toLowerCase()
        .replace(/^\s+|\s+$/g, '')
        .replace(/,+$/, '')
        .replace(/[^A-Z0-9]+/gi, '-');
    },

    subdomainNotAvailableText() {
      return '⚠️ This booking link is not available, please choose another one.';
    },
    domain() {
      if (this.environmentSignUp.environment === 'stage') {
        return 'staging.classforkids';
      } else if (this.environmentSignUp.environment === 'dev') {
        return 'docker.dev.sandbox.classforkids';
      }
      return 'classforkids';
    },

    chosenDomain() {
      if (this.enteredCustomSubdomain) {
        this.$store.commit('UPDATE_CHOSEN_DOMAIN_SIGN_UP', this.customSubdomainTransformed);
        return this.customSubdomainTransformed;
      }
      this.$store.commit('UPDATE_CHOSEN_DOMAIN_SIGN_UP', this.subdomain);
      return this.subdomain;
    },
  },
  methods: {
    async setup() {
      // send sid & phone number to the backend sendVerificationToken()
      await this.$store.commit('UPDATE_GLOBAL_LOADING', { mutation: 'setup', status: true });

      const response = await setup(this.sid, this.phoneNumber, this.selectedCountry);
      if (response.code === 200) {
        if (response.data.data && response.data.data.properties.status === 'pending') {
          this.phoneNumber = response.data.data.properties.to;
          this.$store.commit('UPDATE_SENT_SIGN_UP', true);
          if (this.$router.currentRoute.name !== 'CodeInput') {
            await this.$router.push({ name: 'CodeInput' });
          }
        }
        if (response.data.errorMsg) {
          if (response.data.errorCode === 20429) {
            this.$store.commit('UPDATE_ERROR_MESSAGE_SIGN_UP', 'Too many requests');
          } else {
            this.$store.commit('UPDATE_ERROR_MESSAGE_SIGN_UP', 'Phone number not valid');
          }
        }
      } else {
        this.$store.commit('UPDATE_ERROR_MESSAGE_SIGN_UP', response.data.message);
      }
      await this.$store.commit('UPDATE_GLOBAL_LOADING', { mutation: 'setup', status: false });
    },

    async createNewClient() {
      await this.$store.commit('UPDATE_GLOBAL_LOADING', { mutation: 'createNewClient', status: true });

      this.$store.commit('UPDATE_CHECKING_SUB_DOMAIN_SIGN_UP', true);

      const payload = {
        subdomain: this.chosenDomain,
        countryCode: this.countries[this.selectedCountry].countryCode,
        name: this.clubName,
        message: textSignUp(
          this.clubName,
          this.userName,
          this.completeURL,
          this.countries[this.selectedCountry].countryCode,
          this.phoneNumber,
        ),
        emoji: getEmoji(this.countries[this.selectedCountry].countryCode),
      };

      const response = await this.$store.dispatch('CREATE_NEW_CLIENT_RECORD', payload);
      if (response.status !== 200) {
        await this.$store.commit('UPDATE_CHECKING_SUB_DOMAIN_SIGN_UP', false);
      } else {
        await this.$store.commit('UPDATE_CLUB_URL_SIGN_UP', `https://${this.completeURL}${this.loginLinkSignUp}`);
      }
      window.location.href = this.clubUrl;
    },


    async sendBetaCountrySignUpDetailsToSlack() {
      await this.$store.commit('UPDATE_GLOBAL_LOADING', { mutation: 'betaSignUp', status: true });

      const payload = {
        subdomain: this.clubSignUp.chosenDomain,
        countryCode: this.countries[this.selectedCountry].countryCode,
        name: this.clubName,
        message: textSignUpBeta(
          this.clubName,
          this.userName,
          this.clubSignUp.completeURL,
          this.countries[this.selectedCountry].countryCode,
          this.countries[this.selectedCountry].name,
          this.phoneNumber,
        ),
        emoji: getEmoji(this.countries[this.selectedCountry].countryCode),
      };

      this.$store.dispatch('BETA_COUNTRY_CLIENT_SIGN_UP', payload);
      await this.$store.commit('UPDATE_BETA_CLUB_MESSAGE_SIGN_UP', true);
      await this.$store.commit('UPDATE_GLOBAL_LOADING', { mutation: 'betaSignUp', status: false });
      await this.$router.push({ name: 'BetaCountrySignUpMessagePage' });
    },

    createSubdomainFromName() {
      return this.clubName.toLowerCase().replace(/[^A-Z0-9]+/gi, '-');
    },

    checkSubdomainIsAvailable: debounce(function () {
      this.$store.commit('UPDATE_CHECKING_SUB_DOMAIN_SIGN_UP', true);
      this.$store.commit('UPDATE_SUBDOMAIN_STATUS_SIGN_UP', false);

      const payload = {
        subdomain: this.subdomain,
        countryCode: this.countries[this.selectedCountry].countryCode,
      };

      if (this.subdomain.length > 0) {
        this.$store
          .dispatch('CHECK_SUBDOMAIN_AVAILABILITY', payload)
          .then((response) => {
            this.customSubdomain = this.subdomain;
            this.$store.commit('UPDATE_CHECKING_SUB_DOMAIN_SIGN_UP', false);
          });
      } else {
        this.$store.commit('UPDATE_CHECKING_SUB_DOMAIN_SIGN_UP', false);
      }
    }, 700),

    updateCustomSubdomain: debounce(function () {
      this.$store.commit('UPDATE_CHECKING_SUB_DOMAIN_SIGN_UP', true);
      this.enteredCustomSubdomain = true;

      const payload = {
        subdomain: this.customSubdomain,
        countryCode: this.countries[this.selectedCountry].countryCode,
      };

      if (this.customSubdomain.length > 0) {
        this.$store
          .dispatch('CHECK_SUBDOMAIN_AVAILABILITY', payload)
          .then((response) => {
            this.$store.commit('UPDATE_CHECKING_SUB_DOMAIN_SIGN_UP', false);
          });
      } else {
        this.$store.commit('UPDATE_CHECKING_SUB_DOMAIN_SIGN_UP', false);
      }
    }, 1200),

    updateErrorStateOnUnFocus() {
      this.$store.commit('UPDATE_ERROR_MESSAGE_SIGN_UP', '');
    },
  },
});
</script>
