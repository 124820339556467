<template>

 <tr>
  <td>
    {{parentName}}
  </td>
  <td>
   {{cancelledDateFormatted}}
  </td>
  <td>
    <ManageSubscriptionNavigationDrawer :subscription="subscription" :drawer.sync="drawer" />
    <SubscriptionListItemActionsMenu subscription="subscription" v-if="$vuetify.breakpoint.smAndDown" />
  </td>
 </tr>

</template>

<script>

import { mapGetters } from 'vuex';
import { formatMoney, formatDate } from '&/helpers.js';
import ManageSubscriptionNavigationDrawer from '../ManageSubscription/ManageSubscriptionNavigationDrawer';
import SubscriptionListItemActionsMenu from '../SubscriptionListItemActionsMenu';

export default {
  name: 'CancelledSubscriptionsTableListItem',
  components: { SubscriptionListItemActionsMenu, ManageSubscriptionNavigationDrawer },
  data() {
    return {
      drawer: false,
    };
  },
  props: {
    subscription: {
      type: Object,
      required: true,
    },
  },
  computed: {
    parentName() {
      return `${this.subscription.parent.firstName} ${this.subscription.parent.lastName}`;
    },
    cancelledDateFormatted() {
      return formatDate(this.subscription.c4k.cancelled);
    },
  },

  created() {

  },
};
</script>

<style scoped>

</style>
