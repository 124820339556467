<template>
  <div v-if="!globalLoading">
    <v-row id="setup">
      <v-col>
        <v-row dense justify="center">
          <v-col cols="12">
            <v-card-title class="my-3" primary--text>
              <h2 class="primary--text">
                <span class="no-wrap">
                  <Dictionary module="createClient" string="titleLineOne"/>
                </span>
                <span class="no-wrap">
                  <Dictionary module="createClient" string="titleLineTwo"/>
                </span>
              </h2>
            </v-card-title>
            <v-card-subtitle class="my-3">
              <p class="text-center">
                <Dictionary module="createClient" string="subtitle"/>
              </p>
            </v-card-subtitle>
            <v-text-field
                color="primary"
                label="Club Name"
                type="text"
                v-model="clubName"
                @keyup="checkSubdomainIsAvailable"
                outlined
                :rules="clubNameRulesComputed"
                required
                flat
                autofocus
                @click="updateErrorStateOnUnFocus()"
            />
          </v-col>
        </v-row>

        <v-row dense no-gutters>
          <v-col cols="12">
            <v-card-text>
              <i class="fa fa-info-circle" aria-hidden="true"></i>
              <Dictionary module="createClient" string="suggestionLineOne"/>
              <br>
              <Dictionary module="createClient" string="suggestionLineTwo"/>
            </v-card-text>
          </v-col>

          <v-col cols="12">
            <v-card-subtitle>
              <span class="primary--text">Good Example</span> <br>
              <span> north-gymnastics-club.classforkids.io</span>
            </v-card-subtitle>
          </v-col>

          <v-col cols="12">
            <v-card-subtitle>
              <span class="primary--text"> Bad Example </span> <br>
              <span> northgymnasticsclub-est2010-london.classforkids.io</span>
            </v-card-subtitle>
          </v-col>
        </v-row>



        <v-row dense no-gutters align="center" justify="space-between">
          <v-col cols="12" v-show="checkClubName">
            <CheckClubName/>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-select
                label="Country"
                v-model="selectedCountry"
                @change="checkSubdomainIsAvailable"
                :items="countriesArray"
                item-text="name"
                item-value="countryCode"
                outlined
                dense
            >
            </v-select>
          </v-col>
          <v-col cols="12" v-if="!isAdmin">
            <v-text-field
                type="tel"
                color="primary"
                label="Mobile Number"
                v-model="phoneNumber"
                placeholder="Enter Mobile Number"
                outlined
                :error-messages="errorMessage"
                :rules="mobileNumberRulesComputed"
                required
                flat
                @input="updateErrorStateOnUnFocus()"
                @click="updateErrorStateOnUnFocus()"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-divider class="my-5"/>



        <v-row>
          <v-col cols="12">
            <Button v-if="isAdmin"
                    :loading="checkingSubdomain"
                    @click="adminSignUp()"
                    :disabled="!subdomainAvailable || checkingSubdomain"
                    class="primary"
            >
              {{ buttonTextAdmin }}
            </Button>
            <Button v-if="selectedCountryInBeta && !isAdmin"
                    :loading="checkingSubdomain"
                    @click="sendBetaCountrySignUpDetailsToSlack()"
                    :disabled="!subdomainAvailable || checkingSubdomain || phoneNumberNotLongEnough"
                    class="primary"
            >
              {{ buttonTextBetaCountry }}
            </Button>
            <Button v-if="!selectedCountryInBeta && !isAdmin"
                    :loading="checkingSubdomain"
                    @click="setup"
                    :disabled="!subdomainAvailable || checkingSubdomain || phoneNumberNotLongEnough"
                    class="primary"
            >
              {{ buttonText }}
            </Button>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from 'vue';
import Button from '../../../../global/components/UILibrary/Buttons/Button.vue';
import {
  mobileNumberRequiredRule,
  mobileNumberValidRule,
} from '../../../../global/helpers/rules/mobileNumber';
import { clubNameRequiredRule, clubNameValidRule } from '../../../../global/helpers/rules/clubName';
import SignUpMixin from './mixins/SignUpMixin.vue';
import CheckClubName from '../../components/SignUp/CheckClubName.vue';

export default Vue.extend({
  name: 'CreateClientPage',
  mixins: [
    SignUpMixin,
  ],
  components: {
    Button,
    CheckClubName,
  },
  props: {},
  data() {
    return {
      enteredCustomSubdomain: false,
      mobileNumberRules: [
        mobileNumberRequiredRule,
        mobileNumberValidRule,
      ],
      clubNameRules: [
        clubNameRequiredRule,
        clubNameValidRule,
      ],
      enableVerificationRulesClubName: false,
      enableVerificationRulesMobile: false,
    };
  },
  computed: {
    clubNameRulesComputed() {
      if (this.enableVerificationRulesClubName || clubNameRequiredRule(this.clubName) === true) {
        this.enableVerificationRulesClubName = true;
        return this.clubNameRules;
      }
      return [];
    },
    mobileNumberRulesComputed() {
      if (this.enableVerificationRulesMobile || mobileNumberValidRule(this.phoneNumber) === true) {
        this.enableVerificationRulesMobile = true;
        return this.mobileNumberRules;
      }
      return [];
    },
    isAdmin() {
      return this.roles ? this.roles.some((role) => role === 'ROLE_SUPER_ADMIN') : false;
    },
    checkClubName() {
      return this.clubName ? this.clubName.length > 0 : false;
    },
    buttonTextAdmin() {
      if (!this.subdomainAvailable && this.$vuetify.breakpoint.smAndUp) {
        return 'Admin Sign up';
      }
      if (!this.subdomainAvailable) {
        return 'Admin Sign up';
      }
      return 'Create System';
    },
    buttonText() {
      if (!this.subdomainAvailable && this.$vuetify.breakpoint.smAndUp) {
        return 'Please choose another booking link to continue';
      }
      if (!this.subdomainAvailable) {
        return 'Please choose another booking link';
      }
      if (this.phoneNumberNotLongEnough) {
        return 'Please enter a mobile number';
      }
      return 'Create System';
    },
    buttonTextBetaCountry() {
      if (!this.subdomainAvailable && this.$vuetify.breakpoint.smAndUp) {
        return 'Please choose another booking link to continue';
      }
      if (!this.subdomainAvailable) {
        return 'Please choose another booking link';
      }
      if (this.phoneNumberNotLongEnough) {
        return 'Please enter a mobile number';
      }
      return 'Request System';
    },
  },
  methods: {
    adminSignUp() {
      this.$store.commit('UPDATE_APPROVED_SIGN_UP', true);
      this.createNewClient();
    },
  },
  beforeCreate() {
  },
  created() {
  },
  beforeMount() {
  },
  mounted() {
    this.$store.dispatch('GET_COUNTRIES');
    this.$store.dispatch('GET_SID');
    this.$store.dispatch('GET_USER_NAME');
  },
  updated() {
  },
  destroyed() {
  },
});
</script>

<style scoped>
.v-btn:not(.v-btn--round).v-size--default {
  min-width: 100% !important;
}

.no-wrap {
  white-space: nowrap;
}

.v-card__title {
  flex-direction: column !important;
}
</style>
