<template>
    <v-text-field
            :append-icon="this.validChecker && value ? 'fas fa-check-circle success--text input-icon' : ''"
            color="primary"
            flat
            @input="$emit('input', $event)"
            label="Email Address"
            :autofocus="autofocus"
            prepend-inner-icon="far fa-envelope"
            outlined

            :value="value"
            class="c4kInput"
            :class="{'c4kInput--error' : showError}"
            name="email"
            type="email"
            autocomplete="username"
            :rules="rules"
            required
            :error-messages="errorMessageValue"
            :error="updateErrorStateOnError()"
            @blur="updateErrorStateOnUnfocus()"
            data-test="emailInput"
            @keydown="$emit('keydown', value)"
    >
        <slot>
        </slot>
    </v-text-field>
</template>

<script>
import { VTextField } from 'vuetify/lib';

export default {
  name: 'EmailInput',
  extends: VTextField,
  data() {
    return {
      errorState: false,
    };
  },
  computed: {
    showError() {
      return this.errorState || this.errorMessageState;
    },
    validChecker() {
      return this.rules.length > 0 && this.rules.every((rule) => rule(this.value) === true);
    },
  },
  methods: {
    updateErrorStateOnUnfocus() {
      this.errorState = this.hasError;
    },
    updateErrorStateOnError() {
      if (this.hasState || this.value.length > 0) {
        this.errorState = this.hasError;
      }
    },
  },
  watch: {},
  props: {
    value: {
      type: String,
      default() {
        return '';
      },
    },
    rules: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    errorMessageValue: {
      type: String,
      required: false,
      default() {
        return '';
      },
    },
    errorMessageState: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    autofocus: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
  },
  mounted() {},
  updated() {},
};
</script>

<style lang="scss" scoped>
</style>
