<template>

  <transition
  >
    <div>
      <v-row class="mb-4">
        <v-col align="center">

          <v-skeleton-loader
              class="mb-2 mt-2 resource-loader"

              :type="headingSkeletonLayout"
              :loading="loading"
              :transition-group="transition"
          >
            <h4 class="display-1">
              <Dictionary module="userResources" string="title" :replace="{name: firstName}" v-if="firstName" />
            </h4>
            <p class="mb-5 body-1">
              <Dictionary module="userResources" string="subtitle" />
            </p>

          </v-skeleton-loader>
        </v-col>
      </v-row>

      <ClientOutstandingSubscriptionAlert />

      <v-skeleton-loader
          class="resource-loader"
          min-height="226"
          :type="cardSkeletonLayout"
          :loading="loading"
          transition-group="scroll-y-transition"
      >
        <div>
          <ParentResourceList/>
        </div>

        <div style="margin-top: 0">
          <ParentReferralResource/>
        </div>

        <div>
          <ClientResourceList />
        </div>
        <div style="margin-top: 0">
          <CreateClientResource/>
        </div>
      </v-skeleton-loader>
    </div>
  </transition>

</template>
<script>
import { mapGetters } from 'vuex';
import ParentResourceList from '../../components/UILibrary/Cards/Resources/ParentResourceList.vue';
import ClientResourceList from '../../components/UILibrary/Cards/Resources/ClientResourceList.vue';
import ClientOutstandingSubscriptionAlert from '../../Alerts/ClientOutstandingSubscriptionAlert.vue';
import CreateClientResource from "../../components/UILibrary/Cards/Resources/CreateClientResource.vue";
import ParentReferralResource from "../../components/UILibrary/Cards/Resources/ParentReferralResource.vue";

export default {
  name: 'ResourcesPage',
  data() {
    return {
      callsToLoadOnMount: 2,
      headingSkeletonLayout: 'heading, sentences',
      cardSkeletonLayout: 'list-item-avatar-two-line@3',
      transition: 'scale-transition',
    };
  },
  computed: {
    ...mapGetters(['person', '_loading']),
    firstName() {
      return this.person.firstName;
    },
    loading() {
      return this._loading;
    },
  },

  async created(){

  },
  updated() {
  },
  async mounted() {

  },
  components: {
    ClientOutstandingSubscriptionAlert,
    ParentResourceList,
    ClientResourceList,
    CreateClientResource,
    ParentReferralResource
  },
};

</script>
