<template>
  <div class="spinner">
    <transition
      name="loading-spinner"
      enter-active-class="animated bounceInDown fast"
      leave-active-class="animated  bounceOutUp faster"
      mode="in-out"
      appear
    >
      <img src="/pwa-static/images/cfk-spinner-v2-slower.gif" alt="ClassForKids Logo"/>
    </transition>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.spinner {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  height: 100vh;
  background-color: #f8f8f8;
  filter: grayscale(100%);
  opacity: 0.08;
}

img {
  max-width: 75px;
  margin: 0 auto;
  text-align: center;
}
</style>
