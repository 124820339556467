<template>
  <td>
    <v-row
      v-if="
        registrationQuestionsParentSelectContacts ||
        registrationQuestionsChildSelectContacts
      "
    >
      <v-col cols="12">
        {{ itemComputed }}
      </v-col>
    </v-row>
  </td>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ContactsTableColumnRegistrationAnswers',
  components: {},
  props: {
    item: {
      required: false,
      type: Object,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters([
      'registrationQuestionsChildSelectContacts',
      'registrationQuestionsParentSelectContacts',
    ]),
    itemComputed() {
      if (this.registrationQuestionsParentSelectContacts !== null) {
        return this.item.parent[this.registrationQuestionsParentSelectContacts]
          .answer;
      }
      if (this.registrationQuestionsChildSelectContacts !== null) {
        return this.item.child[this.registrationQuestionsChildSelectContacts]
          .answer;
      }
      // this.$store.commit('RESET_HEADERS');
    },
  },
  methods: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  updated() {},
};
</script>

<style scoped></style>
