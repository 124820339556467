import Vue from 'vue';
import vuetify from './plugins/vuetify';
import './plugins/ui-library';
import './plugins/sentry-logging';
import dictionary from './plugins/dictionary';

const defaultLanguage = vuetify.framework.lang.current;
Vue.use(dictionary, { defaultLanguage });
Vue.config.devtools = true;
Vue.config.performance = true;

export default function CreateApp({ App, store, router }) {
  return new Vue({
    name: 'App',
    router,
    store,
    vuetify,
    template: '<App />',
    components: {
      App,
    },
  });
}
