
import Vuex from 'vuex';
import Vue from 'vue';

import { format } from 'date-fns';

export default {
  state: {
    club: {},
    schedule: {
      terms: [
        {
          termId: '',
          runs: {
            0: {
              runId: 0,
              classId: 0,
              className: '',
              venueName: '',
              venueId: 0,
              coaches: [],
              bookingsCount: 0,
              runInstances: [],
            },
          },
        },
      ],
    },
    schedule_v4: {
      terms: [
        {
          termId: '',
          runs: {
            0: {
              runId: 0,
              classId: 0,
              className: '',
              venueName: '',
              venueId: 0,
              coaches: [],
              bookingsCount: 0,
              runInstances: [],
            },
          },
        },
      ],
    },

    camps: {},


    filters: {
      day: '',
      classType: '',
      venue: '',
      term: '',
      coach: '',
      spaceOnRegister: false,
      hasWaitingList: false,
    },

    runSettings: {},
    runInstances: [],
    runBookings: [],
    runSessions: [],
    runInstancesPaginationProperties: {
      runInstancesToShow: 8,
      currentRunInstanceIndex: 0,
    },

    contact: {
      canSelectRuns: false,
      selectedRuns: [],
      selectedRunsByTerm: [],
    },
  },
  actions: {
    async GET_SCHEDULE(context, payload) {


        try {
        // const getTerms = Routing.generate('ApiBundle_term_get_terms_v2');
        const getTerms = new Request('/api/v4/schedule/terms', {
          method: 'GET',
          credentials: 'same-origin',
        });
        const schedule = await fetch(getTerms);
        const scheduleJson = await schedule.json();
        context.commit('SET_SCHEDULE_DATA', scheduleJson.data);
      } catch (error) {
        console.error('Error: ', error);
      }
    },


    async GET_CAMPS_V4(context, payload) {
      try {
        // const getTerms = Routing.generate('ApiBundle_term_get_terms_v2');
        const getTerms = new Request('/api/v4/schedule/camps', {
          method: 'GET',
          credentials: 'same-origin',
        });
        const schedule = await fetch(getTerms);
        const scheduleJson = await schedule.json();
        context.commit('SET_CAMP_DATA_v4', scheduleJson);
      } catch (error) {
        console.error('Error: ', error);
      }
    },


	    async SET_TERM_INVITE_ONLY(context, payload) {
      var newStatus;

	  		if (parseInt(payload.termInviteOnly)) {
	  			var newStatus = 0;
	  		} else {
	  			var newStatus = 1;
      }

	  		const url = `/api/terms/term/${payload.termId}/invite-only/${newStatus}`;

	  		const request = new Request(url, {
	  			method: 'POST',
	  			credentials: 'same-origin',
	  		});

	  		const response = await fetch(request);
      const json = await response.json();

      context.commit('UPDATE_TERM_INVITE_ONLY', json.data);
	    },


    async GET_RUN(context, payload) {
      // const getRunUrl = new Request(Routing.generate('ApiBundle_run_get_run_v3', {runId: payload}), {method : 'GET'});

      const getRunUrl = new Request(`/api/runs/v3/${payload}`, {
        method: 'GET',
        credentials: 'same-origin',
      });

			await context.commit('SET_RUN_SETTINGS', {});
			await context.commit('SET_RUN_INSTANCES', {runInstances: [], sessions: {}});
			await context.commit('SET_RUN_BOOKINGS', []);


      try {
        const runData = await fetch(getRunUrl);
        const runDataJson = await runData.json();

        await context.commit('SET_RUN_SETTINGS', runDataJson.settings);
        await context.commit(
          'SET_RUN_INSTANCES',
          runDataJson,
        );
        await context.commit('SET_RUN_BOOKINGS', runDataJson.bookings);
      } catch (error) {
        console.error('Error: ', error);
      }
    },

    async UPDATE_ATTENDANCE(context, payload) {
      // https://demoot.class4kids.co.uk/api/registers/register/270/booking/f883c628-442e-4065-bb20-abacedb4ab8f/attendance/11331/1
      const { runId } = payload;
      const { bookingRef } = payload.attendanceData;
      const { bookingInstanceId } = payload.attendanceData;

      // if it's not been set then work it out
      if (!payload.hasOwnProperty('status')) {
        if (
          !payload.attendanceData.attended
					|| payload.attendanceData.attended == 0
        ) {
          var status = 1;
        } else {
          var status = 0;
        }
      } else {
        // else use it
        var { status } = payload;
      }

      const updateAttendanceUrl = `/api/registers/register/${runId}/booking/${bookingRef}/attendance/${bookingInstanceId}/${status}`;
      const request = new Request(updateAttendanceUrl, {
        method: 'POST',
        credentials: 'same-origin',
      });

      if (context.getters.isOnline) {
        try {
          const updateAttendance = await fetch(request);
          const responseJson = await updateAttendance.json();
          context.commit('SET_ATTENDANCE_DATA', responseJson);
        } catch (error) {
          console.error('Error: ', error);
        }
      } else {
        const cachePayload = {
          key: `${bookingRef}-${bookingInstanceId}`,
          bookingInstanceId,
          value: request.url,
        };

        context
          .dispatch('ADD_TO_ATTENDANCE_CACHE', cachePayload)
          .then((response) => {
            const fakeResponse = {
              bookingInstanceId,
              newStatus: !!status,
              status: status
                ? Math.round(new Date().getTime() / 1000)
                : 0,
            };

            context.commit('SET_ATTENDANCE_DATA', fakeResponse);
          });
      }
    },
    async UPDATE_CURRENT_INDEX(context, payload) {
      // doing a wee check to make sure it's not going to an index that doesn't exist
      const index =				context.state.runInstancesPaginationProperties
				  .currentRunInstanceIndex;
      const limit = payload;
      const total = index + limit;

      // var arrayOutOfBoundsCheck = ( total >= context.state.runInstances.length );

      // if (arrayOutOfBoundsCheck) {
      //   return false
      // }

      const arrayOutOfIndexCheck = total < 0;

      if (arrayOutOfIndexCheck) {
        return context.commit('SET_INDEX_TO', 0);
      }

      return context.commit('SET_CURRENT_INDEX', payload);
    },

    async RESET_CURRENT_INDEX(context, payload) {
      context.commit('SET_INDEX_TO', 0);
    },

    async SET_CLOSEST_INDEX(context, payload) {
      const runInstancesToShow = await context.state
        .runInstancesPaginationProperties.runInstancesToShow;
      return context.commit('SET_INDEX_TO', payload);
    },

    async HANDLE_RESIZING(context, payload) {
      const width = window.innerWidth;
      let numberOfInstancesToShow;

      if (width > 1800) {
        // numberOfInstancesToShow = context.getters.totalNumberOfRunInstances;
        numberOfInstancesToShow = 8;
      } else if (width > 1440) {
        numberOfInstancesToShow = 6;
      } else if (width > 767) {
        numberOfInstancesToShow = 4;
      } else {
        numberOfInstancesToShow = 1;
      }

      return context.commit(
        'SET_NUMBER_OF_RUN_INSTANCES_TO_SHOW',
        numberOfInstancesToShow,
      );
    },

    async UPDATE_ABLE_TO_SELECT_RUNS(context, payload) {
      return context.commit('SET_CAN_SELECT_RUNS;', payload);
    },
    async UPDATE_SELECTED_RUNS(context, payload) {
      return context.commit('SET_SELECTED_RUNS', payload);
    },
  },
  mutations: {

    SET_SCHEDULE_DATA(state, payload) {
      state.schedule.terms = payload;

      for (const term in state.schedule.terms) {
        state.contact.selectedRunsByTerm[term] = [];
			 }
    },


    SET_CAMP_DATA_v4(state, payload) {
      state.camps = payload.data;
    },


    SET_RUN_SETTINGS(state, payload) {
      state.runSettings = payload;
    },
		SET_RUN_INSTANCES(state, {runInstances, sessions}) {
			state.runInstances = runInstances;
			state.runSessions = sessions;
		},
    SET_RUN_BOOKINGS(state, payload) {
      state.runBookings = payload;
    },
    SET_ATTENDANCE_DATA(state, payload) {
      for (let i = 0; i < state.runBookings.length; i++) {
        state.runBookings[i].bookings.find((booking) => {
          if (
            booking.bookingInstanceId == payload.bookingInstanceId
          ) {
            booking.attended = payload.status;
          }
        });
      }
    },

    SET_NUMBER_OF_RUN_INSTANCES_TO_SHOW(state, payload) {
      state.runInstancesPaginationProperties.runInstancesToShow = payload;
    },

    UPDATE_TERM_INVITE_ONLY(state, payload) {
      state.schedule_v4.terms[payload.termId].termInviteOnly = payload.status;
    },
    SET_CURRENT_INDEX(state, payload) {
      state.runInstancesPaginationProperties.currentRunInstanceIndex += payload;
    },

    SET_INDEX_TO(state, payload) {
      state.runInstancesPaginationProperties.currentRunInstanceIndex = payload;
    },

    SET_CAN_SELECT_RUNS(state, payload) {
      state.contact.canSelectRuns = payload;
    },

    SET_SELECTED_RUNS(state, payload) {
      state.contact.selectedRuns = payload;
    },
    SET_FILTER(state, payload) {
      state.filters[payload.filter] = payload.value;
    },

    SET_SELECTED_BY_RUN(state, payload) {
      Vue.set(state.contact.selectedRunsByTerm, payload.termId, payload.value);
      // state.contact.selectedRuns = payload.value
    },

  },
  getters: {
    schedule(state) {
      return Object.values(state.schedule.terms)
        .sort((term1, term2) => parseInt(term1.termStartDateTime) - parseInt(term2.termStartDateTime));
    },

    run(state) {
      const runInstances = [];
      const index =				state.runInstancesPaginationProperties.currentRunInstanceIndex;
      const limit =				state.runInstancesPaginationProperties.runInstancesToShow;

      for (let i = index; i < limit + index; i++) {
        runInstances.push(state.runInstances[i]);
      }

      return {
        settings: state.runSettings,
        runInstances: runInstances.filter(Boolean),
        sessions: state.runSessions,
        bookings: state.runBookings.sort((a, b) => {
          if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
            return -1;
          }

          if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) {
            return 1;
          }
        }),
      };
    },

    allRunInstances(state) {
      return state.runInstances;
    },

    totalNumberOfRunInstances(state) {
      return state.runInstances.length;
    },

    allRunInstanceStartTimes(state) {
      return state.runInstances.map((instance) => parseInt(instance.startDateTime));
    },

    runBookings(state) {
      return state.runBookings;
    },

    numberOfRunInstancesToShow(state) {
      return state.runInstancesPaginationProperties.runInstancesToShow;
    },
    currentRunInstanceIndex(state) {
      return state.runInstancesPaginationProperties
        .currentRunInstanceIndex;
    },

    canSelectRuns(state) {
      return state.contact.canSelectRuns;
    },

    selectedRuns(state) {
      return state.contact.selectedRuns;
    },

    selectedRunsByTerm(state) {
      return state.contact.selectedRunsByTerm;
    },

    filters(state) {
      return state.filters;
    },

    days(state) {
      const days = [];
      Object.values(state.schedule_v4.terms).forEach((term) => {
        Object.values(term.runs).forEach((run) => {
          days.push(format(run.runStartTime * 1000, 'dddd'));
        });
      });

      // using set to get all unique values
      return Array.from(new Set(days))
        .sort((day1, day2) => {
          const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
          return daysOfWeek.indexOf(day1) - daysOfWeek.indexOf(day2);
        });
    },

    classTypes(state) {
      const classTypes = [];
      Object.values(state.schedule_v4.terms).forEach((term) => {
        Object.values(term.runs).forEach((run) => {
          classTypes.push(run.className);
        });
      });


      return Array.from(new Set(classTypes));
      // using set to get all unique values
    },

    venues(state) {
      const venues = [];
      Object.values(state.schedule_v4.terms).forEach((term) => {
        Object.values(term.runs).forEach((run) => {
          venues.push(run.venueName);
        });
      });


      return Array.from(new Set(venues));
      // using set to get all unique values
    },

    terms(state) {
      const terms = [];
      Object.values(state.schedule_v4.terms).forEach((term) => {
        terms.push(term.termName);
      });

      return Array.from(new Set(terms));
      // using set to get all unique values
    },


    camps(state) {
      return state.camps;
    },

    coachUserIds(state) {
      const coachUserIds = [];
      Object.values(state.schedule_v4.terms).forEach((term) => {
        Object.values(term.runs).forEach((run) => {
          run.coaches.forEach((userId) => {
            coachUserIds.push(userId);
          });
        });
      });

      return Array.from(new Set(coachUserIds));
      // using set to get all unique values
    },


  },
};
