<template>
<v-card class="mt-4">
    <v-list-item class="py-2" :href="url">
      <v-list-item-avatar>
          <v-icon :color="statusColour">{{ icon }}</v-icon>
      </v-list-item-avatar>

      <v-list-item-content>
          <v-list-item-title>
              <h6 class="title text-truncate">{{ clubName }}</h6>
          </v-list-item-title>
          <v-list-item-subtitle>
              {{subscriptionDetailText}}
          </v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-action>
          <Button :color="statusColour">
              {{manageActionText}}
          </Button>
      </v-list-item-action>

    </v-list-item>
  </v-card>
</template>

<script>
import Vue from 'vue';
import Button from '../../Buttons/Button.vue';
import { formatMoney } from '../../../../../../utilities/helpers';

export default Vue.extend({
  name: 'NoUserResourcesListItem',
  components: { Button },
  data() {
    return {

    };
  },
  props: {
    client: {
      type: Object,
      required: true,
    },
  },

  computed: {
    customerPortalUrl() {
      return this.client.url.customer_portal;
    },
    createCheckoutSessionUrl() {
      return this.client.url.checkout;
    },
    url() {
      return this.subscriptionSetUp ? this.customerPortalUrl : this.createCheckoutSessionUrl;
    },
    clubName() {
      return this.client.clubName;
    },
    status() {
      return this.client.status;
    },
    subscriptionSetUp() {
      return this.status !== 'not-set-up' && this.plan.amount;
    },
    plan() {
      return this.client.plan;
    },
    amountFormatted() {
      return formatMoney(this.plan.amount);
    },
    subscriptionDetailText() {
      return this.subscriptionSetUp ? this.subscriptionActiveText : this.subscriptionNotSetUpText;
    },
    subscriptionActiveText() {
      return this.isOutstanding ? this.$dictionary.billing.subscriptionOutstandingText : `${this.amountFormatted} ${this.$dictionary.billing.subscriptionActiveText}`;
    },
    subscriptionNotSetUpText() {
      return this.$dictionary.billing.subscriptionNotSetUpText;
    },
    isOutstanding() {
      return this.client.outstanding;
    },
    icon() {
      return this.subscriptionSetUp ? this.outstandingIcon : this.notSetUpIcon;
    },
    notSetUpIcon() {
      return 'fas fa-plus-circle';
    },
    outstandingIcon() {
      return this.isOutstanding ? 'fas fa-times-circle' : 'fas fa-check-circle';
    },
    statusColour() {
      return this.isOutstanding ? 'error' : 'accent';
    },
    manageActionText() {
      return this.subscriptionSetUp ? this.manageButtonText : this.subscriptionNotSetUpButtonText;
    },
    manageButtonText() {
      const { manageButtonTextOutstanding, manageButtonTextActive } = this.$dictionary.billing;
      return this.isOutstanding ? manageButtonTextOutstanding : manageButtonTextActive;
    },
    subscriptionNotSetUpButtonText() {
      return this.$dictionary.billing.subscriptionNotSetUpButtonText;
    },
  },
});

</script>

<style scoped>

</style>
