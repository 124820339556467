<template>
  <v-row>
    <v-col cols="8">
      <v-card>

        <v-card-title>
          {{term.termName}}
        </v-card-title>
        <v-expansion-panels>
          <InvoiceRun v-for="run in runs" :run="run" :key="run.runId" />
        </v-expansion-panels>
      </v-card>

    </v-col>

    <v-col cols="4">

      <v-card>

        <v-card-title>
          {{orderIds.length}} Orders Selected
        </v-card-title>

        <v-card-text>

          <div class="row">
            <div class="columns">
              <label for="method">
                <select name="method" id="method" v-model="sendMethod">
                  <option value="all">SMS & Email</option>
                  <option value="sms">SMS</option>
                  <option value="email">Email</option>
                </select>
              </label>
            </div>
          </div>

        </v-card-text>

        <v-card-actions>
          <Button block :disabled="disableButton" @click.prevent="send">
            Send
          </Button>
        </v-card-actions>
      </v-card>

    </v-col>

  </v-row>

</template>

<script>
import { mapGetters } from 'vuex';

import LoadingSpinner from '../../../components/Global/LoadingSpinner';

import InvoiceRun from './F5InvoiceRun';

export default {
  data() {
    return {
      termId: '',
      loading: false,
      sendMethod: 'all',
    };
  },
  methods: {
    send() {
      switch (this.sendMethod) {
        case 'all':
          this.sendSMSInvoices();
          break;
        case 'sms':
          this.sendSMSConfirmations();
          break;
        case 'email':
          break;
        default:
      }
    },

    async sendInvoices() {
      this.loading = true;
      const requestPayload = { orderIds: this.orderIds };
      await this.$store.dispatch('SEND_INVOICES', requestPayload);
      await this.$store.dispatch('GET_OUTSTANDING_INVOICES_FOR_TERM', { termId: this.termId });
      this.loading = false;
    },

    async sendSMSInvoices() {
      this.loading = true;
      const payload = Array.from(new Set(Object.values(this.selectedOrders))).map((order) => {
        const { mobile } = order;
        const name = order.firstName;
        return { substitutions: { name: [name] }, numbers: [mobile] };
      });

      await this.$store.dispatch('SEND_SMS_INVOICES', payload);
      await this.$store.dispatch('GET_OUTSTANDING_INVOICES_FOR_TERM', { termId: this.termId });
      this.loading = false;
    },

    async sendSMSConfirmations() {
      this.loading = true;
      const orderIds = this.orderIds.filter(this.confirmationOrderFilter);
      const requestPayload = { orderIds };
      await this.$store.dispatch('SEND_SMS_CONFIRMATIONS', requestPayload);
      await this.$store.dispatch('GET_OUTSTANDING_INVOICES_FOR_TERM', { termId: this.termId });
      this.loading = false;
    },

    confirmationOrderFilter(order) {
      return parseInt(order.invoiceSentCount) > 0;
    },

    invoiceFilter(order) {
      return !parseInt(order.invoiceSentCount) > 0;
    },

  },

  computed: {
    ...mapGetters(['term', 'selectedOrders', 'selectedOrderIds']),
    runs() {
      return this.term.runs;
    },
    orderIds() {
      return Array.from(new Set(this.selectedOrderIds));
    },
    disableButton() {
      return !this.orderIds.length > 0;
    },

  },

  components: {
    InvoiceRun,
    LoadingSpinner,
  },

  async mounted() {
    this.loading = true;
    this.termId = this.$route.params.termId;
    const requestPayload = { termId: this.termId };
    await this.$store.dispatch('GET_OUTSTANDING_INVOICES_FOR_TERM', requestPayload);

    this.loading = false;
  },

  beforeMount() {
  },

};
</script>

<style lang="scss" scoped>

</style>
