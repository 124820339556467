<template>

        <v-row>
            <v-col>

                <v-list dense two-line class="pt-4 mt-2">
                    <v-list-item>
                        <v-list-item-avatar color="info" class="white--text">
                            <v-icon class="white--text">
                                fad fa-receipt
                            </v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>
                                <h5 class="title font-weight-bold">Summary</h5>
                            </v-list-item-title>
                            <v-list-item-subtitle color="UIAction">
                                Add and Manage Subscription Plans
                            </v-list-item-subtitle>
                        </v-list-item-content>

                    </v-list-item>

                </v-list>




                <v-list avatar two-line subheader>
                    <v-subheader>
                        Summary
                    </v-subheader>

                    <!--                    <v-list-item>-->
                    <!--                        <v-list-item-avatar>-->
                    <!--                            <v-icon color="secondary">fad fa-book-spells</v-icon>-->
                    <!--                        </v-list-item-avatar>-->

                    <!--                        <v-list-item-title>-->
                    <!--                            Collected This Month-->
                    <!--                        </v-list-item-title>-->

                    <!--                        <v-list-item-action>-->
                    <!--                            <h5></h5>-->
                    <!--                        </v-list-item-action>-->
                    <!--                    </v-list-item>-->




                    <v-list-item>
                        <v-list-item-avatar>
                            <v-icon color="success">fad fa-smile</v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content>

                            <v-list-item-title>
                                New Subscriptions
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                Subscribed this Month
                            </v-list-item-subtitle>
                        </v-list-item-content>



                        <v-list-item-action>
                            <h5>{{subscribedThisMonth}}</h5>
                        </v-list-item-action>
                    </v-list-item>

                    <v-list-item>

                        <v-list-item-avatar>
                            <v-icon color="secondary">fad fa-frown</v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content>

                            <v-list-item-title>
                                Failed Payments
                            </v-list-item-title>

                        </v-list-item-content>
                        <v-list-item-action>
                            <h5>{{failedPaymentsTotal}}</h5>
                        </v-list-item-action>
                    </v-list-item>



                </v-list>


            </v-col>
        </v-row>



</template>

<script>

  import {mapGetters} from 'vuex';
  import { formatMoney } from '&/helpers.js';

  export default {
    name: 'ViewSubscriptionSummary',

    computed: {
      ...mapGetters(['plans', 'summary']),

      plansCount() {
        return Object.values(this.plans).length;
      },
      failedPaymentsTotal() {
        return formatMoney(this.summary.problemTotal)
      },
      subscribedThisMonth() {
        return this.summary.thisMonth
      }


    },

    mounted() {
      this.$store.dispatch('GET_SUBSCRIPTIONS_SUMMARY');
    }
  }
</script>

<style scoped>

</style>
