<template>

    <v-skeleton-loader
      type="list-item-avatar-three-line"
      :loading="loading"
    >
    <v-list two-line dense subheader avatar>
        <v-subheader>
            Payment Collection
        </v-subheader>
        <v-list-item>
            <v-list-item-avatar>
                <v-icon color="UIAction">fad fa-credit-card-front</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title>
                    {{statusText}}
                </v-list-item-title>
                <v-list-item-subtitle v-if="isPaused">
                    On {{pausedDateFormatted}}
                </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>


                <v-dialog v-model="modal" persistent max-width="290">

                  <template v-slot:activator="{ on, attrs }">
                            <v-btn text small
                      v-bind="attrs"
                      v-on="on"
                            >
                                <v-icon color="error" v-if="!isPaused">fas fa-pause-circle</v-icon>
                                <v-icon color="info" v-else >fas fa-play-circle</v-icon>
                                &nbsp; {{status}}
                            </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="headline">Notify Parent</v-card-title>
                    <v-card-text>We'll send {{parent.firstName}} an email letting them know we're {{isPaused ? 'resuming' : 'pausing'}} their Subscription.</v-card-text>
                      <v-card-text v-if="user.isAdmin">
                        <v-checkbox
                          v-model="sendEmail"
                          label="Send Email"
                          small
                        ></v-checkbox>
                      </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="UIAction" text @click="modal = false">Cancel</v-btn>
                      <v-btn color="success" text @click="confirmAction">Confirm</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>


            </v-list-item-action>
        </v-list-item>
    </v-list>
        </v-skeleton-loader>
</template>

<script>
import { mapGetters } from 'vuex';
import SubscriptionMixin from '../../../mixins/Subscriptions/SubscriptionMixin';

export default {
  name: 'ManagePaymentCollection',
  mixins: [SubscriptionMixin],
  data() {
    return {
      sendEmail: true,
      modal: false,
    };
  },
  computed: {
    ...mapGetters(['_subscription', 'clientId', 'user', 'loading']),
    stripe() {
      return this._subscription.stripe;
    },
    parent() {
      return this._subscription.parent;
    },
  },

  methods: {
    confirmAction() {
      this.modal = false;
      this.handlePauseAndResumeSubscription(this.stripe.id);
    },
  },
};
</script>

<style scoped>

</style>
