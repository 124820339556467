const SMSPage = () => import(/* webpackPrefetch: true */ './../pages/Messaging/SMS/SMSPage');
const ComposeNewSMSPage = () => import(/* webpackPrefetch: true */ './../pages/Messaging/SMS/ComposeNewSMSPage');
const PreviewNewSMSPage = () => import(/* webpackPrefetch: true */ './../pages/Messaging/SMS/PreviewNewSMSPage');
const SuccessfullySentSMSPage = () => import(/* webpackPrefetch: true */ './../pages/Messaging/SMS/SuccessfullySentSMSPage');
const ViewSMSRecipientsPage = () => import(/* webpackPrefetch: true */ './../pages/Messaging/SMS/ViewSMSRecipientsPage');
const ViewInvalidSMSRecipientsPage = () => import(/* webpackPrefetch: true */ './../pages/Messaging/SMS/ViewInvalidSMSRecipientsPage');
export default [
  {
    component: SMSPage,
    path: '/cms/communications/sms',
    name: 'SMSPage',
    meta: {
      title: 'SMS',
    },
    children: [
      {
        component: ComposeNewSMSPage,
        path: 'compose',
        name: 'ComposeNewSMSPage',
        meta: {
          title: 'Compose',
        },
      },

      {
        component: PreviewNewSMSPage,
        path: 'preview',
        name: 'PreviewNewSMSPage',
        meta: {
          title: 'Preview',
        },
      },

      {
        component: SuccessfullySentSMSPage,
        path: 'success',
        name: 'SuccessfullySentSMSPage',
        meta: {
          title: 'Success 🎉',
        },
      },

      {
        component: ViewSMSRecipientsPage,
        path: 'recipients',
        name: 'ViewSMSRecipientsPage',
        meta: {
          title: 'Recipients',
        },
      },

      {
        component: ViewInvalidSMSRecipientsPage,
        path: 'recipients',
        name: 'ViewInvalidSMSRecipientsPage',
        meta: {
          title: 'Invalid Recipients',
        },
      },
    ],
  },
];
