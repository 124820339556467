<template>
    <div>
        <div class="text-xs-center"  v-if="globalLoading">
            <v-icon>fas fa-circle-notch fa-spin</v-icon>
        </div>
        <span v-if="nextInvoiceDate">
            {{nextInvoiceDateFormatted}}
            <br />
            <span class="caption font-weight-bold">({{nextInvoiceAmountDueFormatted}})</span>
        </span>
    </div>
</template>

<script>

import { formatDate, formatMoney} from '&/helpers.js';
import {mapGetters} from "vuex";

export default {
  name: 'NextInvoice',
  data() {
    return {
    };
  },
  props: {
    subscription: {
      required: true,
      type: Object,
    },
  },
  computed: {
      ...mapGetters(['globalLoading']),
    nextInvoice() {
      return this.subscription.invoices.upcoming;
    },
    nextInvoiceDate() {
      return this.nextInvoice.next_payment_attempt;
    },
    nextInvoiceDateFormatted() {
      return formatDate(this.nextInvoiceDate);
    },
    nextInvoiceAmountDue() {
      return this.nextInvoice.amount_due;
    },
    nextInvoiceAmountDueFormatted() {
      return formatMoney(this.nextInvoiceAmountDue);
    },
  },
  methods: {
  },
  components: {
  },
};
</script>
