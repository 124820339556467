
import Dictionary from '../components/Dictionary';
import config from '../assets/text/index';


export default function (Vue, { defaultLanguage }) {
  // Register the component to be used anywhere
  Vue.component('Dictionary', Dictionary);


  // replace the language properties so they can be more easily accessed
  const dictionary = { ...config };
  for (const module in dictionary) {
    dictionary[module] = dictionary[module][defaultLanguage];
  }

  // Expose a method to get the data in any component
  Vue.prototype.$dictionary = dictionary;
}
