import { render, staticRenderFns } from "./BookingsList.vue?vue&type=template&id=b3d773ac&scoped=true&"
import script from "./BookingsList.vue?vue&type=script&lang=js&"
export * from "./BookingsList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b3d773ac",
  null
  
)

export default component.exports