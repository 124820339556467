
import { post } from '../http';
import {
  AUTHENTICATION_URL,
  AUTHORIZATION_PREFIX,
  AUTHORIZATION_CREDENTIALS
} from './credentials';

/**
 * Get credentials for making authorized API calls
 * @returns {Promise<any>}
 */
export function getCredentials () {
  const authorizationCredentials = `${AUTHORIZATION_PREFIX} ${AUTHORIZATION_CREDENTIALS}`;
  const headers = new Headers({
    'Authorization': authorizationCredentials,
    'Content-Type' : 'application/x-www-form-urlencoded'
  });
  return post({url: AUTHENTICATION_URL, headers })
}
