function getEmoji(countryCode) {
  switch (countryCode) {
    case 'US':
      return ':flag-us:';

    case 'GB':
      return ':flag-gb:';

    case 'IE':
      return ':flag-ie:';

    case 'NZ':
      return ':flag-nz:';

    case 'CA':
      return ':flag-ca:';

    case 'AU':
      return ':flag-au:';

    case 'SG':
      return ':flag-sg:';

    case 'BB':
      return ':flag-bb:';

    case 'BE':
      return ':flag-be:';

    default:
      return ':flag-scotland:';
  }
}

function textSignUp(clubName, emailAddress, clubUrl, countryCode, phoneNumber) {
  return `${clubName
  } has signed up a booking system.\nTheir email address is ${
    emailAddress
  }\nTheir system is ${
    clubUrl
  }, their mobile number is ${phoneNumber} ${
    getEmoji(countryCode)}`;
}

function textSignUpBeta(clubName, emailAddress, clubUrl, countryCode, countryName, phoneNumber) {
  return `${clubName
  } has attempted to sign up a booking system from a country(${countryName}) that's still in beta.\nTheir email address is ${
    emailAddress
  }, their mobile number is ${phoneNumber} ${
    getEmoji(countryCode)}`;
}

export {
  textSignUp,
  textSignUpBeta,
  getEmoji,
};
