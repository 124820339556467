<template>
  <v-text-field
      :append-icon="showPlainPassword ? 'fal fa-eye input-icon' : 'fal fa-eye-slash input-icon'"
      @click:append="showPlainPassword = !showPlainPassword"
      color="primary"
      flat
      @input="$emit('input', $event)"
      :label="label"
      prepend-inner-icon="far fa-lock-alt"
      outlined
      :type="showPlainPassword ? 'text' : 'password'"
      :value="value"
      class="c4kInput"
      :class="{'c4kInput--error' : showError}"
      :autocomplete="autocomplete"
      :autofocus="autofocus"
      :rules="rules"
      required
      :error-messages="errorMessageValue"
      :error="updateErrorStateOnError()"
      @blur="updateErrorStateOnUnfocus()"
      data-test="passwordInput"
  >
    <template v-slot:message v-if="customErrorMessage">
      {{ errorMessageValue }}
      <router-link :to="{name: 'ForgotPassword'}" class="error--text" v-if="promptToReset">
        <Dictionary module="login" string="reset_password_error_prompt"/>
      </router-link>
    </template>

    <slot>
    </slot>

  </v-text-field>
</template>

<script>
import { VTextField } from 'vuetify/lib';

export default {
  name: 'PasswordInput',
  extends: VTextField,
  data() {
    return {
      showPlainPassword: false,
      errorState: false,
    };
  },
  computed: {
    showError() {
      return this.errorState || this.errorMessageState;
    },
    customErrorMessage() {
      return this.errorMessageValue;
    },
    promptToReset() {
      return this.autocomplete === 'current-password' && this.showPrompt;
    },
  },
  methods: {
    updateErrorStateOnUnfocus() {
      this.errorState = this.hasError;
    },
    updateErrorStateOnError() {
      if (this.hasState || this.value.length > 0) {
        this.errorState = this.hasError;
      }
    },
  },
  watch: {},
  props: {
    showPrompt: {
      type: Boolean,
      required: false,
      default() {
        return true;
      },
    },
    value: {
      type: String,
      required: false,
      default() {
        return '';
      },
    },

    label: {
      type: String,
      required: false,
      default() {
        return 'Password';
      },
    },

    rules: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    errorMessageValue: {
      type: String,
      required: false,
      default() {
        return '';
      },
    },
    errorMessageState: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    autocomplete: {
      type: String,
      required: false,
      default() {
        return 'current-password';
      },
    },
    autofocus: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },

  },
  updated() {
  },
};
</script>

<style lang="scss" scoped>

</style>
