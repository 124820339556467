import UserResourcesPage from '../../../global/pages/Users/ResourcesPage';

export default [
  {
    component: UserResourcesPage,
    path: '/profiles',
    name: 'ResourcesPage',
    meta: {
      title: '',
    },
  },
];
