<template>
  <!-- For Each Order in The Run -->

  <v-expansion-panel>
    <v-expansion-panel-header>
      {{run.className}} - {{run.venueName}} <span class="text-right">{{selected.length}} selected </span>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-data-table
          v-model="selectedOrders"
          :headers="headers"
          :items="bookingsFormatted"
          item-key="bookingId"
          show-select>
      </v-data-table>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { formatDate, formatMoney } from '../../../../../../utilities/helpers';
import InvoiceRunBookingListItem from './InvoiceRunBookingListItem';

export default {
  data() {
    return {
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'fullName',
        },
        {
          text: 'Balance',
          align: 'start',
          sortable: true,
          value: 'balanceFormatted',
        },
        {
          text: 'Invoices',
          align: 'start',
          sortable: true,
          value: 'invoiceSentCount',
        },

      ],
      selected: [],
    };
  },

  props: {
    run: {
      required: true,
      type: Object,
    },
  },

  methods: {

    formatDate(timestamp) {
      return formatDate(timestamp);
    },
    bookingFormatter(booking) {
      return {
        fullName: `${booking.firstName} ${booking.lastName}`,
        balanceFormatted: formatMoney(booking.orderItemBalance),
        ...booking,
      }
    }
  },

  computed: {
    selectedOrders: {
      get() {
        return this.$store.getters.selectedOrders;
      },
      set(orders) {
        this.$store.commit('UPDATE_SELECTED_ORDERS', orders);
      },
    },

    id() {
      return `panel${this.run.runId}`;
    },

    href() {
      return `#${this.id}`;
    },

    bookingsFormatted() {
      return this.run.bookings.map(this.bookingFormatter);
    },

  },

  mounted() {

  },

  components: {
    InvoiceRunBookingListItem,
  },

};
</script>

<style scoped>

.checkbox {
  margin: 0 auto;
  text-align: center;
  align-items: center;
}

</style>
