<template>
    <span>{{ planName }}</span>
</template>

<script>

import { formatMoney } from '&/helpers.js';

export default {
  name: 'Plan',
  data() {
    return {};
  },
  props: {
    subscription: {
      required: true,
      type: Object,
    },
  },
  computed: {
    plan() {
      return this.subscription.c4k.plan;
    },
    planCostFormatted() {
      return formatMoney(this.plan.cost);
    },
    planName() {
      return this.plan.planId === null ? this.tempPlanMarkup : this.planMarkup;
    },
    /**
       * @return {string}
       */
    planMarkup() {
      return `${this.plan.name} (${this.planCostFormatted} per ${this.plan.frequency})`;
    },
    /**
       * @return {string}
       */
    tempPlanMarkup() {
      return `Temporary Plan ${this.planCostFormatted} per month`;
    },
  },
  methods: {},
  components: {},
  mounted() {
  },
};
</script>
