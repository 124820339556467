<template>
<!--Just for testing right now-->
  <v-row>
    <v-col>
      <Button block @click="login">
        <v-icon>fas fa-fingerprint</v-icon>
      </Button>
    </v-col>
  </v-row>

</template>

<script>

import Vue from 'vue';

export default Vue.extend({
  name: 'WebAuthnAuthentication',
  props: {
    valid: {
      required: true,
      type: Boolean,
    },
  },
  methods: {
    login() {
      alert('This would log a user in with WebAuthn');
    },
  },
});
</script>

<style scoped>

</style>
