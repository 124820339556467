<template>

        <v-row>
            <v-col>

                <v-list avatar dense two-line class="pt-4 mt-2">
                    <v-list-item>
                        <v-list-item-avatar color="info"  class="white--text">
                            <v-icon class="white--text">
                                fas fa-calendar
                            </v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>
                                <h5 class="title font-weight-bold">Plans</h5>
                            </v-list-item-title>
                            <v-list-item-subtitle color="UIAction">
                                Monthly payment plans
                            </v-list-item-subtitle>
                        </v-list-item-content>

                    </v-list-item>

                </v-list>




                <v-list avatar two-line subheader>


                    <v-list-item href="/cms/payments/recurring/plans">
                        <v-list-item-avatar  color="secondary" class="white--text">
                            {{plansCount}}
                        </v-list-item-avatar>

                        <v-list-item-content>
                        <v-list-item-title>
                            Manage plans
                        </v-list-item-title>
                        <v-list-item-subtitle color="UIAction">
                            View, add and manage monthly payment plans
                        </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action>
                            <v-icon>fas fa-chevron-right</v-icon>
                        </v-list-item-action>
                    </v-list-item>


                    <v-list-item href="/cms/payments/recurring/plans#subsNotes">
                        <v-list-item-avatar  color="secondary" class="white--text">
                            <v-icon color="white"> fas fa-sticky-note</v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content>
                        <v-list-item-title>
                            Update Subscription notes
                        </v-list-item-title>
                        <v-list-item-subtitle color="UIAction">
                            Add or make changes to the Club's Subscription notes
                        </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action>
                            <v-icon>fas fa-chevron-right</v-icon>
                        </v-list-item-action>
                    </v-list-item>


                </v-list>





            </v-col>
        </v-row>



</template>

<script>

  import {mapGetters} from 'vuex';
  import { formatMoney } from '&/helpers.js';

  export default {
    name: 'ViewSubscriptionPlans',

    computed: {
      ...mapGetters(['plans', 'summary']),

      plansCount() {
        return Object.values(this.plans).length;
      },
      failedPaymentsTotal() {
        return formatMoney(this.summary.problemTotal)
      },
      subscribedThisMonth() {
        return this.summary.thisMonth
      }


    },

    mounted() {
      // this.$store.dispatch('GET_SUBSCRIPTIONS_SUMMARY');
    }
  }
</script>

<style scoped>

</style>
