<template>
  <v-row>
    <v-col cols="12">
      <v-list>
        <v-list-item
          v-for="(option, index) in customRegisterOptions"
          :key="index"
          two-line
          :three-line="!!option.options"
        >
          <v-list-item-content>
            <v-list-item-title>{{ option.name }}</v-list-item-title>
            <v-list-item-subtitle>
              {{ getType(option.type) }}
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="option.options">
              <v-chip
                class="ma-2"
                color="primary"
                v-for="(option, index) in option.options"
                :key="index"
                outlined
              >
                {{ option }}
              </v-chip>
            </v-list-item-subtitle>
          </v-list-item-content>


          <v-list-item-action>
            <v-btn fab text small @click="editCustomRegisterOption(option)"
              ><v-icon small color="primary">fal fa-edit </v-icon></v-btn
            >
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-col>
  </v-row>
</template>
<script>
import AddNewCustomRegisterNavigationDrawer from './AddNewCustomRegisterNavigationDrawer';
export default {
  name: 'CustomRegisterOptionsList',
  data() {
    return {
      customRegisterOptionToEdit: {},
    };
  },
  props: {
    customRegisterOptions: {
      type: Array,
      required: true,
    },
  },
  watch: {},
  methods: {
    getType(type) {
      if (type === 'boolean') {
        return 'Checkbox';
      } else if (type === 'multi') {
        return 'Dropdown';
      }
    },
    editCustomRegisterOption(option) {
      this.$emit('edit-custom-register-option', {...option});
    },
    refreshCustomRegisterOptions() {
      this.$emit('refresh');
    },
  },
  computed: {},
  components: {
    AddNewCustomRegisterNavigationDrawer,
  },
};
</script>

<style scoped></style>
