<template>
  <v-container fluid>
    <v-app-bar app class="elevation-1 top-bar" color="background">
      <router-link :to="{ name: 'ResourcesPage' }">
        <v-img
          src="/pwa-static/images/logo_default.png"
          max-width="200"
          max-height="40"
          position="left"
          contain
          :class="{ shrinkLogo: $vuetify.breakpoint.smAndDown }"
        ></v-img>
      </router-link>

      <v-spacer></v-spacer>

      <RegionSelectorDropdownMenu
        v-show="show"
        class="text-center text-sm-right"
      />

      &nbsp;
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-fab-transition>
            <v-btn color="accent" fab small depressed v-on="on" v-show="show">
              <strong>{{ initials }}</strong>
            </v-btn>
          </v-fab-transition>
        </template>
        <v-list min-width="200">
          <v-list-item dense :to="{ name: 'ClientBillingPage' }" v-if="isAdmin">
            <v-list-item-title>
              <v-icon size="medium" left>fal fa-file-invoice</v-icon>
              Billing
            </v-list-item-title>
          </v-list-item>

          <v-list-item dense :to="{ name: 'ParentPermissionsPage' }">
            <v-list-item-title>
              <v-icon size="medium" left>fal fa-camera-alt</v-icon>
              Permissions
            </v-list-item-title>
          </v-list-item>

          <v-list-item dense @click="logout">
            <v-list-item-title>
              <v-icon size="medium" left>fal fa-sign-out</v-icon>
              Sign out
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main>
      <v-row no-gutters class="page-wrapper">
        <v-col>
          <div class="resources-wrapper">
            <router-view></router-view>
          </div>
        </v-col>
      </v-row>
    </v-main>

    <ProfileFooter />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import ProfileFooter from '../components/Login/ProfileFooter.vue';
import { setCookie } from '../../services/cookies';

export default {
  name: 'UserResourcesLayout',
  data() {
    return {
      load: false,
      drawer: null,
    };
  },
  methods: {
    logout() {
      setCookie('REMEMBERME', '');
      window.location.href = '/logout';
    },
  },
  computed: {
    ...mapGetters(['person', 'clients']),
    initials() {
      const { firstName, lastName } = this.person;
      return `${firstName.charAt(0)}${lastName.charAt(0)}`;
    },
    show() {
      return this.initials.length > 0;
    },
    isAdmin() {
      return Object.values(this.clients).length > 0;
    },
  },
  async created() {
    await this.$store.commit('SET_GLOBAL_LOADING', true);
    this.$store.dispatch('GET_MY_PERSON');
    this.$store.dispatch('GET_STRIPE_SUBSCRIPTION_STATUS');
    await this.$store.dispatch('GET_CLIENT_USER_RESOURCES');
    await this.$store.dispatch('GET_PARENT_USER_RESOURCES');
    await this.$store.commit('SET_GLOBAL_LOADING', false);
  },
  updated() {},
  components: {
    ProfileFooter,
  },
};
</script>

<style scoped>
.page-wrapper {
  margin-bottom: 8rem; /* enough space to accommodate the footer on mobile without overlapping */
}

.top-bar {
  border-bottom: 1px solid var(--v-form-border-base);
}

.v-application .elevation-1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.1),
    0px 1px 1px 0px rgba(0, 0, 0, 0.08), 0px 1px 3px 0px rgba(0, 0, 0, 0.09) !important;
}

.resources-wrapper {
  max-width: 623px !important;
  margin: 0 auto;
}

>>> .resource-loader > div {
  margin-top: 16px;
}

.shrinkLogo {
  width: 100px;
}
</style>
