<template>
    <tr>
        <td>
            <v-simple-checkbox
                    :disabled="loading"
                    :value="subscriptions.isSelected"
                    @input="toggle(subscriptions)"
                    :ripple="false"
            ></v-simple-checkbox>
        </td>
        <td>
            {{ parentFullName }}
            <!--            TO DO - fix dodgy implementation of clicking name to open nav-->
            <!--                        <p color="secondary" @click.prevent="drawer = true" class="name">{{ parentFullName }}</p>-->
        </td>
        <td>
            <div v-if="loading" class="text-center">
                <v-icon >fas fa-circle-notch fa-spin</v-icon>
            </div>

            <div v-else class="">
                <v-chip class="ma-2" label v-if="subscriptionIsPaused(subscription.stripe)" small style="font-weight: 600">
                    <v-icon left>fad fa-pause-circle</v-icon> Paused
                </v-chip>
                <v-chip class="ma-2" small label v-else color="info" style="font-weight: 600">
                    <v-icon left>fad fa-play-circle</v-icon> Active
                </v-chip>
            </div>


        </td>
        <td>{{ planName }}</td>
        <td class="hidden">{{ planCostFormatted}}</td>
        <td class="hidden" v-html="childNamesFormatted"></td>

        <td>
            {{ lastPaidDateFormatted}}
            <span v-if="paymentErrorMessage">
                    <br />
                    <span class="caption font-weight-bold error--text">
                        {{paymentErrorMessageFormatted}}
                    </span>
            </span>
        </td>

        <td>
            <div class="text-xs-center"  v-if="globalLoading">
                <v-icon>fas fa-circle-notch fa-spin</v-icon>
            </div>
            <span v-if="nextInvoiceDate">
                {{nextInvoiceDateFormatted}}
                <br />
                <span class="caption font-weight-bold">
                    ({{nextInvoiceAmountDueFormatted}})
                </span>
            </span>
        </td>
        <td>
            <v-tooltip bottom v-if="countOfBookings > 0" >
                <template v-slot:activator="{ on }">
                    <span v-on="on" style="cursor:pointer">{{ countOfBookings}}</span>
                </template>
                <span v-html="childNamesFormatted"></span>
            </v-tooltip>
        </td>
        <td class="">
            <ManageSubscriptionNavigationDrawer :subscription="subscription" :drawer.sync="drawer" />
            <SubscriptionListItemActionsMenu :subscription="subscription" v-if="$vuetify.breakpoint.smAndDown" />
        </td>

    </tr>
</template>

<script>

import { mapGetters } from 'vuex';
import { formatMoney, formatDate } from '&/helpers.js';
import SubscriptionListItemActionsMenu from '../SubscriptionListItemActionsMenu';
import SubscriptionMixin from '../../../mixins/Subscriptions/SubscriptionMixin';
import ManageSubscriptionNavigationDrawer from '../ManageSubscription/ManageSubscriptionNavigationDrawer';

export default {
  name: 'SubscriptionsTableListItem',
  mixins: [SubscriptionMixin],
  data() {
    return {
      drawer: false,
    };
  },
  props: {
    subscriptions: {
      required: true,
      type: Object,
    },
    subscription: {
      required: true,
      type: Object,
    },
  },
  computed: {
    ...mapGetters(['user', 'loading', 'globalLoading']),
    parent() {
      return this.subscription.parent;
    },
    personId() {
      return this.parent.personId;
    },
    parentFullName() {
      return `${this.parent.firstName} ${this.parent.lastName}`;
    },
    bookings() {
      return this.parent.bookings;
    },
    childNamesFormatted() {
      let text = '';

      if (this.bookings) {
        const bookings = Object.values(this.bookings);
        const countOfChildren = bookings.length;
        bookings.forEach((child, index) => {
          const childFullName = `${child.firstName} ${child.lastName}`;
          text += childFullName;
          index < (countOfChildren - 1) ? text += '<br />' : '';
        });
      }

      return text;
    },
    countOfBookings() {
      return this.parent.bookingCount;
    },
    plan() {
      return this.subscription.c4k.plan;
    },
    planCostFormatted() {
      return formatMoney(this.plan.cost);
    },
    planName() {
      return `${this.plan.name} (${this.planCostFormatted} per ${this.plan.frequency})`;
    },
    lastPaidDateFormatted() {
      return formatDate(this.subscription.c4k.lastPaid.date);
    },

    paymentErrorMessage() {
      return this.subscription.c4k.lastPaid.status;
    },
    paymentErrorMessageFormatted() {
      // we log the other message to the DB straight from Stripe so this seemed easy place to change it without mucking up the data
      return this.subscription.c4k.lastPaid.status === 'Your card has insufficient funds.' ? 'The customers card failed due to insufficient funds' : this.subscription.c4k.lastPaid.status;
    },

    nextInvoice() {
      return this.subscription.invoices.upcoming;
    },
    nextInvoiceDate() {
      return this.nextInvoice.next_payment_attempt;
    },
    nextInvoiceDateFormatted() {
      return formatDate(this.nextInvoiceDate);
    },
    nextInvoiceAmountDue() {
      return this.nextInvoice.amount_due;
    },
    nextInvoiceAmountDueFormatted() {
      return formatMoney(this.nextInvoiceAmountDue);
    },


  },

  methods: {
    toggle({ isSelected, select }) {
      select(!isSelected);
    },
  },

  components: {
    ManageSubscriptionNavigationDrawer,
    SubscriptionListItemActionsMenu,
  },
  updated() {
    // if(!this.subscription.stripe.id) {
    //   this.getSubscriptionsForPerson();
    // }
  },
  activated() {

  },
  mounted() {
    // console.log(this.subscription.parent.firstName);
    // if(!this.subscription.stripe.id) {
    // this.hydrate();
    // }
  },
  created() {

  },
};
</script>

<style scoped>
    .name {
        margin: 0;
        padding: 0;
        font-weight: 600;
        cursor: pointer;
    }

    >>>.v-input--selection-control {
        margin-top: 0 !important;
    }
</style>
