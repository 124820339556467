<template>
  <v-card>
    <v-toolbar color="primary" dark>
      <v-toolbar-title>Distinct Participants</v-toolbar-title>
    </v-toolbar>
    <v-expansion-panels>
      <v-expansion-panel v-for="(participant, i) in participants" :key="i">
        <v-expansion-panel-header>
          <strong>{{ participant.schoolName }}</strong>

          <span
            v-for="(value, key, index) in participant.participants"
            :key="index"
          >
            {{ key }}: {{ getTotalParticipants(value) }}
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card flat>
            <v-card-text class="white">
              <AsmoDistinctParticipantsTable :school="participant" />
            </v-card-text>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import AsmoDistinctParticipantsTable from './AsmoDistinctParticipantsTable';

export default {
  name: 'AsmoDistinctParticipants',
  data: function () {
    return {};
  },
  computed: {
    ...mapGetters(['participants']),
  },
  methods: {
    getTotalParticipants: function (participants) {
      // let total = 0;
      let initialValue = 0;
      const sessionsAttended = Object.values(participants).map((yearGroup) => {
        return parseInt(yearGroup.sessionsAttended, 10);
      });
      return sessionsAttended.reduce((previousValue, currentValue) => {
        return previousValue + currentValue;
      }, initialValue);
    },
  },
  mounted: function () {},
  components: {
    AsmoDistinctParticipantsTable,
  },
};
</script>

<style scoped></style>
