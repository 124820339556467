/**
 * APIGateway URL, routes and credentials
 * for Notify API
 *
 */

const AUTHENTICATION_URL = 'https://cdk-starter-test-stable.auth.eu-west-1.amazoncognito.com/oauth2/token?scope=westpoint/authorize&grant_type=client_credentials';
const AUTHORIZATION_PREFIX = 'Basic'
const AUTHORIZATION_CREDENTIALS = 'bDFoZGcwOWE2MmJwaWxxcXJydGYxcmxrZDoxZmpybzYycnNxbjQxOHMzaW9oN2Y0dTU4MTVoN2NyOW5nZDE1MTE3czVmdDd1cmRxdjhq';


const NOTIFY_URL = 'https://34658ac7ua.execute-api.eu-west-1.amazonaws.com/dev';
const SEND_SMS = '/send/sms-multiple';
const SEND_EMAIL = '/send/email-multiple';

const LIST_SMS_TEMPLATES = '/template/list?type=SMS';
const LIST_EMAIL_TEMPLATES = '/template/list?type=email';

const CREATE_SMS_TEMPLATE = '/template/sms/upload';
const READ_SMS_TEMPLATE = '/template/sms/get';
const UPDATE_SMS_TEMPLATE = '/template/sms/edit';
const DELETE_SMS_TEMPLATE = '/template/sms/delete';

const CREATE_EMAIL_TEMPLATE = '/template/email/upload';
const READ_EMAIL_TEMPLATE = '/template/email/get';
const UPDATE_EMAIL_TEMPLATE = '/template/email/edit';
const DELETE_EMAIL_TEMPLATE = '/template/email/delete';


export {
  AUTHENTICATION_URL,
  AUTHORIZATION_PREFIX,
  AUTHORIZATION_CREDENTIALS,
  NOTIFY_URL,
  SEND_SMS,
  SEND_EMAIL,
  LIST_SMS_TEMPLATES,
  LIST_EMAIL_TEMPLATES,
  CREATE_SMS_TEMPLATE,
  READ_SMS_TEMPLATE,
  UPDATE_SMS_TEMPLATE,
  DELETE_SMS_TEMPLATE,
  CREATE_EMAIL_TEMPLATE,
  READ_EMAIL_TEMPLATE,
  UPDATE_EMAIL_TEMPLATE,
  DELETE_EMAIL_TEMPLATE,
}





