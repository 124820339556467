<template>
  <div>
    <div class="offline banner" v-if="!isOnline">

        <p class = "message">
            <strong>Offline - last online {{lastOnline}}</strong>
        </p>

        <p class="message" v-if="attendanceRequestQueue.length > 0">
          {{attendanceRequestQueue.length}} requests pending to be made
        </p>
    </div>

    <div class="banner online" v-else>
      <p class = "message" v-if="attendanceRequestQueue.length > 0">

        <span v-if="syncing">
          Syncing with ClassForKids
        </span>
        <span v-else>
          {{attendanceRequestQueue.length}} requests pending, tap to sync
        </span>
      </p>



      <a href="#" class="syncButton" v-if="attendanceRequestQueue.length > 0" @click.prevent="syncAttendanceRequestsWithServer" v-html="syncIcon"></a>



      <InstallToDevice />
    </div>
  </div>


</template>

<script>
import { mapGetters } from 'vuex';
import InstallToDevice from './InstallToDevice.vue';

const offline = {
  name: 'OfflineBanner',
  data() {
    return {
      loading: false
    };
  },
  methods: {
    syncAttendanceRequestsWithServer() {
      this.loading = true;
      return this.$store
        .dispatch('SYNC_ATTENDANCE_WITH_SERVER')
        .then(response => {
          this.loading = false;
        });
    }
  },

  computed: {
    ...mapGetters([
      'isOnline',
      'lastOnline',
      'attendanceRequestQueue',
      'syncing'
    ]),

    syncIcon() {
      if (this.syncing) {
        return '<i class="fal fa-2x fa-sync fa-spin"></i>';
      } else {
        return '<i class="fal fa-2x fa-sync"></i>';
      }
    }
  },
  components: {
    InstallToDevice
  },
  created: function() {
    this.$store.dispatch('GET_CACHED_RUNS');
    this.$store.dispatch('GET_CACHED_ATTENDANCE_REQUESTS');
  }
};

export default offline;
</script>

<style lang="scss" scoped>
@import './../../assets/scss/variables.scss';

.banner {
  padding: 16px;
  display: grid;
  align-items: center;
  grid-auto-rows: 64px;
  grid-template-columns: 1fr;
}

.banner.online {
  background-color: $cfk-lightblue;
  transition-property: all;
  transition-duration: 0.5s;
  padding: 8px 32px 8px 32px;
  grid-template-columns: 7fr 1fr;
}

.banner.offline {
  background-color: $red;
  transition-property: all;
  transition-duration: 0.5s;
  grid-template-columns: 1fr 1fr;
}

.syncButton {
  text-decoration: none;
  cursor: pointer;
  color: #fff;
}

.message {
  padding: 0;
  margin: 0;
  font-size: 0.7rem;
  font-weight: 700;
  color: #fff;
}

@media (min-width: 415px) {

  .message {
    font-size: 0.9rem;
  }
}


</style>
