import {
  NOTIFY_URL,
  SEND_SMS
} from './../credentials';

import { post } from '../../http';
const CONTENT_TYPE = 'application/json';

export default async function({token_type, access_token}, {template, recipients}) {
  const url = `${NOTIFY_URL}/${SEND_SMS}`;
  const headers = new Headers({
    'Authorization' : `${token_type} ${access_token}`,
    'Content-Type': CONTENT_TYPE
  });

  const body = {
    template,
    recipients
  }
  return await post({url, headers, body});
};


