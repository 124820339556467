<template>
    <div>
  <v-btn class="text-center"
                elevation="0"
                text
                x-small
                @click="drawer = true"
                :disabled="loading"
        >
            {{date}}
        </v-btn>
        <v-navigation-drawer
                v-model="drawer"
                temporary
                hide-overylay
                clipped
                app
                right
                width="420"
        >
            <v-toolbar flat color="white" v-if="$vuetify.breakpoint.smAndDown">
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-btn text icon @click="drawer = false"><v-icon>fas fa-times</v-icon></v-btn>
                </v-toolbar-items>
            </v-toolbar>

            <v-list avatar dense two-line class="pt-4 mt-2">
                <v-list-item>
                    <v-list-item-avatar color="info">
                        <v-icon>
                            fas fa-user
                        </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title>
                            <h5 class="title font-weight-bold">Take Attendance</h5>
                        </v-list-item-title>
                        <v-list-item-subtitle color="UIAction">
                            Mark as either Present or Absent
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <v-skeleton-loader :loading="loading" type="list-item-avatar-three-line@6">
                <span>

                    <TakeAttendance :run-instance-id="runInstance.runInstanceId"
                                        :id="runInstance.runId" v-if="drawer"/>
                </span>
            </v-skeleton-loader>

        </v-navigation-drawer>
    </div>
</template>

<script>
import TakeAttendance from '../../../pages/OfflineRegisters/TakeAttendance';
import moment from 'moment';

export default {
  name: 'TakeAttendanceNavigationDrawer',
  data() {
    return {
      drawer: false,
      loading: false,
    };
  },
  props: {
    runInstance: {
      type: Object,
      required: true,
    },

  },
  computed: {
    date() {
      return moment(this.runInstance.startDateTime * 1000).utc().format('ddd Do MMM');
    },
  },
  components: {
    TakeAttendance,

  },
};
</script>

<style scoped>

</style>
