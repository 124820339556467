import { get } from '../http';

const RESOURCE_URL = '/rest/v1/users/resources';
const CLIENT_RESOURCE_URL = `${RESOURCE_URL}/clients`;
const PARENT_RESOURCE_URL = `${RESOURCE_URL}/parents`;

export async function getClientUserResources() {
  const url = CLIENT_RESOURCE_URL;
  return get({ url });
}

export async function getParentUserResources() {
  const url = PARENT_RESOURCE_URL;
  return get({ url });
}
