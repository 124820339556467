<template>

    <div v-if="supportsPWAFeatures && isOnline">
        <a href="#" :class="switchToggleClass" v-on:click.prevent="toggleCache" v-html="switchToggleText"></a>
    </div>

</template>

<script>


export default {
  name: 'SaveRunOffline',
  data() {
    return {
    };
  },

  props: {
    runId: {
      required: true,
      type: String,
    },
    size: {
      required: false,
      type: String,
    },
    verbose: {
      required: false,
      type: Boolean,
      default() {
        return false;
      }
    }
  },

  computed: {
    cached() {
      const { cachedRuns } = this.$store.getters;
      return cachedRuns.includes(this.runId);
    },
    switchToggleText() {
      let size;
      if (!size) {
        size = '';
      }

      return `<i class="fas fa-toggle-${this.cached ? 'on' : 'off'} ${size}"></i> Save${this.cached ? 'd' : ' '} ${this.verbose ? 'Offline' : ''}`;

    },
    switchToggleClass() {
      if (this.cached) {
        return 'active';
      }
      return 'disabled';
    },
    isOnline() {
      return this.$store.getters.isOnline;
    },
    supportsPWAFeatures() {
      return this.$store.getters.supportsPWAFeatures;
    },
  },


  methods: {
    saveOffline() {
      const { runId } = this;
      this.$store.dispatch('SAVE_OFFLINE', runId);
    },
    removeFromCache() {
      const { runId } = this;
      this.$store.dispatch('REMOVE_FROM_CACHE', runId);
    },
    toggleCache() {
      if (!this.cached) {
        if ('vibrate' in window.navigator) {
          window.navigator.vibrate([100]);
        }
        this.saveOffline();
      } else {
        if ('vibrate' in window.navigator) {
          window.navigator.vibrate([50]);
        }
        this.removeFromCache();
      }
    },

  },

  components: {

  },

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a {
  text-decoration: none;
  cursor: pointer;
}

.active {
  color: #343a51;
}

.disabled {
  color: #b3b6c2;
}

</style>
