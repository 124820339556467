import Vue from 'vue';
import Vuex from 'vuex';

import API from '_GLOBAL/store/API';
import authentication from '_GLOBAL/store/authentication';
import resources from '_GLOBAL/store/resources';
import loading from '_GLOBAL/store/loading';
import authorization from '_GLOBAL/store/authorization';
import notifySMS from '_GLOBAL/store/notifySMS';
import notifyTemplates from '_GLOBAL/store/notifyTemplates';
import resetPassword from '_GLOBAL/store/resetPassword';
import person from '_GLOBAL/store/person';
import pageState from '../../../global/store/pageState';

// each Vuex store is kept in a separate module
import global from './global';
import bookings from './bookings';
import client from './client';
import camp from './camp';
import campBooking from './campBooking';
import notifications from './notifications';
import payments from './payments';
import manageSubscriptions from './manageSubscriptions';
import viewSubscriptions from './viewSubscriptions';
import people from './people';
import ASMO from './asmo-reporting';
import autoEnrol from './../pages/Prototypes/auto-enrol';
import orders from './orders';
import offline from './offline';
import schedule from './schedule';
import signUp from './sign-up';
import customRegisters from './custom-registers';
import contacts from './contacts';


import sms from './sms';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    global,
    API,
    authentication,
    resources,
    resetPassword,
    person,
    notifications,
    bookings,
    client,
    camp,
    campBooking,
    sms,
    payments,
    viewSubscriptions,
    manageSubscriptions,
    people,
    authorization,
    notifySMS,
    notifyTemplates,
    ASMO,
    loading,
    offline,
    schedule,
    autoEnrol,
    orders,
    signUp,
    customRegisters,
    contacts,
    pageState,

  },
});
