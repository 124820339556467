import { post } from '../http';

const RESET_PASSWORD_URL = '/rest/v1/authentication/request-password-reset';
const CHANGE_PASSWORD_URL = '/rest/v1/authentication/change-password';
const MAGIC_LINK_URL = '/rest/v1/authentication/magic-login';

export async function postPasswordResetRequest({ username }) {
  const url = RESET_PASSWORD_URL;
  const body = { username };
  const headers = new Headers({
    'Content-Type': 'application/json',
  });
  return post({ url, headers, body });
}

export async function postNewPasswordForReset({ newPassword, confirmPassword }) {
  const url = CHANGE_PASSWORD_URL;
  const body = { newPassword, confirmPassword };
  const headers = new Headers({
    'Content-Type': 'application/json',
  });
  return post({ url, headers, body });
}

export async function postMagicLinkRequest({ resetKey }) {
  const url = MAGIC_LINK_URL;
  const body = { resetKey };
  const headers = new Headers({
    'Content-Type': 'application/json',
  });
  return post({ url, headers, body });
}
