import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';

function isStaging(hostname) {
  return /.staging.class4kids./.test(hostname);
}

function getEnvironment() {
  if (isStaging(window.location.hostname)) {
    return 'staging';
  }
  return process.env.NODE_ENV;
}

Sentry.init({
  dsn: 'https://b89fd4151007433eb6f8e55539629277@o304837.ingest.sentry.io/5270047',
  environment: getEnvironment(),
  integrations: [new VueIntegration({
    Vue,
    attachProps: true,
  })],
});
