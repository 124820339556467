<template>
<div class="mt-4">

    <div class="title">
        <h2>Dictionary</h2>
   <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          text
        >
         {{currentSelectedFlag}}
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in languages"
          :key="index"
          @click="setLanguage(item)"
        >
          <v-list-item-title>{{item.flag}}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    </div>





    <v-card v-for="(module, key) in dictionary" :key="key" class="mb-2">
        <v-card-title class="primary--text">{{key}}</v-card-title>
        <div v-for=" (string, key) in module[language]" class="definition">
            <v-card-text><strong>{{key}}:</strong></v-card-text>
            <v-card-text>{{string}}</v-card-text>
        </div>
    </v-card>
</div>
</template>

<script>
import config from '../../assets/text/index';

export default {
  name: 'DictionaryPage',

  computed: {
    dictionary() {
      // debugger;
      return config;
    },
    language() {
      return this.$vuetify.lang.current;
    },
    languages() {
      return {
        en: { language: 'English', code: 'en', flag: '🇬🇧' },
        fr: { language: 'French', code: 'fr', flag: '🇫🇷' },
      };
    },
    currentSelectedFlag() {
      return this.languages[this.language].flag;
    },
  },
  methods: {
    setLanguage(language) {
      this.$vuetify.lang.current = language.code;
    },
  },
};
</script>

<style scoped>
    .title {
        display: grid;
        grid-template-columns: 1fr 48px;
    }
.definition {
    display: grid;
    grid-template-columns: 2fr 6fr;
}
</style>
