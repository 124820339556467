export default {
    en: {
      title: 'Permissions',
      subtitle: 'Manage your permissions with the Clubs you attend',
      photo_permission_title: 'Photo Permissions',
      photo_permission_explanation: 'If you opt out of photo permissions for your child, we let this club know by displaying an icon warning them that photography is prohibited in every class they attend.'
  
    },
  
    fr: {
      title: 'S\'identifier',
      subtitle: 'Bienvenue a ClassForKids, c\'est la bonne la monde',
    },
  };
  