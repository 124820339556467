<template>
  <div>
    <v-tabs v-model="tab" >
      <v-tab :key="0">Bookings</v-tab>
      <v-tab :key="1">Invoicing</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item :key="0">
        <F5AutoEnrolBookings />
      </v-tab-item>

      <v-tab-item :key="1">
        <F5InvoiceByTerm />
      </v-tab-item>
    </v-tabs-items>

  </div>
</template>

<script>
import F5AutoEnrolBookings from './F5AutoEnrolBookings';
import F5InvoiceByTerm from './F5InvoiceByTerm';

export default {
  name: 'App',
  data() {
    return {
      tab: null,
    };
  },

  components: {
    F5AutoEnrolBookings,
    F5InvoiceByTerm,
  },
  created() {

  }
};
</script>

<style scoped>

</style>
