<template>
  <v-layout row>
    <Button @click="openNewCustomRegisterOptionForm" class="text-right">
      Add New Column</Button>
    <v-navigation-drawer v-model="show" app clipped right temporary width="420" :permanent="show">

      <v-sheet flat>
        <v-list dense three-line class="pt-4 mt-2">
          <v-list-item>

            <v-list-item-avatar color="success">
              <v-icon> fad fa-book-spells </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>

                <h5 class="title font-weight-bold">{{ editing ? 'Edit' : 'Add New' }} Question </h5>

              </v-list-item-title>


              <v-list-item-subtitle color="UIAction">

                {{ editing ? 'Edit a' : 'Add a' }} Custom Register question


              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-layout fluid fill-height class="mt-2">
          <v-flex xs12>
            <v-select class="pa-2" v-model="contactAttribute.type" :items="items" label="Select question type"
              v-if="!editing">
            </v-select>

            <v-list-item v-if="editing" class="pa-2">
              <v-list-item-content>
                <v-list-item-title> Active </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-switch v-model="contactAttribute.active" inset :true-value="'1'" :false-value="'0'"></v-switch>
              </v-list-item-action>
            </v-list-item>

            <v-list-item-subtitle color="UIAction" v-if="contactAttribute.type">
              <p class="subtitle-2 ma-2">
                {{ userInstructions }}
              </p>
            </v-list-item-subtitle>

            <v-text-field label="Please enter a question" v-model="contactAttribute.name" v-if="contactAttribute.type"
              class="pa-2"></v-text-field>

            <v-chip class="ma-2" color="primary" outlined close @click:close="removeAnswer(index)"
              v-for="(option, index) in contactAttribute.options" :key="index">
              {{ option }}
            </v-chip>

            <v-row v-if="contactAttribute.type === 'multi'">
              <v-col cols="12">
                <v-form @submit="addNewMultiOption">
                  <v-text-field label="Please enter an answer" v-model="newMultiOption" class="pa-2">
                    <template v-slot:append-outer>
                      <v-btn icon fab small @click="addNewMultiOption" :disabled="!validMultiOptionForm">
                        <v-icon>fal fa-plus-circle</v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                </v-form>
              </v-col>
            </v-row>

            <div class="pa-2">
              <Button @click="submit" :disabled="!validForm" block :loading="updating">
                {{ buttonText }}</Button>

              <p color="error">{{ errorMessage }}</p>
              <Button @click="show = false" block ghost>Close without saving</Button>
            </div>

          </v-flex>
        </v-layout>
      </v-sheet>
    </v-navigation-drawer>
  </v-layout>
</template>
<script>
export default {
  name: 'AddNewCustomRegisterNavigationDrawer',
  data() {
    return {
      show: false,
      updating: false,
      newOption: {
        name: '',
        type: '',
        options: [],
      },
      updateOption: {
        contactAttributeId: '',
        name: '',
        type: '',
        options: [],
        active: '1',
      },
      newMultiOption: '',
      items: [
        { text: 'Checkbox', value: 'boolean' },
        { text: 'Dropdown', value: 'multi' },
      ],
      errorMessage: '',
    };
  },
  props: {
    customRegisterOption: {
      type: Object,
      required: false,
    },
    edit: {
      required: false,
      type: Boolean
    }
  },
  watch: {
    customRegisterOption(value) {
      if (value) {
        this.updateOption = value;
        this.show = true;
      }
    },
  },
  methods: {
    openNewCustomRegisterOptionForm() {
      this.show = true;
      this.errorMessage = '';
      this.$emit('update-edit', false);



      this.newMultiOption = '';
      this.newOption = {
        name: '',
        type: '',
        options: [],
      };
      this.updateOption = {
        contactAttributeId: '',
        name: '',
        type: '',
        options: [],
        active: '1',
      };
    },

    addNewMultiOption(event) {
      event.preventDefault();
      this.contactAttribute.options.push(this.newMultiOption);
      this.newMultiOption = '';
    },
    getActiveFlag(option) {
      return parseInt(option.active, 10) === 1;
    },

    async submit() {

      const { type, options } = this.updateOption;
      if (type === 'multi' && options && options.length < 1) {
        this.errorMessage = 'Please add at least two answers';
        return false;
      }


      if (this.editing) {
        await this.updateCustomRegisterOption();
      } else {
        await this.createCustomRegisterOption();
      }
    },
    async createCustomRegisterOption() {
      this.updating = true;
      await this.$store.dispatch(
        'CREATE_CUSTOM_REGISTER_OPTION',
        this.contactAttribute
      );
      this.resetForm();
      this.updating = false;
      this.$emit('refresh');
    },
    async updateCustomRegisterOption() {
      this.updating = true;
      await this.$store.dispatch(
        'UPDATE_CUSTOM_REGISTER_OPTION',
        this.contactAttribute
      );
      this.resetForm();
      this.updating = false;
      this.$emit('refresh');
    },

    resetForm() {
      this.errorMessage = '';
      this.newMultiOption = '';
      this.newOption = {
        name: '',
        type: '',
        options: [],
      };
      this.show = false;
    },
    removeAnswer(index) {
      this.contactAttribute.options.splice(index, 1);
    },
  },
  computed: {
    contactAttribute() {
      const { editing, newOption, updateOption } = this;
      return editing ? updateOption : newOption;
    },
    validForm() {
      return this.isBooleanFormValid || this.isMultiFormValid;
    },

    isBooleanFormValid() {
      const { contactAttribute } = this;
      return (contactAttribute.type === 'boolean') && (contactAttribute.name.length > 0)
    },


    isMultiFormValid() {
      const { contactAttribute } = this;
      return (contactAttribute.type === 'multi') && (contactAttribute.options.length > 1) && (contactAttribute.length != 0)
    },


    validMultiOptionForm() {
      const { contactAttribute, newMultiOption } = this;
      return (contactAttribute.name.length > 0) && (newMultiOption.length > 0);

    },

    editing() {
      return this.updateOption.contactAttributeId.length > 0;
    },

    buttonText() {
      return this.editing ? 'Update question' : 'Add question';
    },
    userInstructions() {
      const { contactAttribute } = this;
      const { type } = contactAttribute;
      const handleType = {
        'multi': 'You must have at least 2 answers when using a drop-down.',
      }
      return handleType[type];
    },
  },
};
</script>

<style scoped>

</style>
