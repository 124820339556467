<template>
  <v-bottom-sheet
    color="primary"
    v-model="sheet"
    hide-overlay
    persistent
    no-click-animation
    scrollable
  >
    <v-card width="75%">
      <v-container fluid class="pa-0">
        <v-row no-gutters>
          <v-col
            cols="12"
            class="pa-5"
            v-show="bottomSheetType === 'ContactsPage'"
          >
            <ContactsBottomNavigation />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-bottom-sheet>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import ContactsBottomNavigation from '../../bookingservice/v4/pages/Contacts/ContactsBottomNavigation.vue';

export default Vue.extend({
  name: 'BottomNavigation',
  components: {
    ContactsBottomNavigation,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['bottomNavigationRouteGlobal']),
    sheet: {
      get() {
        return this.bottomNavigationRouteGlobal !== null;
      },
      set() {},
    },
    bottomSheetType() {
      return this.bottomNavigationRouteGlobal !== null
        ? this.bottomNavigationRouteGlobal
        : '';
    },
  },
  methods: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  updated() {},
});
</script>

<style scoped></style>
