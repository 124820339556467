<template>
<v-list-item :to="{'name':'ManageSubscriptionPage', 'params': {'personId' : subscription.parent.personId}}">
    <v-list-item-title >Manage Subscription</v-list-item-title>
</v-list-item>
</template>

<script>

  

  export default {
    name: 'ManageSubscriptionMenuItem',
    data() {
      return {
      }
    },
    props: {
      subscription : {
        required: true,
        type: Object
      }
    },
  }
</script>

<style scoped>

</style>