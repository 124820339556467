import Vue from 'vue';
import {
  createRunInstance,
  updateRunInstance,
  createInstanceGroup,
  updateInstanceGroup,
  addRunInstanceType,
} from '../../../services/notify/camps/camps';

export default {
  state: {
    campsCalendarLoading: false,
    pageState: {
      inReview: false,
      updateComplete: false,
      runInstanceToEdit: {
        startDateTime: 0,
        endDateTime: 0,
      },
      instanceGroupToEdit: {
        instanceGroupId: '',
        instanceGroupName: '',
      },
    },
    camp: {
      campId: null,
      class: {
        classId: null,
        name: null,
      },
      venue: {
        venueId: null,
        name: null,
      },
      offersHalfDays: false,
      instances: [],
    },

    changeLog: {
      instances: {},
      modifiedInstances: {
        toUpdate: {},
        toRemove: {},
      },
    },
    campsModal: {
      message: '',
      active: false,
    },
  },

  actions: {
    async CAMP_GET_CAMP_BY_ID(context, payload) {
      const request = {
        url: `/api/v4/camps/${payload}`,
      };
      const mutation = 'CAMP_SET_CAMP';
      const options = {
        request,
        mutation,
      };

      await context.dispatch('MAKE_GET_REQUEST', options);
    },

    CAMP_SET_CHANGE_LOG(context, modifiedRunInstance) {
      let sourceRunInstance = null;
      for (const instanceGroup of Object.values(context.state.camp.instances)) {
        if (sourceRunInstance !== null) break;
        for (const runInstances of Object.values(instanceGroup.runInstances)) {
          if (sourceRunInstance !== null) break;
          for (const runInstance of Object.values(runInstances.childRunInstances)) {
            if (sourceRunInstance !== null) break;
            if (modifiedRunInstance.runInstanceId === runInstance.runInstanceId) {
              sourceRunInstance = runInstance;
            }
          }
        }
      }
      if (
        sourceRunInstance !== null && (
          modifiedRunInstance.active !== sourceRunInstance.active
          || modifiedRunInstance.startDateTime !== sourceRunInstance.startDateTime
          || modifiedRunInstance.endDateTime !== sourceRunInstance.endDateTime
        )
      ) {
        const action = modifiedRunInstance.active !== sourceRunInstance.active && modifiedRunInstance.active !== '1' ? 'toRemove' : 'toUpdate';
        context.commit('CAMP_CHANGE_LOG_REMOVE', modifiedRunInstance);
        context.commit('CAMP_CHANGE_LOG_ADD', {
          runInstance: modifiedRunInstance,
          action,
        });
      } else {
        context.commit('CAMP_CHANGE_LOG_REMOVE', modifiedRunInstance);
      }
      context.commit('CHECK_FOR_ORPHANED_INSTANCES_IN_UPDATE_LOG', context);
    },

    async CREATE_NEW_RUN_INSTANCES({ commit }, payload) {
      await createRunInstance(payload);
    },
    async UPDATE_RUN_INSTANCE({ commit }, payload) {
      const result = await updateRunInstance(payload);
    },

    async CREATE_NEW_INSTANCE_GROUP({ commit }, payload) {
      const instanceGroup = await createInstanceGroup(payload);
      if (instanceGroup.data.instanceGroupId) {
        await commit('UPDATE_INSTANCE_GROUPS', instanceGroup);
      }
      return instanceGroup;
    },
    async UPDATE_INSTANCE_GROUP({ commit }, payload) {
      await updateInstanceGroup(payload);
    },
    async ADD_RUN_INSTANCE_TYPE({ commit }, payload) {
      await addRunInstanceType(payload);
    },
    VALIDATE_UPDATE_LOG(context) {
      context.commit('CHECK_FOR_ORPHANED_INSTANCES_IN_UPDATE_LOG', context);
    },
  },

  mutations: {
    SET_CAMPS_CALENDAR_LOADING(state, payload) {
      state.campsCalendarLoading = payload;
    },
    SET_CAMPS_MODAL(state, payload) {
      state.campsModal.message = payload.message;
      state.campsModal.active = true;
    },
    SET_CAMPS_MODAL_STATE(state, payload) {
      state.campsModal.active = payload;
    },
    CHECK_FOR_ORPHANED_INSTANCES_IN_UPDATE_LOG(state, context) {
      const arrayOfFullDaysToUpdate = [];
      const arrayOfFullDaysToRemove = [];

      Object.keys(context.state.camp.instances)
        .forEach((instance) => {
          Object.keys(context.state.camp.instances[instance].runInstances)
            .forEach((runInstance) => {
              if (context.state.camp.instances[instance].runInstances[runInstance].active === '1') {
                arrayOfFullDaysToUpdate.push(context.state.camp.instances[instance].runInstances[runInstance].runInstanceId);
              }
            });
        });

      Object.keys(state.changeLog.modifiedInstances.toUpdate)
        .forEach((item) => {
          if (state.changeLog.modifiedInstances.toUpdate[item].type === 'full') {
            arrayOfFullDaysToUpdate.push(state.changeLog.modifiedInstances.toUpdate[item].runInstanceId);
          }
        });

      Object.keys(state.changeLog.modifiedInstances.toUpdate)
        .forEach((item) => {
          if ((state.changeLog.modifiedInstances.toUpdate[item].type !== 'full') && !arrayOfFullDaysToUpdate.includes(state.changeLog.modifiedInstances.toUpdate[item].childOf)) {
            state.changeLog.modifiedInstances.toUpdate[item].active = '0';
            context.commit('CAMP_CHANGE_LOG_REMOVE', state.changeLog.modifiedInstances.toUpdate[item]);
          }
        });

      Object.keys(state.changeLog.modifiedInstances.toRemove)
        .forEach((item) => {
          if (state.changeLog.modifiedInstances.toRemove[item].type === 'full') {
            arrayOfFullDaysToRemove.push(state.changeLog.modifiedInstances.toRemove[item].runInstanceId);
          }
        });

      Object.keys(state.changeLog.instances)
        .forEach((instance) => {
          Object.keys(state.changeLog.instances[instance].runInstances)
            .forEach((runInstance) => {
              Object.keys(state.changeLog.instances[instance].runInstances[runInstance].childRunInstances)
                .forEach((childInstance) => {
                  if (state.changeLog.instances[instance].runInstances[runInstance].childRunInstances[childInstance].type !== 'full'
                    && state.changeLog.instances[instance].runInstances[runInstance].childRunInstances[childInstance].active === '1'
                    && arrayOfFullDaysToRemove.includes(state.changeLog.instances[instance].runInstances[runInstance].childRunInstances[childInstance].childOf)) {
                    const payload = {
                      action: 'toRemove',
                      runInstance: state.changeLog.instances[instance].runInstances[runInstance].childRunInstances[childInstance],
                    };
                    state.changeLog.instances[instance].runInstances[runInstance].childRunInstances[childInstance].active = '0';
                    context.commit('CAMP_CHANGE_LOG_ADD', payload);
                  }
                });
            });
        });
    },
    CAMP_CHANGE_LOG_ADD(state, payload) {
      Vue.set(state.changeLog.modifiedInstances[payload.action], payload.runInstance.runInstanceId, payload.runInstance);
    },
    CAMP_CHANGE_LOG_REMOVE(state, payload) {
      if (payload.runInstanceId in state.changeLog.modifiedInstances.toRemove) {
        Vue.delete(state.changeLog.modifiedInstances.toRemove, payload.runInstanceId);
      }
      if (payload.runInstanceId in state.changeLog.modifiedInstances.toUpdate) {
        Vue.delete(state.changeLog.modifiedInstances.toUpdate, payload.runInstanceId);
      }
    },
    CAMP_RESET_RUN_INSTANCE(state, modifiedRunInstance) {
      let sourceRunInstance = null;
      for (const instanceGroup of Object.values(state.camp.instances)) {
        if (sourceRunInstance !== null) break;
        for (const runInstances of Object.values(instanceGroup.runInstances)) {
          if (sourceRunInstance !== null) break;
          for (const runInstance of Object.values(runInstances.childRunInstances)) {
            if (sourceRunInstance !== null) break;
            if (modifiedRunInstance.runInstanceId === runInstance.runInstanceId) {
              sourceRunInstance = runInstance;
              Vue.set(
                state.changeLog.instances[instanceGroup.instanceGroupId].runInstances[runInstances.runInstanceId].childRunInstances,
                sourceRunInstance.runInstanceId,
                JSON.parse(JSON.stringify(sourceRunInstance)),
              );
            }
          }
        }
      }
    },
    CAMP_SET_CAMP(state, payload) {
      Vue.set(state, 'camp', payload);
      Vue.set(state.changeLog, 'instances', JSON.parse(JSON.stringify(payload.instances)));
    },
    CAMP_SET_UPDATE_COMPLETE(state, payload) {
      state.pageState.updateComplete = payload;
    },
    SET_RUN_INSTANCE_TO_EDIT(state, payload) {
      state.pageState.runInstanceToEdit = payload;
    },
    SET_INSTANCE_GROUP_TO_EDIT(state, payload) {
      state.pageState.instanceGroupToEdit = payload;
    },
    UPDATE_INSTANCE_GROUPS(state, payload) {
      const instanceGroup = payload.data;
      const instanceGroupFormatted = {
        instanceGroupId: instanceGroup.instanceGroupId.toString(),
        instanceGroupName: instanceGroup.name,
        runInstances: {},
      };
      const tempObject = {};
      tempObject[instanceGroup.instanceGroupId.toString()] = instanceGroupFormatted;
      state.camp.instances = { ...state.camp.instances, ...tempObject };
    },
    RESET_INSTANCE_GROUP_TO_EDIT(state) {
      state.pageState.instanceGroupToEdit = {
        instanceGroupId: '',
        instanceGroupName: '',
      };
    },
  },

  getters: {
    camp(state) {
      return state.camp;
    },
    campRunInstances(state) {
      return state.changeLog.instances;
    },
    campModifiedRunInstances(state) {
      return state.changeLog.modifiedInstances;
    },
    campUpdateComplete(state) {
      return state.pageState.updateComplete;
    },
    runInstanceToEdit(state) {
      return state.pageState.runInstanceToEdit;
    },
    instanceGroupToEdit(state) {
      return state.pageState.instanceGroupToEdit;
    },
    campsModal(state) {
      return state.campsModal;
    },
    campsCalendarLoading(state) {
      return state.campsCalendarLoading;
    },
  },
};
