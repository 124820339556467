<template>
  <v-row>
    <v-col>
      <v-form>
        <v-row justify="center" align="center">
          <v-col cols="12">
            <v-card-title class="my-3" primary--text>
              <h2 class="primary--text">
                                <span class="no-wrap">
                                  <Dictionary module="existingClient" string="titleLineOne"/>
                                </span>
                <span class="no-wrap">
                                  <Dictionary module="existingClient" string="titleLineTwo"/>
                                </span>
              </h2>
            </v-card-title>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12">
            <v-text-field
                color="primary"
                label="Club Name"
                type="text"
                v-model="userName"
                outlined
                required
                flat
                readonly
            />
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12">
            <PasswordInput
                v-model="password"
                :rules="passwordRulesComputed"
                required
                :error-message-state="error"
                :error-message-value="errorMessage"
                @click="updateErrorStateOnUnFocus()"
                autocomplete="current-password"
                :showPrompt="false"
            />
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12">
            <Button
                style="min-width: 100%"
                @click="login()"
                :disabled="!passwordValidChecker"
                class="primary"
                :loading="localLoading"
            >
              <Dictionary module="keyWords" string="login"/>
            </Button>
          </v-col>
        </v-row>
      </v-form>
      <SignUpTestimonial/>
    </v-col>
  </v-row>
</template>

<script>
import Vue from 'vue';
import {mapGetters} from 'vuex';
import SignUpTestimonial from '../../components/SignUp/SignUpTestimonial.vue';
import PasswordInput from '../../../../global/components/UILibrary/Inputs/PasswordInput.vue';
import {passwordValueRequiredRule, passwordValidRule} from '../../../../global/helpers/rules/password';

export default Vue.extend({
  name: 'SignInExistingClientPage',
  components: {
    SignUpTestimonial,
    PasswordInput,
  },
  props: {},
  data() {
    return {
      error: false,
      errorMessage: '',
      password: '',
      localLoading: false,
      passwordRules: [
        passwordValueRequiredRule,
        passwordValidRule,
      ],
      enableVerificationRules: false,
    };
  },
  computed: {
    ...mapGetters(['userNameSignUp', 'environmentSignUp']),
    passwordRulesComputed() {
      if (this.enableVerificationRules || passwordValidRule(this.password) === true) {
        this.enableVerificationRules = true;
        return this.passwordRules;
      }
      return [];
    },
    passwordValidChecker() {
      return this.passwordRules.every((rule) => rule(this.password) === true);
    },
    userName() {
      return this.userNameSignUp;
    },
    environment() {
      return this.environmentSignUp.environment;
    }
  },
  methods: {
    async login() {
      this.error = false;
      this.localLoading = true;
      const response = await this.$store.dispatch('LOGIN_USER_SIGN_UP', this.password);

      if (response.status === 200) {
        var url;
        if (this.environment === 'dev') {
          url = 'http://account.docker.dev.sandbox.classforkids.io/';
        } else {
          url = `https://account${this.environment === 'stage' ? '.staging' : ''}.classforkids.io`;
        }

        window.location.href = url;
        return;
      }
      this.error = true;
      this.errorMessage = 'Your account or password is incorrect.';

      this.localLoading = false;
    },
    updateErrorStateOnUnFocus() {
      this.error = false;
    },
  },
  beforeCreate() {
  },
  created() {
  },
  beforeMount() {
  },
  mounted() {
    if (!this.userName) {
      this.$router.push({name: 'SignUpInitialPage'});
    }
    this.$store.dispatch('CHECK_LOGIN');
  },
  updated() {
  },
  destroyed() {
  },
});
</script>

<style scoped>
.no-wrap {
  white-space: nowrap;
}

.v-card__title {
  flex-direction: column !important;
}
</style>
