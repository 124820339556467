
import Vue from 'vue';

// TO DO REPLACE this call
export default {
  state: {
    stripeSubscriptionStates: ['active', 'trialing', 'unpaid', 'incomplete', 'past_due'],
    subscription: {
      lastUpdated: '',
      clientId: '',
      parent: {
        firstName: '',
        lastName: '',
        personId: '',
        bookings: [],
      },
      c4k: {},
      stripe: {
        id: '',
        status: '',
      },
      invoices: {
        past: [],
        upcoming: {
          amount_due: 0,
          next_payment_attempt: 0,
        },
      },
    },


    subscriptions: [{
      status: 'active',
      plan: {
        amount: 0,
        interval: 'month',
        product: {
          name: '',
        },
      },
      discount: {
        coupon: {
          percent_off: null,
        },
      },
    }],
    invoices: {
      past: [],
      upcoming: {
        amount_due: 0,
        next_payment_attempt: 0,
      },
      coveringPayments: {},
    },
    customer: {},
  },
  actions: {


    async GET_SUBSCRIPTIONS({ dispatch }, payload) {
      const { personId, clientId, localLoading } = payload;

      const request = {
        url: `/api/v4/subscriptions/${personId}`,
        parameters: {
          personId,
          clientId,
        },
      };
      const mutation = 'SET_SUBSCRIPTION';
      const options = {
        request,
        mutation,
        localLoading,
      };

      await dispatch('MAKE_POST_REQUEST', options);
    },

    async PAUSE_RESUME_SUBSCRIPTION({ dispatch }, payload) {
      const {
        subscriptionId, sendEmail, clientId, action, localLoading,
      } = payload;

      const request = {
        url: `/api/v4/subscriptions/${action}`,
        parameters: {
          subscriptionId,
          clientId,
          sendEmail,
        },
      };
      const mutation = 'UPDATE_SUBSCRIPTION';
      const options = {
        request,
        mutation,
        localLoading,
      };

      return await dispatch('MAKE_POST_REQUEST', options);
    },

    async REFUND_CHARGE_FROM_SUBSCRIPTION({ dispatch }, payload) {
      const { chargeId, amount, localLoading } = payload;

      const request = {
        url: '/api/v4/subscriptions/charge/refund',
        parameters: {
          chargeId,
          amount,
        },
      };
      const mutation = 'SET_SUBSCRIPTION';
      const options = {
        request,
        mutation,
        localLoading,
      };

      await dispatch('MAKE_POST_REQUEST', options);
    },

    async CHANGE_SUBSCRIPTION_PLAN({ dispatch }, { subscriptionId, planId }) {
      const request = {
        url: `/api/v4/subscriptions/update/${subscriptionId}/${planId}`,
        parameters: {
          subscriptionId,
          planId,
        },
      };
      const mutation = 'SET_SUBSCRIPTION';
      const options = {
        request,
        mutation,
        localLoading: true,
      };

      return await dispatch('MAKE_POST_REQUEST', options);
    },

    async CANCEL_SUBSCRIPTION({ dispatch }, { subscriptionId }) {
      const request = {
        url: `/api/v4/subscriptions/${subscriptionId}/cancel`,
        parameters: {
          subscriptionId,
        },
      };
      const mutation = 'SET_SUBSCRIPTION';
      const options = {
        request,
        mutation,
        localLoading: true,
      };

      return await dispatch('MAKE_POST_REQUEST', options);
    },


    async REACTIVATE_SUBSCRIPTION({ dispatch }, { subscriptionId }) {
      const request = {
        url: `/api/v4/subscriptions/${subscriptionId}/reactivate`,
        parameters: {
          subscriptionId,
        },
      };
      const mutation = 'SET_SUBSCRIPTION';
      const options = {
        request,
        mutation,
        localLoading: true,
      };

      return await dispatch('MAKE_POST_REQUEST', options);
    },


    async ADD_SUBSCRIPTION_COVERING_PAYMENT({ dispatch }, { recurringPaymentSubscriptionId, amount, message }) {
      const request = {
        url: `/api/subscriptions/add-covering-payment/${recurringPaymentSubscriptionId}`,
        parameters: { amount },
      };
      const mutation = 'UPDATE_SUBSCRIPTION_COVERING_PAYMENTS';
      const options = {
        request,
        mutation,
        message,
      };
      await dispatch('MAKE_POST_REQUEST', options);
    },


    async REMOVE_SUBSCRIPTION_COVERING_PAYMENT({ dispatch }, { subscriptionCoveringPaymentId, message }) {
      const request = {
        url: `/api/subscriptions/remove-covering-payment/${subscriptionCoveringPaymentId}`,
      };
      const mutation = 'UPDATE_SUBSCRIPTION_COVERING_PAYMENTS';
      const options = {
        request,
        mutation,
        message,
      };
      await dispatch('MAKE_GET_REQUEST', options);
    },

    async PROMPT_PARENT_TO_MANAGE_SUBSCRIPTION({ dispatch }, { personId }) {
      const request = {
        url: `/api/v4/subscriptions/prompt`,
        parameters: { personId },
      };
      const mutation = 'UPDATE_SUBSCRIPTION_COVERING_PAYMENTS';
      const options = {
        request,
        mutation,
      };
      await dispatch('MAKE_POST_REQUEST', options);
    },

    async SEND_INVOICE({dispatch}, {orderUuid}) {
      const request = {
        url: `/api/orders/order/${orderUuid}/send-invoice`,
      };

      const options = {
        request,
      };
      await dispatch('MAKE_POST_REQUEST', options);
    }

  },
  mutations: {
    SET_SUBSCRIPTION(state, payload) {
      state.subscription = payload;
      const { subscriptions } = this.state.viewSubscriptions;
      if (subscriptions[payload.parent.personId]) Vue.set(subscriptions, payload.parent.personId, payload);
    },


    UPDATE_SUBSCRIPTION(state, payload) {
      Vue.set(state.subscription, 'stripe', payload.stripe);
      Vue.set(state.subscription.invoices, 'upcoming', payload.invoices.upcoming);
      const { subscriptions } = this.state.viewSubscriptions;
      const subscription = Object.values(subscriptions)
        .find((subscription) => subscription.c4k.stripeSubscriptionId === payload.stripe.id);

      if (subscription) {
        Vue.set(subscription, 'stripe', payload.stripe);
        Vue.set(subscription.invoices, 'upcoming', payload.invoices.upcoming);
      }
    },

    REMOVE_SUBSCRIPTION(state, payload) {
      Vue.delete(state.subscriptions, payload.stripeSubscriptionId);
      // TO DO Make this cleaner
      const { subscriptions } = this.state.viewSubscriptions;
      Vue.delete(subscriptions, payload.personId);
    },

    UPDATE_SUBSCRIPTION_COVERING_PAYMENTS(state, payload) {
      Vue.set(state.subscription.invoices, 'upcoming', payload.upcomingInvoice);
      Vue.set(state.subscription.c4k, 'coveringPayments', payload.coveringPayments);
    },

  },
  getters: {
    stripeSubscriptionStates(state) {
      return state.stripeSubscriptionStates;
    },

    // TO DO fix these and make it global
    _subscription(state) {
      return state.subscription;
    },
    // call it parent - its duplicated in campBooking
    subscriptionParent(state) {
      return state.subscription.parent;
    },
    previousInvoices(state) {
      return state.subscription.invoices.past.filter((invoice) => invoice.amount_due > 0);
    },
    nextInvoice(state) {
      return state.subscription.invoices.upcoming;
    },
    coveringPayments(state) {
      return state.subscription.c4k.coveringPayments;
    },
  },
};
