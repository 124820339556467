const AmendCampBookingPage = () => import(/* webpackPrefetch: true */ './../pages/Booking/Camp/AmendCampBookingPage');
const CampScheduleUpdatePage = () => import(/* webpackPrefetch: true */ './../pages/Booking/Camp/CampScheduleUpdatePage');
const TransferBookingToNewCampPage = () => import(/* webpackPrefetch: true */ './../pages/Booking/Camp/TransferBookingToNewCampPage');
const EditCampBookingConfirmationPage = () => import(/* webpackPrefetch: true */ './../pages/Booking/Camp/EditCampBookingConfirmationPage');

export default [
  {
    component: AmendCampBookingPage,
    path: '/cms/camp/booking/edit/:bookingId',
    name: 'AmendCampBookingPage',
    meta: {
      title: 'Edit Booking',
    },
  },
  {
    component: CampScheduleUpdatePage,
    path: '/cms/camp/schedule/:campId/update',
    name: 'CampScheduleUpdatePage',
    meta: {
      title: 'Edit Camp Schedule',
    },
  },
  {
    component: EditCampBookingConfirmationPage,
    path: '/cms/camp/booking/edit/:bookingId/confirmation',
    name: 'EditCampBookingConfirmationPage',
    meta: {
      title: 'Booking Updated',
    },
  },

  {
    component: TransferBookingToNewCampPage,
    path: '/cms/camp/booking/edit/:bookingId/transfer',
    name: 'TransferBookingToNewCampPage',
  },

];
