
import { formatMoney, formatDate, getCurrencySymbol } from '&/helpers.js';

export default {
  name: 'SubscriptionMixin',
  data() {
    return {
      requesting: false,
      maxParallelRequests: 6,
      pausing: false,
      resuming: false,
    };
  },

  computed: {
    personIdComputed() {
      return this.personId ? this.personId : this.$route.params.personId;
    },
    isPaused() {
      return this.stripe && this.subscriptionIsPaused(this.stripe);
    },
    discount() {
      return this.stripe.discount;
    },

    subscriptionStatus() {
      return !!this.isPaused;
    },


    statusText() {
      if (this.isPaused) {
        return 'Paused';
      }
      return 'Active';
    },

    pausedDate() {
      return this._subscription.stripe.discount.start;
    },

    pausedDateFormatted() {
      return formatDate(this.pausedDate);
    },


    status() {
      if (this.isPaused) {
        return 'resume';
      }
      return 'pause';
    },

    statusFormattedForButton() {
      if (this.isPaused) {
        return 'fad fa-play-circle';
      }
      return 'fad fa-pause-circle';
    },
  },
  methods: {
    formatMoney(value) {
      return formatMoney(value);
    },
    async getSubscriptionsForPerson() {
      const localLoading = true;
      const payload = {
        personId: this.personIdComputed,
        clientId: this.clientId,
        localLoading,
      };
      await this.$store.dispatch('GET_SUBSCRIPTIONS', payload);
    },

    async getPerson() {
      const personId = this.personIdComputed;
      await this.$store.dispatch('GET_PERSON_BY_ID', { personId });
    },
    subscriptionIsPaused(subscription) {
      return subscription && subscription.discount && subscription.discount.coupon && subscription.discount.coupon.percent_off === 100;
    },

    async pauseSubscription(subscriptionId) {
      this.pausing = true;
      const payload = {
        subscriptionId,
        clientId: this.clientId,
        action: 'pause',
        localLoading: true,
        sendEmail: this.sendEmail
      };
      this.$store.dispatch('PAUSE_RESUME_SUBSCRIPTION', payload);
      this.pausing = false;
    },


    async pauseSubscriptions(subscriptionIds) {
      this.pauseModal = false;
      this.requesting = true;
      await this.requestQueue(subscriptionIds, this.pauseSubscription);
      // await this.resetTable();
    },

    async resumeSubscription(subscriptionId) {
      this.resuming = true;
      const payload = {
        subscriptionId,
        clientId: this.clientId,
        action: 'resume',
        localLoading: true,
        sendEmail: this.sendEmail,
      };

      await this.$store.dispatch('PAUSE_RESUME_SUBSCRIPTION', payload);
      this.resuming = false;
    },

    async resumeSubscriptions(subscriptionIds) {
      this.pauseModal = false;
      this.requesting = true;
      await this.requestQueue(subscriptionIds, this.resumeSubscription);
      // await this.resetTable();
    },

    async handlePauseAndResumeSubscription(subscriptionId) {
      if (this.isPaused) {
        await this.resumeSubscription(subscriptionId);
      } else {
        await this.pauseSubscription(subscriptionId);
      }
    },

    async changeSubscriptionPlansAtStripe({ subscriptionId, planId }) {
      return this.$store.dispatch('CHANGE_SUBSCRIPTION_PLAN', { subscriptionId, planId });
    },


    async cancelSubscription(subscriptionId) {
      this.confirm = false;
      const payload = {
        subscriptionId,
      };
      await this.$store.dispatch('CANCEL_SUBSCRIPTION', payload);
    },

    async reactivateSubscription(subscriptionId) {
      this.confirm = false;
      const payload = {
        subscriptionId,
      };
      await this.$store.dispatch('REACTIVATE_SUBSCRIPTION', payload);
    },


    async requestQueue(items, functionToMake) {
      this.requesting = true;
      const requestQ = [];
      for (let i = 0; i < items.length; i++) {
        if (requestQ.length >= this.maxParallelRequests) {
          const nextRequest = requestQ.shift();
          await nextRequest;
        }

        if (this.requesting) {
          await requestQ.push(functionToMake(items[i]));
        }
      }
      this.requesting = false;
    },

  },
};
