<template>
    <v-card class="mb-4">


        <v-list-item
                :href="getTldData.url"
                class="py-2"
        >
            <v-list-item-avatar class="ma-2">
                  {{getTldData.flag}}
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title>
                    <v-card-text class="pa-0 text-truncate"><span class="d-none d-sm-inline">You have access to </span><span class="font-weight-black">{{ domain[1] }} {{ systemString }} on the class4kids{{ domain[0] }}</span> platform </v-card-text>
                </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
                <v-btn icon ripple>
                    <v-icon color="accent" size="16">fas fa-chevron-right</v-icon>
                </v-btn>
            </v-list-item-action>
        </v-list-item>
    </v-card>
</template>

<script>
export default {
  name: 'TopLevelDomainListItem',
  data() {
    return {};
  },
  props: {
    domain: {
      required: false,
      type: Array,
    },
  },
  methods: {
    getCurrentUrlTld() {
      return window.location.href.match(/\.\w{2,5}\b/g).join('');
    },
  },
  computed: {
    getTldData() {
      const tldData = {
        flag: '',
        url: '',
      };
      switch (this.domain[0]) {
        case '.co.uk':
          tldData.flag = '🇬🇧';
          tldData.url = window.location.href.replace(this.getCurrentUrlTld(), '.co.uk');
          break;
        case '.ie':
          tldData.flag = '🇮🇪';
          tldData.url = window.location.href.replace(this.getCurrentUrlTld(), '.ie');
          break;
        case '.club':
          tldData.flag = '🌎';
          tldData.url = window.location.href.replace(this.getCurrentUrlTld(), '.club');
          break;
        default:
          break;
      }
      return tldData;
    },
    systemString() {
      return this.domain[1] === 1 ? 'system' : 'systems';
    },
  },
  mounted() {},
};
</script>

<style scoped>

</style>
