<template>
  <v-btn
    :block="block"
    :color="color"
    :outlined="ghost"
    :disabled="disabled || loading"
    :loading="loading"
    @click="$emit('click', $event)"
    :class="origincolour"
    :to="to"
    :href="href"
    :type="type"
    data-test="button"
    :max-width="maxWidth"
    :max-height="maxHeight"
  >
    <slot v-slot:default> </slot>
    <template v-slot:loader>
      <v-progress-circular
        size="14"
        indeterminate
        width="2"
      ></v-progress-circular
      >&nbsp;
      <Dictionary
        :module="loadingMessage.module"
        :string="loadingMessage.string"
        :replace="loadingMessage.replace"
      />
    </template>
  </v-btn>
</template>

<script>
import { VBtn } from "vuetify/lib";
import Vue, { PropType } from "vue";
import Dictionary from "../../Dictionary.vue";

export default Vue.extend({
  name: "Button",
  extends: VBtn,
  props: {
    maxHeight: {
      required: false,
      type: String,
    },
    maxWidth: {
      required: false,
      type: String,
    },
    color: {
      type: String,
      required: false,
      default() {
        return "accent";
      },
    },
    ghost: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    block: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    disabled: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    loading: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    loadingMessage: {
      type: Object,
      required: false,
      default() {
        return { module: "default", string: "button_loading", replace: {} };
      },
    },
    to: {
      required: false,
      default() {
        return "";
      },
    },
    href: {
      required: false,
      default() {
        return "";
      },
    },
    type: {
      type: String,
      required: false,
      default() {
        return "button";
      },
    },
  },
  computed: {
    origincolour() {
      return this.ghost ? `${this.color}--text` : this.color;
    },
  },
  components: {
    Dictionary,
  },
});
</script>

<style scoped>
>>> .v-btn:not(.v-btn--depressed):not(.v-btn--flat) {
  box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.1),
    inset 0 1px 0 1px rgba(255, 255, 255, 0.09) !important;
  border: solid 1px rgba(0, 0, 0, 0.2) !important;
}

>>> .v-btn__content,
.v-btn {
  /*font-family: 'Montserrat', sans-serif;*/
  font-weight: 600;
  text-transform: none;
  font-size: 16px;
  letter-spacing: 0;
}

.primary.v-btn--disabled {
  /*background-color: var(--v-primary-lighten4) !important;*/
}
</style>
