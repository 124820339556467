<template>


  <v-container>
    <v-row>
      <v-col xs12>
          <LoadingSpinner v-if="loading" size="fa-5x" />
          <div id="terms" v-else>
            <Term v-for="term in schedule" :key="term.termId" :term="term" />
          </div>
      </v-col>
    </v-row>
    <v-bottom-sheet no-click-animation persistent hide-overlay v-model="showBottomSheet">
      <v-sheet>
      <v-row>
        <v-col cols="6" offset-md="3" md="3">
            <div>
            <v-avatar color="secondary" size="36">
              <span class="white--text headline">{{countOfSelectedRuns}}</span>
            </v-avatar>
                <span class="subtitle-1 font-weight-bold">
                    Classes Selected
                </span>
            </div>
        </v-col>
          <v-col cols="6" class="text-right">
              <v-btn class="mr-8" :href.prevent="getContactsRoute" color="primary" text :disabled="!countOfSelectedRuns > 0"><v-icon>fas fa-user-circle</v-icon>&nbsp; Get Contacts</v-btn>
          </v-col>
      </v-row>

      </v-sheet>
    </v-bottom-sheet>


  </v-container>

</template>

<script>

import Term from '../../components/OfflineRegisters/Schedule/Term';
import LoadingSpinner from '../../components/Global/LoadingSpinner.vue';

export default {
  name: 'Schedule',
  data() {
    return {
      loading: true,
    };
  },
  watch: {
    countOfSelectedRuns(value) {

    },
  },

  computed: {
    schedule() {
      return this.$store.getters.schedule;
    },

    selectedRunIds() {
      return this.$store.getters.selectedRuns.map((run) => run.runId);
    },

    countOfSelectedRuns() {
      return this.selectedRunIds.length;
    },

    getContactsRoute() {
      return `/cms/contacts/run/${this.selectedRunIds.toString(',')}`;
      // return Routing.generate('ContactsBundle_runpage', {runId: runIds});
    },
    showBottomSheet() {
      return this.countOfSelectedRuns > 0;
    }
  },
  components: {
    Term,
    LoadingSpinner,
  },

  methods: {
    cancelSelectingRuns() {
      this.$store.dispatch('UPDATE_SELECTED_RUNS', []);
    },
  },

  created() {
    this.$store
      .dispatch('GET_SCHEDULE')
      .then((response) => {
        this.loading = false;
      })
      .catch((error) => {
        console.error('Error: ', error);
      });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

@import '../../assets/scss/variables';
#terms {
  display: grid;
  /*grid-template-columns: repeat(4, 1fr);*/
  grid-template-columns: 1fr;
}

.filters {
  display: grid;
  grid-auto-rows: 48px;
  grid-template-columns: 1fr;
  position: sticky;
  align-items: center;
  top: 0;
  background-color: #fff;

}

.contactsButton {
  text-decoration: none;
  // color: $primary;
  font-weight: 600;
}

</style>
