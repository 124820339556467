<template>

<div>
    <v-menu bottom offset-y>
        <template v-slot:activator="{ on, attrs }">
            <v-btn  class="custom-border"
                    outlined
                    v-bind="attrs"
                    v-on="on"
            >

               <span class="pl-1 primary-text">{{ `${currentRegion.flag} ${currentRegion.label}` }} ▾</span>
            </v-btn>
        </template>

          <v-list>
            <v-list-item  v-for="region in regions" :key="region.id" dense :href="getSwitchedRegionUrl(region)">
              {{ `${region.flag} ${region.label}` }}
            </v-list-item>
        </v-list>
    </v-menu>
</div>

</template>

<script>

export default {
  name: 'RegionSelectorDropdownMenu',
  data() {
    return {
      regions: {
        uk: {
          id: 0,
          label: 'UK',
          flag: '🇬🇧',
          tld: '.co.uk',
        },
        ie: {
          id: 1,
          label: 'Éire',
          flag: '🇮🇪',
          tld: '.ie',
        },
        int: {
          id: 2,
          label: 'International',
          flag: '🌎',
          tld: '.club',
        },
      },
    };
  },
  methods: {
    getSwitchedRegionUrl(region) {
      const defaultAccountPage = 'https://account.class4kids.co.uk';
      return defaultAccountPage.replace(this.currentTopLevelDomain, region.tld);
    },
  },
  computed: {
    currentTopLevelDomain() {
      return window.location.href.match(/\.\w{2,5}\b/g).join('');
    },
    currentRegion() {
      return Object.values(this.regions)
        .find((region) => region.tld === this.currentTopLevelDomain);
    },
  },
  created() {
  },
  mounted() {},
  components: {},
};
</script>

<style scoped>
    .custom-border{
        border: solid 1px var(--v-form-border-base);
    }
</style>
