<template>
  <v-row class="mx-6 mt-6" dense>
    <v-col cols="12">
      <v-row class="mt-0 justify-space-between">
        <v-col cols="12" sm="6" md="3" class="pt-0">
          <v-row justify="center" dense no-gutters>
            <v-col cols="12">
              <v-text-field
                v-model="search"
                label="Search for a Contact"
                filled
                append-icon="fas fa-search"
                hide-details
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="6" md="3" class="pt-0">
          <v-row justify="center" dense no-gutters>
            <v-col cols="12">
              <v-select
                v-model="setupFeeSelect"
                :items="optionsSetupFee"
                label="Setup Fee"
                filled
                dense
              ></v-select>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="6" md="3" class="pt-0">
          <v-row justify="center" dense no-gutters>
            <v-col cols="12">
              <v-select
                v-model="bookingStatusSelect"
                :items="optionsBookingStatus"
                label="Booking status"
                filled
                dense
              ></v-select>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="6" md="3" class="pt-0">
          <v-row justify="center" dense no-gutters>
            <v-col cols="12">
              <v-select
                v-model="photoPermissionSelect"
                :items="photoPermissionOptions"
                label="Photo Permissions"
                filled
                dense
              ></v-select>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-row justify="center" dense no-gutters>
            <v-col cols="12">
              <ContactsSendEmailForm />
            </v-col>

            <v-col cols="12" class="pt-2" v-if="canSendSMSMessages">
              <ContactsSendSmsForm />
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="6" md="3" class="pt-0" align="end">
          <v-row justify="center" dense no-gutters>
            <ContactsDataTableHeaderTabs
              v-if="childQuestions.length > 0 || parentQuestions.length > 0"
            />
            <v-col cols="12">
              <v-row justify="center" no-gutters dense>
                <v-col>
                  <Button
                    block
                    @click="exportCSV"
                    :loading="exportLoading"
                    ghost
                  >
                    <v-icon size="18px">fal fa-download fa-sm</v-icon> &nbsp;
                    {{ exportButtonText }}
                  </Button>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from 'vuex';
import ContactsDataTableHeaderTabs from './ContactsDataTableHeaderTabs.vue';
import CsvMixin from './Mixins/CsvMixin.vue';
import FilteredContactsMixin from './Mixins/FilteredContactsMixin.vue';
import ContactsSendEmailForm from '../../components/Contacts/ContactsSendEmailForm.vue';
import ContactsSendSmsForm from '../../components/Contacts/ContactsSendSmsForm.vue';
export default {
  name: 'ContactsDataTableHeader',
  mixins: [CsvMixin, FilteredContactsMixin],
  components: {
    ContactsDataTableHeaderTabs,
    ContactsSendEmailForm,
    ContactsSendSmsForm,
  },

  props: {},
  data() {
    return {
      exportLoading: false,
      showFilterButtons: false,
      photoPermissionOptions: [
        { text: 'All', value: 'all' },
        { text: 'Yes', value: '1' },
        { text: 'No', value: '0' },
        { text: 'Not Answered', value: null },
      ],
      optionsSetupFee: [
        {
          text: 'All',
          value: null,
        },
        {
          text: 'Paid',
          value: '1',
        },
        {
          text: 'Unpaid',
          value: '0',
        },
      ],
      optionsBookingStatus: [
        {
          text: 'All',
          value: null,
        },
        {
          text: 'In programme (class, camp or trial)',
          value: 'inProgramme',
        },
        {
          text: 'In a class',
          value: 'inClass',
        },
        {
          text: 'In a camp',
          value: 'inCamp',
        },
        {
          text: 'On a trial',
          value: 'onTrial',
        },
        {
          text: 'On a waiting list',
          value: 'onWaitingList',
        },
        {
          text: 'Not in programme',
          value: 'notInProgramme',
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'searchContacts',
      'setupFeeSelectContacts',
      'bookingStatusSelectContacts',
      'contactDetailsCheckBoxContacts',
      'setupFeeDetailsCheckboxContacts',
      'childQuestions',
      'parentQuestions',
      'registrationQuestionsChildSelectContacts',
      'registrationQuestionsParentSelectContacts',
      'photoPermissionSelectValue',
      'settings',
    ]),

    canSendSMSMessages() {
      return this.settings['canSendSMS'];
    },
    exportButtonText() {
      if (this.selectedContacts.length > 0) {
        return `Export ${this.selectedContacts.length} Contacts`;
      }
      return 'Export All Contacts';
    },
    contactDetailsCheckBox: {
      get() {
        return this.contactDetailsCheckBoxContacts;
      },
      set(value) {
        this.$store.commit('SET_CONTACT_DETAILS_CHECKBOX', value);
      },
    },
    setupFeeDetailsCheckbox: {
      get() {
        return this.setupFeeDetailsCheckboxContacts;
      },
      set(value) {
        this.$store.commit('SET_SETUP_FEE_DETAILS_CHECKBOX', value);
      },
    },
    search: {
      get() {
        return this.searchContacts;
      },
      set(value) {
        this.$store.commit('SET_SEARCH_CONTACTS', value);
      },
    },
    setupFeeSelect: {
      get() {
        return this.setupFeeSelectContacts;
      },
      set(value) {
        this.$store.commit('SET_SETUP_FEE_SELECT_CONTACTS', value);
      },
    },
    photoPermissionSelect: {
      get() {
        return this.photoPermissionSelectValue;
      },
      set(value) {
        this.$store.commit('SET_PHOTO_PERMISSION_FILTER_CONTACTS', value);
      },
    },
    bookingStatusSelect: {
      get() {
        return this.bookingStatusSelectContacts;
      },
      set(value) {
        this.$store.commit('SET_BOOKING_STATUS_SELECT_CONTACTS', value);
      },
    },
  },
  methods: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {
    this.$store.commit('SET_BOOKING_STATUS_SELECT_CONTACTS', 'inProgramme');
  },
  updated() {},
};
</script>

<style scoped></style>
