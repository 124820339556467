<template>
	<v-card>
		<v-card-title>
			<h6 :class="fullDayOfTheWeekToLowerCase + '-text'">{{ run.className }}</h6>
			<v-spacer></v-spacer>
				
					<div style="font-size: 0.9rem;">
						<SaveRunOffline :run-id="run.runId.toString()" />
					</div>
				
		</v-card-title>

		<v-card-text class="run" :class="{ selected: selected }">
			<div class="top">
				<div class="dayIcon">
					<v-checkbox
						v-model="selectedRuns"
						:id="run.runId"
						:name="run.runId"
						:value="run"
					></v-checkbox>
				</div>


				<div class="runDetails">
					<div>
						<router-link
							:to="{ name: 'Run', params: { id: run.runId } }"
						>
							<p class="venueName"><v-icon size="16">fas fa-map-marker</v-icon> &nbsp; {{ run.venueName }}</p>

							<p class="bookings">
								<v-icon size="16">fas fa-child</v-icon> &nbsp; {{ run.bookingsCount }} / {{ run.capacity }}
							</p>
						</router-link>
					</div>
				</div>

			<router-link
				:to="{ name: 'Run', params: { id: run.runId } }"
				class="runTimeLink"
				:class="fullDayOfTheWeekToLowerCase + '-text'"
			>
				<div class="runTime" v-html="times">

				</div>
			</router-link>


			</div>


		</v-card-text>
	</v-card>
</template>

<script>

import moment from 'moment';
import SaveRunOffline from './SaveRunOffline';

export default {
  name: 'TermRunItem',
  data() {
    return {};
  },

  props: {
    run: {
      type: Object,
      required: true,
    },
  },

  computed: {
	times() {
	let output = '';
	Object.values(this.run.sessions).forEach((session, index) => {
		output += `
		<h5>${session.day}</h5>
		<h5>${session.startTime}</h5>  
					<div>
						<i class="fal fa-arrow-right"></i>
					</div>
					<h5>
						${session.endTime}
					</h5>`;
	});
	return output;
	},	

    runStartTime() {
      return this.formattedRunDate(this.run.runStartTime);
    },
    runEndTime() {
      return this.formattedRunDate(this.run.runEndTime);
    },
    runDay() {
      return moment
        .utc(this.run.runStartTime * 1000)
        .format('ddd')
        .toUpperCase();
    },
    fullDayOfTheWeekToLowerCase() {
      return moment
        .utc(this.run.runStartTime * 1000)
        .format('dddd')
        .toLowerCase();
    },
    selectedRuns: {
      get() {
        return this.$store.getters.selectedRuns;
      },
      set(value) {
        this.$store.dispatch('UPDATE_SELECTED_RUNS', value);
      },
    },

    selected() {
      return this.$store.getters.selectedRuns.indexOf(this.run) != -1;
    },
  },

  methods: {
    formattedRunDate(timestamp) {
      return moment.utc(timestamp * 1000).format('h:mmA');
    },
  },

  components: {
    SaveRunOffline,
  },
  created() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import './../../../assets/scss/variables.scss';

h6 {
	margin: 8px 0 4px 0;
	font-size: 0.8rem;
	// color: $primary;
}
.run {
	// grid-auto-rows: 128px;
	// border: 2px solid #efefef;
	// box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	background-color: #fff;
	// border: 2px solid #35495E;
	border-radius: 2px;
	align-items: center;
}

.run.selected {
	// border: 2px solid $primary;
}

.top {
	display: grid;
	grid-template-columns: 32px 6fr 4fr;
	
}

.runTimeLink {
	text-decoration: none;
}

.runTime {
	display: grid;
	grid-template-columns: 4fr 1fr 48px 1fr;
	column-gap: 8px;
	align-items: center;
	text-align: center;
	margin-top: 16px;
	

	// border-top: 1px solid #efefef;

	h5 {
		margin: 8px;
	}
}

.runDetails {
	display: grid;
	grid-template-columns: 1fr;
	padding: 16px;
	align-items: center;
}

.runDetails .timeContainer {
	margin-top: 8px;
}

.runDetails a {
	text-decoration: none;
}

.dayIcon {
	text-align: center;
	cursor: pointer;
}

.dayIcon label {
	cursor: pointer;
}

.runDetails p {
	margin: 0;
	font-size: 0.8rem;
}

.className {
	font-weight: 600;
	color: #343a51;
}

.venueName {
	color: #b3b6c2;
}

.bookings {
	color: #35495e;
}

.runControls {
	display: grid;
	grid-template-columns: 1fr 32px;
	align-items: center;
}

.link {
	text-align: center;
}

.link a {
	color: #35495e;
}

/*larger than small*/

@media (min-width: 415px) {
	.run {
		grid-auto-rows: 96px;
	}

	.runDetails {
		grid-template-columns: 1fr;
	}

	.runDetails .timeContainer {
		margin-top: 0;
	}
}
</style>
