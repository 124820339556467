import Vue from 'vue';
import vuetify from '_GLOBAL/plugins/vuetify';
import store from './store/index';
import router from './router/router';
import App from './App.vue';
import CreateApp from '../../global/app';

const bookingServiceId = '#app-v4';
const vm = CreateApp({ App, store, router });
vm.$mount(bookingServiceId);
