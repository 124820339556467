<template>
    <v-col>
        <v-row justify="center">
            <v-col cols="12">
                <v-card-title primary--text>
                    <h2 class="primary--text">
                        <span class="no-wrap">
                          <Dictionary module="codeInput" string="titleLineOne"/>
                        </span>
                        <span class="no-wrap">
                          <Dictionary module="codeInput" string="titleLineTwo"/>
                        </span>
                    </h2>
                </v-card-title>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="12">
                <v-card-text class="text-center">
                    <i class="far fa-envelope-open-text"></i>
                  <Dictionary module="codeInput" string="verification" :replace="{phoneNumber}"/>
                    <br>
                  <Dictionary module="codeInput" string="codeEntry"/>
                </v-card-text>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="12">
                <v-text-field
                        class="code-input"
                        label="Code"
                        v-model="codeComputed"
                        placeholder="Enter Verification Code"
                        outlined
                        :rules="codeRulesComputed"
                        type="tel"
                        maxLength="6"
                        :error-messages="errorMessage"
                        @input="updateErrorStateOnUnFocus()"
                        autofocus
                        @click="updateErrorStateOnUnFocus()"
                >
                </v-text-field>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="12">
                <Button :disabled="disableSendCodeButton"
                        @click="sendAuth()"
                >
                  <Dictionary module="keyWords" string="continue"/>
                </Button>
            </v-col>
        </v-row>
            <v-card flat v-if="resendCodeButton && code.length < 6">
                <v-slide-x-transition>
                    <v-row justify="center">
                        <v-col cols="5">
                            <Button color="alert"
                                    @click="goBack()"
                            >
                              <Dictionary module="keyWords" string="back"/>
                            </Button>
                        </v-col>
                        <v-col cols="2">
                        </v-col>
                        <v-col cols="5">
                            <Button
                                    @click="setup()"
                            >
                              <Dictionary module="keyWords" string="resend"/>
                            </Button>
                        </v-col>
                    </v-row>
                </v-slide-x-transition>
            </v-card>
            <v-row justify="center" v-if="!resendCodeButton && code.length < 6">
                <v-card-text class="text-center">
                    <a @click="noCode()">
                        <i class="fa fa-info-circle" aria-hidden="true"></i>
                      <Dictionary module="codeInput" string="noCode"/>
                    </a>
                </v-card-text>
            </v-row>
    </v-col>
</template>

<script>

import Vue from 'vue';
import Button from '../../../../global/components/UILibrary/Buttons/Button.vue';

import { codeRequiredRule, codeValidRule } from '../../../../global/helpers/rules/codeRules';
import { sendAuth } from './services/twillio-mobile-verification';

import SignUpMixin from './mixins/SignUpMixin.vue';

export default Vue.extend({
  name: 'CodeInput',
  mixins: [SignUpMixin],
  components: {
    Button,
  },
  props: {},
  data() {
    return {
      resendCodeButton: false,
      code: '',
      codeRules: [
        codeRequiredRule,
        codeValidRule,
      ],
      enableVerificationRules: false,
    };
  },
  computed: {
    codeRulesComputed() {
      if (this.enableVerificationRules || codeValidRule(this.code) === true) {
        this.enableVerificationRules = true;
        return this.codeRules;
      }
      return [];
    },
    disableSendCodeButton() {
      return this.codeComputed.length < 6 || this.codeComputed.length > 6;
    },
    codeComputed: {
      get() {
        return this.code;
      },
      set(value) {
        this.code = value;
      },
    },
  },
  methods: {
    updateErrorStateOnUnFocus() {
      this.$store.commit('UPDATE_ERROR_MESSAGE_SIGN_UP', '');
    },
    noCode() {
      this.resendCodeButton = true;
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/');
    },

    async sendAuth() {
      await this.$store.commit('UPDATE_GLOBAL_LOADING', { mutation: 'sendAuth', status: true });

      const response = await sendAuth(this.sid, this.phoneNumber, this.code);

      if (response.code === 200) {
        if (response.data.data.properties && response.data.data.properties.status === 'approved') {
          this.$store.commit('UPDATE_APPROVED_SIGN_UP', true);
          this.createNewClient();
        } else {
          this.$store.commit('UPDATE_ERROR_MESSAGE_SIGN_UP', 'Not validated, try again');
          await this.$router.push({ name: 'CreateClientPage' });
        }
      } else {
        this.$store.commit('UPDATE_ERROR_MESSAGE_SIGN_UP', response.data.message);
        await this.$router.push({ name: 'CreateClientPage' });
      }
      await this.$store.commit('UPDATE_GLOBAL_LOADING', { mutation: 'sendAuth', status: false });
    },
    resend() {
      this.$store.commit('RESET_STATE_SIGN_UP', false);
      this.setup();
    },
  },

  mounted() {
    if (this.booleansSignUp.sent === false) {
      this.$router.push({ name: 'CreateClientPage' });
    }
    this.$store.commit('RESET_STATE_SIGN_UP', false);
  },
});
</script>

<style scoped>
    .v-btn:not(.v-btn--round).v-size--default {
        min-width: 100% !important;
    }

    .v-card__title {
        flex-direction: column !important;
    }

    .no-wrap {
        white-space: nowrap;
        text-align: center;
    }
</style>
