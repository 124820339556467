import { getCredentials } from '../../services/notify/authorization';

const now = Math.round((new Date()).getTime() / 1000);

const getDefaultAuthorizationState = () => ({
  access_token: '',
  expires_in: 0,
  token_type: 'Bearer',
  now,
});

export default {
  state: getDefaultAuthorizationState(),

  actions: {
    async GET_AUTHORIZATION({ commit, getters }) {
      const auth = !getters.authorized ? await getCredentials() : getters.authorization;
      await commit('UPDATE_AUTHORIZATION', auth);
      return auth;
    },
  },
  mutations: {
    // eslint-disable-next-line camelcase
    UPDATE_AUTHORIZATION(state, { access_token, expires_in, token_type }) {
      // eslint-disable-next-line camelcase
      state.access_token = access_token;
      state.expires_in = parseInt(expires_in);
      state.token_type = token_type;
    },
    RESET_AUTHORIZATION_STATE(state) {
      Object.assign(state, getDefaultAuthorizationState());
    },
  },
  getters: {
    authorization(state) {
      return state;
    },
    authorized(state) {
      const expires = state.expires_in + state.now;
      return state.access_token && (expires) >= now;
    },
  },
};
