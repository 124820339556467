<template>
    <v-switch
            inset
            dense
            :label="label"
            :value="value"
            @change="$emit('click', $event)"
            @click="$emit('click', $event)"
    ></v-switch>
</template>

<script>
import { VSwitch } from 'vuetify/lib';

export default {
  name: 'InsetSwitch',
  extends: VSwitch,
  props: {
    value: {
      type: Boolean,
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
  },
};
</script>

<style scoped>

</style>
