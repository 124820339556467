<template>
    <div>  
    Settings

    <button @click="triggerAddToHomeScreen">Trigger</button>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

const settings = {
  name: 'Settings',
  data() {
    return {
      loading: false,
      installPromptEvent: {}

    };
  },
  methods: {

    triggerAddToHomeScreen() {
      this.installPromptEvent.prompt();
    }
  },

  computed: {

  },
  components: {
    
  },
  created: function() {
  window.addEventListener('beforeinstallprompt', (event) => {
    // Prevent Chrome <= 67 from automatically showing the prompt
    event.preventDefault();
    // Stash the event so it can be triggered later.
    this.installPromptEvent = event;
    // Update the install UI to notify the user app can be installed

    console.log(this.installPromptEvent);
    // document.querySelector('#install-button').disabled = false;
  });
  }
};

export default settings;
</script>

<style lang="scss" scoped>

</style>
