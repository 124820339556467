import { post, get } from '../http';
import { AUTHENTICATE, LOGIN_CHECK_URL } from './constants';

export async function loginUser({ username, password, rememberMe = false }) {
  const url = `/${AUTHENTICATE.url}`;
  const body = { username, password, _remember_me: rememberMe };
  const headers = new Headers({
    'Content-Type': 'application/json',
  });

  return post({ url, headers, body }, AUTHENTICATE);
}

export async function loginCheck() {
  const url = `/${LOGIN_CHECK_URL}`;
  const headers = new Headers({
    'Content-Type': 'application/json',
  });

  const request = new Request(url, {
    cache: 'no-store',
    headers,
    method: 'GET',
  });

  const response = await fetch(request);
  return response.json();
}
