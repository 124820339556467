export default {
  en: {
    title: 'Welcome back, {{name}} ',
    subtitle: 'Select from the following accounts to access',
  },
  fr: {
    title: 'Bienvenue, {{name}} ',
    subtitle: 'Sélectionnez parmi les comptes suivants',
  },

};
