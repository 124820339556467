<template>
  <v-col cols="12">
    <v-tabs v-model="registrationQuestionsTab"
            grow
            slider-color="primary"
    >
      <v-tab :key="0">{{ tabsTitleChild }}</v-tab>
      <v-tab :key="1">{{ tabsTitleParent }}</v-tab>
    </v-tabs>
    <v-tabs-items v-model="registrationQuestionsTab">
      <v-tab-item :key="0">
        <v-select
            v-model="registrationQuestionsChildSelect"
            :items="registrationQuestionChildItems"
            label="Child Registration Questions"
            filled
            dense
            clearable
        ></v-select>
      </v-tab-item>
      <v-tab-item :key="1">
        <v-select
            v-model="registrationQuestionsParentSelect"
            :items="registrationQuestionParentItems"
            label="Parent Registration Questions"
            filled
            dense
            clearable
        ></v-select>
      </v-tab-item>
    </v-tabs-items>
  </v-col>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'ContactsDataTableHeaderTabs',
  components: {},
  props: {},
  data() {
    return {
      registrationQuestionsTab: 'child',
    };
  },
  computed: {
    ...mapGetters(['searchContacts',
      'childQuestions',
      'parentQuestions',
      'registrationQuestionsChildSelectContacts',
      'registrationQuestionsParentSelectContacts',
    ]),
    registrationQuestionChildItems() {
      return Object.keys(this.childQuestions).map((item) => ({
        text: this.childQuestions[item].question,
        value: this.childQuestions[item].questionId,
      }));
    },
    registrationQuestionParentItems() {
      return Object.keys(this.parentQuestions).map((item) => ({
        text: this.parentQuestions[item].question,
        value: this.parentQuestions[item].questionId,
      }));
    },
    registrationQuestionsChildSelect: {
      get() {
        return this.registrationQuestionsChildSelectContacts;
      },
      set(value) {
        this.$store.commit('SET_REGISTRATION_QUESTIONS_CHILD_SELECT_CONTACTS', value);
        this.$store.commit('SET_REGISTRATION_QUESTIONS_PARENT_SELECT_CONTACTS', null);
      },
    },
    registrationQuestionsParentSelect: {
      get() {
        return this.registrationQuestionsParentSelectContacts;
      },
      set(value) {
        this.$store.commit('SET_REGISTRATION_QUESTIONS_PARENT_SELECT_CONTACTS', value);
        this.$store.commit('SET_REGISTRATION_QUESTIONS_CHILD_SELECT_CONTACTS', null);
      },
    },
    tabsTitleChild() {
      return this.$vuetify.breakpoint.xlOnly ? 'Child Questions' : 'Child';
    },
    tabsTitleParent() {
      return this.$vuetify.breakpoint.xlOnly ? 'Parent Questions' : 'Parent';
    },
  },
  methods: {},
  beforeCreate() {
  },
  created() {
  },
  beforeMount() {
  },
  mounted() {
  },
  updated() {
  },
});
</script>

<style scoped>
</style>
