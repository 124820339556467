<template>
  <v-app id="c4k">
    <LoadingSpinner v-if="globalLoading" />
    <template v-show="!globalLoading">
      <router-view></router-view>
      <NotificationSnackbar />
    </template>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex';
import LoadingSpinner from './components/Global/LoadingSpinner.vue';
import NotificationSnackbar from '../../parentdashboard/v2/components/Global/Notifications/NotificationSnackbar.vue';
import BottomNavigation from '../../global/components/BottomNavigation.vue';

export default {
  name: 'App',
  data() {
    return {};
  },
  watch: {},
  methods: {},
  computed: {
    ...mapGetters(['darkMode', 'globalLoading', 'bottomNavigationRouteGlobal']),
    showBottomNavigation() {
      return this.bottomNavigationRouteGlobal !== null;
    },
  },
  components: {
    NotificationSnackbar,
    LoadingSpinner,
    BottomNavigation,
  },
  mounted() {},
  updated() {},
  beforeDestroy() {},
};
</script>

<style lang="scss">
@import '../../global/assets/scss/main';

#c4k {
  background-color: #f8f8f8;
}

.v-toolbar__title {
  margin-left: 0 !important;
  font-size: 16px !important;
}

table.v-table thead th {
  font-size: 15px !important;
  font-weight: 700 !important;
}

table td {
  font-size: 14px !important;
}
</style>
