import Vue from 'vue';
// VUEX Store
export default {
  state: {
    term: {
      runs: [],
    },
    selectedOrders: [],
  },
  actions: {
    async GET_OUTSTANDING_INVOICES_FOR_TERM(context, payload) {
      try {
        const url = `/api/v4/orders/${payload.termId}/outstanding-orders`;
        const request = new Request(url, {
          method: 'GET',
          credentials: 'same-origin',
        });

        const response = await fetch(request);
        const json = await response.json();

        const orderDetails = { data: json.data, termId: payload.termId };

        context.commit('UPDATE_TERM_OUTSTANDING_LIST', orderDetails);
      } catch (error) {
        console.error(error);
      }
    },

    async SEND_INVOICES(context, payload) {
      try {
        const url = '/api/orders/send-invoices';
        const request = new Request(url, {
          method: 'POST',
          credentials: 'same-origin',
          body: JSON.stringify({ orderIds: payload.orderIds }),
        });

        const response = await fetch(request);
        const json = await response.json();

        // context.commit('UPDATE_LAST_SENT_DATE', json);
      } catch (error) {
        return error;
      }
    },
  },
  mutations: {
    UPDATE_TERM_OUTSTANDING_LIST(state, payload) {
      // state.term[payload.termId] = payload.data;
      state.term = payload.data;
    },

    UPDATE_SELECTED_ORDERS(state, payload) {
      state.selectedOrders = payload;
    },

    UPDATE_LAST_SENT_DATE(state, payload) {
      Object.keys(state.term.runs).forEach((runId) => {
        state.term.runs[runId].bookings.find;
      });
    },
  },
  getters: {
    term(state) {
      return state.term;
    },
    selectedOrders(state) {
      return state.selectedOrders;
    },

    selectedOrderIds(state) {
      return state.selectedOrders.map((order) => order.orderId);
    },
  },
};
