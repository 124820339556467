<template>

	<div class="container">

    <div id="classDetails">

    </div>

    <div id="cancel" v-if="onStandaloneRoute">
      <a @click="backToSchedule" id="backButton"><i class="fal fa-times fa-2x"></i></a>
    </div>

    <div v-if="attendees.length > 0">
      <div id="runInstanceDetails">

        <div>
          <h5>{{runInstanceDate}}</h5>
        </div>

        <div>
          <h4>{{takingAttendancePosition}}</h4>
        </div>

      </div>

      <div id="childContainer">

        <div id="details">

          <div id="controlsAndAvatar">

            <div id="back" @click="decrementBooking">
              <i v-if="absentDisabled" class="fal fa-chevron-left fa-4x"></i>
            </div>

            <div id="avatar">
              <Avatar :first-name="selectedBooking.firstName" :last-name="selectedBooking.lastName" :image="showImageAvatars" :person-id="selectedBooking.personId" :number="Math.random()"/>
            </div>

    <!--         <div id="forward" @click="incrementBooking">
              <i class="fal fa-chevron-right fa-4x"></i>
            </div> -->

          </div>

          <div id="name">
            <h3>{{selectedBooking.firstName}}</h3>
            <h3>{{selectedBooking.lastName}}</h3>
           <!--  <h4>RunInstanceID: {{selectedBooking.runInstanceId}}</h4> -->
            <!-- <h4>{{date(selectedBooking.attended)}}</h4> -->
          </div>

          <div id="childDetails">

            <div id="childDOB" v-if="selectedBooking.dob != '0000-00-00'">
             <i class="fal fa-calendar-alt"></i><h6>{{selectedBooking.dob}}</h6>
            </div>

            <div v-else>
              <i>&nbsp;</i>
              <h6>&nbsp;</h6>
            </div>

            <div id="parentName" v-if="selectedBooking.parentName != (selectedBooking.firstName + ' ' + selectedBooking.lastName)">
              <i class="fal fa-user-circle"></i><h6>{{selectedBooking.parentName}}</h6>
            </div>
            <div id="contactNumber" v-if="selectedBooking.mobile.length > 0">
              <a :href="selectedBookingMobileFormatted">
                <i class="fal fa-mobile"></i><h6>{{selectedBooking.mobile}}</h6>
              </a>
            </div>

            <div id="trial" v-if="selectedBooking.booking.isTrial == 1">
                <i class="fal fa-gavel"></i><h6>Trial</h6>
            </div>

          </div>

        </div>

        <div id="buttons">
          <a class="button present" :disabled="presentDisabled" @click.prevent="setPresent">
            <i class="fal fa-check-circle fa-2x" style="color: #fff" aria-hidden="true"></i>
          </a>
          <a class="button absent" :disabled="absentDisabled" @click.prevent="setAbsent">
            <i class="fal fa-times-circle fa-2x" style="color: #fff" aria-hidden="true"></i>
          </a>
        </div>
      </div>

       <div id="progress">
          <svg height="50" width="100%">
            <g class="progress-content">
              <rect v-for="(attendee,index) in attendees" :x="getX(index)" y="0" :width="width" height="50"
                :fill="getFill(attendee)" :stroke="getSelected(attendee)" stroke-width="4" />
            </g>
          </svg>
       </div>
    </div>

    <div v-else>
        <h2>No one is attending today</h2>
    </div>

	</div>
</template>

<script>

import date from 'date-fns';
import Avatar from '../../../../../components/global/Avatar';

export default {
  name: 'TakeAttendance',
  data() {
    return {
      attendees: [],
      attendanceRecord: [],
      current: 0,
      loading: true,
      buttons: {
        present: false,
        absent: false,
      },
      requesting: false,
    };
  },
  props: {
    id: {
      type: String,
      required: false,
      default() {
        return this.$route.params.id;
      },
    },
    runInstanceId: {
      type: String,
      required: false,
      default() {
        return this.$route.params.runInstanceId;
      },
    },
  },

  methods: {
	  getAllBookingsForDay() {
      const results = [];
      const selectedInstanceId = this.runInstanceId;

      this.bookings.forEach((booking) => {
        booking.bookings.find((bookingInstance) => {
          if (selectedInstanceId == bookingInstance.runInstanceId && bookingInstance.bookingInstanceId) {
            const result = {
              firstName: booking.firstName,
              lastName: booking.lastName,
              parentName: `${booking.parentFirstName} ${booking.parentLastName}`,
              dob: booking.dob,
              personId: booking.childId,
              mobile: booking.mobile,
              booking: bookingInstance,
            };

            results.push(result);
          }
        });
      });

      this.attendees = results;
    },
    incrementBooking() {
      this.finishTakingAttendance();

      if (this.attendees.length == this.current + 1) {
        return this.current = 0;
    	}

    	return this.current++;
    },
    decrementBooking() {
    	if (this.current != 0) {
    		return this.current--;
    	}
      this.current = this.attendees.length - 1;
      return this.current;
    },

    finishTakingAttendance() {
      if (this.attendanceRecord.length == this.attendees.length) {

        if(this.onStandaloneRoute) {
            this.$router.go(-1);
        }
      }
    },

    backToSchedule() {
      this.$router.push({ name: 'Run', params: { runId: this.id } });
    },
    date(timeStamp) {
      if (timeStamp == null) {
        return 'No Attendance Taken';
      }
      return timeStamp == 0 ? 'Absent' : `Present: ${date.format(timeStamp * 1000, 'H:mm - D/M/YY')}`;
    },

    setPresent() {
      this.requesting = true;

      this.$store.dispatch('UPDATE_ATTENDANCE', { attendanceData: this.selectedBooking.booking, runId: this.id, status: 1 })
        .then(() => {
          this.requesting = false;
        });

      // update where we count who all has had their attendance taken
      this.updateAttendanceRecord(this.selectedBooking.booking.childId, true);

      // play tone
      this.playPresentTone();
      // increment index
      this.incrementBooking();
    },
    setAbsent() {
      this.requesting = true;
      // disabling for now
      this.$store.dispatch('UPDATE_ATTENDANCE', { attendanceData: this.selectedBooking.booking, runId: this.id, status: 0 })
        .then(() => {
          this.requesting = false;
        });

      this.updateAttendanceRecord(this.selectedBooking.booking.childId, false);

      // play tone
      this.playAbsentTone();

      // increment index
      this.incrementBooking();
    },

    sayName(name) {
      if (this.speech) {
        const msg = new SpeechSynthesisUtterance();
        const voices = window.speechSynthesis.getVoices();
        msg.voice = voices[10]; // Note: some voices don't support altering params
        msg.voiceURI = 'native';
        msg.volume = 1; // 0 to 1
        msg.rate = 1; // 0.1 to 10
        msg.pitch = 0.5; // 0 to 2
        msg.text = name;
        msg.lang = 'en-US';

        msg.onend = function (e) {
          console.log(`Finished in ${event.elapsedTime} seconds.`);
        };

        speechSynthesis.speak(msg);
      } else {
        return false;
      }
    },

    playPresentTone() {
      if (this.speech) {
        const audio = new Audio('/pwa-static/sounds/chime_done.wav');
        audio.play();
      }

      if ('vibrate' in window.navigator) {
        window.navigator.vibrate([200]);
      }
    },

    playAbsentTone() {
      if (this.speech) {
        const audio = new Audio('/pwa-static/sounds/beep_short_off.wav');
        audio.play();
      }
      if ('vibrate' in window.navigator) {
        window.navigator.vibrate([100, 100]);
      }
    },

    fullName(booking) {
      return `${booking.firstName} ${booking.lastName}`;
    },

    getX(index) {
      if (index == 0) {
        return 0;
      }

      return `${(index / this.attendees.length) * 100}%`;
    },

    getFill(attendee) {
      const childId = attendee.personId;
      const child = this.attendanceRecord.find((child) => child.childId == childId);

      // debugger;

      if (child) {
        return child.attended ? '#24A9E6' : '#FF3B30';
      }
      return '#fefefe';
    },

    getSelected(attendee) {
      const childId = attendee.personId;

      if (childId == this.attendees[this.current].personId) {
        return '#ddd';
      }
      return false;
    },

    updateAttendanceRecord(childId, status) {
      // find if there's already a record
      const child = this.attendanceRecord.find((child) => child.childId == childId);

      if (child) {
        // update if so
        child.attended = status;
      } else {
        // otherwise create the record
        const record = {
          childId,
          attended: status,
        };
        this.attendanceRecord.push(record);
      }
    },

  },

  computed: {
	  bookings() {
      return this.$store.getters.runBookings;
    },
    selectedBooking() {
      // dirty
      if (this.attendees.length > 0 && this.current < this.attendees.length) {
        return this.attendees[this.current];
      }
      return {
        firstName: '',
        lastName: '',
        parentName: '',
        dob: '',
        mobile: '',
        booking: '',
      };
    },

    selectedBookingMobileFormatted() {
      return `tel:${this.selectedBooking.mobile}`;
    },

    runInstance() {
      return this.$store.getters.run.runInstances.find((runInstance) => this.runInstanceId == runInstance.runInstanceId);
    },

    onStandaloneRoute() {
	    return this.$route.params.runInstanceId && this.$route.params.id;
    },

    presentDisabled() {
      return this.selectedBooking.attended > 0 ? this.buttons.present = true : this.buttons.present = false;
    },
    absentDisabled() {
      return this.current > 0;
    },

    runInstanceDate() {
      if (this.runInstance) {
        return `${'Attendance: ' + ' '}${date.format(this.runInstance.startDateTime * 1000, 'dddd Do MMM')}`;
      }
    },
    takingAttendancePosition() {
      return `${this.current + 1}/${this.attendees.length}`;
    },

    speech() {
      return this.$store.getters.speech;
    },

    showImageAvatars() {
      // return this.id == '27';
      return false;
    },

    width() {
      return `${100 / this.attendees.length}%`;
    },

  },
  components: {
    Avatar,
  },
  mounted() {
    if (this.bookings.length == 0) {
      const runId = this.id;
      this.$store.dispatch('GET_RUN', runId)
        .then((response) => {
          this.getAllBookingsForDay();
          this.loading = false;
          // this.sayName(this.fullName(this.selectedBooking));
        })
        .catch((error) => {
          console.error('Error: ', error);
        });
    } else {
      this.getAllBookingsForDay();

      // this.sayName(this.fullName(this.selectedBooking));
    }
  },
};
</script>

<style scoped lang="scss">
@import '../../assets/scss/variables';

/*.container {*/
/*  height: 100vh;*/
/*}*/

#runInstanceDetails {
  display: grid;
  grid-template-columns: 5fr 2fr;
  /*background-color: #253B45;*/
  // color: $primary;
  align-items: center;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

#runInstanceDetails h5 {
  margin-left: 16px;
}

#runInstanceDetails h4 {
  text-align: center;
  font-size: 1.2rem;
  margin: 16px 0;
}

#controlsAndAvatar {
  display: grid;
  grid-template-columns: 1fr 6fr 1fr;
  align-items: center;
}

#back, #forward {
  color: #e6e6e6;
  text-align: center;
}

#cancel {
  text-align: right;
  color: #b3b6c2;
  margin-right: 24px;
  cursor: pointer;
}

#name {
  display: grid;
  grid-template-columns: 1fr;
  text-align: center;
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  margin-top: 16px;
}

#name h3 {
  margin: 0;
}

#childContainer {

}

#details {
  display: grid;
  grid-template-columns: 1fr;
}

#childDetails {
  display: grid;
  grid-column-gap: 8px;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  text-align: center;
  align-items: center;
  padding: 16 0px;
}

#childDetails a {
  text-decoration: none;
  color: inherit;
}

#childDetails div h6 {
  margin: 8px 0;
}

#buttons {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 16px;
  padding: 0 32px;
}

.button {
  padding: 16px;
  border-radius: 4px;
  text-align: center;
  color: #fff;
  cursor: pointer;
  transition: all .2s ease-in-out;
}

.present:hover, .absent:hover {
  transition: all .2s ease-in-out;
  transform: scale(1.05) perspective(1px)
}

/*.present:active, .absent:active {
  transition: all .2s ease-in-out;
  transform: scale(1.15) perspective(1px)
}

.present:active:after, .absent:active:after {
  transition: all .2s ease-in-out;
}
*/

.present {
  background-color: #24A9E6;
}
.absent {
  background-color: #FF3B30;
}

.avatar {
  width: 50px;
  height: 50px;
}

  #progress {
    border: 1px solid #efefef;
    border-radius: 4px;
    margin-top: 16px;
    width: 100%;
    height: 50px;
  }

   rect {
    transition: all 1s ease-in-out;
   }

@media (min-width: 648px) {
  /*.container {*/
  /*  width: 55%;*/
  /*  margin: 0 auto;*/
  /*  height: 100vh;*/
  /*}*/

  #buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 64px;
    text-align: center;
  }

  .button {
/*    display: grid;
    align-items: center;
    border-radius: 50%;
    width: 50px;
    height: 50px;*/
  }

  .absent {
     grid-column: 1;
     grid-row: 1;
  }

  .present {
    grid-column: 2;
    grid-row: 1;
  }

}

</style>
