import minCharacterRule from './input';

export const minNumbersInMobileNumber = 11;

const mobileNumberRequiredMessage = 'Mobile number is required';

export function mobileNumberRequiredRule(value) {
  return !!value || mobileNumberRequiredMessage;
}

export function mobileNumberValidRule(value) {
  return minCharacterRule(minNumbersInMobileNumber, value) || `Mobile Number must contain ${minNumbersInMobileNumber} numbers`;
}
