<template>
  <div class="panel">
    <div class="termDetails" v-if="showTermDetails">
      <h2>{{ term.termName }}</h2>
      <h3>{{ termStartDate }} - {{ termEndDate }}</h3>
    </div>
    <div class="terms">
      <TermRunItem v-for="run in runs" :key="run.runId" :run="run"/>
    </div>
  </div>
</template>

<script>

import { format } from 'date-fns';
import TermRunItem from './TermRunItem';

export default {
  name: 'Term',
  data() {
    return {
      daysOfTheWeek: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    };
  },
  props: {
    term: {
      type: Object,
      required: true,
    },
  },

  computed: {
    runs() {
      const runs = Object.values(this.term.runs);
      if (this.$store.getters.isOnline) {
        return runs
            .sort(this.timeSort)
            .sort(this.daySort);
      }
      return runs.filter(this.cachedRunFilter)
          .sort(this.timeSort)
          .sort(this.daySort);
    },
    termStartDate() {
      return this.formattedTermDate(this.term.termStartDateTime);
    },
    termEndDate() {
      return this.formattedTermDate(this.term.termEndDateTime);
    },
    showTermDetails() {
      return Object.values(this.runs).length > 0;
    },
  },
  methods: {
    formattedTermDate(timestamp) {
      return format(timestamp * 1000, 'Do MMM');
    },
    cachedRunsFilter(run) {
      const { cachedRuns } = this.$store.getters;
      return cachedRuns.includes(run.runId);
    },
		timeSort(a, b) {
		//sort by earliest class start - ensures we pick 1st day if there are multiple
		const aDow = Object.values(a.classTimes)[0].dow;
		const aHours = Object.values(a.classTimes)[0].starttime.hours;
		const aMins = Object.values(a.classTimes)[0].starttime.mins;

		const bDow = Object.values(a.classTimes)[0].dow;
		const bHours = Object.values(b.classTimes)[0].starttime.hours;
		const bMins = Object.values(b.classTimes)[0].starttime.mins;

		return (aDow + aHours + aMins) - (bDow + bHours + bMins);
		},
	
		daySort(a, b) {
 			return (Object.values(a.classTimes)[0].dow > Object.values(b.classTimes)[0].dow) - (Object.values(a.classTimes)[0].dow < Object.values(b.classTimes)[0].dow)
		},
    getDay(timestamp) {
      return format(timestamp * 1000, 'dddd');
    },
  },
  components: {
    TermRunItem,
  },
  created() {

  },

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.panel {
  margin-top: 32px;
}

.termDetails {
  display: grid;
  grid-template-columns: 1fr;
}

.terms {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 8px;
}

@media (min-width: 968px) {

  .terms {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1680px) {

  .terms {
    grid-template-columns: repeat(3, 1fr);
  }
}

h2 {
  margin: 8px 0 0 0;
}

h3 {
  margin: 0;
}

h2 {
  font-size: 1.1rem;
  font-family: 'Helvetica';
  font-weight: 800;
}

h3 {
  font-size: 0.9rem;
  font-weight: 100;
}

</style>
