<template>
  <v-row>
    <v-col>
      {{ firstBookingDateFormatted }}
    </v-col>
  </v-row>
</template>

<script>
import { formatLocaleDateFromTimestamp } from './../../../../../../utilities/helpers';

export default {
  name: 'ContactsTableColumnFirstBooking',
  components: {},
  props: {
    firstBookingDate: {
      required: false,
      type: String,
    },
  },
  data() {
    return {};
  },
  computed: {
    firstBookingDateFormatted() {
      return formatLocaleDateFromTimestamp(this.firstBookingDate);
    },
  },
  methods: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  updated() {},
};
</script>

<style scoped></style>
