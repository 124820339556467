<template>
    <span>{{parentFullName}}</span>
</template>

<script>


export default {
  name: 'ParentName',
  data() {
    return {
    };
  },
  props: {
    subscription: {
      required: true,
      type: Object,
    },
  },
  computed: {
    parent() {
      return this.subscription.parent;
    },
    parentFullName() {
      return `${this.parent.firstName} ${this.parent.lastName}`;
    },
  },
  methods: {
  },
  components: {
  },
};
</script>
