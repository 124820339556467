<template>
  <span>
    <v-btn
      color="primary"
      @click="dialog = !dialog"
      outlined
      class="font-weight-bold"
      small
    >
      <v-icon small>fas fa-exchange fa-2x</v-icon> Open
    </v-btn>

    <v-layout row>
      <v-navigation-drawer
        v-model="dialog"
        app
        clipped
        right
        :temporary="!updating"
        width="420"
      >
        <v-sheet flat>
          <v-list dense three-line class="pt-4 mt-2">
            <v-list-item>
              <v-list-item-avatar color="success">
                <v-icon> fad fa-mail-circle </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <h5 class="title font-weight-bold">Send Message</h5>
                </v-list-item-title>
                <v-list-item-subtitle color="UIAction">
                  Update the plans the selected parents are currently subscribed
                  to.
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-layout row fluid fill-height class="mt-2">
            <v-flex xs12>
              {{ selectedContacts }}
            </v-flex>
          </v-layout>
        </v-sheet>
      </v-navigation-drawer>
    </v-layout>
  </span>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'ContactsPageActionsDrawer',
  data() {
    return {
      dialog: false,
      updating: false,
    };
  },
  computed: {
    ...mapGetters(['selectedContacts']),
    // dialog() {
    //   return this.selectedContacts.length > 0;
    // },
  },
};
</script>
