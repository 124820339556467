import { loginUser, loginCheck } from '../../services/authentication/login';

const getDefaultAuthenticationState = () => ({
  user: {
    userId: '',
    firstName: '',
    lastName: '',
    username: '',
    roles: [],
    topLevelDomains: {},
  },
  loginErrorMessage: '',
  loginErrorMessageState: false,
});

export default {
  state: getDefaultAuthenticationState(),

  actions: {
    async LOGIN_USER({ commit }, { username, password, rememberMe }) {
      commit('UPDATE_LOGIN_ERROR_MESSAGE_STATE', false);
      const response = await loginUser({ username, password, rememberMe });
      if (response.code === 201) {
        commit('UPDATE_LOGIN_DATA', response.data);
      } else if (response.status === 404) {
        commit('UPDATE_LOGIN_ERROR_MESSAGE', 'Something went wrong');
        commit('UPDATE_LOGIN_ERROR_MESSAGE_STATE', true);
      } else {
        const payload = {
          message: response.data.message,
        };
        commit('UPDATE_LOGIN_ERROR_MESSAGE', payload.message);
        commit('UPDATE_LOGIN_ERROR_MESSAGE_STATE', true);
      }
      return response;
    },
    async UPDATE_USER({ commit }) {
      const response = await loginCheck();
      if (response.code === 200) {
        commit('UPDATE_LOGIN_DATA', response.data);
      }
      return response;
    },
  },
  mutations: {
    UPDATE_LOGIN_DATA(state, payload) {
      state.user = payload;
    },
    UPDATE_ROLES(state, payload) {
      state.user.roles = payload.user.roles;
      state.user.userId = payload.user.userId;
    },
    UPDATE_LOGIN_ERROR_MESSAGE(state, payload) {
      state.loginErrorMessage = payload;
    },
    UPDATE_LOGIN_ERROR_MESSAGE_STATE(state, payload) {
      state.loginErrorMessageState = payload;
    },
    RESET_AUTHENTICATION_STATE(state) {
      Object.assign(state, getDefaultAuthenticationState());
    },

  },
  getters: {
    loginData(state) {
      return state.user;
    },
    userRoles(state) {
      return state.user.roles;
    },
    loginErrorMessage(state) {
      return state.loginErrorMessage;
    },
    loginErrorMessageState(state) {
      return state.loginErrorMessageState;
    },
  },
};
