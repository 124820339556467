<template>

    <v-form v-model="valid">
        <!-- using a fixed height sheet lets the inner content height change without re-centering the whole container vertically -->
        <v-sheet
                height="310px"
                color="transparent"
        >

            <v-slide-x-transition :hide-on-leave="true">

                <div v-if="usernameSubmitted === false" key="username">

                    <v-row>
                        <v-col>

                            <UsernameInput
                                    v-model="username"
                                    :rules="emailRules"
                                    required
                                    :autofocus="usernameSubmitted === false"
                                    :errorMessageState="errorMessage.state"
                                    tabindex="1"/>
                            <input type="password" autocomplete="current-password" style="display:none" />

                        </v-col>
                    </v-row>

                    <v-row dense>
                        <v-col>
                            <Button
                                    :disabled="!valid"
                                    block
                                    type="submit"
                                    @click.prevent="submitUsername"
                                    :loading="loading">
                                <Dictionary module="default" string="button_next_stage"/>
                            </Button>
                        </v-col>
                    </v-row>

                </div>

                <div v-else key="authentication">

                    <v-row>

                        <v-col class="text-center">
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn block outlined v-on="on" @click="changeUsername" class="text-truncate text-lowercase username-label">
                <span class="text-truncate text-lowercase" style="max-width: 90%;"><i
                        class="fal fa-edit"></i> {{ username }}</span>
                                    </v-btn>
                                </template>
                                <span>
              <Dictionary module="login" string="not_your_username_prompt"/>
            </span>
                            </v-tooltip>

                        </v-col>
                    </v-row>

                    <AuthenticationMethod :username="username" :valid="valid"/>

                </div>

            </v-slide-x-transition>

        </v-sheet>
    </v-form>

</template>

<script>
import { mapGetters } from 'vuex';

import { emailValueRequiredRule, emailValidRule } from '../../helpers/rules/email';
import UsernameInput from '../UILibrary/Inputs/UsernameInput';
import AuthenticationMethod from './AuthenticationMethod.vue';

export default {
  name: 'LoginForm',
  data() {
    return {
      username: '',
      usernameSubmitted: false,
      rememberMe: false,
      loading: false,
      valid: true,
      emailRules: [
        emailValueRequiredRule,
        emailValidRule,
      ],
    };
  },
  computed: {
    ...mapGetters(['loginErrorMessage', 'loginErrorMessageState']),
    errorMessage() {
      return {
        value: this.loginErrorMessageState ? this.loginErrorMessage : '',
        state: this.loginErrorMessageState,
      };
    },

    enterTransition() {
      return this.usernameSubmitted ? 'animated fadeInRight' : 'animated fadeInLeft';
    },
    exitTransition() {
      return this.usernameSubmitted ? 'animated fadeOutLeft' : 'animated fadeOutRight';
    },

  },
  methods: {
    changeLocale() {
      this.$vuetify.lang.current = 'fr';
    },
    handleSuccessfulLoginRequest(response) {
      if (response.code === 201) {
        if (response.redirect) {
          window.location.href = response.redirect.url;
        } else {
          this.$router.push({ name: 'ResourcesPage' });
        }
      } else {
        this.loading = false;
      }
    },
    submitUsername() {
      this.usernameSubmitted = true;
    },
    changeUsername() {
      this.usernameSubmitted = false;
    },
  },
  watch: {
    username() {
      if (this.loginErrorMessageState) {
        this.$store.commit('UPDATE_LOGIN_ERROR_MESSAGE_STATE', false);
      }
    },
    password() {
      if (this.loginErrorMessageState) {
        this.$store.commit('UPDATE_LOGIN_ERROR_MESSAGE_STATE', false);
      }
    },
  },

  created() {
  },

  mounted() {
  },

  updated() {
  },

  components: {
    AuthenticationMethod,
    UsernameInput,
  },
};
</script>

<style scoped="true">

    .username-label {
        color: var(--v-primary-base);
        border-color: var(--v-form-border-base);
    }
</style>
