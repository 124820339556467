import { maxCharacterRule } from './input';

const maxNumbersInClubName = 30;

const clubNameRequiredMessage = 'Club name is required';

export function clubNameRequiredRule(value) {
  return !!value || clubNameRequiredMessage;
}

export function clubNameValidRule(value) {
  return maxCharacterRule(maxNumbersInClubName, value) || `Club Name shouldn't be longer than ${maxNumbersInClubName} characters`;
}
