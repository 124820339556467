<template>
    <v-row>
        <v-col cols="12">
            <v-row justify="center" align="center">
                <v-col cols="12">
                    <v-img
                            class="logo-image text-center"
                            src="/assets-dev/images/app/logo_organisers.png"
                            contain
                            position="center"
                            max-height="70px"
                            min-height="70px"
                    />
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="12">
                    <h4 class="text-center">Why do we need to accept these new terms?</h4>
                </v-col>
                <v-col cols="12">
                    <p>In light of new GDPR regulations, we need to be clear about how and why we collect data. The
                        following
                        agreement will ensure your ability to:</p>
                </v-col>
            </v-row>

            <v-row class="row">
                <v-col cols="2">
                    <CfkGreenTickSuccess :size="36"/>
                </v-col>
                <v-col cols="10">
                    <h4 class="mbs">Access the platform</h4>
                    <p>To allow your business to connect with parents through the platform</p>
                </v-col>
            </v-row>

            <v-divider class="mb-5"/>

            <v-row>
                <v-col cols="2">
                    <CfkGreenTickSuccess :size="36"/>
                </v-col>
                <v-col cols="10">
                    <h4 class="mbs">Control the data</h4>
                    <p>To allow you to use parent and child data to run your classes and take payments</p>
                </v-col>
            </v-row>

            <v-divider class="mb-5"/>

            <v-row class="row">
                <v-col cols="2">
                    <CfkGreenTickSuccess :size="36"/>
                </v-col>
                <v-col cols="10">
                    <h4 class="mbs">Add data to the system</h4>
                    <p>To allow you to add notes and registration questions</p>
                </v-col>
            </v-row>

            <v-divider class="mb-5"/>

            <v-row>
                <v-col>

                    <h4>ClassForKids – Organiser Terms of Use </h4>
                    <p>
                        ClassForKids is an online booking platform (www.classforkids.co.uk) aimed at making Organisers’
                        lives easier, built by coaches and parents for coaches and parents (Platform).
                    </p>

                    <p>
                        “You” or “you” or “Organiser” is a coach or club who registers with the Platform for the purpose
                        of
                        running the administrative aspects of their Classes (hereinafter defined) through the Platform.
                    </p>

                    <p>
                        “Parent” is an individual who visits the Platform and/or registers on the Platform.
                    </p>

                    <p>
                        We ask that you read through these Terms of Use which apply once you have created an account
                        with us
                        and govern:
                    </p>

                    <ul>
                        <li>Your rights and responsibilities</li>
                        <li>Our responsibilities to you</li>
                        <li>The relationship between Parents and Organisers</li>
                    </ul>

                    <p>
                        These terms apply to the use of the Platform and by accessing and using the Platform you agree
                        to be
                        bound by the terms set out below. If you do not agree to be bound by these terms, you may not
                        use or
                        access the Platform.
                    </p>

                    <ol>
                        <li>
                            <strong>Who are we?</strong>
                            <p>
                                We are Class4Kids Ltd, a company incorporated in Scotland (Company Number SC458210) with
                                a
                                Registered Office at 6th Floor, Gordon Chambers, 90 Mitchell Street, Glasgow G1 3NQ
                                (ClassForKids). We can be contacted at support@classforkids.co.uk and 0800 011 9443 and
                                any
                                mail can be addressed to 6th Floor, Gordon Chambers, 90 Mitchell Street, Glasgow G1 3NQ.
                            </p>
                            <p>
                                We will also operate a live chat channel for Organisers to contact us direct.
                            </p>
                        </li>

                        <li>
                            <strong>Our Services</strong>
                            <p>
                                We contract with you to: (i) list your information and schedules for your events,
                                classes,
                                camps and groups for children (Classes); (ii) enable Parents to book Classes via the
                                Platform and (iii) enable Parents to pay for the Classes (together the Services).
                            </p>

                            <p>
                                We aim to provide constant and uninterrupted access to the Services, but we do not take
                                responsibility for issues outside our control, such as internet or connection issues.
                                Specifically we do not guarantee that:
                            </p>

                            <ul>
                                <li>the availability of the Platform on each and every device or platform all of the
                                    time;
                                </li>
                                <li>any technical issues that arise can be resolved quickly; and</li>
                                <li>the Platform is error, virus or bug free.</li>
                            </ul>
                            <br>
                        </li>

                        <li>
                            <strong>Accessing the Services</strong>
                            <p>
                                If you would like to have your Classes listed on the Platform you will have to register
                                for
                                an account on the Platform <strong>(Organiser Account).</strong>
                            </p>

                            <p>
                                You warrant that the personal information which you are required to provide when you
                                create
                                your Parent Account is true, accurate, current and complete in all respects.
                            </p>

                            <p>In order for you to create an Organiser Account, you must provide your</p>
                            <ul>
                                <li>email address and name</li>
                                <li>Club name</li>
                                <li>System name</li>
                            </ul>

                            <p>
                                You warrant that the information which you are required to provide in the registration
                                process is true, accurate, current and complete in all respects.
                            </p>
                        </li>

                        <li>
                            <strong>Your Organiser Account</strong>

                            <p>
                                On your Organiser Account, you will be able to:

                            <ul>
                                <li>
                                    access the personal information of any Parent who registers their child for your
                                    Class(es)
                                </li>
                                <li>
                                    access the Child Profile of the child who has been registered for your Class(es),
                                    including emergency contact details, and medical information
                                </li>
                                <li>
                                    insert any specific terms and conditions which Parents must accept before
                                    registering
                                    for your Class
                                </li>
                                <li>
                                    register additional personnel to help run the Organiser Account
                                    <strong>(Administrators)</strong>
                                </li>
                                <li>
                                    send information and updates about Classes they have booked to Parents by email
                                    and/or
                                    text e.g. invoices, booking confirmations, payment receipts, cancellations,
                                    equipment
                                    requests, other specific reminders etc. <strong>(Organiser Messages)</strong>
                                </li>
                            </ul>
                            </p>

                            <p>

                                In addition, you will be able to:
                            <ul>
                                <li>
                                    record notes concerning any child <strong>(Notes)</strong>
                                </li>
                                <li>
                                    add the personal information of a Parent and their child who registers for your
                                    Classes
                                    outwith the Platform <strong>(New Parent Details);</strong>
                                </li>
                                <li>
                                    request additional information re: levels of ability, any specific access
                                    requirements
                                    etc <strong>(Additional Information);</strong>
                                </li>
                                <li>
                                    download or export the personal data of any Parent and child registered for your
                                    Classes
                                    for your own legitimate business purposes i.e. class registers, data analytics etc.
                                    <strong>(Class Data).</strong>
                                </li>
                            </ul>
                            </p>

                            <p>

                                Please note that the (i) Notes (ii) New Parent Details; (iii) the Additional Information
                                and
                                (ivii) Class Data will be regarded as being Shared Personal Data (as defined below) that
                                is
                                controlled jointly by the Organiser and ClassForKids. Both parties shall be controllers
                                under the Data Protection Legislation (as defined below) and the shared personal
                                information
                                shall be subject to the undernoted personal data sharing provisions.

                            </p>

                            <p>
                                Your Organiser Account is personal to you and cannot be assigned or transferred to
                                anyone.
                            </p>

                        </li>

                        <li>

                            <strong>
                                Personal Data
                            </strong>

                            <p>
                                This clause 5 sets out the framework for the sharing of personal data between
                                ClassForKids
                                and the Organiser as data controllers. We both acknowledge that one party (the Data
                                Discloser) will regularly disclose to the other party. Shared Personal Data collected by
                                the
                                Data Discloser for the Agreed Purposes.
                            </p>

                            <p>
                                The following definitions shall apply:
                            </p>

                            <p>
                                <strong>Agreed Purposes:</strong> the personal data is to be shared in order to help the
                                Organiser deliver Classes effectively and efficiently through the Platform, to the
                                benefit
                                of the Parents and the Organiser.
                            </p>

                            <p>
                                <strong>
                                    Controller, data controller, processor, data processor, data subject, personal data,
                                    processing and appropriate technical and organisational measures:
                                </strong> as set out in the Data Protection Legislation in force at the time.
                            </p>

                            <p>
                                <strong>Data Protection Legislation:</strong> (i) the Data Protection Act 1998, until
                                the
                                effective date of its repeal (ii) the General Data Protection Regulation ((EU) 2016/679)
                                (GDPR) and any national implementing laws, regulations and secondary legislation, for so
                                long as the GDPR is effective in the UK, and (iii) any successor legislation to the Data
                                Protection Act 1998 and the GDPR, in particular the Data Protection Bill 2017-2019, once
                                it
                                becomes law.
                            </p>

                            <p>
                                <strong>Permitted Recipients: </strong> The parties to these Terms of Use, the employees
                                of
                                each party, any third parties engaged to perform obligations in connection with these
                                Terms
                                of Use
                            </p>

                            <p>
                                <strong>Shared Personal Data:</strong> the personal data to be shared between the
                                parties to
                                these Terms of Use. Shared Personal Data shall be confined to the following categories
                                of
                                information

                            <ul>

                                <li>
                                    Emails
                                </li>

                                <li>
                                    Names
                                </li>

                                <li>
                                    Addresses
                                </li>

                                <li>
                                    Telephone Numbers
                                </li>

                                <li>
                                    Emergency Contact – name and number
                                </li>

                                <li>
                                    Health or medical information *
                                </li>
                            </ul>

                            </p>

                            <p>
                                *We would remind you that personal data concerning health is a special category of
                                personal
                                data under GDPR. Processing special categories of personal data is generally prohibited.
                                However, we have obtained the Parents’ explicit consent to processing any special
                                category
                                personal data provided by them to us for the purposes of delivering the Services.
                            </p>

                            <p>
                                Together we shall comply with all the obligations imposed on a controller under the Data
                                Protection Legislation, and any material breach of the Data Protection Legislation by
                                one
                                party shall, if not remedied within 30 days of written notice from the other party, give
                                grounds to the other party to terminate these Terms of Use with immediate effect.
                            </p>

                            <p>
                                ClassForKids and the Organiser shall:

                            <ul>
                                <li>
                                    ensure that it has all necessary notices and consents in place to enable lawful
                                    transfer
                                    of the Shared Personal Data to the Permitted Recipients for the Agreed Purposes;
                                </li>
                                <li>
                                    give full information to any data subject whose personal data may be processed under
                                    these Terms of Use of the nature such processing. This includes giving notice that,
                                    on
                                    the termination of these Terms of Use, personal data relating to them may be
                                    retained by
                                    or, as the case may be, transferred to one or more of the Permitted Recipients,
                                    their
                                    successors and assignees;
                                </li>
                                <li>
                                    process the Shared Personal Data only for the Agreed Purposes;
                                </li>
                                <li>
                                    not disclose or allow access to the Shared Personal Data to anyone other than the
                                    Permitted Recipients;
                                </li>
                                <li>
                                    ensure that all Permitted Recipients are subject to written contractual obligations
                                    concerning the Shared Personal Data (including obligations of confidentiality) which
                                    are
                                    no less onerous than those imposed by these Terms of Use;
                                </li>
                                <li>
                                    ensure that it has in place appropriate technical and organisational measures,
                                    reviewed
                                    and approved by the other party, to protect against unauthorised or unlawful
                                    processing
                                    of personal data and against accidental loss or destruction of, or damage to,
                                    personal
                                    data.
                                </li>
                                <li>
                                    not transfer any personal data received from the Data Discloser outside the EEA
                                    unless
                                    the transferor:
                                    <ul>
                                        <li>
                                            complies with the provisions of Articles 26 of the GDPR (in the event the
                                            third
                                            party is a joint controller); and
                                        </li>
                                        <li>
                                            ensures that (i) the transfer is to a country approved by the European
                                            Commission as providing adequate protection pursuant to Article 45 GDPR;
                                            (ii)
                                            there are appropriate safeguards in place pursuant to Article 46 GDPR; or
                                            (iii)
                                            one of the derogations for specific situations in Article 49 GDPR applies to
                                            the
                                            transfer.
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            </p>

                            <p>
                                ClassForKids and the Organiser shall assist each other in complying with all applicable
                                requirements of the Data Protection Legislation. In particular, each party shall:

                            <ul>
                                <li>
                                    consult with the other party about any notices given to data subjects in relation to
                                    the
                                    Shared Personal Data;
                                </li>
                                <li>
                                    promptly inform the other party about the receipt of any data subject access
                                    request;
                                </li>
                                <li>
                                    provide the other party with reasonable assistance in complying with any data
                                    subject
                                    access request;
                                </li>
                                <li>
                                    not disclose or release any Shared Personal Data in response to a data subject
                                    access
                                    request without first consulting the other party wherever possible;
                                </li>
                                <li>
                                    assist the other party, at the cost of the other party, in responding to any request
                                    from a data subject and in ensuring compliance with its obligations under the Data
                                    Protection Legislation with respect to security, breach notifications, impact
                                    assessments and consultations with supervisory authorities or regulators;
                                </li>
                                <li>
                                    notify the other party without undue delay on becoming aware of any breach of the
                                    Data
                                    Protection Legislation;
                                </li>
                                <li>
                                    at the written direction of the Data Discloser, delete or return Shared Personal
                                    Data
                                    and copies thereof to the Data Discloser on termination of these Terms of Use unless
                                    required by law to store the personal data;
                                </li>
                                <li>
                                    use compatible technology for the processing of Shared Personal Data to ensure that
                                    there is no lack of accuracy resulting from personal data transfers;
                                </li>
                                <li>
                                    maintain complete and accurate records and information to demonstrate its compliance
                                    with this clause 5; and
                                </li>
                                <li>
                                    provide the other party with contact details of at least one employee as point of
                                    contact and responsible manager for all issues arising out of the Data Protection
                                    Legislation, including the joint training of relevant staff, the procedures to be
                                    followed in the event of a data security breach, and the regular review of the
                                    parties’
                                    compliance with the Data Protection Legislation.
                                </li>
                            </ul>
                            </p>

                            <p>
                                As required by law, we shall not retain any personal data for any longer than is
                                necessary
                                in light of the purpose(s) for which that data is collected, held, and processed.
                                Different
                                types of personal data, used for different purposes, will necessarily be retained for
                                different periods (and its retention periodically reviewed), as set out in our Data
                                Retention Policy
                            </p>

                            <p>
                                Each party shall indemnify the other against all liabilities, costs, expenses, damages
                                and
                                losses (including but not limited to any direct, indirect or consequential losses, loss
                                of
                                profit, loss of reputation and all interest, penalties and legal costs (calculated on a
                                full
                                indemnity basis) and all other reasonable professional costs and expenses) suffered or
                                incurred by the indemnified party arising out of or in connection with the breach of the
                                Data Protection Legislation by the indemnifying party, its employees or agents, provided
                                that the indemnified party gives to the indemnifier prompt notice of such claim, full
                                information about the circumstances giving rise to it, reasonable assistance in dealing
                                with
                                the claim and sole authority to manage, defend and/or settle it.
                            </p>

                        </li>

                        <li>
                            <strong>Platform Access Fee </strong>
                            <p>
                                Organisers shall pay ClassForKids a monthly fee plus % commission for use of the
                                Platform,
                                as agreed. (Access Fee).
                            </p>
                        </li>

                        <li>
                            <strong>
                                Payment for Classes
                            </strong>

                            <p>
                                The Platform enables Parents to pay Class fees direct to the Club via our online payment
                                partner, Stripe. The information which you provide when registering for a Stripe Account
                                will be governed in accordance with Stripe’s Terms and Conditions
                                [https://stripe.com/gb/legal].
                            </p>

                            <p>
                                Alternatively, other methods of payment can be arranged with Parents direct.
                            </p>
                        </li>

                        <li>
                            <strong>
                                Marketing
                            </strong>

                            <p>
                                We ask Parents for their express consent to receive email and/or text marketing messages
                                from ClassForKids through the Platform. These messages will:
                            <ul>
                                <li>
                                    keep Parents up to date with new Classes and Organisers as and when they join the
                                    Platform;
                                </li>

                                <li>
                                    suggest Classes which ClassForKids think might be of interest to Parents but which
                                    are
                                    not necessarily linked to Classes they have booked; and
                                </li>
                                <li>
                                    present offers of products and services from selected third parties
                                </li>
                            </ul>
                            </p>

                            <p>
                                (together the <strong>Marketing Messages</strong>)
                            </p>
                        </li>

                        <li>
                            <strong>
                                Our Intellectual Property
                            </strong>

                            <p>
                                The following definitions shall apply to this clause:
                            </p>

                            <p>
                                Intellectual Property means patents, utility models, rights to inventions, copyright and
                                neighbouring and related rights, trade marks and service marks, business names and
                                domain
                                names, rights in get-up and trade dress, goodwill and the right to sue for passing off
                                or
                                unfair competition, rights in designs, database rights, rights to use, and protect the
                                confidentiality of, confidential information (including know-how and trade secrets), and
                                all
                                other intellectual property rights owned or used by the Supplier, in each case whether
                                registered or unregistered and including all applications and rights to apply for and be
                                granted, renewals or extensions of, and rights to claim priority from, such rights and
                                all
                                similar or equivalent rights or forms of protection which subsist or will subsist now or
                                in
                                the future in any part of the world.
                            </p>

                            <p>
                                You acknowledge that our rights to the Intellectual Property used in connection with the
                                Platform, Services and our business and the goodwill connected with that (ClassForKids
                                Intellectual Property) are our property.
                            </p>

                            <p>
                                You accept that:
                            <ul>
                                <li>
                                    you are permitted to use the ClassForKids Intellectual Property only for the
                                    purposes of
                                    and during the term of these Terms of Use and only as authorised by us in these
                                    Terms of
                                    Use or otherwise in writing;
                                </li>
                                <li>
                                    other than to that extent, you have and shall have no right to use or to allow
                                    others to
                                    use the ClassForKids Intellectual Property or any part of it.
                                </li>
                                <li>
                                    you shall not seek to register any ClassForKids Intellectual Property on our behalf
                                    without the our express consent;
                                </li>
                                <li>
                                    you shall not use any trade marks, trade names or get-up which resemble the our
                                    trade
                                    marks, trade names or get-up and which would therefore be likely to confuse or
                                    mislead
                                    the public or any section of the public;
                                </li>
                                <li>
                                    you shall not remove or alter any trade marks, trade names, or logos used in
                                    connection
                                    with the Services and shall not use any trade mark or trade name of its own in
                                    connection with the Services;
                                </li>
                                <li>
                                    you shall not do or omit to do, or authorise any third party to do or to omit to do,
                                    anything which could invalidate or be inconsistent with the ClassForKids
                                    Intellectual
                                    Property; and
                                </li>
                            </ul>
                            </p>

                            <p>
                                You shall notify us of:

                            <ul>
                                <li>
                                    any actual, threatened or suspected infringement of any ClassForKids Intellectual
                                    Property of which you become aware; and
                                </li>
                                <li>
                                    any claim by any third party of which it becomes aware that the supply or
                                    advertising of
                                    the Services infringes any rights of any other person.
                                </li>
                            </ul>
                            </p>

                            <p>
                                You indemnify us for all loss and expense arising out of or connected with any claim
                                made
                                against you for actual or alleged infringement of a third party’s intellectual property
                                rights arising out of or in connection with any use of ClassForKids Intellectual
                                Property in
                                accordance with these Terms of Use.
                            </p>

                        </li>

                        <li>
                            <strong>Our rights </strong>

                            <p>
                                We reserve the right to:

                            <ul>
                                <li>
                                    modify or withdraw, temporarily or permanently, the Platform (or any part thereof)
                                    with
                                    or without notice to you and you confirm that we shall not be liable to you or any
                                    third
                                    party for any modification to or withdrawal of the Platform; and/or
                                </li>
                                <li>
                                    change these Terms of Use from time to time, and your continued use of the Platform
                                    (or
                                    any part thereof) following such change shall be deemed to be your acceptance of
                                    such
                                    change. It is your responsibility to check regularly to determine whether the Terms
                                    of
                                    Use have been changed. If you do not agree to any change to the Terms of Use then
                                    you
                                    must immediately stop using the Platform.

                                </li>
                            </ul>

                            </p>
                        </li>

                        <li>
                            <strong>
                                Your use of the Platform
                            </strong>
                            <p>
                                You agree to use the Platform only for lawful purposes, and in a manner that does not
                                infringe the rights of third parties, or restrict or inhibit the use and enjoyment of
                                the
                                Platform by any other user.
                            </p>
                            <p>
                                Specifically, you agree you shall not:
                            <ul>
                                <li>
                                    use the Platform for the transmission of any spam or computer viruses
                                </li>
                                <li>
                                    do anything that brings (or in our opinion is likely to bring) ClassForKids into
                                    disrepute
                                </li>
                                <li>
                                    embed, upload, or make any of the Platform available via any third party platform,
                                    website or service without our prior consent
                                </li>
                                <li>
                                    copy, reproduce or otherwise publish any of our trademarks or logos
                                </li>
                                <li>
                                    frame the Platform on other websites without our express permission
                                </li>
                            </ul>
                            </p>

                            <p>
                                You agree fully to indemnify, defend and hold us, and our officers, directors,
                                employees,
                                agents and suppliers, harmless immediately on demand, from and against all claims,
                                liability, damages, losses, costs and expenses, including reasonable legal fees, arising
                                out
                                of any breach of these terms of use by you or any other liabilities arising out of your
                                use
                                of the Platform, or the use by any other person accessing the Platform using your
                                Organiser
                                Account.
                            </p>
                        </li>

                        <li>
                            <strong>
                                Breach of these Terms of Use
                            </strong>

                            <p>
                                We will determine, at our sole discretion, where there has been a breach of these Terms
                                of
                                Use. Where we suspect that a breach has occurred, we may take such action as we deem
                                appropriate which may include all, or any, of the following:
                            <ul>
                                <li>
                                    Immediate, temporary or permanent withdrawal of your right to use the Platform
                                </li>
                                <li>
                                    Issue of a warning to you
                                </li>
                                <li>
                                    Commencement of legal proceedings against you
                                </li>
                                <li>
                                    Disclosure of information to law enforcement authorities
                                </li>
                            </ul>
                            </p>
                            <p>
                                We exclude any liability for lawful actions taken by us in response to breaches of these
                                Terms of Use and we reserve the right to seek compensation from you for any loss or
                                damage
                                we may suffer as a direct consequence of any breach by you of these Terms of Use.
                            </p>
                            <p>
                                If you breach any of these Terms of Use, and we choose not to take action against you,
                                that
                                does not necessarily mean that we will waive any subsequent breaches by you. In
                                addition, if
                                we do not exercise the entire remedy available to us, we may still do so at a later
                                date.
                            </p>
                        </li>

                        <li>
                            <strong>
                                Our Liability
                            </strong>
                            <p>
                                In no event shall we be liable for any claims, loss or damage, arising out of or in
                                connection with your use of, or inability to use, the Platform or your use of the
                                Services,
                                including, without limitation, direct or indirect loss, consequential loss or damage,
                                loss
                                of profit or goodwill, or for loss arising from any errors or omissions in the Platform.
                            </p>

                            <p>
                                Nothing in these terms of use excludes or limits any liability which may not be excluded
                                by
                                any applicable law.
                            </p>
                        </li>

                        <li>
                            <strong>
                                No partnership or agency
                            </strong>
                            <p>
                                Nothing in these Terms of Use is intended to, or shall be deemed to, establish any
                                partnership or joint venture between us, constitute any party the agent of another
                                party,
                                nor authorise any party to make or enter into any commitments for or on behalf of any
                                other
                                party.
                            </p>
                        </li>

                        <li>
                            <strong>
                                Severance
                            </strong>

                            <p>
                                If any part of these Terms of Use shall be deemed unlawful, void or for any reason
                                unenforceable, then that provision shall be deemed to be severable from these Terms of
                                Use
                                and shall not affect the validity and enforceability of any of the remaining provisions
                                of
                                these Terms of Use.
                            </p>
                        </li>

                        <li>
                            <strong>
                                Law and Jurisdiction
                            </strong>

                            <p>
                                These Terms of Use shall be governed by and construed in accordance with Scots law. All
                                disputes arising from them shall be submitted to the non-exclusive jurisdiction of the
                                These
                                Terms of Use shall be governed by and construed in accordance with Scots law. All
                                disputes
                                arising from them shall be submitted to the non-exclusive jurisdiction of the Scottish
                                courts.
                            </p>
                        </li>
                    </ol>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import Vue from 'vue';
import CfkGreenTickSuccess from './CfkGreenTickSuccess.vue';

export default Vue.extend({
  name: 'TermsOfUse',
  components: {
    CfkGreenTickSuccess,
  },
  props: {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
  beforeCreate() {
  },
  created() {
  },
  beforeMount() {
  },
  mounted() {
  },
  updated() {
  },
  destroyed() {
  },
});
</script>

<style scoped>
</style>
