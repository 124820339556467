<template>
  <v-card flat style="background-color: #f6f8fb">
    <v-row no-gutters dense align="center" justify="start">
      <v-col align-self="start">
        <v-card-text class="text-center" style="font-weight: 600">
          Are we missing a club? Click
          <span style="font-weight: normal">
            <a href="https://share.hsforms.com/1KHVpCVRwRJakS2tfBgXCBw40sxw"
               class="cfk-link"
               target="_blank"
            >
              here
            </a>
          </span>
          to refer us a club for a chance to win an entire term of classes for free!
        </v-card-text>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'ParentReferralResource',
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  updated() {},
});
</script>

<style scoped>
.v-card__text{
  padding: 0px 0px 64px 0px;
}
</style>
