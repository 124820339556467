<template>
    <v-card elevation="1" class="intercom-gap">

        <v-container fluid grid-list-md>

            <v-row align-content-center>
                <v-col cols="12" md="3">
                    <v-text-field
                            v-model="search"
                            label="Search for a Contact"
                            filled
                            append-icon="fas fa-search"
                            hide-details
                            dense
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="3" class="text-center pb-0">
                    <v-select
                            v-model="filters.state"
                            :items="states"
                            label="Payment Collection"
                            filled
                            dense
                    ></v-select>
                </v-col>

                <v-col cols="12" md="3" class="pb-0">
                    <v-select
                            v-model="planFilterModel"
                            :items="plansComputed"
                            clearable
                            label="Plans"
                            filled
                            dense
                    ></v-select>
                </v-col>

                <v-col cols="12" md="3" class="pb-0">
                    <v-select
                            v-model="runFilterModel"
                            :items="runsComputed"
                            label="Classes"
                            clearable
                            filled
                            dense
                    ></v-select>

                </v-col>

            </v-row>

            <v-spacer></v-spacer>

            <!-- group controls -->

            <v-sheet v-if="canPauseAndResume">

                <v-dialog v-model="pauseModal" persistent max-width="290">

                  <template v-slot:activator="{ on, attrs }">

                    <v-btn
                            text
                            :disabled="loading || !selectedSubscriptionIds.length > 0"
                            outlined color="primary"
                            v-if="countOfSelectedPausedSubscriptions.length > 0 && countOfSelectedActiveSubscriptions.length === 0"
                            class="font-weight-bold"
                            small
                            v-bind="attrs"
                            v-on="on"
                    >
                        <v-icon small>fad fa-play-circle</v-icon> &nbsp;
                        <span v-html="resumeButtonText"></span> &nbsp;
                        <span v-if="countOfSelectedPausedSubscriptions.length > 0">{{countOfSelectedPausedSubscriptions.length}}</span>
                    </v-btn>

                    <v-btn
                            text
                            :disabled="loading || !selectedSubscriptionIds.length > 0"
                            outlined
                            v-else
                            color="primary"
                            class="font-weight-bold"
                            small
                            v-bind="attrs"
                            v-on="on"
                    >
                        <v-icon>fad fa-pause-circle</v-icon> &nbsp;
                        <span v-html="pauseButtonText"></span> &nbsp;
                        <span v-if="countOfSelectedActiveSubscriptions.length > 0">{{countOfSelectedActiveSubscriptions.length}}</span>
                    </v-btn>

                  </template>
                  <v-card>
                    <v-card-title class="headline">Notify Parent</v-card-title>
                    <v-card-text>We'll notify these parents of the changes made to their Subscriptions</v-card-text>
                      <v-card-text v-if="user.isAdmin">
                        <v-checkbox
                          v-model="sendEmail"
                          label="Send Email"
                          small
                        ></v-checkbox>
                      </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="UIAction" text @click="pauseModal = false">Cancel</v-btn>
                      <v-btn color="success" text v-if="countOfSelectedPausedSubscriptions.length > 0 && countOfSelectedActiveSubscriptions.length === 0" @click.prevent="resumeSubscriptions(selectedPausedSubscriptionIds)">
                          Confirm
                      </v-btn>
                       <v-btn color="success" text @click.prevent="pauseSubscriptions(selectedActiveSubscriptionIds)" v-else>Confirm</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <ChangeSubscriptionNavigationDrawer :subscriptions="selected" v-on:reset="resetTable" />

            </v-sheet>

            <v-data-table
                    show-select
                    :loading="loading"
                    color="secondary"
                    v-model="selected"
                    item-key="c4k.recurringPaymentSubscriptionId"
                    :headers="table.headers"
                    :items="subscriptionsFiltered"
                    :search="search"
                    @current-items="updateItems"
                    :footer-props="{
                        itemsPerPageOptions: [25, 50]
                    }"
                    no-results-text="No Subscriptions match this filter"
                    ref="dataTable"
            >

                <template v-slot:loading>

                    <div class="text-xs-center grey lighten-4 pa-5 mt-4 mb-4">
                        <img  width="100" height="100" src="/pwa-static/images/cfk-spinner-v2-slower.gif">
                        <p class="headline font-weight-light primary--text">Loading <strong class="font-weight-bold">{{typeLabel}}</strong> subscriptions...</p>
                        <p class="grey--text">This can take up to 60 seconds</p>
                    </div>

                </template>

                <template v-slot:no-data>
                    <div class="text-xs-center grey lighten-4 pa-5 mt-4 mb-4">
                        <p class="headline font-weight-light primary--text">No <strong class="font-weight-bold">{{typeLabel}}</strong> subscriptions</p>
                        <p class="grey--text">There are no subscriptions currently in the {{typeLabel}} state.</p>
                    </div>
                </template>

                <template v-slot:no-results>
                    <v-alert :value="true" color="info">
                        Sorry, no Subscriptions match your filter
                    </v-alert>
                </template>

<!--                <template v-slot:item="subscriptionsFiltered">-->
<!--                    <SubscriptionsTableListItem :subscriptions="subscriptionsFiltered" :subscription="subscriptionsFiltered.item" />-->
<!--                </template>-->

                <template v-slot:item.parent.firstName="subscriptionsFiltered">
                    <ParentName :subscription="subscriptionsFiltered.item" />
                </template>

                <template v-slot:item.collection="subscriptionsFiltered">
                    <Collection :subscription="subscriptionsFiltered.item" />
                </template>

                <template v-slot:item.plan="subscriptionsFiltered">
                    <Plan :subscription="subscriptionsFiltered.item" />
                </template>

                <template v-slot:item.lastPaid="subscriptionsFiltered">
                    <PaymentAttempt :subscription="subscriptionsFiltered.item" />
                </template>

                <template v-slot:item.subscription.invoices="subscriptionsFiltered">
                    <NextInvoice :subscription="subscriptionsFiltered.item" />
                </template>

                <template v-slot:item.parent.bookingCount="subscriptionsFiltered">
                    <Bookings :subscription="subscriptionsFiltered.item" />
                </template>

                <template v-slot:item.actions="subscriptionsFiltered">
                    <Actions :subscription="subscriptionsFiltered.item" />
                </template>

            </v-data-table>

        </v-container>

    </v-card>

</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import SubscriptionsTableListItem from './SubscriptionsTableListItem';
import ParentName from './SubscriptionTableFields/ParentName';
import Collection from './SubscriptionTableFields/Collection';
import Plan from './SubscriptionTableFields/Plan';
import PaymentAttempt from './SubscriptionTableFields/PaymentAttempt';
import NextInvoice from './SubscriptionTableFields/NextInvoice';
import Bookings from './SubscriptionTableFields/Bookings';
import Actions from './SubscriptionTableFields/Actions';

import SubscriptionMixin from '../../../mixins/Subscriptions/SubscriptionMixin';
import ChangeSubscriptionNavigationDrawer from '../ChangeSubscriptionPlanNavigationDrawer';
import { formatMoney } from '&/helpers.js';

export default {
  name: 'SubscriptionsTable',
  props: {
    action: {
      required: true,
      type: String,
    },
    type: {
      required: true,
      type: String,
    },
    title: {
      required: true,
      type: String,
    },
    synchronous: {
      required: true,
      type: Boolean,
    },

  },
  data() {
    return {
      filters: {
        state: 'all',
      },
      selected: [],
      search: '',
      planFilterModel: [],
      runFilterModel: [],
      snackbar: false,
      dataTable: {
        filteredItems: [],
      },
      pauseModal: false,
      sendEmail: true,

    };
  },
  mixins: [SubscriptionMixin],
  watch: {
    filteredItems(items, oldItems) {
      const subscriptionsFiltered = items
        .filter((subscription) => !oldItems.includes(subscription) && !subscription.invoices.upcoming.next_payment_attempt);

      // const subscriptionIds = subscriptionsFiltered.map( subscription => {
      //   return subscription.c4k.stripeSubscriptionId;
      //   });
      //   if(subscriptionIds.length > 0) {
      //     const localLoading = true;
      //     this.$store.dispatch('GET_STRIPE_SUBSCRIPTIONS', {subscriptionIds, localLoading})
      //   }

      const customerIds = subscriptionsFiltered.map((subscription) => subscription.c4k.stripeCustomerId);

      if (customerIds.length > 0) {
        const localLoading = false;
        this.$store.dispatch('GET_SUBSCRIPTION_UPCOMING_INVOICES', { customerIds, localLoading });
      }

      // subscriptionNeedHydrating.forEach( subscription => {
      //   this.hydrate(subscription)
      // })
    },
  },
  computed: {
    ...mapGetters(['table', 'plans', 'schedule', 'clientId', 'user']),
    loading() {
      return this.$store.getters.globalLoading
    },
    plansComputed() {
      const plans = [];

      const tempPlan = {
        value: '0',
        text: `Temporary - ${formatMoney(0)} / month`,
        id: '0',
      };
      plans.push(tempPlan);
      Object.values(this.plans).forEach((plan) => {
        const countOfSubscribers = this.subscriptions.filter((subscription) => {
          const { planId } = subscription.c4k.plan;
          return plan.recurringPaymentPlanId === planId;
        }).length;

        const computedPlan = {
          value: plan.recurringPaymentPlanId,
          text: `${plan.name} - ${formatMoney(plan.cost)} / ${plan.frequency} (${countOfSubscribers})`,
          id: plan.recurringPaymentPlanId,
        };
        plans.push(computedPlan);
      });
      return plans;
    },
    runsComputed() {
      const terms = Object.values(this.schedule);
      const runs = [];
      terms.forEach((term) => {
        if (term.paymentScheme === 'recurring') {
          Object.values(term.runs).forEach((run) => {

            let dateTime = '';
            Object.values(run.sessions).forEach((session, index) => {
              dateTime += `${session.day} ${session.startTime} - ${session.endTime}, `;
            });

            const text = `(${dateTime}) - ${run.className}, ${run.venueName}`;
            runs.push({ value: run.runId, text, id: run.runId });
          });
        }
      });

      return runs;
    },
    subscriptions: {
      cache: false,
      get() {
        if (this.type === 'successfulSubscriptions') {
          return Object.values(this.$store.getters.successfulSubscriptions);
        } if (this.type === 'waitingSubscriptions') {
          return Object.values(this.$store.getters.waitingSubscriptions);
        } if (this.type === 'problemSubscriptions') {
          return Object.values(this.$store.getters.problemSubscriptions);
        }
        // return Object.values(this.$store.getters[this.type]);
      },
      set(value) {

      },
    },
    subscriptionsFiltered() {
      return this.subscriptions
        .filter(this.pausedFilter)
        .filter(this.planFilter)
        .filter(this.runFilter);
    },

    selectedSubscriptionIds() {
      return this.selected.map((subscription) => subscription.c4k.stripeSubscriptionId);
    },

    countOfSelectedPausedSubscriptions() {
      return this.selected.filter((subscription) => this.subscriptionIsPaused(subscription.stripe));
    },

    selectedPausedSubscriptionIds() {
      return this.countOfSelectedPausedSubscriptions.map((subscription) => subscription.c4k.stripeSubscriptionId);
    },

    countOfSelectedActiveSubscriptions() {
      return this.selected.filter((subscription) => !this.subscriptionIsPaused(subscription.stripe));
    },

    selectedActiveSubscriptionIds() {
      return this.countOfSelectedActiveSubscriptions.map((subscription) => subscription.c4k.stripeSubscriptionId);
    },

    typeLabel() {
      if (this.type === 'successfulSubscriptions') {
        return 'successful';
      } if (this.type === 'waitingSubscriptions') {
        return 'waiting';
      } if (this.type === 'problemSubscriptions') {
        return 'problem';
      }
    },

    runIdsInBookings() {
      const runIds = [];

      Object.values(this.subscriptions).forEach((subscription) => {
        const bookings = Object.values(subscription.parent.bookings);
        bookings.forEach((booking) => {
          runIds.push(booking.runId);
        });
      });
      return runIds;
    },
    countOfActiveSubscriptions() {
      return this.subscriptions.filter((subscription) => !this.subscriptionIsPaused(subscription.stripe)).length;
    },
    countOfPausedSubscriptions() {
      return this.subscriptions.filter((subscription) => this.subscriptionIsPaused(subscription.stripe)).length;
    },
    states() {
      const activeSubs = this.countOfActiveSubscriptions;
      const pausedSubs = this.countOfPausedSubscriptions;
      const total = parseInt(activeSubs) + parseInt(pausedSubs);
      return [{ text: `All (${total})`, value: 'all' }, { text: `Active(${activeSubs})`, value: 'active' }, { text: `Paused(${pausedSubs})`, value: 'paused' }];
    },
    resumeButtonText() {
      return this.loading && this.resuming ? 'Resuming' : 'Resume';
    },
    pauseButtonText() {
      return this.loading && this.pausing ? 'Pausing' : 'Pause';
    },
    filteredItems() {
      return this.dataTable.filteredItems;
    },

    canPauseAndResume() {
      return this.type !== 'problemSubscriptions';
    },

  },
  methods: {
    async resetTable() {
      this.selected = [];
      await this.getData();
    },

    async getData() {
      return this.$store.dispatch(this.action, { globalLoading: false });
    },

    planFilter(subscription) {
      const filterModel = this.planFilterModel;
      if (filterModel && filterModel.length > 0) {
        const { planId } = subscription.c4k.plan;
        const planIdCheck = planId === null ? '0' : planId;
        return filterModel === planIdCheck;
      }
      return true;
    },

    runFilter(subscription) {
      const runId = this.runFilterModel;
      if (runId && runId.length > 0) {
        const bookings = Object.values(subscription.parent.bookings);
        let bookingMatch = false;
        for (let i = 0; i < bookings.length; i++) {
          if (bookingMatch === false) {
            bookingMatch = bookings[i].runId === runId;
          }
        }
        return bookingMatch;
      }
      return true;
    },
    pausedFilter(subscription) {
      if (this.filters.state === 'all') {
        return true;
      } if (this.filters.state === 'paused') {
        return this.subscriptionIsPaused(subscription.stripe);
      }
      return !this.subscriptionIsPaused(subscription.stripe);
    },

    hydrate(subscription) {
      const payload = {
        personId: subscription.parent.personId,
        clientId: subscription.clientId,
        localLoading: true,
      };
      this.$store.dispatch('GET_SUBSCRIPTIONS', payload);
    },
    updateItems(items) {
      this.dataTable.filteredItems = items;
    },

  },
  created() {},
  mounted() {},
  updated() {},
  components: {
    SubscriptionsTableListItem,
    ParentName,
    Collection,
    Plan,
    PaymentAttempt,
    ChangeSubscriptionNavigationDrawer,
    NextInvoice,
    Bookings,
    Actions,
  },
};
</script>

<style scoped>
    >>>.hidden {
        display: none !important;
    }

    >>>.v-input__icon .v-icon {
        font-size: 16px;
    }

    >>>.v-text-field--filled > .v-input__control > .v-input__slot:before {
        border-style: solid;
        border-width: 0 0 1px 0;
    }

    >>>.v-input {
        font-size: 14px;
    }

    >>>.radioActive {
        font-weight: bold;
        color: #1f4153;
    }

    .intercom-gap {
        margin-bottom: 10rem;
    }

</style>
