import { render, staticRenderFns } from "./InsetSwitch.vue?vue&type=template&id=daf7f812&scoped=true&"
import script from "./InsetSwitch.vue?vue&type=script&lang=js&"
export * from "./InsetSwitch.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "daf7f812",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VSwitch})
