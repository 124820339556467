export default {

  primary:  '#7698b1', //  '#00334d', // cfk navy,

  secondary: '#F48BAB', // cfk pink,

  accent: '#0092AC', // cfk vibrant blue

  warning: '#F57A51', // cfk orange,

  error: {
    base: '#aa3444', // darker pink shade '#ff2636',
    lighten5: '#1d171b',
  },



  success: '#57b9ac', // c4k cyan/green,

  background: {
    base: '#111517',
    darken1: '#171f23'
  }, // light grey



  body: '#121212',
  'form-border': '#565656',

  info:'#66c5f2', // cfk light blue
  oldcfkorange: '#f69170', // cfk orange,

  'top-bar-background': '#2A3D48',



  disabled: '#fafafa', // greyed out(grey lighten-5),
  UIAction: '#bdbdbd', // greyed out(grey lighten-1)
  cyan: '#66c5f2', // '#72c1ec',
  magenta: '#f05b8b',
  white: '#fff',



  // primary: '#92d3f3', // cfk vibrant blue,
  // secondary: '#f48bab', // cfk pink,
  // accent: '#00334d', // cfk navy,
  // warning: '#f85c8d', // cfk orange
  // error: {
  //   base: '#ff2636',
  //   lighten5: '#85171f',
  // },
  // success: '#57b9ac', // c4k cyan/green,
  // background: '#1A2125',
  // 'menu-background': '#25343D',
  // 'menu-color': '#ffffff',
  // body: '#AFB1B3',
  //
  //
  //
  // cyan: '#92d3f3', //200
  // magenta: '#f48bab', //200


};
