import { render, staticRenderFns } from "./ContactsDataTable.vue?vue&type=template&id=c506b8c6&scoped=true&"
import script from "./ContactsDataTable.vue?vue&type=script&lang=js&"
export * from "./ContactsDataTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c506b8c6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VDataTable})
