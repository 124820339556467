<template>
  <v-row>
    <v-col cols="8">
      <v-card>
        <RunsList/>
      </v-card>
    </v-col>

    <v-col cols="4">
      <v-card class="pa-4">

        <div class="">
          <p>{{selected.length}} bookings selected across {{selectedRunsCount}} runs</p>
        </div>

        <div v-if="canPreviewBookings">
          <Button block @click="previewChanges" :disabled="!canPreviewBookings">
            Preview Bookings
          </Button>
        </div>

        <v-row v-if="preview">
          <v-col cols="12">
            <Button block color="success" @click="startRequestingBookings" >
              <span v-html="requestButtonState"></span>
            </Button>
          </v-col>

          <v-col cols="12">
            <Button block ghost @click="previewChanges" :disabled="requesting">
              Back
            </Button>
            <div class="text-center mtm" v-if="requesting">
              <i class="fas fa-circle-notch fa-spin fa-2x"></i>
            </div>
          </v-col>

        </v-row>
      </v-card>

      <v-card color="primary" class="mt-4">
        <v-card-title>
          Debug Menu
        </v-card-title>

        <v-row>
          <v-col>
            <label for="requests">Parallel Requests</label>
            <input type="number" v-model.number="maxParallelRequests">
          </v-col>
        </v-row>
      </v-card>
    </v-col>

  </v-row>
</template>

<script>

import { mapGetters } from 'vuex';
import LoadingSpinner from '../../../components/Global/LoadingSpinner';
import RunsList from './RunsList';
import Button from '../../../../../global/components/UILibrary/Buttons/Button';

export default {
  name: 'F5AutoEnrolBookings',
  data() {
    return {
      loading: false,
      requesting: false,
      maxParallelRequests: 6,

    };
  },
  watch: {
    preview() {
      if (this.preview) {
        window.onbeforeunload = (() => true);
      } else {
        window.onbeforeunload = null;
      }
    },
    // one at a time request queue
    // async requesting(value) {
    //   if(value) {
    //       for (let i = 0; i < this.selectedToBook.length; i++) {
    //             if(this.requesting) {
    //                 await this.makeAutoEnrolBookingForOneParentOneRun(this.selectedToBook[i]);
    //             }
    //       }
    //       this.requesting = false;
    //       this.preview = false;
    //   } else {
    //     await this.getBookings();
    //   }
    // },

    async requesting(value) {
      if (value) {
        const requestQ = [];
        for (let i = 0; i < this.selectedToBook.length; i++) {
          if (requestQ.length >= this.maxParallelRequests) {
            const nextRequest = requestQ.shift();
            await nextRequest;
          }

          if (this.requesting) {
            requestQ.push(this.makeAutoEnrolBookingForOneParentOneRun(this.selectedToBook[i]));
          }
        }
        this.requesting = false;
        this.preview = false;
      } else {
        await this.getBookings();
      }
    },
  },
  computed: {
    ...mapGetters(['selected', 'runs']),
    preview: {
      get() {
        return this.$store.getters.preview;
      },
      set(value) {
        this.$store.commit('SET_PREVIEW', value);
      },
    },
    canPreviewBookings() {
      return !this.preview && this.selectedToBook.length > 0;
    },
    termId() {
      return this.$route.params.termId;
    },
    selectedToBook() {
      return this.selected.map((selectedBooking) => ({ runId: selectedBooking.runId, childId: selectedBooking.personId }));
    },

    selectedRunsCount() {
      const runIds = new Set();

      [...this.selectedToBook].forEach((booking) => {
        runIds.add(booking.runId);
      });

      return runIds.size;
    },

    requestButtonState() {
      if (this.requesting) {
        return 'Pause  <i class="fas fa-pause-circle fa-lg"></i>';
      } if (this.preview) {
        return 'Start Booking  <i class="fas fa-play-circle fa-lg"></i>';
      }
    },

  },

  methods: {
    async getBookings() {
      this.loading = true;
      await this.$store.dispatch('GET_BOOKINGS_BY_TERM_ID', { termId: this.termId });
      this.loading = false;
    },
    previewChanges() {
      this.preview = !this.preview;
    },
    confirmAllBookings() {
      this.loading = true;
      this.$store.dispatch('MAKE_AUTO_ENROL_BOOKINGS', { termId, bookingsToMake: this.selectedToBook });
      this.loading = false;
    },

    async startRequestingBookings() {
      this.requesting = !this.requesting;
    },

    async makeAutoEnrolBookingForOneParentOneRun(bookingToMake) {
      const { termId } = this;
      return await this.$store.dispatch('MAKE_AUTO_ENROL_BOOKINGS', { termId, bookingsToMake: [bookingToMake] });
    },

    preventBeforeUnload(event) {
      return event.preventDefault();
    },
  },

  async mounted() {
    await this.getBookings();
  },

  components: {
    RunsList,
    LoadingSpinner,
  },
};
</script>

<style scoped>

.sticky-menu {
  position: sticky;
  top: 16px;
  left: 0;
}

</style>
