<template>
    <table class="full-width"  v-if="bookingCount > 0">
        <thead>
            <th class="text-center" width="10%">
                <input type="checkbox" v-if="!preview" @click="selectAll">
            </th>
            <th width="70%">Booking</th>
            <th width="20%">Age</th>
        </thead>
        <tbody>
            <BookingListItem v-for="booking in bookingsToMake" :key="booking.bookingId" :booking="booking" :run="run"/>
            <BookingMadeListItem v-for="booking in bookingsMade" :key="booking.bookingId" :booking="booking" :run="run"/>
        </tbody>
    </table>
</template>
<script>
  import { mapGetters } from 'vuex';
  import BookingListItem from './BookingListItem'
  import BookingMadeListItem from './BookingMadeListItem'
  export default {
    name: 'BookingsList',
    props: {
      run: {
        type: Object,
        required: true
      },
      bookings: {
        required: true,
        type: Object
      }
    },
    computed: {
      ...mapGetters(['preview', 'selected']),
      bookingsToMake() {
        let bookings = {};
        Object.values(this.bookings).forEach( booking => {
          !booking.created ? bookings[booking.personId] = booking : null;
        });
        return bookings;
      },

      bookingsMade() {
        let bookings = {};
        Object.values(this.bookings).forEach( booking => {
          booking.created ? bookings[booking.personId] = booking : null;
        });

        return bookings;

      },
      bookingCount() {
        return Object.values(this.bookings).length
      }
    },
    methods: {
      selectAll() {
        let bookings = [];
        for (const bookingId in this.bookings) {
          this.bookings[bookingId].runId = this.run.runId;
          bookings.push(this.bookings[bookingId]);
        }
        this.$store.commit('SET_SELECTED', bookings);
      }
    },
    components: {
      BookingListItem,
      BookingMadeListItem
    }
  }
</script>

<style scoped>

</style>