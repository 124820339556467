import App from '../pages/Prototypes/F5AutoEnrol/App'
export default [
  {
    component: App,
    path: '/cms/schedule/terms/auto-enrol/:termId',
    name: 'Auto Enrol V2',
    meta: {
      title: 'Auto Enrol V2',
    },
  },
];
