<template>
  <div>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="contactsFiltered"
      :items-per-page="100"
      item-key="childId"
      class="ma-7"
      show-select
      @toggle-select-all="toggleAll"
      :footer-props="{
        'items-per-page-options': [50, 100, 200],
      }"
    >
      <template v-slot:item.childFirstName="{ item }">
        <ContactsTableColumnName
          :childId="item.childId"
          :childFirstName="item.childFirstName"
          :childLastName="item.childLastName"
          :parentId="item.parentPersonId"
          :parentFirstName="item.parentFirstName"
          :parentLastName="item.parentLastName"
          :photoPermission="item.photoPermission"
        />
      </template>

      <template v-slot:item.username="{ item }">
        <ContactsTableColumnContact
          :mobile="item.mobile"
          :userName="item.username"
          :parentId="item.parentPersonId"
        />
      </template>

      <template v-slot:item.firstBookingDate="{ item }">
        <ContactsTableColumnFirstBooking
          :firstBookingDate="item.firstBookingDate"
        />
      </template>
      <template v-slot:item.lastBookingDate="{ item }">
        <ContactsTableColumnLatestBooking
          :lastBookingDate="item.lastBookingDate"
        />
      </template>

      <template v-slot:item.setupFeePaid="{ item }">
        <ContactsTableColumnSetupFeePaid
          :setupFeePaid="item.setupFeePaid"
          :lastSetupFeePaidDate="item.lastSetupFeePaidDate"
          :childId="item.childId"
        />
      </template>
      <template v-slot:item.registrationAnswers="{ item }">
        <ContactsTableColumnRegistrationAnswers
          :item="item.registrationQuestions"
        />
      </template>

      <template v-slot:top="{ pagination }">
        <p class="text-right pa-4">
          Showing {{ pagination.itemsLength }}
          {{ pagination.itemsLength > 1 ? 'Contacts' : 'Contact' }}
        </p>
      </template>
    </v-data-table>
    <!-- <ContactsPageActionsDrawer /> -->
  </div>
</template>

<script>
import ContactsTableColumnName from './ContactsTableColumns/ContactsTableColumnName.vue';
import ContactsTableColumnSetupFeePaid from './ContactsTableColumns/ContactsTableColumnSetupFeePaid.vue';
import ContactsTableColumnContact from './ContactsTableColumns/ContactsTableColumnContact.vue';
import ContactsTableColumnFirstBooking from './ContactsTableColumns/ContactsTableColumnFirstBooking.vue';
import ContactsTableColumnLatestBooking from './ContactsTableColumns/ContactsTableColumnLatestBooking.vue';
import ContactsTableColumnRegistrationAnswers from './ContactsTableColumns/ContactsTableColumnRegistrationAnswers.vue';
import ContactsPageActionsDrawer from './ContactsPageActionsDrawer';
import FilteredContactsMixin from './Mixins/FilteredContactsMixin.vue';
import {
  headersContacts,
  registrationColumn,
} from '../../assets/js/headersContacts';

export default {
  name: 'ContactsDataTable',
  components: {
    ContactsTableColumnName,
    ContactsTableColumnSetupFeePaid,
    ContactsTableColumnContact,
    ContactsTableColumnFirstBooking,
    ContactsTableColumnLatestBooking,
    ContactsTableColumnRegistrationAnswers,
    ContactsPageActionsDrawer,
  },
  mixins: [FilteredContactsMixin],
  props: {},
  computed: {
    headers() {
      const questionHeaders = [...headersContacts(), registrationColumn()];
      return this.registrationQuestionsChildSelectContacts ||
        this.registrationQuestionsParentSelectContacts
        ? questionHeaders
        : headersContacts();
    },
  },
  methods: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  updated() {},
};
</script>

<style scoped></style>
