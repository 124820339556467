<template>
  <v-card flat style="background-color: #f6f8fb">
    <v-row no-gutters dense align="center" justify="start">
      <v-col align-self="start">
        <v-card-text class="text-center" style="font-weight: 600">
          Need a Club Management System for your kids activity business?
          Register a
          <span style="font-weight: normal">
            <a
              :href="createResource.url"
              class="cfk-link"
            >
              New club
            </a>
            .
          </span>
        </v-card-text>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'CreateClientResource',
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {
        ...mapGetters(['resources']),
    clientResources() {
      return this.resources.client;
    },
    createResource() {
      return this.clientResources.find((resource) => {
        return resource.type === 'create';
      });
    },
  },
  methods: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  updated() {},
});
</script>

<style scoped>
.v-card__text {
  padding: 0px 0px 64px 0px;
}
</style>
