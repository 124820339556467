// Contacts v2
import ContactsPage from '../pages/Contacts/ContactsPage.vue';

export default [
  {
    component: ContactsPage,
    path: '/cms/contacts/:type?/:id?',
    name: 'Contacts',
    meta: {
      title: 'Contacts',
    },
  },
];
