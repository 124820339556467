
import Vue from 'vue';
import { loginCheck } from '../../../services/authentication/login';

export default {
  state: {
    darkMode: false,
    synced: false,
    // todo change this to client
    settings: {
      smsUnitPrice: '',
      clubName: '',
      user: {
        firstName: '',
        lastName: '',
        username: '',
        isAdmin: false,
        isOrganiser: false,
      },
      settings: {
        'show-asmo-reports': 0,
      },
      clientId: '',
    },
    paymentSettings: {
      stripeAccountId: '',
      stripePublicKey: '',
    },
    roles: [],
  },
  actions: {
    async GET_SETTINGS({ dispatch }, { globalLoading = false }) {
      const request = {
        url: '/api/v4/client/settings',
      };
      const mutation = 'SET_SETTINGS';
      const options = {
        request,
        mutation,
        globalLoading,
      };

      await dispatch('MAKE_GET_REQUEST', options);
    },

    async GET_PAYMENT_SETTINGS({ dispatch }, payload) {
      const request = {
        url: '/api/v4/client/settings/payments',
      };
      const mutation = 'SET_PAYMENT_SETTINGS';
      const options = {
        request,
        mutation,
      };

      await dispatch('MAKE_GET_REQUEST', options);
    },

    async CHECK_LOGIN({ commit }) {
      const result = await loginCheck();
      commit('SET_ROLES', result);
      return result;
    },

  },
  mutations: {
    SET_SETTINGS(state, payload) {
      state.settings = payload;
    },
    SET_DARK_MODE(state, payload) {
      state.darkMode = payload;
    },
    SET_PAYMENT_SETTINGS(state, payload) {
      state.paymentSettings = payload;
    },
    SET_SYNCED(state, payload) {
      state.synced = payload;
    },
    SET_ROLES(state, payload) {
      state.roles = payload.data ? payload.data.roles : [];
    },
  },
  getters: {
    darkMode(state) {
      return state.darkMode;
    },
    synced(state) {
      return state.synced;
    },
    user(state) {
      return state.settings.user;
    },
    settings(state) {
      return state.settings;
    },
    smsUnitPrice(state) {
      return state.settings.smsUnitPrice;
    },
    clientId(state) {
      return state.settings.clientId;
    },
    stripeAccountId(state) {
      return state.paymentSettings.stripeAccountId;
    },
    stripePublicKey(state) {
      return state.paymentSettings.stripePublicKey;
    },
    roles(state) {
      return state.roles;
    },
  },
};
