<template>
<v-container>

  <v-card outlined flat color="transparent">
    <v-card-title class="justify-center py-6">
      <h1 class="font-weight-bold text-h4">Custom Registers</h1>
      <v-spacer></v-spacer>
      <div>
        <AddNewCustomRegisterNavigationDrawer
          @refresh="getCustomRegisters"
          @update-edit="updateEdit"
          :custom-register-option="customRegisterOptionToEdit"
          :edit="edit"
        />
      </div>
    </v-card-title>

    <v-card-text>
      Custom registers allow you to customise your registers with unique drop downs and checkboxes, with answers of your choice. The answers are easily accessible on your registers to give you quick access to this information. 

</v-card-text>
    <v-card-text>
      Parents are not able to view or edit custom registration questions. If you want to gather information from parents, you can use the <a class="cfk-link" href="/cms/settings/questions">registration questions feature</a> instead.
    </v-card-text>

  </v-card>

  <v-card color="">
    <v-card-actions> </v-card-actions>

    <v-card-text>
      <v-tabs v-model="tab" background-color="transparent" color="" grow>
        <v-tab> Active ({{activeCustomRegisterOptions.length}})</v-tab>
        <v-tab> Inactive ({{inactiveCustomRegisterOptions.length}})</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item >
          <v-card min-height="100%" flat>
            <CustomRegisterOptionsList
              @edit-custom-register-option="setCustomRegisterOptionToEdit"
              :custom-register-options="activeCustomRegisterOptions"
            />
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card min-height="100%" flat>
            <CustomRegisterOptionsList
              @edit-custom-register-option="setCustomRegisterOptionToEdit"
              :custom-register-options="inactiveCustomRegisterOptions"
            />
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
  </v-container>
</template>

<script>
import CustomRegisterOptionsList from './../../components/Settings/CustomRegisterOptionsList.vue';
import AddNewCustomRegisterNavigationDrawer from './../../components/Settings/AddNewCustomRegisterNavigationDrawer.vue';
import { mapGetters } from 'vuex';
export default {
  name: 'CustomRegisterSettingsPage',
  data() {
    return {
      customRegisterOptionToEdit: {},
      edit: false,
      tab: 'active',
    };
  },

  computed: {
    ...mapGetters(['customRegisterOptions']),

    activeCustomRegisterOptions() {
      return Object.values(this.customRegisterOptions).filter(
        this.isActiveFilter
      );
    },
    inactiveCustomRegisterOptions() {
      return Object.values(this.customRegisterOptions).filter(
        (o) => !this.isActiveFilter(o)
      );
    },
  },
  methods: {
    async getCustomRegisters() {
      await this.$store.dispatch('GET_CUSTOM_REGISTER_OPTIONS');
    },
    isActiveFilter(option) {
      return parseInt(option.active, 10) === 1;
    },

    setCustomRegisterOptionToEdit(option) {
      this.edit = true;
      this.customRegisterOptionToEdit = option;
    },
    updateEdit(value) {
      this.edit = value;
    }
  },
  async mounted() {
    await this.getCustomRegisters();
  },
  components: {
    CustomRegisterOptionsList,
    AddNewCustomRegisterNavigationDrawer,
  },
};
</script>

<style scoped></style>
