<template>
    <v-card height="70px" flat class="pa-0">
        <v-card-text>
                <span v-if="subdomainAvailable">
                    <v-row dense no-gutters justify="space-between">
                        <v-col cols="10">
                            <v-text-field
                                    label="Your booking link will be"
                                    type="text"
                                    v-model="customSubdomainTransformed"
                                    :disabled="checkingSubdomain"
                                    readonly
                                    outlined
                                    dense
                            >
                            </v-text-field>
                        </v-col>
                        <v-col cols="1"></v-col>
                            <v-col cols="1" v-if="subdomainAvailable">
                                    <CfkGreenTickSuccess :size="35" :delay="200"/>
                            </v-col>
                    </v-row>
                </span>
            <span class="ml-3" v-if="checkingSubdomain">
                   Checking if available
                <i class="fal fa-circle-notch fa-spin fa-fw"></i>
            </span>
            <span v-if="!checkingSubdomain && !subdomainAvailable"
                  :class="{'text-alert': !subdomainAvailable}">
                    {{ subdomainNotAvailableText }}
            </span>
        </v-card-text>
    </v-card>
</template>

<script>
import Vue from 'vue';
import SignUpMixin from '../../pages/SignUp/mixins/SignUpMixin.vue';
import CfkGreenTickSuccess from './CfkGreenTickSuccess.vue';

export default Vue.extend({
  name: 'CheckClubName',
  mixins: [SignUpMixin],
  components: {
    CfkGreenTickSuccess,
  },
  props: {},
  data() {
    return {
      customSubdomain: '',
    };
  },
  computed: {},
  methods: {},
  beforeCreate() {
  },
  created() {
  },
  beforeMount() {
  },
  mounted() {
  },
  updated() {
  },
});
</script>

<style scoped>
    .v-card__text {
        padding: 16px 0 0 0 !important;
    }
</style>
