<template>
    <v-card elevation="1">
        <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field
                    v-model="search"
                    label="Search"
                    single-line
                    hide-details
            ></v-text-field>
        </v-card-title>


        <v-data-table
                :loading="loading"
                v-model="selected"
                item-key="parentId"
                :headers="headers"
                :items="subscriptions"
                :search="search"
        >
            <template v-slot:item="subscriptions">
                <tr>
                    <td>{{subscriptions.item.firstName}} {{subscriptions.item.lastName}}</td>
                    <td>{{formatDate(subscriptions.item.created)}}</td>
                    <td>
<!--                        <v-btn small text primary icon-->
<!--                               @click="promptToManageSubscription(subscriptions.item.parentId)"-->
<!--                               :loading="sending">-->
<!--                            <v-icon small>fad fa-paper-plane</v-icon>-->
<!--                            Prompt Parent to Manage-->
<!--                        </v-btn>-->


                 <v-btn small text primary icon
                               @click="sendInvoice(subscriptions.item.uuid)"
                               :loading="sending">
                            <v-icon small>fad fa-paper-plane</v-icon>
                           Send Invoice
                        </v-btn>

                    </td>
                </tr>

            </template>
        </v-data-table>
    </v-card>
</template>

<script>

import { mapGetters } from 'vuex';
import { formatMoney, formatDate } from '&/helpers.js';

export default {
  name: 'NotSetUpSubscriptionsTable',
  data() {
    return {
      selected: [],
      sending: false,
      headers: [
        { value: 'name', text: 'Name' },
        { value: 'created', text: 'Booked' },
        { value: 'created', text: 'Actions' },
      ],
      search: '',
    };
  },
  computed: {
    ...mapGetters(['notSetUpSubscriptions', 'loading']),
    subscriptions() {
      return Object.values(this.notSetUpSubscriptions);
    },
  },


  methods: {
    formatDate(value) {
      return formatDate(value);
    },
    async promptToManageSubscription(personId) {
      this.sending = true;
      await this.$store.dispatch('PROMPT_PARENT_TO_MANAGE_SUBSCRIPTION', { personId });
      this.sending = false;
    },

    async sendInvoice(uuid) {
      this.sending = true;
      await this.$store.dispatch('SEND_INVOICE', { orderUuid: uuid });
      this.sending = false;
    },



  },


  mounted() {
    this.$store.dispatch('GET_SUBSCRIPTIONS_NOT_SET_UP', { localLoading: true });
  },
};
</script>

<style scoped>

</style>
