var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"ma-7",attrs:{"headers":_vm.headers,"items":_vm.contactsFiltered,"items-per-page":100,"item-key":"childId","show-select":"","footer-props":{
      'items-per-page-options': [50, 100, 200],
    }},on:{"toggle-select-all":_vm.toggleAll},scopedSlots:_vm._u([{key:"item.childFirstName",fn:function(ref){
    var item = ref.item;
return [_c('ContactsTableColumnName',{attrs:{"childId":item.childId,"childFirstName":item.childFirstName,"childLastName":item.childLastName,"parentId":item.parentPersonId,"parentFirstName":item.parentFirstName,"parentLastName":item.parentLastName,"photoPermission":item.photoPermission}})]}},{key:"item.username",fn:function(ref){
    var item = ref.item;
return [_c('ContactsTableColumnContact',{attrs:{"mobile":item.mobile,"userName":item.username,"parentId":item.parentPersonId}})]}},{key:"item.firstBookingDate",fn:function(ref){
    var item = ref.item;
return [_c('ContactsTableColumnFirstBooking',{attrs:{"firstBookingDate":item.firstBookingDate}})]}},{key:"item.lastBookingDate",fn:function(ref){
    var item = ref.item;
return [_c('ContactsTableColumnLatestBooking',{attrs:{"lastBookingDate":item.lastBookingDate}})]}},{key:"item.setupFeePaid",fn:function(ref){
    var item = ref.item;
return [_c('ContactsTableColumnSetupFeePaid',{attrs:{"setupFeePaid":item.setupFeePaid,"lastSetupFeePaidDate":item.lastSetupFeePaidDate,"childId":item.childId}})]}},{key:"item.registrationAnswers",fn:function(ref){
    var item = ref.item;
return [_c('ContactsTableColumnRegistrationAnswers',{attrs:{"item":item.registrationQuestions}})]}},{key:"top",fn:function(ref){
    var pagination = ref.pagination;
return [_c('p',{staticClass:"text-right pa-4"},[_vm._v("\n        Showing "+_vm._s(pagination.itemsLength)+"\n        "+_vm._s(pagination.itemsLength > 1 ? 'Contacts' : 'Contact')+"\n      ")])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }