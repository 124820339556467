<template>
  <td>
    <v-row no-gutters dense>
      <v-col cols="12">
        <a :href="'tel:' + mobile" style="text-decoration: none">
          {{ mobile }}
        </a>
      </v-col>
    </v-row>
    <v-row no-gutters dense>
      <v-col cols="12">
        <a
          :href="`/cms/communications/send-message/${parentId}`"
          style="text-decoration: none"
        >
          {{ userName }}
        </a>
      </v-col>
    </v-row>
  </td>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'ContactsTableColumnContact',
  components: {},
  props: {
    mobile: {
      required: false,
      type: String,
    },
    userName: {
      required: false,
      type: String,
    },
    parentId: {
      required: false,
      type: String || undefined,
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  updated() {},
});
</script>

<style scoped></style>
