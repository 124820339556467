import { getClientUserResources, getParentUserResources } from '../../services/users/resources';

const getDefaultResourcesState = () => ({
  resources: {
    client: [],
    parent: [],
  },
});

export default {
  state: getDefaultResourcesState(),
  actions: {
    async GET_CLIENT_USER_RESOURCES({ commit }) {
      const json = await getClientUserResources();
      commit('UPDATE_CLIENT_USER_RESOURCES', json.data);
      return json;
    },
    async GET_PARENT_USER_RESOURCES({ commit }) {
      const json = await getParentUserResources();
      commit('UPDATE_PARENT_USER_RESOURCES', json.data);
      return json;
    },
  },
  mutations: {
    UPDATE_CLIENT_USER_RESOURCES(state, payload) {
      state.resources.client = payload;
    },
    UPDATE_PARENT_USER_RESOURCES(state, payload) {
      state.resources.parent = payload;
    },
    RESET_RESOURCES_STATE(state) {
      Object.assign(state, getDefaultResourcesState());
    },
  },
  getters: {
    resources({ resources }) {
      return resources;
    },
  },
};
