import sendSMS from '../../services/notify/send/sms';

const getDefaultNotifySMSState = () => ({
  sms: {},
});

export default {
  state: getDefaultNotifySMSState(),
  actions: {
    async SEND_SMS({ commit, dispatch }, parameters) {
      const authorization = await dispatch('GET_AUTHORIZATION');
      await sendSMS(authorization, parameters);
    },
  },
  mutations: {
    RESET_NOTIFYSMS_STATE(state) {
      Object.assign(state, getDefaultNotifySMSState());
    },
  },
  getters: {

  },
};
