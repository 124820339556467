/**
 *
 * @param url
 * @param headers
 * @param body
 * @returns {Request}
 */
async function createStandardRequest({ url, headers, body }) {
  return new Request(url, {
    method: 'POST',
    headers,
    credentials: 'include',
    body: JSON.stringify(body),
  });
}

/**
 *
 * @param url
 * @param headers
 * @param body
 * @param action
 * @returns {Promise<Request>}
 */
async function createRecaptchaRequest({ url, headers, body }, { action }) {
  body.recaptcha_token = await grecaptcha.execute('6Lfm8dkZAAAAADe7XVNJ17fUZTCQH1I1J9_njZZM', { action });
  return new Request(url, {
    method: 'POST',
    headers,
    credentials: 'include',
    body: JSON.stringify(body),
  });
}

/**
 *
 * @param url
 * @param headers
 * @param body
 * @param options
 * @returns {Promise<Request>}
 */
async function createRequest({ url, headers, body }, options) {

  if (options.recaptcha) {
    return createRecaptchaRequest({ url, headers, body }, options);
  }

  return createStandardRequest({ url, headers, body });
}

export default createRequest;
