<template>
<v-row>

    <v-col cols="12">
       <v-card elevation="1">
        <v-list two-line class="mb-3" >

            <v-list-item
                    href="https://help.class4kids.co.uk/en/articles/7826740-how-to-use-the-subscription-feature#h_2933be15e0"
                    target="_blank"
            >
                <v-list-item-avatar>
                    <v-icon class="success white--text">fal fa-file-alt</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title>Guide: Pausing Subscriptions</v-list-item-title>
                    <v-list-item-subtitle>How to pause individual or groups of subscriptions</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                    <v-btn icon ripple>
                        <v-icon color="success">fa fa-chevron-right</v-icon>
                    </v-btn>
                </v-list-item-action>
            </v-list-item>


            <v-list-item
                    href="https://help.class4kids.co.uk/en/articles/7826740-how-to-use-the-subscription-feature#h_ae8adab69d"
                    target="_blank"
            >
                <v-list-item-avatar>
                    <v-icon class="success white--text">fal fa-file-alt</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title>Guide: Changing Subscriptions</v-list-item-title>
                    <v-list-item-subtitle>How to change the payment plan for individual or groups of subscriptions</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                    <v-btn icon ripple>
                        <v-icon color="success">fa fa-chevron-right</v-icon>
                    </v-btn>
                </v-list-item-action>
            </v-list-item>

        </v-list>
       </v-card>

    </v-col>

</v-row>
</template>

<script>
    import SupportHelpMessageNonLoad from '../../../components/Global/SupportHelpMessageNonLoad';

    export default {
        name: 'SubscriptonGuides',
        data() {
            return {
            }
        },
        components: {
            SupportHelpMessageNonLoad
        },
    }
</script>
