import Vue from 'vue';
import { headersContacts, registrationColumn } from '../assets/js/headersContacts';
import {
  getContacts,
  getRegistrationQuestions,
  updateSetupFeePaidStatus,
} from '../pages/Contacts/services/contacts-service';

export default {
  state: {
    messageModalDialog: '',
    registrationQuestions: {
      parent: {},
      child: {},
    },
    registrationQuestionsChildSelect: null,
    registrationQuestionsParentSelect: null,
    settings: {},
    setupFeeSelect: null,
    photoPermissionSelect: 'all',
    bookingStatusSelect: null,
    search: '',
    checkBox: {
      contactDetailsCheckBox: true,
      setupFeeDetailsCheckbox: true,
    },
    table: {
      amountOfContactsSelected: 0,
      selected: [],
      headers: [],
      contacts: [],
    },
  },
  actions: {
    async ALL_CONTACTS(context, payload, loading = true) {
      if (loading) {
        await context.commit('UPDATE_GLOBAL_LOADING', {
          mutation: 'getContacts',
          status: true,
        });
      }
      const response = await getContacts(payload);
      context.commit('SET_CONTACTS_DATA', response.data);

      if (loading) {
        await context.commit('UPDATE_GLOBAL_LOADING', {
          mutation: 'getContacts',
          status: false,
        });
      }
      return response;
    },
    async GET_ALL_REGISTRATION_QUESTIONS(context) {
      await context.commit('UPDATE_GLOBAL_LOADING', {
        mutation: 'getRegistrationSettings',
        status: true,
      });

      const response = await getRegistrationQuestions();
      context.commit('SET_REGISTRATION_QUESTIONS', response.data);

      await context.commit('UPDATE_GLOBAL_LOADING', {
        mutation: 'getRegistrationSettings',
        status: false,
      });
    },
    async UPDATE_SETUP_FEE_PAID_STATUS(context, payload) {
      const request = new Request(`/api/v4/people/person/toggle-setup-fee`, {
        method: 'POST',
        credentials: 'same-origin',
        body: JSON.stringify({personId: payload.childId, status: payload.status}),
      });

      const response = await fetch(request);
      const json = await response.json();

      context.commit('UPDATE_SETUP_FEE', json.data);
      // await context.dispatch('ALL_CONTACTS', false);
      return response;
    },

    async SEND_GROUP_EMAIL_CONTACTS(context) {
      await context.commit('UPDATE_GLOBAL_LOADING', {
        mutation: 'sendEmailContacts',
        status: true,
      });
    },

    async SEND_GROUP_SMS_CONTACTS(context) {
      await context.commit('UPDATE_GLOBAL_LOADING', {
        mutation: 'sendSmsContacts',
        status: true,
      });
    },
  },
  mutations: {
    SET_AMOUNT_OF_CONTACTS(state, payload) {
      Vue.set(state.table, 'amountOfContactsSelected', payload);
    },
    SET_SELECTED_CONTACTS(state, payload) {
      Vue.set(state.table, 'selected', payload);
    },
    SET_SEARCH_CONTACTS(state, payload) {
      Vue.set(state, 'search', payload);
    },
    SET_SETUP_FEE_SELECT_CONTACTS(state, payload) {
      Vue.set(state, 'setupFeeSelect', payload);
    },
    SET_PHOTO_PERMISSION_FILTER_CONTACTS(state, payload) {
      Vue.set(state, 'photoPermissionSelect', payload);
    },    
    SET_BOOKING_STATUS_SELECT_CONTACTS(state, payload) {
      Vue.set(state, 'bookingStatusSelect', payload);
    },
    SET_REGISTRATION_QUESTIONS_CHILD_SELECT_CONTACTS(state, payload) {
      Vue.set(state, 'registrationQuestionsChildSelect', payload);
    },
    SET_REGISTRATION_QUESTIONS_PARENT_SELECT_CONTACTS(state, payload) {
      Vue.set(state, 'registrationQuestionsParentSelect', payload);
    },
    SET_SETTINGS_CONTACTS(state, payload) {
      Vue.set(state, 'settings', payload);
    },
    SET_CONTACTS_DATA(state, payload) {
      Vue.set(state.table, 'contacts', Object.values(payload));
    },
    SET_REGISTRATION_QUESTIONS(state, payload) {
      Vue.set(state, 'registrationQuestions', payload);
    },
    REGISTRATION_QUESTIONS_COLUMN_EDIT(state) {
      console.log(`I'm being called again and again`);
      const headers = headersContacts();
      Vue.set(state.table, 'headers', headers
        .concat([registrationColumn()]));
    },
    RESET_HEADERS(state) {
      const updatedHeaders = headersContacts();
      Vue.set(state.table, 'headers', updatedHeaders);
    },
    SET_MESSAGE_MODAL_DIALOG_CONTACTS(state, payload) {
      Vue.set(state, 'messageModalDialog', payload);
    },
    UPDATE_SETUP_FEE(state, {personId, setupFeePaid}) {
      const contact = state.table.contacts.find(contact => {
        return contact.childId === personId;
      })
      contact.setupFeePaid = setupFeePaid;
    }
  },
  getters: {
    tableContacts(state) {
      return state.table;
    },
    selectedContacts(state) {
      return state.table.selected;
    },
    searchContacts(state) {
      return state.search;
    },
    setupFeeSelectContacts(state) {
      return state.setupFeeSelect;
    },
    photoPermissionSelectValue(state) {
      return state.photoPermissionSelect;
    },    
    bookingStatusSelectContacts(state) {
      return state.bookingStatusSelect;
    },
    contactDetailsCheckBoxContacts(state) {
      return state.checkBox.contactDetailsCheckBox;
    },
    setupFeeDetailsCheckboxContacts(state) {
      return state.checkBox.setupFeeDetailsCheckbox;
    },
    amountOfContactsSelected(state) {
      return state.table.amountOfContactsSelected;
    },
    childQuestions(state) {
      return Object.values(state.registrationQuestions)
        .filter((question) => question.scope === 'child');
    },
    parentQuestions(state) {
      return Object.values(state.registrationQuestions)
        .filter((question) => question.scope === 'parent');
    },
    registrationQuestionsChildSelectContacts(state) {
      return state.registrationQuestionsChildSelect;
    },
    registrationQuestionsParentSelectContacts(state) {
      return state.registrationQuestionsParentSelect;
    },
    messageModalDialogContacts(state) {
      return state.messageModalDialog;
    },
  },
};
