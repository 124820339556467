import {
  listSMSTemplates,
  createSMSTemplate,
  getSMSTemplate,
  updateSMSTemplate,
  deleteSMSTemplate,
}
  from '../../services/notify/templates/sms';

const getDefaultNotifyTemplates = () => ({
  sms: {
    templates: [],
  },
});

export default {
  state: getDefaultNotifyTemplates(),
  actions: {
    async LIST_SMS_TEMPLATES({ commit, dispatch }, parameters) {
      const authorization = await dispatch('GET_AUTHORIZATION');
      const json = await listSMSTemplates(authorization);
      return commit('SET_SMS_TEMPLATES', json);
    },
    async CREATE_SMS_TEMPLATE({ commit, dispatch }, parameters) {
      const authorization = await dispatch('GET_AUTHORIZATION');
      const json = await createSMSTemplate(authorization, parameters);
      return commit('UPDATE_SMS_TEMPLATES', json);
    },
    async GET_SMS_TEMPLATE({ commit, dispatch }, parameters) {
      const authorization = await dispatch('GET_AUTHORIZATION');
      const json = await getSMSTemplate(authorization, parameters);
      return commit('UPDATE_SMS_TEMPLATES', json);
    },
    async UPDATE_SMS_TEMPLATE({ commit, dispatch }, parameters) {
      const authorization = await dispatch('GET_AUTHORIZATION');
      const json = await updateSMSTemplate(authorization, parameters);
      await commit('REMOVE_SMS_TEMPLATE', json);
      return commit('UPDATE_SMS_TEMPLATES', json);
    },
    async DELETE_SMS_TEMPLATE({ commit, dispatch }, parameters) {
      const authorization = await dispatch('GET_AUTHORIZATION');
      const json = await deleteSMSTemplate(authorization, parameters);
      return commit('REMOVE_SMS_TEMPLATE', json);
    },
  },
  mutations: {
    SET_SMS_TEMPLATES(state, payload) {
      state.sms.templates = payload;
    },
    UPDATE_SMS_TEMPLATES(state, payload) {
      state.sms.templates.push(payload);
    },
    REMOVE_SMS_TEMPLATE(state, payload) {
      const index = state.sms.templates
        .findIndex((template) => template.TemplateName === payload.TemplateName);
      delete state.sms.templates[index];
    },
    RESET_NOTIFYTEMPLATE_STATE(state) {
      Object.assign(state, getDefaultNotifyTemplates());
    },
  },
  getters: {
    templates(state) {
      return state.sms.templates;
    },
  },
};
