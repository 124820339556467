<template>
	<div v-cloak>
		<v-row row wrap>
			<v-col cols="12" md="6">

				<v-menu
						ref="menu"
						v-model="menuFrom"
						:close-on-content-click="true"

						transition="scale-transition"
						offset-y
						:nudge-right="40"
						max-width="290px"
						min-width="290px"
				>
					<template v-slot:activator="{ on }">
						<v-text-field
								slot="activator"
								label="Select start date"
								v-model="formattedDateFrom"
								prepend-icon="fas fa-calendar-day"
								readonlyY
								v-on="on"
						></v-text-field>
					</template>
					<v-date-picker v-model="dateFrom" no-title locale="en-GB" scrollable reactive @change="save">

					</v-date-picker>
				</v-menu>





			</v-col>

			<v-col cols="12" md="6">


				<v-menu
						ref="menu"
						v-model="menuTo"
						:close-on-content-click="true"

						transition="scale-transition"
						offset-y
						:nudge-right="40"
						max-width="290px"
						min-width="290px"
				>
					<template v-slot:activator="{ on }">
						<v-text-field
								slot="activator"
								label="Select end date"
								v-model="formattedDateTo"
								prepend-icon="fas fa-calendar-day"
								readonlyY
								v-on="on"
						></v-text-field>
					</template>
					<v-date-picker v-model="dateTo" no-title locale="en-GB" scrollable reactive @change="save">

					</v-date-picker>
				</v-menu>

			</v-col>

		</v-row>
	</div>
</template>



<script>

	import moment from 'moment';

	export default {

		name: 'DatePicker',
		data: function() {
			return {
				menuFrom: false,
				menuTo: false,
				menu: false

			}
		},

		methods: {
			save (date) {
				this.$refs.menu.save(date)
			}

		},

		watch: {

		},

		computed: {
			dateTo: {
				get: function() {
					return this.$store.getters.dateTo
				},
				set: function(value) {
					const payload = {
						key: 'dateTo',
						value: value
					};
					this.$store.dispatch('UPDATE_DATE_FIELDS', payload);
				}
			},

			dateFrom: {
				get: function() {
					return this.$store.getters.dateFrom
				},
				set: function(value) {
					const payload = {
						key: 'dateFrom',
						value: value
					};
					this.$store.dispatch('UPDATE_DATE_FIELDS', payload);

				}
			},
			formattedDateFrom: function(){
				return moment(this.dateFrom).toDate().toLocaleDateString()
			},
			formattedDateTo: function (){
				return moment(this.dateTo).toDate().toLocaleDateString()
			}
		},
		components: {
		},

		mounted: function() {

		},
		created: function() {
			this.dateFrom = moment().format('YYYY-MM-DD');
			this.dateTo = moment().format('YYYY-MM-DD');
		}

	}
</script>



<style>



</style>