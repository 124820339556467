
import OfflineRegistersLayout from '../layouts/OfflineRegistersLayout.vue';

import Schedule from '../pages/OfflineRegisters/Schedule.vue';
import Run from '../pages/OfflineRegisters/Run.vue';
import TakeAttendance from '../pages/OfflineRegisters/TakeAttendance.vue';
import Settings from '../components/OfflineRegisters/Settings.vue';
import ResetCache from '../components/OfflineRegisters/ResetCache.vue';

export default [

  {
    path: '',
    name: 'OfflineRegistersLayout',
    component: OfflineRegistersLayout,
    children: [
      {
        path: '/cms/pwa/schedule',
        name: 'OfflineRegistersSchedule',
        component: Schedule,
      },
      {
        path: '/cms/pwa/settings',
        name: 'Settings',
        component: Settings,
      },
      {
        path: '/cms/pwa/run/:id',
        name: 'Run',
        component: Run,
      },
      {
        path: '/cms/pwa/run/:id/:runInstanceId/attendance',
        name: 'TakeAttendance',
        component: TakeAttendance,
      },
      {
        path: '/cms/pwa/reset',
        name: 'ResetCache',
        component: ResetCache,
      },

    ],
  },


];
