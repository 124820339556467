<template>
    <v-list two-line dense>
        <ParentResourceListItem v-for="(resource, index) in parentResources" :key="resource.key" :resource="resource" :index="index"/>
        <br />
        <br />
        <v-img contain left width="300px" src="/assets-dev/images/app/logo_organisers.png" class="mb-4"></v-img>
        <TopLevelDomainListItem v-for="domain in FilterOutCurrentTldFromData" :key="domain[0]" :domain="domain"/>
        <ClientResourceListItem v-for="(resource, index) in clientResources" :key="resource.key" :resource="resource" :index="index"/>

<!--        <a href="#" class="subtitle-1"> Create a new Club on ClassForKids</a>-->

        <NoUserResourcesListItem v-if="userHasNoResources"/>
    </v-list>
</template>

<script>

import { mapGetters } from 'vuex';
import ParentResourceListItem from './ParentResourceListItem.vue';
import ClientResourceListItem from './ClientResourceListItem.vue';
import TopLevelDomainListItem from './TopLevelDomainListItem.vue';
import NoUserResourcesListItem from './NoUserResourcesListItem.vue';

export default {
  name: 'ResourceList',
  methods: {
    getCurrentUrlTld() {
      return window.location.href.match(/\.\w{2,5}\b/g).join('');
    },
  },
  computed: {
    ...mapGetters(['resources', 'loginData']),
    clientResources() {
      return this.resources.client;
    },
    parentResources() {
      return this.resources.parent;
    },
    TLDData() {
      return Object.entries(this.loginData.topLevelDomains);
    },
    FilterOutCurrentTldFromData() {
      const array = [];
      for (let i = 0; i < this.TLDData.length; i++) {
        if (this.TLDData[i][0] !== this.getCurrentUrlTld()) {
          array.push(this.TLDData[i]);
        }
      }
      return array;
    },
    userHasNoResources() {
      return this.resources.length === 0 && Object.values(this.loginData.topLevelDomains).length === 0;
    },
  },
  components: {
    ParentResourceListItem,
    ClientResourceListItem,
    TopLevelDomainListItem,
    NoUserResourcesListItem,
  },
  mounted() {},
  updated() {},
};
</script>

<style scoped>

    .v-list {
        background: transparent;
    }
</style>
