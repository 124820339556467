import LoginPage from '../../../global/pages/Login/LoginPage';


export default [
  {
    component: LoginPage,
    path: '/login',
    name: 'PublicLoginPage',
    meta: {
      title: 'Login',
    },
  },
  {
    component: LoginPage,
    path: '/cms/login',
    name: 'AdminLoginPage',
    meta: {
      title: 'Login',
    },
  },
];
