import DictionaryPage from '../../../global/pages/ClassForKidsUILibrary/DictionaryPage';

const AllUIComponentsPage = () => import(/* webpackPrefetch: true */ '../../../global/pages/ClassForKidsUILibrary/AllUIComponentsPage');
const ButtonsPage = () => import(/* webpackPrefetch: true */ '../../../global/pages/ClassForKidsUILibrary/ButtonsPage');

export default [
  {
    component: AllUIComponentsPage,
    path: '/cms/uilibrary',
    name: 'AllUIComponentsPage',
    meta: {
      title: 'ClassForKids UI Library',
    },
  },
  {
    component: ButtonsPage,
    path: '/cms/uilibrary/buttons',
    name: 'ButtonsPage',
    meta: {
      title: 'Buttons',
    },
  },

  {
    component: DictionaryPage,
    path: '/cms/uilibrary/dictionary',
    name: 'DictionaryPage',
    meta: {
      title: 'Spot the spelling mistakes',
    },
  },


];
