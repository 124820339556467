<template>
  <div>
    <h5 class="title">Take Attendance</h5>

    <div id="shortcuts">
      <div>
        <div class="instances">
          <router-link
            :to="{
              name: 'TakeAttendance',
              params: { id: id, runInstanceId: instance.runInstanceId },
            }"
            v-for="instance in runInstances"
            :key="instance.runInstanceId"
          >
            <v-btn fab class="mx-2" elevation="2" color="primary">
              <div style="font-size: 0.6rem;">
                {{ day(instance.startDateTime) }}
                <br />
                {{ date(instance.startDateTime) }}
              </div>
            </v-btn>
          </router-link>
        </div>
      </div>

      <v-btn fab class="mx-2" @click="show = !show" v-if="showMoreButton" outlined>
        <div style="font-size: 0.6rem;">
          <strong v-html="showMoreButtonText"> MORE </strong>
          <br />
          <strong> DATES </strong>
        </div>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { format } from "date-fns";
import TakeAttendanceNavigationDrawer from './TakeAttendanceNavigationDrawer.vue'

export default {
  name: "QuickTakeAttendance",
  data() {
    return {
      show: false,
    };
  },

  methods: {
    date(timestamp) {
      return format(timestamp * 1000, "DD MMM").toUpperCase();
    },

    day(timestamp) {
      return format(timestamp * 1000, "ddd").toUpperCase();
    },

    takeAttendance() {
      this.$router.push({
        name: "TakeAttendance",
        params: { id: this.id, runInstanceId: this.selected },
      });
    },
  },

  computed: {
    id() {
      return this.$route.params.id;
    },
    runInstances() {
      if (this.show) {
        return this.$store.getters.allRunInstances;
      } else {
        return this.$store.getters.allRunInstances.slice(0, 4);
      }
    },
    showMoreButton() {
      return this.$store.getters.allRunInstances.length > 4;
    },
    showMoreButtonText() {
      if (this.show) {
        return "LESS";
      } else {
        return "MORE";
      }
    },
  },

  components: {TakeAttendanceNavigationDrawer},
  mounted() {
    this.selected = this.runInstances[0].runInstanceId;
  },
};
</script>


<style scoped lang="scss">
@import "./../../../assets/scss/variables.scss";

#shortcuts {
  display: grid;
  grid-template-columns: 6.4fr 1.6fr;
}

.instances {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 8px;
  transition: all 2s ease-in-out;
}

a {
  text-decoration: none;
  // color: $primary;
}

.title {
  margin-top: 0;
  margin-bottom: 16px;
}

.circle {
  width: 60px;
  height: 60px;
  // border: 1px solid $grey;
  border-radius: 50%;
  margin: 0;
  font-size: 0.7rem;
  text-align: center;
  /*margin: 0 auto;*/
  align-items: center;
}

.circle:hover {
  //background-color: $primary;
  color: #fff;
  // border: 1px solid $primary;
}

.day {
  margin-bottom: 0;
  margin-top: 25%;
}

.date {
  margin: 0;
}
</style>
