import getPerson from '../../services/users/people';

const getDefaultPersonState = () => ({
  person: {
    firstName: '',
    lastName: '',
    addressLine1: '',
    addressLine2: '',
    username: '',
    photoUrl: '',
    mobile: '',
    postcode: '',
    city: '',
    personId: '',
  },
});

export default {
  state: getDefaultPersonState(),
  actions: {
    async GET_MY_PERSON({ commit }) {
      const json = await getPerson();
      commit('UPDATE_PERSON', json.data);
      return json;
    },
  },
  mutations: {
    UPDATE_PERSON(state, payload) {
      state.person = payload;
    },
    RESET_PERSON_STATE(state) {
      Object.assign(state, getDefaultPersonState());
    },
  },
  getters: {
    person({ person }) {
      return person;
    },
  },
};
