export function headersContacts() {
  return [
    {
      text: 'Name',
      align: 'start',
      sortable: true,
      value: 'childFirstName',
      width: '25%',
    },
    {
      text: 'Contact',
      align: 'start',
      sortable: true,
      value: 'username',
      width: '15%',
    },
    {
      text: 'First Booking',
      align: 'start',
      sortable: true,
      value: 'firstBookingDate',
    },
    {
      text: 'Latest Booking',
      align: 'start',
      sortable: true,
      value: 'lastBookingDate',
    },
    {
      text: 'Setup Fee',
      align: 'start',
      sortable: true,
      value: 'setupFeePaid',
    },
  ];
}
export function registrationColumn() {
  return {
    text: 'Registration Answers',
    align: 'start',
    sortable: true,
    value: 'registrationAnswers',
  };
}
