<template>

    <v-row>
        <v-col cols="12">
            <v-tabs background-color="transparent" v-model="activeTab" show-arrows>
                <v-tabs-slider color="accent"></v-tabs-slider>

                <v-tooltip bottom  v-for="(tab, index) in tabs" :key="index">
                    <template v-slot:activator="{ on }">
                        <v-tab ripple v-on="on">
                            {{tab.title}} ({{getCountOfSubscriptions(tab.type)}})
                        </v-tab>
                    </template>
                    <span>{{tab.description}}</span>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                                <v-tab
                                        :key="3"
                                        ripple
                                        v-on="on"
                                >
                                    Not Set Up
                                </v-tab>
                    </template>
                    <span>Parent's without a Subscription Plan</span>
                </v-tooltip>

                <v-tab
                        :key="4"
                        ripple
                >
                    Summary
                </v-tab>

                <v-tab
                        :key="5"
                        ripple
                >
                    Plans
                </v-tab>

                <v-tab
                        :key="6"
                        ripple
                >
                    Cancelled
                </v-tab>


                <v-tabs-items v-model="activeTab" touchless>

                    <v-tab-item
                            v-for="(tab, index) in tabs"
                            :key="index"
                            :value="index"

                    >
                        <!-- lazy -->
                        <keep-alive>
                            <SubscriptionsTable :action="tab.action" :type="tab.type" :key="tab.title" :title="tab.title" :synchronous="tab.synchronous" v-if="activeTab === index" />
                        </keep-alive>
                    </v-tab-item>

                    <v-tab-item :key="3" :value="3" v-if="activeTab === 3">
                        <NotSetUpSubscriptionsTable />
                    </v-tab-item>

                    <v-tab-item eager :key="4" :value="4" >
                        <v-card elevation="1">
                            <ViewSubscriptionsSummary />
                        </v-card>
                    </v-tab-item>

                    <v-tab-item :key="5" :value="5" >
                        <v-card elevation="1">
                            <ViewSubscriptionPlans />
                        </v-card>
                    </v-tab-item>
                    <v-tab-item :key="6" :value="6" >
                        <CancelledSubscriptionsTable />
                    </v-tab-item>


                </v-tabs-items>
            </v-tabs>
        </v-col>
    </v-row>

</template>

<script>

import { mapGetters } from 'vuex';
import SubscriptionsTable from './SubscriptionsTable';
import NotSetUpSubscriptionsTable from './NotSetUpSubscriptionsTable';
import CancelledSubscriptionsTable from './CancelledSubscriptionsTable';
import ViewSubscriptionsSummary from './ViewSubscriptionsSummary';
import ViewSubscriptionPlans from './ViewSubscriptionPlans';

export default {
  name: 'SubscriptionsTables',
  data() {
    return {
      activeTab: 0,
    };
  },
  computed: {
    ...mapGetters(['summary']),
    tabs() {
      return [
        {
          title: 'Successful',
          action: 'GET_SUCCESSFUL_SUBSCRIPTIONS',
          type: 'successfulSubscriptions',
          description: 'Successfully taking payments',
          synchronous: true,
        },
        {
          title: 'Waiting',
          action: 'GET_WAITING_SUBSCRIPTIONS',
          type: 'waitingSubscriptions',
          description: 'Waiting for 1st payment',
          synchronous: false,
        },
        {
          title: 'Problem',
          action: 'GET_PROBLEM_SUBSCRIPTIONS',
          type: 'problemSubscriptions',
          description: 'Parents with payment problems',
          synchronous: false,
        },
      ];
    },
  },

  methods: {
    getCountOfSubscriptions(type) {
      return Object.values(this.$store.getters[type]).length;
    },
  },
  components: {
    NotSetUpSubscriptionsTable,
    CancelledSubscriptionsTable,
    ViewSubscriptionsSummary,
    ViewSubscriptionPlans,
    SubscriptionsTable,
  },
  created() {
    this.$store.dispatch('GET_SCHEDULE', { localLoading: true });
  },
};
</script>

<style scoped>

    >>>.v-tabs__slider {
        height:3px;
    }

    >>>.v-tabs__item--active {
        font-weight: 700;
        color: #1f4153;
    }

</style>
