<template>
  <div class="runInstance">
      <TakeAttendanceNavigationDrawer :run-instance="runInstance" v-if="$vuetify.breakpoint.mdAndUp" />
      <strong v-else>
      <router-link :to="{name: 'TakeAttendance', params: {id: runInstance.runId, runInstanceId: runInstance.runInstanceId}}" >
        <h4 @mouseover="showDate = false" @mouseleave="showDate = true">{{ date() }}</h4>
      </router-link>
      </strong>
      <div>
       <h4> <span class="count"> {{ countOfAttended }} </span> / <span class="total"> {{ totalNumberOfAttendeesPerClass() }} </span> </h4>
      </div>
  </div>
</template>


<script>
import TakeAttendanceNavigationDrawer from './TakeAttendanceNavigationDrawer';
import date from 'date-fns';

export default {
  name: 'RunInstances',
  data() {
    return {
      drawer: false,
      showDate: true,
      loading: false,
    };
  },

  computed : {

   //this counts the number of children marked as attended
   countOfAttended (){
     return this.allChildrenInRunInstance().filter(child => child.attended).length;
   },
  },

  methods: {
    //this shows date above each run instance
    date () {
       if(this.showDate) {
         return date.format(this.runInstance.startDateTime * 1000, 'ddd Do MMM');
       } else {
         return 'Take Attendance'
       }
     },

    // this filters all the individual data to the register format (ie children in each run instance)
    allChildrenInRunInstance (){
      return this.allBookingsArray.filter(booking => booking.runInstanceId == this.runInstanceId && booking.bookingInstanceId);
    },
    // this returns the total children on each register (ie run intance)
    totalNumberOfAttendeesPerClass (){
      return this.allChildrenInRunInstance().length;
     },

  },

  props: {
  	runInstance: {
  		type: Object,
  		required: true
  	},
    runInstanceId: {
      type: String,
      required: true
    },
    allBookingsArray: {
      type: Array,
      required: true
    }
  },

  components: {
    TakeAttendanceNavigationDrawer,
  },

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.runInstance {
  text-align: center;
  }

  h4 {

    margin: 0;
    color: #35495E;
  }

  a {
    text-decoration: none;

  }

/*.runInstances {*/
/*  transition: all .2s ease-in-out;*/
/*}*/

/*.runInstances:hover {*/
/*  transition: all .2s ease-in-out;*/
/*  transform: scale(1.05) perspective(1px)*/
/*}*/

.count {
  font-weight: bold;
}

.total {
   font-weight: lighter;
}




</style>
