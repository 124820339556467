import createRequest from './request';

/**
 * Used to GET HTTP resources
 * @param parameters
 */

async function get({ url, headers }) {
  const request = new Request(url, {
    method: 'GET',
    headers,
    credentials: 'include',
  });

  try {
    const response = await fetch(request);
    return await response.json();
  } catch (error) {
    console.error(error);
    throw new Error(`GET request Failed: ${error} on ${url}`);
  }
}

/**
 * Used to POST resources
 * @param url
 * @param headers
 * @param body
 * @param options
 * @returns {Promise<any>}
 */
async function post({ url, headers, body }, options = { recaptcha: false }) {
  const request = await createRequest({ url, headers, body }, options);
  const response = await fetch(request);
  try {
    return response.json();
  } catch (error) {
    console.error(error);
    return response;
  }
}

/**
 * Used to PUT resources
 * @param url
 * @param headers
 * @param body
 * @returns {Promise<any>}
 */
async function put({ url, headers, body }) {
  const request = new Request(url, {
    method: 'PUT',
    headers,
    body: JSON.stringify(body),
    credentials: 'include',
  });

  try {
    const response = await fetch(request);
    return await response.json();
  } catch (error) {
    console.error(error);
    throw new Error(`PUT request Failed: ${error} on ${url}`);
  }
}

/**
 * Used to DELETE resources
 * @param url
 * @param headers
 * @param body
 * @returns {Promise<any>}
 */
async function remove({ url, headers, body }) {
  const request = new Request(url, {
    method: 'DELETE',
    headers,
    body: JSON.stringify(body),
    credentials: 'include',
  });

  try {
    const response = await fetch(request);
    return await response.json();
  } catch (error) {
    console.error(error);
    throw new Error(`DELETE request Failed: ${error} on ${url}`);
  }
}

export {
  get, post, put, remove,
};
