<template>
  <v-container fluid v-if="!globalLoading">
    <v-row class="pa-3">
      <v-col cols="6" align="start"> <h1>Contacts</h1></v-col>
      <v-col cols="6" align="end" class="text-right">
        <Button @click="addAChild">
          <v-icon>fal fa-plus-circle</v-icon> Add new Contact
        </Button>
      </v-col>
    </v-row>
    <v-row dense no-gutters>
      <v-col cols="12">
        <v-card min-height="100vh" class="elevation-1 ma-3">
          <v-row dense no-gutters>
            <v-col cols="12">
              <ContactsDataTableHeader />
            </v-col>
          </v-row>
          <v-row dense no-gutters>
            <v-col cols="12">
              <ContactsDataTable />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <ContactsMessageModal />
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
import ContactsDataTable from '../../components/Contacts/ContactsDataTable.vue';

import ContactsDataTableHeader from '../../components/Contacts/ContactsDataTableHeader.vue';
import ContactsMessageModal from '../../components/Contacts/ContactsMessageModal.vue';

export default {
  name: 'ContactsPage',
  components: {
    ContactsDataTable,
    ContactsDataTableHeader,
    ContactsMessageModal,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['tableContacts', 'setupFeeSelectContacts', 'globalLoading']),
  },
  methods: {
    addAChild() {
      window.location.href = '/cms/contacts/add/choose-parent';
    },
  },
  beforeCreate() {},
  created() {},
  async beforeMount() {
    this.$store.commit('SET_BOTTOM_NAVIGATION', null);
    await this.$store.dispatch('GET_ALL_REGISTRATION_QUESTIONS');
  },
  async mounted() {
    const payload = {
      id: this.$route.params.id,
      type: this.$route.params.type,
    };
    await this.$store.dispatch('ALL_CONTACTS', payload);
  },
  updated() {},
};
</script>

<style scoped></style>
