<template>
    <v-list-item :href="viewOrderRoute">
        <v-list-item-content>
            <v-list-item-title>{{childName}}</v-list-item-title>

            <v-list-item-subtitle class="text--primary">
                {{ booking.className }}, {{booking.venueName}}
            </v-list-item-subtitle>

            <v-list-item-subtitle >
                <v-chip :color="chipColour" class="white--text" disabled small v-if="isRun || isCamp">{{isRun ? 'Term' : 'Camp'}}</v-chip>
                <v-chip :color="chipColour" :class="chipColourText" outlined disabled small v-if="sessionCount > 0" ><strong>{{sessionCount}} Session{{sessionCount > 1 ? 's' : ''}}</strong> &nbsp; {{startToEndDateSessions}}</v-chip>
            </v-list-item-subtitle >

        </v-list-item-content>

        <v-list-item-action>
            <v-list-item-action-text>{{ createdDateFormatted }}</v-list-item-action-text>
            <v-btn text icon color="UIAction" :href="viewOrderRoute">
                <v-icon> fas fa-chevron-right</v-icon>
            </v-btn>
        </v-list-item-action>

    </v-list-item>
</template>

<script>

import { mapGetters } from 'vuex';
import { formatShortDate, formatDate } from '&/helpers.js';

export default {
  name: 'ParentBookingLogListItem',
  props: {
    booking: {
      required: true,
      type: Object,
    },
  },
  computed: {
    ...mapGetters(['_subscription']),
    isRun() {
      return this.booking.runId;
    },
    isCamp() {
      return this.booking.campId;
    },
    parent() {
      return this._subscription.parent;
    },
    child() {
      if (this.parent && this.parent.children) {
        return this.parent.children[this.booking.childId];
      }
    },
    childName() {
      if (this.child) {
        return `${this.child.firstName} ${this.child.lastName}`;
      }
    },
    className() {
      return this.booking.className;
    },
    venueName() {
      return this.booking.venueName;
    },
    termName() {
      return this.booking.termName;
    },
    createdDateFormatted() {
      return formatDate(this.booking.created);
    },
    runBookingSessionCount() {
      return Object.values(this.booking.bookingInstances).length;
    },
    campBookingSessionCount() {
      let count = 0;

      Object.values(this.booking.instanceGroups).forEach((instanceGroup) => {
        count += Object.values(instanceGroup.bookingInstances).length;
      });

      return count;
    },
    sessionCount() {
      if (this.isCamp) {
        return this.campBookingSessionCount;
      } if (this.isRun) {
        return this.runBookingSessionCount;
      }
    },
    viewOrderRoute() {
      return `/cms/bookings/booking/${this.booking.bookingRef}`;
    },
    chipColour() {
      return this.isRun ? 'primary' : 'secondary';
    },

    chipColourText() {
      return this.isRun ? 'primary--text' : 'secondary--text';
    },

    runStartToEndSessions() {
      const bookingInstances = Object.values(this.booking.bookingInstances);
      const startDate = formatShortDate(bookingInstances[0].startDateTime);
      const endDate = formatShortDate(bookingInstances[bookingInstances.length - 1].startDateTime);
      return `${startDate} - ${endDate}`;
    },

    campStartToEndSessions() {
      const instanceGroups = Object.values(this.booking.instanceGroups);
      const start = Object.values(instanceGroups[0].bookingInstances)[0];
      const end = Object.values(instanceGroups[instanceGroups.length - 1].bookingInstances)[Object.values(instanceGroups[instanceGroups.length - 1].bookingInstances).length - 1];
      // const end = Object.values(instanceGroups[instanceGroups.length - 1]).bookingInstances[Object.values(instanceGroups[instanceGroups.length - 1]).length - 1];
      const startDate = formatShortDate(start.startDateTime);
      const endDate = formatShortDate(end.startDateTime);
      return `${startDate} - ${endDate}`;
    },

    startToEndDateSessions() {
      return this.isRun ? this.runStartToEndSessions : this.campStartToEndSessions;
    },

  },

  mounted() {

  },

};
</script>
