<template>

    <v-data-table
            item-key="recurringPaymentSubscriptionId"
            :items="subscriptions"
            :loading="loading"
            :headers="headers">
        <template v-slot:item="subscriptions">
            <CancelledSubscriptionsTableListItem
                    :subscription="subscriptions.item"
            />
        </template>
    </v-data-table>

</template>

<script>

import { mapGetters } from 'vuex';
import CancelledSubscriptionsTableListItem from './CancelledSubscriptionsTableListItem';

export default {
  name: 'CancelledSubscriptionsTable',
  data() {
    return {
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'parent.firstName',
        },
        {
          text: 'Cancelled',
          align: 'start',
          sortable: true,
          value: 'c4k.cancelled',
        },
        { text: 'Actions', value: 'plan', sortable: false },


      ],
    };
  },
  computed: {
    ...mapGetters(['cancelledSubscriptions', 'loading']),
    subscriptions() {
      return Object.values(this.cancelledSubscriptions);
    },
  },

  created() {
    this.$store.dispatch('GET_CANCELLED_SUBSCRIPTIONS', { localLoading: true });
  },
  components: {
    CancelledSubscriptionsTableListItem,
  },
};
</script>

<style scoped>

</style>
