<template>
  <v-row>
    <v-col cols="3" md="2" lg="1" class="text-center">
      <v-avatar :tile="false" :size="64" color="info">
        <img :src="avatarImage" alt="Avatar" />
      </v-avatar>
    </v-col>

    <v-col cols="9" md="10" lg="11">
      <v-alert
        :value="true"
        color="info"
        class="alert-with-avatar white--text"
        :class="{ success }"
        transition="scale-transition"
      >
        <strong>
          {{ message }}
        </strong>
      </v-alert>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {};
  },
  methods: {},
  computed: {
    ...mapGetters(['loading']),
    success() {
      return this.type === 'success';
    }
  },
  components: {},
  props: {
    message: {
      type: String,
      required: true
    },
    avatarImage: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: false,
      default: 'info'
    }
  }
};
</script>
<style>
.alert-with-avatar {
  border-radius: 5px;
  border-top: none;
  font-size: 0.9rem;
}

@media only screen and (min-width: 40.063em) {
  .alert-with-avatar {
    font-size: 1.1rem;
  }
}
</style>
