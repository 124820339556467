<script>
import { mapGetters } from 'vuex';
export default {
  name: 'FilteredContactsMixin',

  computed: {
    ...mapGetters([
      'tableContacts',
      'selectedContacts',
      'searchContacts',
      'setupFeeSelectContacts',
      'bookingStatusSelectContacts',
      'registrationQuestionsChildSelectContacts',
      'registrationQuestionsParentSelectContacts',
      'photoPermissionSelectValue',
    ]),
    contacts() {
      return this.tableContacts.contacts;
    },
    contactsFiltered() {
      return this.contacts
        .filter(this.setupFeeFilter)
        .filter(this.bookingStatusFilter)
        .filter(this.registrationQuestionsChildFilter)
        .filter(this.registrationQuestionsParentFilter)
        .filter(this.searchFilter)
        .filter(this.photoPermissionsFilter);
    },
    selected: {
      get() {
        return this.selectedContacts;
      },
      set(value) {
        value.length > 0
          ? this.$store.commit('SET_BOTTOM_NAVIGATION', 'ContactsPage')
          : this.$store.commit('SET_BOTTOM_NAVIGATION', null);
        this.$store.commit('SET_SELECTED_CONTACTS', value);
      },
    },
  },
  methods: {
    hasSearchTerm(value, searchTerm) {
      return value.toLowerCase().includes(searchTerm.toLowerCase());
    },
    searchFilter(item) {
      if (this.searchContacts.length === 0) {
        return true;
      }
      const searchTerms = this.searchContacts.split(' ');
      return searchTerms.some((searchTerm) => {
        if (searchTerm.length > 0) {
          const {
            childFirstName,
            childLastName,
            parentFirstName,
            parentLastName,
            username,
            mobile,
          } = item;

          return (
            this.hasSearchTerm(childFirstName, searchTerm) ||
            this.hasSearchTerm(childLastName, searchTerm) ||
            this.hasSearchTerm(parentFirstName, searchTerm) ||
            this.hasSearchTerm(parentLastName, searchTerm) ||
            this.hasSearchTerm(username, searchTerm) ||
            this.hasSearchTerm(mobile.replace(/\s+/g, ''), searchTerm)
          );
        }
      });
    },
    setupFeeFilter(item) {
      if (this.setupFeeSelectContacts === null) {
        return item;
      }
      return item.setupFeePaid === this.setupFeeSelectContacts;
    },
    bookingStatusFilter(item) {
      return (
        item[this.bookingStatusSelectContacts] === 'Yes' ||
        item[this.bookingStatusSelectContacts] === 'yes' ||
        this.bookingStatusSelectContacts === null ||
        (this.bookingStatusSelectContacts === 'notInProgramme' &&
          item.inProgramme === null)
      );
    },
    registrationQuestionsChildFilter(item) {
      return this.registrationQuestionsChildSelectContacts
        ? item.registrationQuestions.child[
            this.registrationQuestionsChildSelectContacts
          ]
        : true;
    },
    registrationQuestionsParentFilter(item) {
      return this.registrationQuestionsParentSelectContacts
        ? item.registrationQuestions.parent[
            this.registrationQuestionsParentSelectContacts
          ]
        : true;
    },

    photoPermissionsFilter(item) {
      return this.photoPermissionSelectValue === 'all'
        ? item
        : item.photoPermission === this.photoPermissionSelectValue;
    },
    toggleAll(event) {
      if (this.bookingStatusSelectContacts === null) {
        this.$store.commit('SET_MESSAGE_MODAL_DIALOG_CONTACTS', true);
        return this.$store.commit('SET_SELECTED_CONTACTS', []);
      }

      this.selected = event.value ? this.contactsFiltered : [];
    },
  },
};
</script>
