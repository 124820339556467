import Vue from 'vue';

export default {
  state: {
    bottomNavigationRoute: null,
  },
  actions: {},
  mutations: {
    SET_BOTTOM_NAVIGATION(state, payload) {
      Vue.set(state, 'bottomNavigationRoute', payload);
    },
  },
  getters: {
    bottomNavigationRouteGlobal(state) {
      return state.bottomNavigationRoute;
    },
  },
};
