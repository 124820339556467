<template>
    <span v-if="nextPaymentDate">
        <v-subheader>Upcoming Payment</v-subheader>
        <v-list-group
                no-action
                value="true"
        >
            <template v-slot:activator>
                <v-list-item>
                    <v-list-item-avatar>
                        <v-icon color="UIAction">fas fa-redo</v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                        <v-list-item-title color="UIAction"><strong>{{amountDueFormatted}}</strong></v-list-item-title>
                        <v-list-item-subtitle>
                            {{invoiceStatusText}}
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </template>
            <UpcomingSubscriptionInvoiceLineItems v-for="lineItem in invoiceLineItems" :line-item="lineItem" :key="lineItem.unique_id" />


            <v-list-item v-if="discount">
                <v-list-item-content>
                    <v-list-item-title>
                        {{couponValue}} Discount
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        {{couponDuration}}
                    </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                     <v-icon disabled>fal fa-times-circle</v-icon>
                </v-list-item-action>
            </v-list-item>

            <v-list-item v-if="!addingCoveringPayment">
                <v-btn small depressed block color="info" @click="addCoveringPaymentChecker" style="width:100%;"> Add Covering Payment</v-btn>
            </v-list-item>

            <v-list-item v-else>
                <v-list-item-content style="height: unset !important">
                    <v-text-field
                            v-model="add.amount"
                            :rules="[rules.required, rules.maxValue, rules.minValue, rules.maxCoveringPayments]"
                            :prefix="currencySymbol"
                            :loading="loading"
                            label="Add Covering Payment"
                            :disabled="loading"
                            autofocus
                            ref="coveringPaymentInput"
                            append-icon="fas fa-times-circle"
                            append-outer-icon="fas fa-check-circle"
                            @click:append="closeAddCoveringPayment"
                            @click:append-outer="addCoveringPaymentToNextInvoice"
                            style="width:100%;"
                    ></v-text-field>
                </v-list-item-content>

            </v-list-item>


        </v-list-group>
        <v-layout justify="center">
            <v-flex xs12>
                <v-dialog v-model="subscriptionPausedModal" persistent max-width="290">
                    <v-card>
                        <v-card-title class="headline">Subscription Paused</v-card-title>
                        <v-card-text>You can't add a covering payment while the subscription is paused.</v-card-text>
                        <v-card-actions>
                            <v-btn color="info" block small depressed @click="subscriptionPausedModal = false">OK</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-flex>
        </v-layout>
    </span>

</template>

<script>

  import { mapGetters } from 'vuex';
  import { formatMoney, formatDate } from '&/helpers.js';
  import ViewCoveringPaymentsMenuItem from './ViewCoveringPaymentsMenuItem'
  import CoveringPaymentsMixin from '../../mixins/Subscriptions/CoveringPaymentsMixin'
  import SubscriptionMixin from '../../mixins/Subscriptions/SubscriptionMixin'
  import UpcomingSubscriptionInvoiceLineItems from './CoveringPayments/UpcomingSubscriptionInvoiceLineItems'

  export default {
    name: 'UpcomingC4KSubscriptionPaymentListItem',
    mixins: [CoveringPaymentsMixin, SubscriptionMixin],
    data() {
      return {
        loading: false,
        addingCoveringPayment: false,
        error: false,
        subscriptionPausedModal: false
      }
    },
    props: {

    },
    computed: {
      ...mapGetters(['_subscription', 'clientId', 'currentSubscription', 'allSubscriptions', 'stripeSubscriptionStates']),
      subscription () {
        return this._subscription
      },
      stripe() {
        return this.subscription.stripe
      },
      invoice() {
        return this.subscription.invoices.upcoming;
      },
      invoiceLineItems() {
        const lineItems = this.invoice.lines.data;
        // subscription invoice items and other invoice items sort differently
        // removing it and adding it back later so it can be at the top
        const subscriptionLineItemIndex = lineItems.findIndex( item => {return item.type==='subscription'});
        //splice returns an array of the item
        const subscriptionLineItem = lineItems.splice(subscriptionLineItemIndex, 1)[0];

        lineItems.sort( (itemA, itemB) => {
          return itemA.period.start - itemB.period.start;
        });
        lineItems.unshift(subscriptionLineItem);
        return lineItems;




      },
      amountDue() {
        return this.invoice.amount_due
      },
      amountDueFormatted() {
        return formatMoney(this.amountDue)
      },
      nextPaymentDate() {
        return this.invoice.next_payment_attempt;
      },
      nextPaymentDateFormatted() {
        return formatDate(this.nextPaymentDate)
      },

      invoiceStatusText() {
        return `Payment scheduled for ${this.nextPaymentDateFormatted}`;
      },
      discount() {
        return this.invoice.discount;
      },

      couponValue() {
        if(this.discount.coupon.percent_off) {
          return `${this.discount.coupon.percent_off}% off`
        } else {
          return `${getCurrencySymbol()}${this.discount.coupon.amount_off} off`
        }
      },
      couponDuration() {
        return this.discount.coupon.duration === 'forever' ? 'Until Collection Resumes' : this.discount.coupon.duration;
      }


    },

    methods: {
      async addCoveringPaymentToNextInvoice() {

        if(!this.$refs.coveringPaymentInput.hasError) {
            this.loading = true;
            await this.addCoveringPayment();
            this.add.amount = 1.00;
            this.addingCoveringPayment = false;
            this.loading = false;
        }
      },
      closeAddCoveringPayment() {
        this.addingCoveringPayment = false
      },
      addCoveringPaymentChecker() {
        if(this.isPaused){
            this.subscriptionPausedModal = true;
        }else{
            this.addingCoveringPayment = true;
        }
      }
    },

    components: {
      ViewCoveringPaymentsMenuItem,
      UpcomingSubscriptionInvoiceLineItems,
    },

  }
</script>

<style scoped>

</style>