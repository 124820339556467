<template>
    <div>
        <div v-if="loading" class="text-center">
            <v-icon >fas fa-circle-notch fa-spin</v-icon>
        </div>

        <div v-else class="">
            <v-chip small class="ma-2" v-if="subscriptionIsPaused(subscription.stripe)" style="font-weight: 600">
                <v-avatar left>
                    <v-icon left>fad fa-pause-circle</v-icon>
                </v-avatar>
                Paused
            </v-chip>
            <v-chip small class="ma-2" v-else color="info" style="font-weight: 600">
                <v-avatar left>
                    <v-icon left>fad fa-play-circle</v-icon>
                </v-avatar>
                Active
            </v-chip>
        </div>
    </div>
</template>

<script>


import { mapGetters } from 'vuex';
import SubscriptionMixin from '../../../../mixins/Subscriptions/SubscriptionMixin';

export default {
  name: 'Collection',
  mixins: [SubscriptionMixin],
  data() {
    return {
    };
  },
  props: {
    subscription: {
      required: true,
      type: Object,
    },
  },
  computed: {
    ...mapGetters(['loading']),

  },
  methods: {
  },
  components: {
  },
};
</script>

<style>
    .v-application--is-ltr .v-chip .v-avatar--left, .v-application--is-ltr .v-chip .v-icon--left {
        margin-right: 0;
    }
</style>
