<template>
    <tr>
        <td class="text-center">
            <label :for="id">
                <input :id="id" type="checkbox" v-model="selected" :value="bookingWithRun" :disabled="preview">
            </label>
        </td>
        <td>{{fullName}}</td>
        <td>{{booking.dob}}</td>
    </tr>
</template>

<script>
  import { mapGetters } from 'vuex';
  export default {
    name: 'BookingListItem',
    props: {
      run: {
        type: Object,
        required: true
      },
      booking: {
        required: true,
        type: Object
      }
    },
    computed: {
      ...mapGetters(['preview']),
      id() {
        return `bookings${this.booking.runId}-${this.booking.personId}`
      },
      fullName() {
        return `${this.booking.firstName} ${this.booking.lastName}`
      },
      selected: {
        get() {
          return this.$store.getters.selected
        },
        set(value) {
          this.$store.commit('SET_SELECTED', value);
        }
      },
      bookingWithRun() {
        return {...this.booking, ...{runId:this.run.runId} }
      }
    }
  }
</script>

<style scoped>

</style>