<template>
  <v-list two-line dense>
    <PhotoPermissionAvailableBanner />
    <ParentResourceListItem
      v-for="(resource, index) in parentResources"
      :key="resource.key"
      :resource="resource"
      :index="index"
    />
  </v-list>
</template>

<script>
import { mapGetters } from 'vuex';
import ParentResourceListItem from './ParentResourceListItem.vue';
import PhotoPermissionAvailableBanner from './PhotoPermissionAvailableBanner';
export default {
  name: 'ParentResourceList',
  methods: {
    getCurrentUrlTld() {
      return window.location.href.match(/\.\w{2,5}\b/g).join('');
    },
  },
  computed: {
    ...mapGetters(['resources', 'loginData']),
    parentResources() {
      return this.resources.parent;
    },
  },
  components: {
    ParentResourceListItem,
    PhotoPermissionAvailableBanner,
  },
  updated() {},
};
</script>

<style scoped>
.v-list {
  background: transparent;
}
</style>
