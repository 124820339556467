<template>

  <v-expansion-panel>
    <v-expansion-panel-header>
      {{runDetails}}
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-data-table
          v-model="selected"
          :headers="headers"
          :items="bookingsFormatted"
          item-key="personId"
          show-select
      >
      </v-data-table>
    </v-expansion-panel-content>
  </v-expansion-panel>

</template>
<script>
import BookingsList from './BookingsList';
import { formatDate } from '../../../../../../utilities/helpers';

export default {
  name: 'RunListItem',
  data() {
    return {
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'fullName',
        },
        {
          text: 'DOB',
          align: 'start',
          sortable: true,
          value: 'dob',
        },

        {
          text: 'Booked',
          align: 'start',
          sortable: true,
          value: 'booked',
        },

      ],
      items: [],
    };
  },
  props: {
    run: {
      required: true,
      type: Object,
    },
  },
  computed: {
    bookings() {
      return this.run.bookings;
    },
    bookingsFormatted() {
      return Object.values(this.bookings)
        .map(this.bookingsFormatter);
    },
    uniqueKey() {
      return `panel${this.run.runId}`;
    },
    runId() {
      return this.run.runId;
    },
    uniqueKeyHref() {
      return `#${this.uniqueKey}`;
    },
    className() {
      return this.run.class.name;
    },
    venueName() {
      return this.run.venue.name;
    },
    venueCity() {
      return this.run.venue.city;
    },
    bookingCount() {
      return this.run.bookingCount;
    },
    trialCount() {
      return this.run.trialCount;
    },
    runDetails() {
      return `${this.className} - ${this.venueName}, ${this.venueCity} - (${this.bookingCount} kids) (${this.trialCount} trials)`;
    },

    selected: {
      get() {
        return this.$store.getters.selected;
      },
      set(value) {
        this.$store.commit('SET_SELECTED', value);
      },
    },
  },

  methods: {
    bookingsFormatter(booking) {
      return {
        fullName: `${booking.firstName} ${booking.lastName}`,
        booked: formatDate(booking.created),
        ...booking,
      };
    },
  },
  components: {
    BookingsList,
  },
};
</script>

<style scoped>

</style>
