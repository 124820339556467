// Subscriptions v2
import ViewSubscriptionsPage from '../pages/Subscriptions/ViewSubscriptionsPage';
import ManageSubscriptionPage from '../pages/Subscriptions/ManageSubscriptionPage';

export default [
  {
    component: ViewSubscriptionsPage,
    path: '/cms/subscriptions',
    name: 'ViewSubscriptionsPage',
    meta: {
      title: 'View Subscriptions',
    },
  },

  {
    component: ManageSubscriptionPage,
    path: '/cms/subscription/:personId/manage',
    name: 'ManageSubscriptionPage',
    meta: {
      title: 'Manage Subscription',
    },
  },

];
