import minCharacterRule from './input';

export const minCharactersInPassword = 8;

export const passwordRequiredMessage = 'Password is required';

export function passwordValueRequiredRule(value) {
  return !!value || passwordRequiredMessage;
}

export function passwordValidRule(value) {
  return minCharacterRule(minCharactersInPassword, value) || `Password must be greater than ${minCharactersInPassword} characters`;
}
