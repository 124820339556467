<template>


    <div>


        <v-row no-gutters class="">
            <v-col align="center">
                <Logo/>
            </v-col>
        </v-row>

        <v-row class="pb-4">
            <v-col align="center">
                <RegionSelectorDropdownMenu />
            </v-col>
        </v-row>

        <v-row class="">
            <v-col align="center">
                <h5 class="headline">
                    <Dictionary module="login" string="title"/>
                </h5>
            </v-col>
        </v-row>

        <LoginForm/>
    </div>


</template>


<script>


import Vue from 'vue';
import { mapGetters } from 'vuex';
import LoginForm from '../../components/Login/LoginForm.vue';
import Dictionary from '../../components/Dictionary.vue';


export default Vue.extend({
  name: 'LoginPage',
  methods: {},
  computed: {
    ...mapGetters(['loginData']),
  },
  created() {
  },
  components: {
    LoginForm,
    Dictionary,
  },
});
</script>


<style scoped>

    .login-form-wrapper {
        max-width: 424px !important;
    }

    .login-logo {
        max-width: 424px !important;
    }

</style>
