import { post, put } from '../../http';

const CREATE_INSTANCE_GROUP_URL = '/api/v4/camps/instance-group';
const UPDATE_INSTANCE_GROUP_URL = '/api/v4/camps/instance-group/update';

const CREATE_RUN_INSTANCE_URL = '/api/v4/camps/edit/run-instance';
const UPDATE_RUN_INSTANCE_URL = '/api/v4/camps/edit/run-instance';
const ADD_RUN_INSTANCE_TYPE = '/api/v4/camps/edit/run-instance/type';


const headers = new Headers({
  'Content-Type': 'application/json',
});

export function createRunInstance({
  startDateTime,
  dayOfTheWeek,
  instanceGroupId,
  campId,
  active,
}) {
  const body = {
    startDateTime,
    dayOfTheWeek,
    instanceGroupId,
    campId,
    active,
  };
  return post({ url: CREATE_RUN_INSTANCE_URL, headers, body });
}


export function updateRunInstance({
  runInstanceId,
  startDateTime,
  dayOfWeek,
  instanceGroupId,
  endDateTime,
  campId,
  active,
  childOf,
  type,
}) {
  const body = {
    startDateTime,
    endDateTime,
    dayOfWeek,
    instanceGroupId,
    campId,
    runInstanceId,
    active,
    childOf,
    type,
  };
  return put({ url: `${UPDATE_RUN_INSTANCE_URL}/${runInstanceId}`, headers, body });
}




export function createInstanceGroup({ name, campId }) {
  const body = { name, campId };
  return post({ url: CREATE_INSTANCE_GROUP_URL, headers, body });
}

export function updateInstanceGroup({ runInstanceIds, instanceGroupId }) {
  const body = { runInstanceIds, instanceGroupId };
  return post({ url: UPDATE_INSTANCE_GROUP_URL, headers, body });
}
export function addRunInstanceType(payload) {
  const body = payload;
  return post({ url: ADD_RUN_INSTANCE_TYPE, headers, body });
}


