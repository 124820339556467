import minCharacterRule, { maxCharacterRule } from './input';

const codeLength = 6;

const codeRequiredMessage = 'Enter 6 digit code';

export function codeRequiredRule(value) {
  return !!value || codeRequiredMessage;
}

export function codeValidRule(value) {
  return (minCharacterRule(codeLength, value) && maxCharacterRule(codeLength + 1, value)) || `'Enter ${codeLength} digit code'`;
}
